import sagas from './sagas';
import { AuthRoute } from "../auth/components";
import React, { lazy } from "react";

const SubmissionNotePage = lazy(() => import('./pages/SubmissionNotePage'));
const DiaryNotePage = lazy(() => import('./pages/DiaryNotePage'));
const NotesList = lazy(() => import('./pages/NotesList'));

const notesModule = {
  routes: [
    <AuthRoute key="submission-notes" exact path="/notes/submission/:journeyId" component={SubmissionNotePage} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="diary-notes-create" exact path="/notes/diary" component={DiaryNotePage} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="diary-notes-edit" exact path="/notes/diary/:noteId" component={DiaryNotePage} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="notes-list-diary" exact path="/notes/list/diary" component={NotesList} headerType={'private'} isSpecialistOnly />,
  ],
  sagas,
};

export default notesModule;
