import React, { useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PublishIcon from '@mui/icons-material/Publish';
import {Grid, IconButton} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import styles from './styles.module.scss';
import { USER_ROLE } from '../../../../utils/authorisation';


const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px 20px 10px',
  border: 'none',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  backgroundColor: '#fafafc',
  color: '#8B90A0',
  outline: 'none',
  transition: 'background .24s ease-in-out',
  cursor: 'pointer'
};

const activeStyle = {
  backgroundColor: '#fafafc'
};

const acceptStyle = {
  backgroundColor: '#2597E3'
};

const rejectStyle = {
  backgroundColor: '#2597E3'
};

const emptyStyle = {
  padding: '20px',
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10
};

const disabledStyle = {
  cursor: 'not-allowed'
}

const DocumentDropzone = ({ files, setFiles, disabled, userRole, singleFile = false }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    accept: `image/jpg, image/jpeg, image/png, application/pdf, image/bmp, image/gif, application/doc, application/docx, text/txt, application/rtf, .doc, .docx, .txt, .rtf${
      (!!userRole && userRole !== USER_ROLE.Customer) ? ', .xlsx, .xlsm, .xlsb, .xltx, .xltm, .xls, .xlt, .xls, .xml, .xlam, .xla, .xlw, .xlr, .csv' : ''
    }`,
    multiple: !singleFile
  });

  useEffect(() => {
    if (acceptedFiles.length) {
      if (singleFile) setFiles([...acceptedFiles]);
      else setFiles([...files, ...acceptedFiles]);
    }
  }, [acceptedFiles]); //eslint-disable-line

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
    ...(!files?.length ? emptyStyle : {}),
    ...(disabled ? disabledStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
    files,
    disabled
  ]);

  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);
  };

  const renderFiles = () => {
    return files.map((file, idx) => {
      return (
        <Grid container justifyContent="space-between" alignItems="center" className={styles.fileLine} key={idx}>
          <Grid item className={styles.fileName}>{file.path}</Grid>
          <Grid item>
            <IconButton aria-label="remove" className={styles.removeButton} onClick={() => removeFile(file)}>
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} disabled={disabled} />
        <p className={`${styles.dropzoneText} ${disabled ? styles.disabled : ''}`}>
          <PublishIcon /> Drag and drop file{singleFile ? '' : 's'} here
        </p>
        <p className={`${styles.dropzoneTextClick} ${disabled ? styles.disabled : ''}`}>
          or click here to browse local file{singleFile ? '' : 's'}
        </p>
      </div>
      <aside className={styles.filesContainer}>
        {renderFiles()}
      </aside>

    </div>
  );
};

export default DocumentDropzone;
