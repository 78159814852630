import React, {useState, useEffect, useRef} from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Divider,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu
} from '@mui/material';

import styles from './styles.module.scss';
import './styles.global.scss';

import { withDimensions } from '../../../common/HOC';
import { getAllowedJourneysWithDebounce, getJourney } from '../../../journeys/sagas';
import history from '../../../../utils/history';
import HeaderedPageLayout from '../../../common/components/HeaderedPageLayout';
import { displayErrorMessage } from '../../../../utils/displayErrorMessage';
import { clearTimeTrackingInLocalStorage } from '../../../../utils/helpers';
import { getUserRole, isJustDataOperations, USER_ROLE } from '../../../../utils/authorisation';
import {
  createCase,
  createCaseTimeLog,
  deleteCaseTimeLog,
  getAndUpdateEditors,
  getCase,
  getCaseTimeLogs,
  getCaseTypes,
  updateCase, updateCaseTimeLog
} from '../../sagas';
import { getAllowedUsersWithDebounce, getSpecialists } from '../../../user/sagas';
import ValidationErrorLabel from '../../../common/components/ValidationErrorLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import auLocale from 'date-fns/locale/en-AU';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/lab';
import AttachFilesModal from '../../../documents/components/AttachFilesModal';
import {
  createDocument,
  deleteDocuments,
  getDocuments,
  getDocumentsByIds,
  getDocumentTypes,
  updateDocument,
  uploadDocumentFiles,
  uploadDocumentOriginalFiles
} from '../../../documents/sagas';
import { differenceInMinutes, format } from 'date-fns';
import ErrorIcon from '@mui/icons-material/Error';
import ConfirmDeletionModal from '../../../common/components/ConfirmDeletionModal';
import { deepClone } from '../../../../utils/deepClone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import queryString from 'query-string';
//import { displayNotification } from '../../../../utils/snackbarUtils';
import TinyMCEditor from '../../../common/components/TinyMCEditor';
import ValidatedTextInput from '../../../common/components/ValidatedTextInput';
import ProvideElapsedTimeModal from '../../../common/components/ProvideElapsedTimeModal';


const OTHER = 'Other';
const ACTIONS_STATUSES = [
  'IN PROGRESS',
  'COMPLETED',
  'ON HOLD'
];
const ACTIONS_STATUSES_CREATION = [
  'IN PROGRESS',
  'ON HOLD'
];
const HISTORY_FILTERS = [
  'COMMENTS',
  'DOCUMENTS',
  'ALL'
];
const MAX_NOTES_SIZE = 2 * 1024 * 1024;

let intervalId;
let timerCheckIntervalId;
let checkBoxUpdateTimeoutId;

const CaseForm = ({ location }) => {
  const dimensions = useSelector(state => state.dimensions);
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { journeyId, source } = queryString.parse(location.search);

  const { caseId } = useParams();
  const [nextDisabled, setNextDisabled] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [actionType, setActionType] = useState('');
  const [actionTypeOptions, setActionTypeOptions] = useState([]);
  const [customActionTypeOptions, setCustomActionTypeOptions] = useState([]);
  const [journeyOwner, setJourneyOwner] = useState(undefined);
  const [journeyOwnerOptions, setJourneyOwnerOptions] = useState([]);
  const [journeySearchString, setJourneySearchString] = useState('');
  const [journey, setJourney] = useState(undefined);
  const [journeyOptions, setJourneyOptions] = useState([]);
  const [actionStatus, setActionStatus] = useState('IN PROGRESS');
  const [userRole, setUserRole] = useState(undefined);
  const [effectiveUser, setEffectiveUser] = useState(undefined);
  const [currentActionState, setCurrentActionState] = useState(undefined);
  const [isUpdatingJourneys, setIsUpdatingJourneys] = useState(false);
  const [reason, setReason] = useState('');
  const [remindTime, setRemindTime] = useState(null);
  const [isWaitingOnHoldParameters, setIsWaitingOnHoldParameters] = useState(false);

  const [newHistory, setNewHistory] = useState([]);
  const [oldHistory, setOldHistory] = useState([]);
  const [assignedTo, setAssignedTo] = useState(null);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [reviewedJourneyDocuments, setReviewedJourneyDocuments] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentEditors, setCurrentEditors] = useState([]);

  const [notesHtml, setNotesHtml] = useState('');
  const [shouldUpdateTinyEditor, setShouldUpdateTinyEditor] = useState(false);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [notesBeforeEditing, setNotesBeforeEditing] = useState('');

  const [commentHtml, setCommentHtml] = useState('');
  const [shouldUpdateCommentEditor, setShouldUpdateCommentEditor] = useState(false);
  const [isEditingComment, setIsEditingComment] = useState(false);

  const [description, setDescription] = useState('');
  const [checklist, setChecklist] = useState([]);
  const [groupedChecklist, setGroupedChecklist] = useState([]);
  const [timeLogs, setTimeLogs] = useState([]);
  const [aggregatedTimeLogs, setAggregatedTimeLogs] = useState([]);
  const [isLogsLoading, setIsLogsLoading] = useState(true);
  const [historyFilter, setHistoryFilter] = useState('COMMENTS');

  const [touchedInputs, setTouchedInputs] = useState(-1);
  const [createTimeLogOpen, setCreateTimeLogOpen] = useState(false);
  const [createTimeLogParameters, setCreateTimeLogParameters] = useState({});
  const [currentProgress, setCurrentProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [openAttachFiles, setOpenAttachFiles] = useState(false);
  const [documentTypes, setDocumentTypes] = useState(undefined);
  const [documentToView, setDocumentToView] = useState(undefined);
  const [purpose, setPurpose] = useState('create');//create|view
  const [limitationMode, setLimitationMode] = useState('actionDocument');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmationParameters, setDeleteConfirmationParameters] = useState({});
  const [actionMenuPosition, setActionMenuPosition] = useState(null);
  const [actionLogId, setActionLogId] = useState(null);
  const [activeTimeTracker, setActiveTimeTracker] = useState(undefined);
  const [userTracking, setUserTracking] = useState(undefined);
  const [prevActiveTimeTracker, setPrevActiveTimeTracker] = useState(undefined);

  const currentActionStateRef = useRef(undefined);
  const assigneeOptionsRef = useRef([]);

  useEffect(() => {
    dispatch(getCaseTypes({
      onSuccess: (data) => {
        setActionTypeOptions((data ?? []).filter((type) => !type.custom && ![
          'background_report',
          'backchannel_unmapped',
          'backchannel_info',
          'journey_withdrawn'
        ].includes(type.uid)));
        setCustomActionTypeOptions((data ?? []).filter((type) => !!type.custom));
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to retrieve action types');
      }
    }));

    dispatch(getSpecialists({
      onSuccess: (data) => {
        setAssigneeOptions(data.elements ?? []);
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to retrieve specialists');
      }
    }));

    dispatch(getDocumentTypes({
      onSuccess: (data) => {
        setDocumentTypes(data);
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to get document types');
      }
    }));

    checkCurrentEditors();

    setActiveTimeTracker(localStorage.getItem('timeTrackingAction') ?? undefined);
    setUserTracking(localStorage.getItem('timeTrackingUser') ?? undefined);

    intervalId = setInterval(() => {
      setCurrentTime(new Date());
      if (!!caseId) {
        checkAndUpdateAction();
        checkCurrentEditors();
      }
    }, 20000);

    timerCheckIntervalId = setInterval(() => {
      setActiveTimeTracker(localStorage.getItem('timeTrackingAction') ?? undefined);
      setUserTracking(localStorage.getItem('timeTrackingUser') ?? undefined);
    }, 400);

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (timerCheckIntervalId) clearInterval(timerCheckIntervalId);
    };
  }, []);//eslint-disable-line

  useEffect(() => {
    if ((!!activeTimeTracker || !!prevActiveTimeTracker) && activeTimeTracker !== prevActiveTimeTracker) {
      setTimeout(() => {
        setIsLogsLoading(true);
        if (caseId) {
          dispatch(getCaseTimeLogs({
            caseId,
            onSuccess: (data) => {
              setTimeLogs(data);
              setIsLogsLoading(false);
            },
            onError: (data) => {
              displayErrorMessage(data, 'Unable to retrieve action\'s time logs');
              setIsLogsLoading(false);
            }
          }));
        }
      }, 2000);
    }

    setPrevActiveTimeTracker(activeTimeTracker);
  }, [activeTimeTracker, prevActiveTimeTracker]);//eslint-disable-line

  useEffect(() => {
    setIsWaitingOnHoldParameters(actionStatus === 'ON HOLD' && (!reason || !remindTime));
  }, [reason, remindTime, actionStatus]); //eslint-disable-line

  useEffect(() => {
    if (isJustDataOperations(user)) history.push('/deals-management');

    setUserRole(getUserRole(user));
    setEffectiveUser(user);
  }, [user]);//eslint-disable-line


  useEffect(() => {
    if (assigneeOptions?.length) {
      assigneeOptionsRef.current = assigneeOptions;
      if (caseId) {
        dispatch(getCase({
          id: caseId,
          onSuccess: (actionData) => {
            setCurrentActionState(actionData);
            setAssignedTo(assigneeOptions.find((option) => option.id === actionData.assignee?.id) ?? null);
            setActionStatus(actionData.caseStatus);
            setOldHistory(actionData.history ?? []);
            setNewHistory([]);
            setJourney(actionData?.journey);
            setJourneyOwner(actionData?.journey?.user);
            if (actionData.typeObj?.custom) setDescription(actionData?.description ?? '');
            if (actionData.notes) {
              setNotesHtml(actionData.notes);
              setShouldUpdateTinyEditor(true);
            } else {
              setShouldUpdateTinyEditor(false);
            }

            if (!actionData.checklist?.length) {
              setChecklist([]);
              setGroupedChecklist([]);
            } else {
              const formattedChecklist = actionData.checklist.map((item) => {
                const itemTypeObject = actionData.typeObj?.checklist.find((it) => it.uid === item.uid);
                return {...item, title: itemTypeObject?.title, section: itemTypeObject?.section};
              });

              let newGroupedCheckList = [];
              let otherItems = {section: OTHER, items: []};
              formattedChecklist.forEach((it) => {
                const { section, ...rest } = it;
                const sectionTitle = !!(section?.trim()) ? section : OTHER;

                if (sectionTitle === OTHER) {
                  otherItems.items.push(rest);
                } else {
                  const sectionIndex = newGroupedCheckList.findIndex((group) => group.section === section);
                  if (sectionIndex < 0) {
                    newGroupedCheckList.push({section, items: [rest]});
                  } else {
                    newGroupedCheckList[sectionIndex].items.push(rest);
                  }
                }

              });
              if (otherItems.items.length) newGroupedCheckList.push(otherItems);

              setGroupedChecklist(newGroupedCheckList);
              setChecklist(formattedChecklist);
            }

            setIsLogsLoading(true);
            dispatch(getCaseTimeLogs({
              caseId,
              onSuccess: (timeLogsData) => {
                setTimeLogs(timeLogsData);
                setIsLogsLoading(false);

                if (!!actionData.journey) {
                  dispatch(getDocuments({
                    journeyId: actionData.journey.id,
                    statuses: ['Reviewed'],
                    onSuccess: (documentsData) => {
                      setReviewedJourneyDocuments(documentsData?.elements ?? []);
                    },
                    onError: (data) => {
                      displayErrorMessage(data, 'Unable to retrieve journey\'s documents');
                    }
                  }));
                } else {
                  const ids = (actionData.history ?? []).map((it) => it?.meta?.document).filter(Boolean);

                  if (!!ids.length) {
                    dispatch(getDocumentsByIds({
                      documentIds: ids,
                      onSuccess: (documentsData) => {
                        setReviewedJourneyDocuments(documentsData ?? []);
                        setInProgress(false);
                      },
                      onError: (data) => {
                        displayErrorMessage(data, 'Unable to retrieve documents');
                      }
                    }));
                  } else {
                    setInProgress(false);
                  }
                }
              },
              onError: (data) => {
                displayErrorMessage(data, 'Unable to retrieve action\'s time logs');
                setIsLogsLoading(false);
              }
            }));
          },
          onError: (data) => {
            displayErrorMessage(data, 'Unable to retrieve action');
          }
        }));
      } else {
        setCurrentActionState(undefined);
      }
    }
  }, [caseId, assigneeOptions]);//eslint-disable-line

  useEffect(() => {
    if (journeyId) {
      dispatch(getJourney({
        id: journeyId,
        onSuccess: (data) => {
          setJourney(data);
          setJourneyOwner(data.user)
        },
        onError: (data) => {
          displayErrorMessage(data, 'Unable to retrieve the journey');
        }
      }));
    }
  }, [journeyId]);//eslint-disable-line

  useEffect(() => {
    dispatch(getAllowedJourneysWithDebounce({
      limit: 100,
      userId: journeyOwner?._id,
      search: !!journeySearchString ? journeySearchString : undefined,
      onSuccess: (data) => {
        setJourneyOptions(data?.elements ?? []);
        setIsUpdatingJourneys(false);
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to retrieve the journeys number');
        setIsUpdatingJourneys(false);
      }
    }))
  }, [journeyOwner, journeySearchString]);//eslint-disable-line

  useEffect(() => {
    if (userRole === USER_ROLE.Customer) history.push(`/${source ?? 'dashboard'}${!!journeyId ? `/${journeyId}` : ''}`);
  }, [userRole]); //eslint-disable-line

  useEffect(() => {
    currentActionStateRef.current = currentActionState;
    if (!!currentActionState) {
      setActionStatus(currentActionState?.caseStatus);
      if (currentActionState?.remindTime) setRemindTime(new Date(currentActionState.remindTime));
      else setRemindTime(new Date());
    }
  }, [currentActionState]); //eslint-disable-line

  useEffect(() => {
    setNextDisabled(
      (!actionType && !currentActionState)
      || !actionStatus
      || (
        actionStatus === 'ON HOLD'
        && (!reason && (currentActionState?.caseStatus !== 'ON HOLD' || (!remindTime || (!!currentActionState?.remindTime && Math.abs((new Date(currentActionState.remindTime)).getTime() - remindTime.getTime()) > 60000))))
      )
      || (actionStatus === 'COMPLETED' && (!assignedTo || !!checklist.find((it) => !it.isDone)))
      || (!journeyOwner && !((!!actionType?.custom || !!currentActionState?.typeObj?.custom) && !actionType?.customerRequired && !currentActionState?.typeObj?.customerRequired ))
      || (!journey && !(!!actionType?.custom || !!currentActionState?.typeObj?.custom || !!actionType?.customerRequired || !!currentActionState?.typeObj?.customerRequired ))
      || (notesHtml ?? '').length > MAX_NOTES_SIZE
    );
  }, [actionType, actionStatus, currentActionState, reason, remindTime, journeyOwner, journey, assignedTo, checklist]); //eslint-disable-line

  useEffect(() => {
    let aggregated = [];
    timeLogs.forEach((log) => {
      const idx = aggregated.findIndex((a) => a.user === log?.user);
      if (idx < 0) {
        let newItem = deepClone(log);
        const userObj = assigneeOptions.find((ao) => ao.id === log?.user);
        newItem.userName = !!userObj ? `${userObj.name} ${userObj.surname}` : 'undefined';
        newItem.lastTimerDate = log?.createdAt;
        newItem.lastEntry = log?.minutes;
        newItem.totalTime = log?.minutes;
        newItem.lastEntryId = log?.id;
        const { createdAt, updatedAt, minutes, id, ...cleanedNewItem } = newItem;
        aggregated.push(cleanedNewItem);
      } else {
        let currentItem = deepClone(aggregated[idx]);
        const isNewer = (`${log?.createdAt}`).localeCompare(currentItem.lastTimerDate) > 0;
        currentItem.lastTimerDate = isNewer ? log?.createdAt : currentItem.lastTimerDate;
        currentItem.lastEntry = isNewer ? log?.minutes : currentItem.lastEntry;
        currentItem.totalTime = currentItem.totalTime + log?.minutes;
        currentItem.lastEntryId = isNewer ? log?.id : currentItem.lastEntryId;
        aggregated[idx] = currentItem;
      }
      const sortedAggregated = (aggregated ?? [])?.sort((a, b) => (`${b.lastTimerDate}`).localeCompare(a.lastTimerDate));
      setAggregatedTimeLogs(sortedAggregated);
    });
  }, [timeLogs]); //eslint-disable-line


  const checkCurrentEditors = () => {
    if (caseId) {
      dispatch(getAndUpdateEditors({
        id: caseId,
        onSuccess: (data) => {
          setCurrentEditors(data);
        },
        onError: () => {
          setCurrentEditors([]);
        }
      }));
    }
  };

  const updateActionState = (data, updatedByOther = false) => {
    if (!data) {
      displayErrorMessage(null, 'This action has been deleted by other user');
      setTimeout(() => {
        history.push(`/${source ?? 'dashboard'}${!!journeyId ? `/${journeyId}` : ''}`);
      }, 3000);
    } else if (currentActionStateRef.current?.updatedAt !== data.updatedAt) {
      setNewHistory([]);
      setAssignedTo((assigneeOptionsRef.current ?? []).find((option) => option.id === data.assignee?.id) ?? null);
      setActionStatus(data.caseStatus);
      setOldHistory(data.history ?? []);
      if (data.typeObj?.custom) setDescription(data?.description ?? '');
      if (data.notes && currentActionState.notes !== data.notes) {
        setNotesHtml(data.notes);
        setShouldUpdateTinyEditor(true);
      } else {
        setShouldUpdateTinyEditor(false);
      }
      setCurrentActionState(data);

      if (!data.checklist?.length) {
        setChecklist([]);
      } else {
        const formattedChecklist = data.checklist.map((item) => {
          const itemTypeObject = data.typeObj?.checklist.find((it) => it.uid === item.uid);
          return {...item, title: itemTypeObject?.title};
        });

        setChecklist(formattedChecklist);
      }

      setIsLogsLoading(true);
      dispatch(getCaseTimeLogs({
        caseId,
        onSuccess: (timeLogsData) => {
          setTimeLogs(timeLogsData);
          setIsLogsLoading(false);

          if (!!data.journey) {
            dispatch(getDocuments({
              journeyId: data.journey.id,
              statuses: ['Reviewed'],
              onSuccess: (documentsData) => {
                setReviewedJourneyDocuments(documentsData?.elements ?? []);
                //if (!!updatedByOther) displayNotification.success(`The action has been updated${updatedByOther ? ' by other user' : ''}`);
                setInProgress(false);
              },
              onError: (data) => {
                displayErrorMessage(data, 'Unable to retrieve journey\'s documents');
              }
            }));
          } else {
            const ids = (data.history ?? []).map((it) => it?.meta?.document).filter(Boolean);

            if (!!ids.length) {
              dispatch(getDocumentsByIds({
                documentIds: ids,
                onSuccess: (documentsData) => {
                  setReviewedJourneyDocuments(documentsData ?? []);
                  //if (!!updatedByOther) displayNotification.success(`The action has been updated${updatedByOther ? ' by other user' : ''}`);
                  setInProgress(false);
                },
                onError: (data) => {
                  displayErrorMessage(data, 'Unable to retrieve documents');
                }
              }));
            } else {
              setInProgress(false);
            }

          }
        },
        onError: (data) => {
          displayErrorMessage(data, 'Unable to retrieve action\'s time logs');
          setIsLogsLoading(false);
        }
      }));
    }
  };

  const checkAndUpdateAction = () => {
    dispatch(getCase({
      id: caseId,
      notExtended: true,
      onSuccess: (data) => {
        if (!data) {
          displayErrorMessage(null, 'This action has been deleted by other user');
          setTimeout(() => {
            history.push(`/${source ?? 'dashboard'}${!!journeyId ? `/${journeyId}` : ''}`);
          }, 3000);
        } else if (currentActionStateRef.current?.updatedAt !== data.updatedAt) {
          dispatch(getCase({
            id: caseId,
            onSuccess: (actionData) => {
              updateActionState(actionData, true);
            },
            onError: (data) => {
              displayErrorMessage(data, 'Unable to retrieve action');
            }
          }));
        }
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to check action state');
      }
    }));
  };

  const getElapsedTimeString = (prevDate, currentDate) => {
    if (!prevDate || !currentDate) return '';

    let timeDiffMinutes = differenceInMinutes(currentDate, prevDate);
    const minutes = timeDiffMinutes % 60;
    const hours = Math.floor(timeDiffMinutes / 60) % 24;
    const days = Math.floor(timeDiffMinutes / (24 * 60));

    return `${days}d ${hours}h ${minutes}m`;
  };

  const isTimeOutAlarm = (prevDate, currentDate, timeLimitMinutes) => {
    if (!prevDate || !currentDate || !timeLimitMinutes) return false;

    let timeDiffMinutes = differenceInMinutes(currentDate, prevDate);

    return timeDiffMinutes > timeLimitMinutes;
  };

  const doDeleteComment = (idx) => {
    setDeleteConfirmationParameters({
      entityType: 'comment',
      entityName: null,
      action: () => {
        let updatedNewHistory = [...newHistory];
        updatedNewHistory.splice(idx, 1);
        setNewHistory(updatedNewHistory);
      }
    });
    setDeleteConfirmationOpen(true);
  };

  const doDeleteDocument = (idx) => {
    setDeleteConfirmationParameters({
      entityType: 'comment',
      entityName: null,
      action: () => {
        let updatedNewHistory = [...newHistory];
        const itemsToDelete = updatedNewHistory.splice(idx, 1);
        const docsToDelete = deepClone(itemsToDelete.map((it) => it.meta?.document?.id).filter(Boolean));
        dispatch(deleteDocuments({
          documentIds: docsToDelete,
          onSuccess: () => {
            setNewHistory(updatedNewHistory);
          },
          onError: (data) => {
            displayErrorMessage(data, 'Unable to delete document');
          }
        }));
      }
    });
    setDeleteConfirmationOpen(true);
  };

  const saveAction = () => {
    setInProgress(true);
    setTimeout(() => { doSave(); }, 800);
  };

  //TODO apply on each change
  const doSave = (updatedNewHistory, updatedAssignee, updatedActionStatus, updatedRemindTime, updatedChecklist) => {
    setInProgress(true);
    let body = (!!caseId) ? {} : {
      typeObj: actionType,
      journey: journey?._id ?? journey?.id,
      creator: effectiveUser?.id ?? effectiveUser?._id,
      customer: journeyOwner?.id ?? journeyOwner?._id
    };
    const assigneeToSet = updatedAssignee ? (updatedAssignee === 'null' ? null : updatedAssignee) : assignedTo;
    body.assignee = assigneeToSet?.id ?? assigneeToSet?._id;
    body.caseStatus = updatedActionStatus ?? actionStatus;
    body.remindTime = (actionStatus === 'ON HOLD' && !!(updatedRemindTime ?? remindTime)) ? (updatedRemindTime ?? remindTime).toISOString() : undefined;
    body.reason = actionStatus === 'ON HOLD' ? reason : undefined;
    body.notes = notesHtml ?? '';
    if (actionType?.custom || currentActionState?.typeObj?.custom) body.description = description ?? '';

    if (!!(updatedChecklist ?? checklist)?.length) {
      body.checklist = (updatedChecklist ?? checklist).map((it) => { return { uid: it.uid, isDone: !!it.isDone }; })
    } else {
      body.checklist = [];
    }

    body.history = (updatedNewHistory ?? newHistory ?? []).map((it) => {
      return {
        ...it,
        meta: {
          comment: it.meta?.comment,
          document: it.meta?.document?.id
        }
      };
    });

    if (!caseId) {
      dispatch(createCase({
        body,
        onSuccess: () => {
          history.push(`/${source ?? 'dashboard'}${!!journeyId ? `/${journeyId}` : ''}`);
          setInProgress(false);
        },
        onError: (data) => {
          displayErrorMessage(data, 'Unable to create the action');
        }
      }));
    } else {
      dispatch(updateCase({
        body,
        id: caseId,
        onSuccess: (data) => {
          updateActionState(data);
        },
        onError: (data) => {
          displayErrorMessage(data, 'Unable to update the action');
        }
      }));
    }
  };

  const checkIfDataLoaded = () => {
    return (!!actionTypeOptions?.length || !!customActionTypeOptions?.length)
      && !!assigneeOptions?.length
      && !!documentTypes?.length
      && ((!!journey && !!journeyOwner) || !journeyId)
      && (!!currentActionState || !caseId);
  };

  const setInputTouched = (id) => {
    if (id > touchedInputs) { setTouchedInputs(id); }
  };

  const ifValidationActive = (id) => {
    return (id <= touchedInputs);
  };

  const onJourneyOwnerSearchStringChanged = (newValue) => {
    let options = {
      limit: 10,
      onSuccess: (data) => {
        setJourneyOwnerOptions(data?.elements ?? []);
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to retrieve allowed users');
      }
    };
    if (newValue) options.search = newValue;

    dispatch(getAllowedUsersWithDebounce(options));
  };

  const onJourneySearchStringChanged = (newValue) => {
    setJourneySearchString(newValue);
  };

  const isUserInSpecialistsList = () => {
    return !!assigneeOptions.find((option) => option.id === effectiveUser?.id) && assignedTo?.id !== effectiveUser?.id;
  };

  const doAssignToMe = () => {
    const newAssignee = assigneeOptions.find((option) => option.id === effectiveUser?.id);
    setAssignedTo(newAssignee);
    if (!!caseId) doSave(null, newAssignee);
  }

  const onChangeRemindTime = (date, id) => {
    setRemindTime(new Date() > date ? new Date() : date);
    setInputTouched(id);

    if (!!caseId && actionStatus === 'ON HOLD' && !!remindTime && !!reason) doSave(null, null, null, new Date() > date ? new Date() : date);
  };

  const onAddComment = () => {
    setCommentHtml('');
    setIsEditingComment(true);
  };

  const onSaveComment = () => {
    const updatedNewHistory = [...(newHistory ?? []), {
      createdBy: effectiveUser?.id,
      createdByName: `${effectiveUser?.name} ${effectiveUser?.surname}`,
      historyItemType: 'Commented',
      meta: {
        comment: commentHtml
      }
    }];

    setNewHistory(updatedNewHistory);

    if (!!caseId) doSave(updatedNewHistory);
    else setNewHistory(updatedNewHistory);
  };

  const onCreateTimeLog = () => {
    setCreateTimeLogParameters({
      yesAction: (time) => {
        const minutes = Number(time.minutes ?? 0) + 60 * Number(time.hours ?? 0);
        dispatch(createCaseTimeLog({
          body: {
            minutes,
            case: caseId,
            user: user?.id,
            journey: journey?.id ?? undefined,
            customer: journey?.user?.id ?? undefined,
          },
          onSuccess: (data) => {
            setTimeLogs([data, ...timeLogs]);
            setCreateTimeLogOpen(false);
          },
          onError: (data) => {
            displayErrorMessage(data, 'Unable to create the time log');
          },
        }));
      }
    });

    setCreateTimeLogOpen(true);
  };

  const onEditTimeLog = () => {
    let currentTimeLogs = deepClone(timeLogs);
    const currentIndex = currentTimeLogs.findIndex((ctl) => ctl.id === actionLogId);
    const currentLog = currentIndex >= 0 ? currentTimeLogs[currentIndex] : undefined;
    const hours = Math.floor(currentLog.minutes / 60);
    const minutes = currentLog.minutes % 60;

    setCreateTimeLogParameters({
      yesAction: (time) => {
        const minutes = Number(time.minutes ?? 0) + 60 * Number(time.hours ?? 0);
        dispatch(updateCaseTimeLog({
          minutes,
          logId: actionLogId,
          onSuccess: (data) => {
            if (currentIndex >= 0) {
              currentTimeLogs[currentIndex] = data;
            }

            setTimeLogs(currentTimeLogs);
            setCreateTimeLogOpen(false);
          },
          onError: (data) => {
            displayErrorMessage(data, 'Unable to update the time log entry');
          },
        }));
      },
      initialHours: hours > 0 ? hours.toString() : '',
      initialMinutes: minutes > 0 ? minutes.toString() : '',
    });

    setCreateTimeLogOpen(true);
  };

  const onAddDocument = () => {
    setPurpose('create');
    setLimitationMode('actionDocument');
    setDocumentToView(undefined);
    setOpenAttachFiles(true);
  };

  const onViewDocument = (doc) => {
    setPurpose('view');
    setLimitationMode('not-editable');
    setDocumentToView(doc);
    setOpenAttachFiles(true);
  };

  const addDocumentHistoryItem = (document) => {
    const updatedNewHistory = [...(newHistory ?? []), {
      createdBy: effectiveUser?.id,
      createdByName: `${effectiveUser?.name} ${effectiveUser?.surname}`,
      historyItemType: 'Attached file',
      meta: {
        document
      }
    }];

    setNewHistory(updatedNewHistory);

    if (!!caseId) doSave(updatedNewHistory);
  };

  const doUpload = (document, files, originalFiles, type, isNew = false, body, mustBeInternal = false) => {
    setIsUploading(true);
    setCurrentProgress(0);
    const uploadFiles = (newDocument) => {
      const refresh = () => {
        setOpenAttachFiles(false);
        setCurrentProgress(0);
        setIsUploading(false);
      };

      const originalCallback = () => {
        setCurrentProgress(0);
        if (files?.length) {
          dispatch(uploadDocumentFiles({
            documentId: document?.id ?? newDocument?.id,
            files,
            onSuccess: () => {
              dispatch(updateDocument({
                documentId: newDocument?.id,
                body: {
                  ...(body ?? {}),
                  currentStatus: 'Reviewed',
                  journey: body?.journey?.id ?? body?.journey
                },
                onSuccess: (data) => {
                  addDocumentHistoryItem(data);
                  refresh();
                },
                onError: (data2) => {
                  setIsUploading(false);
                  setCurrentProgress(0);
                  displayErrorMessage(data2, 'Unable to update document');
                }
              }));
            },
            onError: (data) => {
              setIsUploading(false);
              setCurrentProgress(0);
              displayErrorMessage(data, 'Unable to upload document files');
            },
            onProgressEvent: (progress) => {
              setCurrentProgress(progress ?? 0);
            }
          }));
        } else {
          addDocumentHistoryItem(newDocument);
          refresh();
        }
      };

      if (originalFiles?.length) {
        dispatch(uploadDocumentOriginalFiles({
          documentId: document?.id ?? newDocument?.id,
          files: originalFiles,
          onSuccess: () => {
            originalCallback();
          },
          onError: (data) => {
            setIsUploading(false);
            setCurrentProgress(0);
            displayErrorMessage(data, 'Unable to upload original document files');
          },
          onProgressEvent: (progress) => {
            setCurrentProgress(progress ?? 0);
          }
        }));
      } else {
        originalCallback();
      }
    };

    if (isNew) {
      dispatch(createDocument({
        body: {
          ...(body ?? {}),
          documentType: type.id,
          journey: body?.journey?.id ?? body?.journey?._id ?? body?.journey,
          internal: mustBeInternal ? true : undefined
        },
        onSuccess: (data) => {
          uploadFiles(data);
        },
        onError: (data) => {
          setIsUploading(false);
          setCurrentProgress(0);
          displayErrorMessage(data, 'Unable to create document');
        }
      }));
    } else if (document.documentType?.id === type.id && !(Object.keys(body ?? {})).length) {
      uploadFiles();
    } else {
      dispatch(updateDocument({
        documentId: document.id,
        body: {
          ...(body ?? {}),
          documentType: type.id,
          journey: body?.journey?.id ?? body?.journey?._id ?? body?.journey
        },
        onSuccess: () => {
          uploadFiles();
        },
        onError: (data) => {
          setIsUploading(false);
          setCurrentProgress(0);
          displayErrorMessage(data, 'Unable to update document');
        }
      }));
    }
  };

  const switchIsDoneState = (uid, groupIndex, itemIndex) => {
    const updatedChecklist = (deepClone(checklist)).map((it) => {
      return {...it, isDone: uid === it.uid ? !it.isDone : !!it.isDone}
    });

    setChecklist(updatedChecklist);

    let groups = deepClone(groupedChecklist);
    groups[groupIndex].items[itemIndex].isDone = !groups[groupIndex].items[itemIndex].isDone;

    setGroupedChecklist(groups);
    if (checkBoxUpdateTimeoutId) clearTimeout(checkBoxUpdateTimeoutId);
    checkBoxUpdateTimeoutId = setTimeout(() => { doSave(null, null, null, null, updatedChecklist); }, 2500);
  };

  const doDeleteLog = () => {
    const updateLogsList = () => {
      dispatch(getCaseTimeLogs({
        caseId,
        onSuccess: (data) => {
          setTimeLogs(data);
          setIsLogsLoading(false);
        },
        onError: (data) => {
          displayErrorMessage(data, 'Unable to retrieve action\'s time logs');
          setIsLogsLoading(false);
        }
      }));
    }

    setActionMenuPosition(null);
    setActionLogId(null);
    if (actionLogId) {
      setDeleteConfirmationParameters({
        entityType: 'time log',
        entityName: null,
        action: () => {
          setIsLogsLoading(true);
          dispatch(deleteCaseTimeLog({
            logId: actionLogId,
            onSuccess: () => {
              updateLogsList();
            },
            onError: (data) => {
              displayErrorMessage(data, 'Unable to delete the journey');
              updateLogsList();
            }
          }));
        }
      });

      setDeleteConfirmationOpen(true);
    }
  };

  const handleActionMenuOpen = (event, log) => {
    if (actionMenuPosition) return;

    event.preventDefault();
    setActionLogId(log.lastEntryId);
    setActionMenuPosition({
      top: event.clientY + 12,
      left: event.clientX + 12
    });
  };

  const getTimeStringFromMinutes = (minutes) => {
    const h = Math.floor((minutes ?? 0) / 60);
    const m = (minutes ?? 0) % 60;

    return `${h > 0 ? `${h}h ` : ''}${m < 10 ? '0' : ''}${m}m`;
  };

  const startTracking = () => {
    localStorage.setItem('timeTrackingAction', caseId);
    localStorage.setItem('timeTrackingActionName', effectiveUser?.name ? `${effectiveUser.name} ${effectiveUser.surname}` : 'undefined');
    localStorage.setItem('timeTrackingUser', effectiveUser?.id);
    localStorage.setItem('timeTrackingSeconds', '0');
    localStorage.setItem('timeTrackingLastUpdate', (Date.now()).toString());
    if (journey?.id) localStorage.setItem('timeTrackingJourney', journey.id);
    if (journey?.user?.id) localStorage.setItem('timeTrackingCustomer', journey.user.id);
    setActiveTimeTracker(caseId);
    setUserTracking(effectiveUser?.id);
  };

  const logTime = () => {
    if (!localStorage.getItem('timeTrackingSeconds') || !localStorage.getItem('timeTrackingAction') || !localStorage.getItem('timeTrackingUser')) {
      clearTimeTrackingInLocalStorage();
      setActiveTimeTracker(undefined);
      setUserTracking(undefined);
    } else {
      const minutes = Math.floor(Number(localStorage.getItem('timeTrackingSeconds') ?? 0) / 60);

      const clearAndUpdate = (data) => {
        clearTimeTrackingInLocalStorage();
        setActiveTimeTracker(undefined);
        setUserTracking(undefined);
        if (data) setTimeLogs([data, ...timeLogs]);
      };

      if (minutes > 0) {
        dispatch(createCaseTimeLog({
          body: {
            minutes,
            case: localStorage.getItem('timeTrackingAction'),
            user: localStorage.getItem('timeTrackingUser'),
            journey: journey?.id ?? undefined,
            customer: journey?.user?.id ?? undefined,
          },
          onSuccess: (data) => {
            clearAndUpdate(data);
          },
          onError: (data) => {
            displayErrorMessage(data, 'Unable to create the time log');
          },
        }));
      } else {
        clearAndUpdate();
      }
    }
  };

  const updateActionStatus = (status) => {
    setActionStatus(status);

    if (!!caseId && status !== currentActionState.caseStatus && (status !== 'ON HOLD' || (!!remindTime && !!reason))) {
      doSave(null, null, status);
    }
  };

  const createMarkup = (content) => {
    return {
      __html: content
    };
  };

  const renderActionMenu = (log) => {
    return (
      <Menu
        open={!!actionMenuPosition && log.lastEntryId === actionLogId}
        onClose={() => { setActionMenuPosition(null); setActionLogId(null); }}
        anchorReference="anchorPosition"
        anchorPosition={actionMenuPosition}
      >
        {([USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.OperationsManagerWithCurrentJourneyEditRights].includes(userRole) || log.user === effectiveUser?.id) && (<>
          <MenuItem
            onClick={() => { setActionMenuPosition(null); setActionLogId(null); onEditTimeLog();  }}
            disabled={!actionLogId || !!inProgress}
          >
            Edit entry
          </MenuItem>

          <MenuItem className={styles.deleteMenuItem} onClick={() => doDeleteLog()}>Delete</MenuItem>
        </>)}
      </Menu>
    );
  };

  const renderHistoryItem = (historyItem, index, isNew) => {
    const doc = isNew ? historyItem.meta?.document : reviewedJourneyDocuments.find((doc) => doc.id === historyItem.meta?.document);
    const docType = isNew ? documentTypes.find((dt) => dt.id === doc?.documentType) : doc?.documentType;

    const renderHistoryItemContent = () => {
      switch (historyItem.historyItemType) {
        case 'Assigned to':
          return (<div className={styles.text}>Assigned to {historyItem.meta?.assignedToName ?? '???'}</div>);
        case 'Attached file':
          return (<Grid container spacing={2} justifyContent={'space-between'} justifyItems={'space-between'} alignContent={'center'} alignItems={'center'}>
            <Grid item className={styles.type}>{docType?.category} / {docType?.type}</Grid>
            <Grid item>
              <Button color="primary" variant="contained" className={styles.smallButton} onClick={() => onViewDocument(doc)}>
                VIEW
              </Button>
            </Grid>
          </Grid>);
        case 'Commented':
          return (<div dangerouslySetInnerHTML={createMarkup(historyItem.meta?.comment ?? '')} />);
        case 'Status changed':
          return (<div className={styles.text}>
            <span>
              {`[${historyItem.meta?.oldStatus}] -> [${historyItem.meta?.newStatus}]`}
              {historyItem.meta?.remindTime ? `, remind on ${format(new Date(historyItem.meta?.remindTime), 'dd.MM.yyyy HH:mm')}` : ''}
            </span>
            {!!historyItem.meta?.comment && (<p className={`${styles.preWrap}`}>{historyItem.meta.comment}</p>)}
          </div>);
        case 'Checklist item marked':
          return (<div className={styles.text}>
            "{checklist.find((it) => it.uid === historyItem.meta?.checklistItemUID)?.title}"{' '}
            marked {`[${historyItem.meta?.oldIsDone ? 'DONE' : 'OPEN'}] -> [${historyItem.meta?.newIsDone ? 'DONE' : 'OPEN'}]`}
          </div>);
        case 'Action created':
        default:
          return null;
      }
    };

    const renderHistoryItemHeader = () => {
      return (<>
        {historyItem.createdAt ? `${format(new Date(historyItem.createdAt), 'dd.MM.yyyy HH:mm')} ` : ''}
        {historyItem.historyItemType === 'Commented' ? 'Commented' : null}
        {historyItem.historyItemType === 'Status changed' ? 'Status change' : null}
        {historyItem.historyItemType === 'Checklist item marked' ? 'Checklist updated' : null}
        {historyItem.historyItemType === 'Attached file' ? 'Attached file' : null}
        {historyItem.historyItemType === 'Assigned to' ? 'Assignee changed' : null}
        {historyItem.historyItemType === 'Action created' ? 'Action created' : null}
        {historyItem.createdByName ? ` by ${historyItem.createdByName}` : ''}

        {isNew && historyItem.historyItemType === 'Commented' && !caseId && (<IconButton
          aria-label="delete"
          onClick={() => { doDeleteComment(newHistory.length - 1 - index); }}
          className={styles.iconButton}
        >
          <DeleteForeverIcon />
        </IconButton>)}
        {isNew && historyItem.historyItemType === 'Attached file' && !caseId && (<IconButton
          aria-label="delete"
          onClick={() => { doDeleteDocument(newHistory.length - 1 - index); }}
          className={styles.iconButton}
        >
          <DeleteForeverIcon />
        </IconButton>)}
      </>);
    }

    return (<React.Fragment key={index}>
      <Box mt={3} className={styles.historyItemHeader}>
        {renderHistoryItemHeader()}
      </Box>
      {historyItem.historyItemType === 'Commented' ? (
        <div className={`${styles.notesWrapper} ${styles.defaultEditor} ${styles.noTopBorderRadius}`}>
          {renderHistoryItemContent()}
        </div>
      ) : historyItem.historyItemType === 'Action created' ? null : (
        <Box className={`${styles.historyItemContent} ${historyItem.historyItemType === 'Attached file' ? styles.lighter : ''}`}>
          {renderHistoryItemContent()}
        </Box>
      )}
    </React.Fragment>);
  };

  const renderContent = () => {
    const primaryOwner = currentActionState?.journey?.user ?? currentActionState?.customer;

    return (<>
      <Box sx={{ mt: 2 }} >
        <Grid container spacing={4}>
          {!caseId ? (<>
            <Grid item xs={12}>
              <Box className={styles.info}>Action</Box>
              <FormControl variant="outlined" className={styles.selectWrapper}>
                <Select
                  value={actionType}
                  displayEmpty
                  renderValue={(val) => !!actionType ? val.name : <span className={styles.placeholder}>Choose action</span>}
                  onChange={(e) => {
                    setActionType(e.target.value);
                    setInputTouched(2);
                  }}
                >
                  {actionTypeOptions.map((option) => {
                    return <MenuItem value={option} key={option.id}><div>{option.name}</div></MenuItem>;
                  })}
                  {(!!actionTypeOptions?.length && !!customActionTypeOptions?.length) && <Divider />}
                  {customActionTypeOptions.map((option) => {
                    return <MenuItem value={option} key={option.id}><div>{option.name}</div></MenuItem>;
                  })}
                </Select>
                <ValidationErrorLabel errorMessage={ifValidationActive(2) && !actionType ? 'Action type is required' : ''} />
              </FormControl>
            </Grid>

            {(!!actionType || !!currentActionState?.typeObj) && (<>
              {!journeyId && (<>
                <Grid item xs={12}>
                  <Box className={styles.info}>Customer</Box>
                  <Autocomplete
                    options={journeyOwnerOptions}
                    getOptionLabel={(option) => `${option?.name}${option?.surname ? ' ' + option?.surname : ''}` }
                    fullWidth
                    value={journeyOwner ?? null }
                    onChange={(event, newValue) => {
                      setIsUpdatingJourneys(true);
                      setJourneyOwner(newValue);
                    }}
                    filterOptions={(options) => options}
                    disabled={!!journey}
                    renderInput={(params) => <TextField
                      {...params}
                      style={{ backgroundColor: "white" }}
                      onChange={(event) => {
                        onJourneyOwnerSearchStringChanged(event.target.value);
                        setInputTouched(3);
                      }}
                      onFocus={(event) => {
                        if (!event.target.value) onJourneyOwnerSearchStringChanged(event.target.value);
                      }}
                      onBlur={() => setInputTouched(3)}
                      placeholder={`Choose associated customer ${
                        (!actionType?.custom && !currentActionState?.typeObj?.custom) 
                        || actionType?.customerRequired 
                        || currentActionState?.typeObj?.customerRequired 
                          ? '' 
                          : '(optional)'
                      }`}
                      variant="outlined"
                      maxLength="100"
                    />}
                  />
                  <ValidationErrorLabel errorMessage={(ifValidationActive(3) && !journeyOwner && !((!!actionType?.custom || !!currentActionState?.typeObj?.custom) && !actionType?.customerRequired && !currentActionState?.typeObj?.customerRequired))
                    ? 'Customer is required for selected Action type'
                    : ''
                  } />
                </Grid>

                {!!journeyOwner && (
                  <Grid item xs={12}>
                    <Box className={styles.info}>Journey</Box>
                    <Autocomplete
                      options={journeyOptions}
                      getOptionLabel={(option) => `${option?.name}` }
                      fullWidth
                      value={journey ?? null}
                      disabled={isUpdatingJourneys || !journeyOwner}
                      filterOptions={(options) => options}
                      onChange={(event, newValue) => {
                        setJourney(newValue);
                        setJourneyOwner(newValue?.user)
                      }}
                      renderInput={(params) => <TextField
                        {...params}
                        style={{ backgroundColor: "white" }}
                        value={journeySearchString}
                        onChange={(event) => {
                          onJourneySearchStringChanged(event.target.value);
                          setInputTouched(4);
                        }}
                        onFocus={(event) => {
                          if (!event.target.value) onJourneySearchStringChanged(event.target.value);
                        }}
                        onBlur={() => setInputTouched(4)}
                        placeholder={`Choose journey${!!actionType?.custom || !!currentActionState?.typeObj?.custom || !!actionType?.customerRequired || !!currentActionState?.typeObj?.customerRequired ? ' (optional)' : ''}`}
                        variant="outlined"
                        maxLength="100"
                      />}
                    />
                    <ValidationErrorLabel errorMessage={(ifValidationActive(4) && !journey && !!journeyOwner)
                      ? `${!((!!actionType?.custom || !!currentActionState?.typeObj?.custom || !!actionType?.customerRequired || !!currentActionState?.typeObj?.customerRequired)) ? 'Journey is required for selected Action type' : ''}`
                      : ''
                    } />
                  </Grid>
                )}
              </>)}
            </>)}
          </>) : (<>
            <Grid item xs={12}>
              {!!currentEditors?.length && (
                <div className={styles.warning}>
                  <Grid container justifyContent="space-between">
                    <Grid item className={styles.iconBlock}><ErrorIcon /></Grid>
                    <Grid item className={styles.textBlock}>
                      Other user{currentEditors?.length === 1 ? '' : 's'} {currentEditors?.length === 1 ? 'is' : 'are'} viewing/editing this action:{' '}
                      {currentEditors.map((editor) => editor.editorName).filter(Boolean).join(', ')}
                    </Grid>
                  </Grid>
                </div>
              )}
              <Grid container justifyContent={'space-between'}>
                <Grid item className={`${styles.label} ${styles.pt1px}`} xs={4}>Time in progress</Grid>
                <Grid item className={styles.value} xs={8}>
                  {isTimeOutAlarm(
                    !!currentActionState?.createdAt ? new Date(currentActionState.createdAt) : undefined,
                    currentTime,
                    Number(currentActionState?.typeObj?.minutesToAlarm)
                  ) && (<><ErrorIcon className={styles.errorIcon} />{' '}</>)}
                  {getElapsedTimeString(
                    !!currentActionState?.createdAt ? new Date(currentActionState.createdAt) : undefined, currentTime
                  )}
                </Grid>
              </Grid>
              <Grid container justifyContent={'space-between'}>
                <Grid item className={styles.label} xs={4}>Date created</Grid>
                <Grid item className={styles.value} xs={8}>
                  {!!currentActionState?.createdAt ? format(new Date(currentActionState.createdAt), 'dd.MM.yy HH:mm') : '-'}
                </Grid>
              </Grid>
              {!!currentActionState?.journey ? (<>

                {!!primaryOwner && (
                  <Grid container justifyContent={'space-between'}>
                    <Grid item className={styles.label} xs={4}>Primary owner</Grid>
                    <Grid
                      item
                      className={`${styles.value} ${styles.linkToDetails}`}
                      xs={8}
                    >
                      <a
                        href={`/profile/${primaryOwner?.id}`}
                        onClick={(e) => { e.preventDefault(); history.push(`/profile/${primaryOwner?.id}`); }}
                      >
                        {primaryOwner?.name}{!!primaryOwner?.middlename ? ` ${primaryOwner.middlename}` : ''}{!!primaryOwner?.surname ? ` ${primaryOwner.surname}` : ''}
                      </a>
                    </Grid>
                  </Grid>
                )}
                <Grid container justifyContent={'space-between'}>
                  <Grid item className={styles.label} xs={4}>Journey</Grid>
                  <Grid
                    item
                    className={`${styles.value} ${styles.linkToDetails}`}
                    xs={8}
                    onClick={() => history.push(`/journey-details/${currentActionState.journey?.id}`)}
                  >
                    <a
                      href={`/journey-details/${currentActionState.journey?.id}`}
                      onClick={(e) => { e.preventDefault(); history.push(`/journey-details/${currentActionState.journey?.id}`); }}
                    >
                      {currentActionState.journey.name}
                    </a>
                  </Grid>
                </Grid>
              </>) : (<>
                {!!primaryOwner && (
                  <Grid container justifyContent={'space-between'}>
                    <Grid item className={styles.label} xs={4}>Customer</Grid>
                    <Grid
                      item
                      className={`${styles.value} ${styles.linkToDetails}`}
                      xs={8}
                    >
                      <a
                        href={`/profile/${primaryOwner?.id ?? primaryOwner?._id}`}
                        onClick={(e) => { e.preventDefault(); history.push(`/profile/${primaryOwner?.id ?? primaryOwner?._id}`); }}
                      >
                        {primaryOwner?.name}{!!primaryOwner?.middlename ? ` ${primaryOwner.middlename}` : ''}{!!primaryOwner?.surname ? ` ${primaryOwner.surname}` : ''}
                      </a>
                    </Grid>
                  </Grid>
                )}
              </>)}
            </Grid>
          </>)}

          {(!!actionType || !!currentActionState?.typeObj) && (<>
            {(actionStatus === 'ON HOLD' && (currentActionState?.caseStatus !== 'ON HOLD' || (!!currentActionState?.remindTime && Math.abs((new Date(currentActionState.remindTime)).getTime() - remindTime.getTime()) > 60000))) && (<>
              <Grid item xs={12}>
                <Box className={styles.info}>Reason</Box>
                <TextareaAutosize
                  placeholder="Input on hold reason here"
                  multiline={'true'}
                  minRows={3}
                  maxRows={3}
                  className={`${styles.textArea} ${ifValidationActive(5) && !reason.length ? styles.inputWithError : ''}`}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                    setInputTouched(5);
                  }}
                  onBlur={() => { if (actionStatus === 'ON HOLD' && !!remindTime) doSave(); }}
                  disabled={!!inProgress}
                />
                <ValidationErrorLabel errorMessage={ifValidationActive(5) && !reason.length ? 'Reason is required' : ''} />
              </Grid>
            </>)}

            {(actionStatus === 'ON HOLD') && (<>
              <Grid item xs={12} md={6}>
                <Box className={styles.info}>Remind date and time</Box>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={auLocale}>
                  <DateTimePicker
                    value={remindTime}
                    onChange={(date) => {
                      onChangeRemindTime(date, 6);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={new Date()}
                    onBlur={() => setInputTouched(6)}
                    style={{ width: '100%' }}
                    disabled={!!inProgress}
                  />
                  <ValidationErrorLabel errorMessage={ifValidationActive(6) && !remindTime ? 'Remind date and time are required' : ''} />
                </LocalizationProvider>
              </Grid>
            </>)}

            <Grid item xs={12}>
              <Box className={styles.info}>Assigned to</Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl variant="outlined" className={styles.selectWrapper}>
                    <Select
                      defaultValue={assignedTo ?? null}
                      value={assignedTo ?? null}
                      displayEmpty
                      style={{ backgroundColor: "white" }}
                      disabled={(!!caseId && (isEditingComment || isEditingNotes || isWaitingOnHoldParameters)) || inProgress || (!!assignedTo && actionStatus === 'COMPLETED')}
                    >
                      <MenuItem
                        value={null}
                        style={ !assignedTo ? { display: 'none' } : {} }
                        onClick={() => { setAssignedTo(null); setInputTouched(7); if (!!caseId) doSave(null, 'null'); }}
                        key={-1}
                        disabled={(!!caseId && (isEditingComment || isEditingNotes || isWaitingOnHoldParameters)) || inProgress}
                      >
                        <div style={{ textTransform: "none", color: '#8B90A0' }}>{assignedTo ? ('Clear selection') : ('Choose assignee')}</div>
                      </MenuItem>
                      {(assigneeOptions ?? []).map((option, idx) => {
                        return (
                          <MenuItem
                            value={option}
                            key={idx}
                            onClick={() => { setAssignedTo(option); setInputTouched(7); if (!!caseId) doSave(null, option);  }}
                            disabled={(!!caseId && (isEditingComment || isEditingNotes || isWaitingOnHoldParameters)) || inProgress}
                          >
                            <div style={{ textTransform: "none" }}>{option.name} {option.surname}</div>
                          </MenuItem>
                        )
                      })}
                    </Select>
                    <ValidationErrorLabel errorMessage={actionStatus === 'COMPLETED' && !assignedTo ? 'Action can\'t be "COMPLETED" with no assignee' : ''} />
                  </FormControl>
                  {(dimensions.isScreenLessMD && isUserInSpecialistsList()) && (<>
                    <Grid container justifyItems={'flex-end'} justifyContent={'flex-end'}>
                      <Grid item className={`${styles.link} ${!!caseId && (isEditingComment || isEditingNotes) ? styles.disabled : ''}`} onClick={() => {
                        if (!caseId || !(isEditingComment || isEditingNotes)) {
                          doAssignToMe();
                          setInputTouched(7);
                        }
                      }}>
                        Assign to me
                      </Grid>
                    </Grid>
                  </>)}
                </Grid>
                <Grid item xs={12} md={8} container justifyContent={'space-between'}>
                  {(!dimensions.isScreenLessSM) && (
                    <Grid item xs={4}>
                      {(!dimensions.isScreenLessMD && isUserInSpecialistsList()) && (
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={() => { doAssignToMe(); setInputTouched(7); }}
                          disabled={(!!caseId && (isEditingComment || isEditingNotes || isWaitingOnHoldParameters)) || !!inProgress}
                        >
                          ASSIGN TO ME
                        </Button>
                      )}
                    </Grid>
                  )}
                  <Grid item container spacing={2} justifyContent={'flex-end'} xs={12} md={8}>
                    <Grid item className={`${styles.fullWidthOnXS} ${styles.halfWideOnMd}`}>
                      {!!activeTimeTracker ? (
                        <Button
                          type="button"
                          variant="outlined"
                          color="secondary"
                          className={styles.logTimeButton}
                          onClick={() => { logTime(); }}
                          disabled={userTracking !== effectiveUser?.id || activeTimeTracker !== caseId}
                        >
                          STOP & LOG<span className={`${styles.afterInscriptionIcon} ${styles.stop}`}><StopIcon /></span>
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          variant="outlined"
                          color="secondary"
                          className={styles.logTimeButton}
                          onClick={() => { startTracking(); }}
                        >
                          START<span className={`${styles.afterInscriptionIcon} ${styles.start}`}><PlayArrowIcon /></span>
                        </Button>
                      )}
                    </Grid>
                    <Grid item className={`${styles.fullWidthOnXS} ${styles.halfWideOnMd}`}>
                      <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        className={styles.logTimeButton}
                        onClick={() => { onCreateTimeLog(); }}
                      >
                        LOG TIME<span className={styles.afterInscriptionIcon}><AvTimerIcon /></span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {!!aggregatedTimeLogs?.length && (
              <Grid item xs={12}>
                <Box className={styles.info}>Time log</Box>
                <Grid container>
                  <Grid item xs={12} className={`${styles.aggregatedTimeLogTableWrapper} ActionTimeLogTableInCaseForm`}>
                    <TableContainer className={`${styles.tableContainer} ${styles.fixedWidth}`}>
                      <Table size={dimensions.isScreenBiggerSM ? "medium" : "small"} stickyHeader>
                        <TableHead>
                          <TableRow className={styles.tableHead}>
                            <TableCell align={'left'} style={{ minWidth: 220 }}>USER</TableCell>
                            <TableCell align={'left'} style={{ minWidth: 120 }}>LAST TIMER DATE</TableCell>
                            <TableCell align={'left'} style={{ minWidth: 96 }}>LAST ENTRY</TableCell>
                            <TableCell align={'left'} style={{ minWidth: 120 }}>TOTAL TIME SPENT</TableCell>
                            <TableCell align={'right'} style={{ minWidth: 10, width: 10 }}/>
                          </TableRow>
                        </TableHead>

                        <TableBody className={styles.tableBody}>
                          {(aggregatedTimeLogs ?? []).map((log, idx) => {
                            return (
                              <TableRow className={styles.tableItem} key={idx}>
                                <TableCell align={'left'} className={styles.name}>
                                  {log.userName}
                                </TableCell>

                                <TableCell align={'left'} className={styles.date}>
                                  {log.lastTimerDate ? format(new Date(log.lastTimerDate), 'dd.MM.yyyy HH:mm') : '???'}
                                </TableCell>

                                <TableCell align={'left'} className={styles.name}>
                                  {getTimeStringFromMinutes(log.lastEntry)}
                                </TableCell>

                                <TableCell align={'left'} className={styles.name}>
                                  {getTimeStringFromMinutes(log.totalTime)}
                                </TableCell>

                                <TableCell align={'left'}>
                                  {([USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.OperationsManagerWithCurrentJourneyEditRights].includes(userRole) || log.user === effectiveUser?.id) && (<>
                                    <IconButton
                                      className={`${styles.iconBtn} ${styles.noPadding}`}
                                      onClick={(event) => { handleActionMenuOpen(event, log); }}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                    {renderActionMenu(log)}
                                  </>)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      {(isLogsLoading) && (<CircularProgress size={40} className={`${styles.cardProgress} ${styles.timeLog}`} />)}
                    </TableContainer>
                    <Grid container justifyItems={'flex-end'} justifyContent={'flex-end'}>
                      <Grid item>
                        <div className={styles.link} onClick={() => {
                          history.push(`/action-time-log/${caseId}`);
                        }}>
                          Show all logs
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {(actionType?.custom || currentActionState?.typeObj?.custom) && (<>
              <Grid item xs={12} className={'Action-description-editor'}>
                <Box className={styles.info}>Description</Box>
                <ValidatedTextInput
                  autoFocus
                  placeholder="Input description there"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setInputTouched(8);
                  }}
                  onBlur={() => { if (description !== currentActionState?.description && !!caseId) doSave(); }}
                  variant="outlined"
                  name="editingName"
                  validators={['maxStringLength:85']}
                  errorMessages={['Not more then 85 characters are allowed']}
                  fullWidth
                  disabled={(!!caseId && (isEditingComment || isEditingNotes || isWaitingOnHoldParameters)) || !!inProgress}
                />
              </Grid>
            </>)}

            {(!actionType?.hideNotes && !currentActionState?.typeObj?.hideNotes) && (
              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
                  <Grid item className={styles.info}>Details</Grid>
                  {isEditingNotes ? (
                    <Grid container item className={`${styles.editIconsWrapper} ${styles.up}`}>
                      <Grid item className={`${styles.icon}`}>
                        <IconButton className={`${styles.iconBtn} ${styles.linkColor}`} onClick={() => { setIsEditingNotes(false); if (!!caseId) doSave(); }}>
                          <SaveIcon />
                        </IconButton>
                      </Grid>
                      <Grid item className={`${styles.icon}`}>
                        <IconButton
                          className={`${styles.iconBtn} ${styles.linkColor}`}
                          onClick={() => { setIsEditingNotes(false); setNotesHtml(notesBeforeEditing); }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item className={styles.editIconsWrapper}>
                      <Grid item className={`${styles.icon}`}>
                        <IconButton
                          className={`${styles.iconBtn}`}
                          onClick={() => { setIsEditingNotes(true); setShouldUpdateTinyEditor(true); setNotesBeforeEditing(notesHtml); }}
                          disabled={(isEditingComment && !!caseId) || !!inProgress || isWaitingOnHoldParameters}
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {isEditingNotes ? (<>
                  <TinyMCEditor
                    state={notesHtml}
                    updateState={setNotesHtml}
                    shouldUpdate={shouldUpdateTinyEditor}
                    setShouldUpdate={setShouldUpdateTinyEditor}
                  />
                  <ValidationErrorLabel errorMessage={(notesHtml ?? '').length > MAX_NOTES_SIZE ? 'Maximum allowed size is 2 MB.' : ''} />
                </>) : notesHtml?.length ? (
                  <div className={`${styles.notesWrapper} ${styles.defaultEditor}`}>
                    <div dangerouslySetInnerHTML={createMarkup(notesHtml)} />
                  </div>
                ) : (
                  <Grid container justifyContent={'center'} justifyItems={'center'}>
                    <Grid item className={styles.emptyLabel}>No details provided</Grid>
                  </Grid>
                )}
              </Grid>
            )}

            {(!!groupedChecklist?.length) && (<>
              <Grid item xs={12}>
                <Box className={styles.info}>Checklist</Box>
                {groupedChecklist.map((group, groupIndex) => {
                  return (<React.Fragment key={groupIndex}>
                    {(group.section !== OTHER || groupIndex > 0) && (
                      <Box className={styles.sectionTitle} sx={{ mt: groupIndex > 0 ? 2 : 0 }}>{group.section}</Box>
                    )}
                    {group.items.map((item, itemIndex) => {
                      return (<Grid item container xs={12} spacing={1} key={itemIndex}>
                        <Grid item>
                          <Checkbox
                            checked={!!item.isDone}
                            onChange={() => {
                              switchIsDoneState(item.uid, groupIndex, itemIndex);
                            }}
                            size={"small"}
                            className={styles.checkbox}
                            disabled={(!!caseId && (isEditingComment || isEditingNotes || isWaitingOnHoldParameters)) || !!inProgress}
                          />
                        </Grid>
                        <Grid item className={`${styles.checkboxLabel} ${!!item.isDone ? styles.checked : ''}`}>
                          {item?.title ? item.title : 'No title'}
                        </Grid>
                      </Grid>);
                    })}
                  </React.Fragment>);
                })}
              </Grid>
            </>)}

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className={styles.addHistoryItemButton}
                    onClick={() => { onAddComment(); setInputTouched(9); }}
                    disabled={(!!caseId && (isEditingComment || isEditingNotes || isWaitingOnHoldParameters)) || !!inProgress}
                  >
                    ADD COMMENT
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    className={styles.addHistoryItemButton}
                    onClick={() => { onAddDocument(); setInputTouched(10); }}
                    disabled={(!!caseId && (isEditingComment || isEditingNotes || isWaitingOnHoldParameters)) || !!inProgress}
                  >
                    ADD DOCUMENT
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>)}

          {(!!oldHistory?.length || !!newHistory?.length || isEditingComment) && (<>
            {!!isEditingComment && (
              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
                  <Grid item className={styles.historyItemHeader}>Input comments below</Grid>
                  <Grid container item className={`${styles.editIconsWrapper}`}>
                    <Grid item className={`${styles.icon}`}>
                      <IconButton className={`${styles.iconBtn} ${styles.linkColor}`} onClick={() => { setIsEditingComment(false); onSaveComment(); }}>
                        <SaveIcon />
                      </IconButton>
                    </Grid>
                    <Grid item className={`${styles.icon}`}>
                      <IconButton
                        className={`${styles.iconBtn} ${styles.linkColor}`}
                        onClick={() => { setIsEditingComment(false); setCommentHtml(''); }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <TinyMCEditor
                  state={commentHtml}
                  updateState={setCommentHtml}
                  shouldUpdate={shouldUpdateCommentEditor}
                  setShouldUpdate={setShouldUpdateCommentEditor}
                />
                <ValidationErrorLabel errorMessage={(commentHtml ?? '').length > MAX_NOTES_SIZE ? 'Maximum allowed size is 2 MB.' : ''} />
              </Grid>
            )}

            <Grid container item xs={12}>
              <Grid item xs={12}>
                {dimensions.isScreenBiggerSM ? (
                  <Grid container alignItems={"flex-start"} direction={"row"} justifyContent={"flex-start"}>
                    {HISTORY_FILTERS.map((f, idx) => { return (
                      <Grid
                        item
                        container
                        direction={"column"}
                        alignItems={"center"}
                        className={`${styles.filterItem} ${f === historyFilter ? styles.selected : ''}`}
                        onClick={() => { setHistoryFilter(f); }}
                        key={idx}
                      >
                        <Grid item>{f}</Grid>
                      </Grid>
                    ); })}
                  </Grid>
                ) : (
                  <Box sx={{ mt: 4 }}>
                    <FormControl variant="outlined" fullWidth>
                      <Select value={historyFilter ?? null} fullWidth>
                        {(HISTORY_FILTERS ?? []).map((f, idx) => {
                          return (
                            <MenuItem
                              value={f}
                              key={idx}
                              onClick={() => { setHistoryFilter(f); }}
                            >
                              <div>{f}</div>
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </Grid>

              <Grid item xs={12}>
                {[...(newHistory ?? [])]
                  .filter((historyItem) => historyFilter === 'COMMENTS' ? historyItem.historyItemType === 'Commented' : (historyFilter === 'DOCUMENTS' ? historyItem.historyItemType === 'Attached file' : true))
                  .reverse()
                  .map((historyItem, idx) => renderHistoryItem(historyItem, idx, true))
                }
                {[...(oldHistory ?? [])]
                  .filter((historyItem) => historyFilter === 'COMMENTS' ? historyItem.historyItemType === 'Commented' : (historyFilter === 'DOCUMENTS' ? historyItem.historyItemType === 'Attached file' : true))
                  .reverse()
                  .map((historyItem, idx) => renderHistoryItem(historyItem, idx, false))
                }
                {(historyFilter === 'COMMENTS' && !([...(newHistory ?? []), ...(oldHistory ?? [])].filter((historyItem) => historyItem.historyItemType === 'Commented'))?.length) && (
                  <Box sx={{ mt: 2 }}>
                    <Grid container justifyContent={'center'} justifyItems={'center'}>
                      <Grid item className={styles.emptyLabel}>No comments provided</Grid>
                    </Grid>
                  </Box>
                )}
                {(historyFilter === 'DOCUMENTS' && !([...(newHistory ?? []), ...(oldHistory ?? [])].filter((historyItem) => historyItem.historyItemType === 'Attached file'))?.length) && (
                  <Box sx={{ mt: 2 }}>
                    <Grid container justifyContent={'center'} justifyItems={'center'}>
                      <Grid item className={styles.emptyLabel}>No documents uploaded</Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Grid>
          </>)}
        </Grid>
      </Box>
    </>);
  };

  const renderTitle = () => {
    return (<Grid container justifyContent="space-between" spacing={2} alignContent={'center'} alignItems={'center'}>
      <Grid item className={styles.fullWidthOnXS}>{!!caseId ? `Action: ${!!currentActionState?.typeObj?.name ? currentActionState.typeObj.name : ''}` : 'Create action'}</Grid>
      <Grid item className={styles.fullWidthOnXS}>
        <FormControl variant="outlined" className={`${styles.selectWrapper} ${styles.actionStatusSelector}`}>
          <Select value={actionStatus} style={{ backgroundColor: "white" }} fullWidth disabled={!!inProgress}>
            {(!caseId ? ACTIONS_STATUSES_CREATION : ACTIONS_STATUSES).map((it) => (
              <MenuItem
                value={it}
                onClick={() => { if (!caseId) setActionStatus(it); else updateActionStatus(it); setInputTouched(1); }}
                key={it}
                disabled={!!inProgress || ((!assignedTo || !!checklist.find((it) => !it.isDone)) && it === 'COMPLETED')}
              >
                <div>{it}</div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>)
  };

  return <HeaderedPageLayout
    title={null}
    aboveCardTitle={renderTitle()}
    headerTitle={'Action'}
    progress={null}
    backAction={() => history.push(`/${source ?? 'dashboard'}${!!journeyId ? `/${journeyId}` : ''}`)}
    backCaption={!!caseId ? 'BACK' : 'CANCEL'}
    nextAction={saveAction}
    nextCaption={'SAVE'}
    nextDisabled={nextDisabled || inProgress || isEditingNotes || isEditingComment || isWaitingOnHoldParameters}
    nextHidden={!!caseId}
    inProgress={inProgress}
    noProgressBar
    noHeader
  >
    {checkIfDataLoaded() ? renderContent() : <CircularProgress size={80} className={styles.cardProgress} />}
    <ProvideElapsedTimeModal
      open={createTimeLogOpen}
      setOpen={setCreateTimeLogOpen}
      parameters={createTimeLogParameters}
      closeOnSubmit
      title={'Tracking action time'}
      description={'How much time you spent working on this action?'}
    />
    <AttachFilesModal
      journeys={journey ? [journey] : undefined}
      definedJourney={journey ?? undefined}
      hideJourneySelector
      documentTypes={documentTypes}
      setOpen={setOpenAttachFiles}
      onUpload={doUpload}
      open={openAttachFiles}
      isUploading={isUploading}
      currentProgress={currentProgress}
      updateDocumentsList={() => {}}
      purpose={purpose}
      document={documentToView}
      userRole={userRole}
      mustBeInternal={true}
      limitationMode={limitationMode}
      requiredUserNotRequired
    />
    <ConfirmDeletionModal open={deleteConfirmationOpen} setOpen={setDeleteConfirmationOpen} parameters={deleteConfirmationParameters}/>
  </HeaderedPageLayout>;
};

export default withDimensions(withRouter(CaseForm));
