import React, { useEffect } from 'react';
import { AppBar, Toolbar, Button, Grid, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { NestedMenuItem } from 'mui-nested-menu';

import styles from './styles.module.scss';
import {clearSelectedUser, getNextGenSSOURL, logOut} from '@user/sagas';
import Logo from '../Logo';
import history from '../../../../utils/history';
import { displayErrorMessage } from '@utils/displayErrorMessage';
import { withDimensions } from '../../HOC';
import config from '../../../../config';
import {getUserRole, hasAdminRole, hasSupervisorRole, isAllowedByRoles, USER_ROLE} from '@utils/authorisation';
import { displayNotification } from '@utils/snackbarUtils';
import ActionTimer from '../ActionTimer';


const MainHeader = ({ userMenuItems, navMenuItems }) => {
  const anchorRef = React.useRef(null);
  const anchorHeaderRef = React.useRef(null);

  const sortMenuItems = (items) => {
    return items.sort((a, b) => a.order - b.order);
  };

  const dispatch = useDispatch();
  const { user, selectedUser } = useSelector(state => state.user);
  const { headerType } = useSelector(state => state.common);
  const dimensions = useSelector(state => state.dimensions);

  const [open, setOpen] = React.useState(false);
  const [userRole, setUserRole] = React.useState(undefined);
  const [effectiveNavMenuItems, setEffectiveNavMenuItems] =
    React.useState(sortMenuItems(navMenuItems.filter((item) => !item.isForSpecialist && !item.isForSelectedUser)));
  const [effectiveUserMenuItems, setEffectiveUserMenuItems] =
    React.useState(userMenuItems.filter((item) => !item.isForSpecialist && !item.isForSelectedUser));
  const [mode, setMode] = React.useState(undefined);

  useEffect(() => {
    const handleLoggingOut = (e) => {
      if (e.key === 'shouldLogOut') {
        const shouldLogOutItem = localStorage.getItem('shouldLogOut');
        const explicitlyLoggedOutItem = sessionStorage.getItem('explicitlyLoggedOut');
        const shouldLogOutDate = shouldLogOutItem ? Number(shouldLogOutItem) : undefined;
        const explicitlyLoggedOutDate = explicitlyLoggedOutItem ? Number(explicitlyLoggedOutItem) : undefined;
        const shouldLogOutOutdated = !shouldLogOutDate || isNaN(shouldLogOutDate) || Date.now() - shouldLogOutDate > 1000 * 5; //5 seconds
        const explicitlyLoggedOutDateOutdated = !explicitlyLoggedOutDate || isNaN(explicitlyLoggedOutDate) || Date.now() - explicitlyLoggedOutDate > 1000 * 5; //5 seconds
        if (!shouldLogOutOutdated && explicitlyLoggedOutDateOutdated) {
          dispatch(logOut({}));
        } else {
          if (shouldLogOutOutdated && !!shouldLogOutItem) localStorage.removeItem('shouldLogOut');
        }
      }

      if (e.key === 'shouldRedirectToDashboard') {
        const shouldRedirectToDashboardItem = localStorage.getItem('shouldRedirectToDashboard');
        const explicitlyLoggedInItem = sessionStorage.getItem('explicitlyLoggedIn');
        const shouldRedirectToDashboardDate = shouldRedirectToDashboardItem ? Number(shouldRedirectToDashboardItem) : undefined;
        const explicitlyLoggedInDate = explicitlyLoggedInItem ? Number(explicitlyLoggedInItem) : undefined;
        const shouldRedirectToDashboardOutdated = !shouldRedirectToDashboardDate || isNaN(shouldRedirectToDashboardDate) || Date.now() - shouldRedirectToDashboardDate > 1000 * 5; //5 seconds
        const explicitlyLoggedInDateOutdated = !explicitlyLoggedInDate || isNaN(explicitlyLoggedInDate) || Date.now() - explicitlyLoggedInDate > 1000 * 5; //5 seconds
        if (!shouldRedirectToDashboardOutdated && explicitlyLoggedInDateOutdated) {
          sessionStorage.setItem('explicitlyLoggedIn', (Date.now()).toString());
          window.location.reload(false);
        } else {
          if (shouldRedirectToDashboardOutdated && !!shouldRedirectToDashboardItem) localStorage.removeItem('shouldRedirectToDashboard');
        }
      }
    };

    window.addEventListener('storage', handleLoggingOut)
    return function cleanup() {
      window.removeEventListener('storage', handleLoggingOut)
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    setUserRole(getUserRole(user));
  }, [user]); // eslint-disable-line

  useEffect(() => {
    if (!userRole || userRole === USER_ROLE.Customer) {
      setMode('customer');
      setEffectiveNavMenuItems(sortMenuItems(navMenuItems.filter((item) => !item.isForSpecialist && !item.isForSelectedUser)));
      setEffectiveUserMenuItems(userMenuItems.filter((item) => !item.isForSpecialist));
    } else if (userRole !== USER_ROLE.Customer) {
      if (selectedUser?.id) {
        setMode('selected');
        setEffectiveNavMenuItems(sortMenuItems(navMenuItems.filter((item) => !!item.isForSelectedUser && isAllowedByRoles(user, item.allowedRoles))));
        setEffectiveUserMenuItems(userMenuItems.filter((item) => isAllowedByRoles(user, item.allowedRoles)));
      } else {
        setMode('specialist');
        setEffectiveNavMenuItems(sortMenuItems(navMenuItems.filter((item) => !!item.isForSpecialist && isAllowedByRoles(user, item.allowedRoles) )));
        setEffectiveUserMenuItems(userMenuItems.filter((item) => isAllowedByRoles(user, item.allowedRoles)));
      }
    }
  }, [userRole, selectedUser, navMenuItems, user]); // eslint-disable-line

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, destination, externalUrl) => {
    if (destination) {
      history.push(destination);
      setOpen(false);
    } else if (externalUrl) {
      setOpen(false);
      const win = window.open(externalUrl, '_blank');
      if (win) win.focus();
    }

    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (anchorHeaderRef.current && anchorHeaderRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const doClearSelectedUser = () => {
    dispatch(clearSelectedUser({
      onSuccess: () => { history.push('/dashboard'); },
      onError: () => { displayErrorMessage(null, 'Unable to clear selected user'); }
    }));
  };

  const goToNextGenPage = (e) => {
    handleClose(e);
    displayNotification.success('The destination page will be opened in new tab');
    dispatch(getNextGenSSOURL({
      onSuccess: (data) => {
        handleClose(e, null, data);
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to get NextGen SSO URL');
      }
    }));
  };

  const renderUserAccountLabel = () => {
    return (
      <div>
        {user?.photo ? (
          <img
            width="42"
            height="42"
            className={`${styles.accountName}`}
            src={user.photo}
            alt="user avatar"
            data-test={'user-avatar'}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            onClick={handleToggle}
          />
        ) : (
          <div
            className={styles.accountName}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            data-test={'user-avatar'}
            onClick={handleToggle}
          >
            <PersonIcon />
          </div>
        )}
        <Popper
          open={open && dimensions.isScreenBiggerSM}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom"
          className={styles.userMenuPopper}
          arrow={{ enabled: true }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center top' }}
            >
              <Paper className={styles.userMenuPaper} elevation={1}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem className={`${styles.menuItem} ${styles.info}`} disableRipple disableTouchRipple>
                      <span>Signed in as{' '}<span className={styles.emphasis}>{user?.name ?? 'unknown'}</span></span>
                    </MenuItem>
                    {(hasSupervisorRole(user) || hasAdminRole(user)) && (<>
                      {window.mobileCheck() ? (<>
                        <MenuItem className={`${styles.menuItem}`} onClick={(e) => { goToNextGenPage(e); }}>Open NextGen</MenuItem>
                      </>) : (
                        <NestedMenuItem className={`${styles.nestedMenuItem}`} disableRipple disableTouchRipple label="Services" parentMenuOpen={!!open}>
                          <div className={styles.childMenuWrapper}>
                            <MenuItem className={`${styles.extendedMenuItem}`} onClick={(e) => { goToNextGenPage(e); }}>Open NextGen</MenuItem>
                          </div>
                        </NestedMenuItem>
                      )}
                    </>)}
                    {effectiveUserMenuItems.map((item) => (
                      <MenuItem className={styles.menuItem} onClick={(e) => handleClose(e, item.path, item.externalUrl)} key={item.path}>
                        <span>{item.name}</span>
                      </MenuItem>
                    ))}

                    <MenuItem
                      className={`${styles.menuItem} ${styles.logout}`}
                      onClick={(e) => {setOpen(false); dispatch(logOut({})); handleClose(e)}}
                      data-test={'logout-item'}
                    >
                      Log Out
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  return (
      <>
        <AppBar
          className={`${styles.appBar} ${headerType === 'registrationWizard' ? styles.wizard : ''}`}
          position={headerType === 'registrationWizard' ? 'static' : 'fixed'}
        >
          <Toolbar className={
            `${styles.toolBar} ${dimensions?.isScreenLessLG ? styles.mobile : ''} 
            ${headerType === 'wizard' || headerType === 'registrationWizard' ? styles.wizard : ''}`
          } ref={anchorHeaderRef}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" >
              <Grid item>
                <Grid container alignContent="center" direction={dimensions.isScreenLessMD ? 'column' : 'row'}>
                  <Grid item>
                    <Logo
                      to={headerType === 'scheduled' ? undefined : '/'}
                      externalLink={!user && headerType === 'registrationWizard' ? config.EXTERNAL_INFO_SITE : undefined}
                      clickAction={() => { setOpen(false); }}
                    />
                  </Grid>
                  {(userRole !== USER_ROLE.Customer) && mode === 'specialist' && (<Grid item className={styles.specialistLogin}>
                    {userRole === USER_ROLE.OperationsManagerWithCurrentJourneyEditRights ? USER_ROLE.OperationsManager : userRole}
                  </Grid>)}
                  {(userRole !== USER_ROLE.Customer) && mode === 'selected' && (<Grid item className={styles.specialistLogin}>
                    viewing as <span className={styles.emphasis}>{selectedUser?.name} {selectedUser?.surname}</span>
                    {' '}<span className={styles.close} onClick={() => doClearSelectedUser()}>[clear]</span>
                  </Grid>)}
                </Grid>
              </Grid>
              {headerType !== 'wizard' && headerType !== 'registrationWizard' && headerType !== 'scheduled' && (
                <Grid item>
                  {dimensions.isScreenBiggerSM ? (<>
                    <Grid
                      container
                      className={styles.navControlWrapper}
                      justifyContent="flex-end"
                      alignContent="center"
                      alignItems="center"
                    >
                      <ActionTimer />
                      {effectiveNavMenuItems.map((item) => (
                        <Button
                          color="inherit"
                          onClick={(e) => { e.preventDefault(); history.push(item.path); }}
                          className={styles.navButton}
                          key={item.path}
                          data-test={'nav-menu-item-button'}
                        >
                          <a
                            href={item.path}
                            onClick={(e) => { e.preventDefault(); }}
                            className={styles.anchor}
                          >
                            {item.name}
                          </a>
                        </Button>
                      ))}
                      {renderUserAccountLabel()}
                    </Grid>
                  </>) : (<Grid
                    item
                    container
                    className={styles.navControlWrapper}
                    justifyContent="flex-end"
                    alignContent="center"
                    alignItems="center"
                  >
                    <ActionTimer />
                    {open ? (
                      <CloseIcon onClick={handleToggle} className={styles.mobileMenuToggleIcon} />
                    ) : (
                      <MenuIcon onClick={handleToggle} className={styles.mobileMenuToggleIcon} />
                    )}
                  </Grid>)}
                </Grid>
              )}
              {(headerType === 'wizard' || headerType === 'registrationWizard' || headerType === 'scheduled') && (
                <ActionTimer noMargin />
              )}
              {/*{headerType === 'registrationWizard' && !user && (
                <Grid item>
                  <Grid
                    container
                    className={styles.navControlWrapper}
                    justifyContent="flex-end"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Button
                      color="inherit"
                      onClick={() => history.push('/')}
                      className={`${styles.navButton} ${styles.signInWizard}`}
                    >
                      SIGN IN
                    </Button>
                  </Grid>
                </Grid>
              )}*/}
            </Grid>
          </Toolbar>
          <Popper
            open={open && !dimensions.isScreenBiggerSM}
            anchorEl={anchorHeaderRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom"
            className={styles.mobileMenuPopper}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: 'center top' }}
              >
                <Paper className={styles.mobileMenuPaper} elevation={0}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow-mobile" onKeyDown={handleListKeyDown}>
                      <MenuItem className={styles.menuItemMobile} onClick={(e) => handleClose(e, '/dashboard')} key="/dashboard">
                        <span>Home</span>
                      </MenuItem>
                      {effectiveNavMenuItems.map((item) => (
                        <MenuItem className={styles.menuItemMobile} onClick={(e) => { e.preventDefault(); handleClose(e, item.path); }} key={item.path}>
                          <a
                            href={item.path}
                            onClick={(e) => { e.preventDefault(); }}
                            className={styles.anchor}
                          >
                            {item.name}
                          </a>
                        </MenuItem>
                      ))}
                      {effectiveUserMenuItems.map((item) => (
                        <MenuItem className={styles.menuItemMobile} onClick={(e) => handleClose(e, item.path, item.externalUrl)} key={item.path}>
                          <span>{item.name}</span>
                        </MenuItem>
                      ))}

                      {(hasSupervisorRole(user) || hasAdminRole(user)) && (<>
                        <MenuItem
                          className={styles.menuItemMobile}
                          onClick={(e) => {setOpen(false); goToNextGenPage(e);}}
                        >
                          Open NextGen
                        </MenuItem>
                      </>)}

                      <MenuItem
                        className={styles.menuItemMobile}
                        onClick={(e) => {setOpen(false); dispatch(logOut({})); handleClose(e)}}
                      >
                        Log Out
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </AppBar>
      </>
  )
};

MainHeader.propTypes = {
  userMenuItems: PropTypes.arrayOf(PropTypes.object),
  navMenuItems: PropTypes.arrayOf(PropTypes.object)
};

export default withDimensions(MainHeader);