import React, { useRef } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import { isFunc } from '../../../../utils/functions';


const ValidatedTextInput = (props) => {
  const {
    value,
    onChange,
    style = {},
    fullWidth = false,
    validators = [],
    errorMessages = [],
    name = 'inputField',
    InputProps = {},
    className,
    ref,
    ...other
  } = props;
  const fieldRef = useRef(null);
  const formRef = useRef(null);

  const handleChange = (event) => {
    if (isFunc(onChange)) onChange(event);
  };

  return (
    <ValidatorForm onSubmit={() => {}} debounceTime={600} ref={formRef} style={{ width: '100%' }}>
      <TextValidator
        value={value}
        onChange={handleChange}
        fullWidth={fullWidth}
        style={style}
        variant="outlined"
        name={name}
        validators={validators}
        errorMessages={errorMessages}
        InputProps={InputProps}
        ref={fieldRef}
        className={className}
        {...other}
      />
    </ValidatorForm>
  );
};

export default ValidatedTextInput;