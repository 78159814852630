import React, {lazy} from 'react';

import { AuthRoute } from '../auth/components';
//import { FinancialAssessment, Scheduled } from './pages';
//import IncorrectLink from './pages/IncorrectLink';


const IncorrectLink = lazy(() => import('./pages/IncorrectLink'));
const FinancialAssessment = lazy(() => import('./pages/FinancialAssessment'));
const Scheduled = lazy(() => import('./pages/Scheduled'));

const mainJourneyFlowModule = {
  routes: [
    <AuthRoute
      key="financial-situation"
      exact
      path="/financial-situation/:journeyId/:step"
      component={FinancialAssessment}
      headerType="private"
      componentProps={{ isFinancialSituation: true }}
    />,
    <AuthRoute
      key="financial-assessment"
      exact
      path="/financial-assessment/:journeyId/:step"
      component={FinancialAssessment}
      headerType="private"
    />,
    <AuthRoute
      key="approval"
      exact
      path="/approval/:journeyId/:step"
      component={FinancialAssessment}
      headerType="private"
    />,
    <AuthRoute
      key="conveyancing"
      exact
      path="/conveyancing/:journeyId/:step"
      component={FinancialAssessment}
      headerType="private"
    />,
    <AuthRoute
      key="settlement"
      exact
      path="/settlement/:journeyId/:step"
      component={FinancialAssessment}
      headerType="private"
    />,
    <AuthRoute
      key="scheduled"
      exact
      path="/scheduled"
      component={Scheduled}
      headerType="private"
    />,
    <AuthRoute
      key="incorrect-link"
      exact
      path="/incorrect-link"
      component={IncorrectLink}
      headerType="private"
    />,
    <AuthRoute
      key="find-deals"
      exact
      path="/find-deals/:step"
      component={FinancialAssessment}
      headerType="private"
    />
  ],
  navMenuItems: [
    {
      name: 'Deals',
      path: '/find-deals/find-deal-specialist',
      isForSpecialist: true,
      order: 2
    }
  ],
};

export default mainJourneyFlowModule;
