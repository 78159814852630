import config from "../config";
import { WPM, MONTHLY, WEEKLY } from '../vars';


export const addCommas = number => {
  const originString = number + '';
  const numArray = originString.split('.');
  let intPart = numArray[0];
  const fractionPart = numArray.length > 1 ? '.' + numArray[1] : '';
  const regExp = /(\d+)(\d{3})/;
  while (regExp.test(intPart)) {
    intPart = intPart.replace(regExp, '$1,$2');
  }
  return intPart + fractionPart;
};

export const removeCommas = strNumber => {
  return parseFloat(strNumber.replace(/,/g, ''));
};

export const formatDollarAmountMonthly = (value, abs = false) => {
  const coefficient = value?.frequency === MONTHLY ? 1 : value?.frequency === WEEKLY ? WPM : 1 / 12;
  return `$${addCommas(((abs ? Math.abs(Number(value?.amount ?? 0)) : Number(value?.amount ?? 0)) * coefficient).toFixed(2))}`;
};

export const formatDollarAmountYearly = (value, abs = false) => {
  const coefficient = value?.frequency === MONTHLY ? 12 : value?.frequency === WEEKLY ? 12 * WPM : 1;
  return `$${addCommas(((abs ? Math.abs(Number(value?.amount ?? 0)) : Number(value?.amount ?? 0)) * coefficient).toFixed(2))}`;
};

export const dollarAmountMonthly = (value) => {
  const coefficient = value?.frequency === MONTHLY ? 1 : value?.frequency === WEEKLY ? WPM : 1 / 12;
  return Number(value?.amount ?? 0) * coefficient;
};

export const base64ToBlob = (base64String) => {
  const splitDataURI = base64String.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? window.atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const setVerticalLineRenderParameters = (refs, setLineParameters) => {
  const activeRefs = refs
    .filter((ref) => !!ref?.current && !isNaN(ref.current.offsetLeft) && !isNaN(ref.current.offsetTop));
  let lineParameters = { left: undefined, top: undefined, height: undefined };
  if (activeRefs.length > 1) {
    const left = activeRefs[0].current?.offsetLeft;
    const tops = activeRefs.map((ref) => ref.current?.offsetTop);
    lineParameters = {
      left: `${left + 6}px`,
      top: `${Math.min(...tops) + 6}px`,
      height: `${Math.max(...tops) - Math.min(...tops)}px`
    };
  }

  setLineParameters(current => JSON.stringify(lineParameters) !== JSON.stringify(current) ? lineParameters : current);
};

export const getFileTypeObject = (fileName) => {
  const extension = (fileName ?? '').split('.').reverse()[0];
  switch (extension) {
    case 'png':
      return { type: 'image/png' };
    case 'jpg':
    case 'jpeg':
      return { type: 'image/jpeg' };
    case 'bmp':
      return { type: 'image/bmp' };
    case 'gif':
      return { type: 'image/gif' };
    case 'pdf':
      return { type: 'application/pdf' };
    case 'doc':
      return { type: 'application/msword' };
    case 'docx':
      return { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' };
    case 'rtf':
      return { type: 'application/rtf' };
    case 'txt':
      return { type: 'text/plain' };
    default:
      return {};
  }
};

export const decodeHTMLEntities = (function() {
  const element = document.createElement('div');

  function decodeHTMLEntities (str) {
    if(str && typeof str === 'string') {
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }

    return str;
  }

  return decodeHTMLEntities;
})();

export const getFileExtension = (file) => {
  const substrings = (file?.originalName ?? '').split('.');
  return substrings?.[(substrings?.length ?? 1) - 1] ?? '';
};

export const isFilePreviewSupported = (file) => {
  return ['jpg', 'jpeg', 'png', 'pdf', 'bmp', 'gif'].includes(getFileExtension(file));
};

export const isUrgentWithSignedContract = (journey) => {
  let userNeeds = journey?.userNeeds;

  return userNeeds?.whereAreYouInJourney === 'Urgent home loan'
    && !!userNeeds.conveyancerNeeds?.contractSigned
    && userNeeds.conveyancerNeeds.settlementDate;
};

export const isUrgentWithNoSignedContract = (journey) => {
  let userNeeds = journey?.userNeeds;

  return userNeeds?.whereAreYouInJourney === 'Urgent home loan'
    && (
      !userNeeds.conveyancerNeeds?.contractSigned || !userNeeds.conveyancerNeeds.settlementDate
    );
};

export const clearJourneysDashboardFilters = () => {
  localStorage.removeItem('SPECIALIST-JOURNEY-SEARCH-STRING');
  localStorage.removeItem('SPECIALIST-CHOSEN-USER-FILTER');
  localStorage.removeItem('SPECIALIST-CHOSEN-MILESTONE-FILTER');
  localStorage.removeItem('SPECIALIST-CHOSEN-SPECIALIST-FILTER-JOURNEYS');
  localStorage.removeItem('SPECIALIST-CHOSEN-ACCOUNT-FILTER-JOURNEYS');
  localStorage.removeItem('SPECIALIST-CHOSEN-REFERENCE-FILTER-JOURNEYS');
};

export const clearCustomersDashboardFilters = () => {
  localStorage.removeItem('SPECIALIST-CUSTOMER-SEARCH-STRING');
  localStorage.removeItem('SPECIALIST-CHOSEN-IDENTITY-FILTER');
  localStorage.removeItem('SPECIALIST-CHOSEN-STATUS-FILTER');
  localStorage.removeItem('SPECIALIST-CHOSEN-SPECIALIST-FILTER-CUSTOMERS');
  localStorage.removeItem('SPECIALIST-CHOSEN-ACCOUNT-FILTER-CUSTOMERS');
  localStorage.removeItem('SPECIALIST-CHOSEN-REFERENCE-FILTER-CUSTOMERS');
};

export const clearActionsDashboardFilters = () => {
  localStorage.removeItem('ACTIONS-SPECIALIST-ACTION-SEARCH-STRING');
  localStorage.removeItem('ACTIONS-SPECIALIST-CHOSEN-OWNER-FILTER');
  localStorage.removeItem('ACTIONS-SPECIALIST-CHOSEN-MILESTONE-FILTER');
  localStorage.removeItem('ACTIONS-SPECIALIST-CHOSEN-SPECIALIST-FILTER');
  localStorage.removeItem('ACTIONS-SPECIALIST-CHOSEN-ASSIGNEE-FILTER');
  localStorage.removeItem('ACTIONS-SPECIALIST-CHOSEN-STATUSES-FILTER');
  localStorage.removeItem('ACTIONS-SPECIALIST-CHOSEN-TYPE-FILTER');
  localStorage.removeItem('ACTIONS-SPECIALIST-SELECTED-TAB');
};

export const clearTimeTrackingInLocalStorage = () => {
  localStorage.removeItem('timeTrackingAction');
  localStorage.removeItem('timeTrackingActionName');
  localStorage.removeItem('timeTrackingUser');
  localStorage.removeItem('timeTrackingSeconds');
  localStorage.removeItem('timeTrackingLastUpdate');
  localStorage.removeItem('timeTrackingJourney');
  localStorage.removeItem('timeTrackingCustomer');
};

export const processActionTimeTrackerTick = (user) => {
  const prevUpdateStr = localStorage.getItem('timeTrackingLastUpdate');
  const prevUpdate = prevUpdateStr && !isNaN(Number(prevUpdateStr)) ? Number(prevUpdateStr) : undefined;

  if (prevUpdate) {
    const currentMilliseconds = Date.now();
    const elapsedTimeSec = (currentMilliseconds - prevUpdate) / 1000;

    if (elapsedTimeSec > config.TIME_LOGGER_RESET_TIME_SECONDS) {
      clearTimeTrackingInLocalStorage();
    } else {
      if (user?.id && user.id === localStorage.getItem('timeTrackingUser') && elapsedTimeSec > 0) {
        const prevSeconds = Number(localStorage.getItem('timeTrackingSeconds') ?? '0');
        const newTimeSeconds = prevSeconds + (elapsedTimeSec > config.TIME_LOGGER_PERIOD_SECONDS + 0.05 ? config.TIME_LOGGER_PERIOD_SECONDS + 0.05 : elapsedTimeSec);
        localStorage.setItem('timeTrackingLastUpdate', currentMilliseconds.toString());
        localStorage.setItem('timeTrackingSeconds', newTimeSeconds.toString());
      }
    }
  }
};

export const setRowsPerPageLimit = (rowsPerPage, setNewLimitFn) => {
  const LIMIT_OPTIONS = [10, 50, 100];

  if (isNaN(rowsPerPage) || !LIMIT_OPTIONS.includes(rowsPerPage)) {
    let rppCookie = localStorage.getItem('ROWS-PER-PAGE');
    if (!isNaN(rppCookie) && rppCookie !== '0' && LIMIT_OPTIONS.includes(Number(rppCookie))) {
      setNewLimitFn(Number(rppCookie));
    } else {
      setNewLimitFn(LIMIT_OPTIONS[0]);
      localStorage.removeItem('ROWS-PER-PAGE');
    }
  }
};

export const convertToKandM = (value) => {
  const number = Number(value);
  if (isNaN(number)) return number;
  if (Math.abs(number) < 1e3) return number.toFixed(2);
  if (Math.abs(number) >= 1e3 && number < 1e6) return (number / 1e3).toFixed(1) + 'K';
  if (Math.abs(number) >= 1e6 && number < 1e9) return (number / 1e6).toFixed(1) + 'M';
  if (Math.abs(number) >= 1e9 && number < 1e12) return (number / 1e9).toFixed(1) + 'B';
  if (Math.abs(number) >= 1e12) return (number / 1e12).toFixed(1) + 'T';
};

export const getDiscountDescription = (discounts) => {
  let description = '';

  (discounts ?? []).forEach((discount, idx) => {
    if (!!discount.description?.length) description += `Fee waiver: ${discount.description}\n\n`;

    if (discount.eligibility?.length && (discount.eligibility ?? []).some((e) => e.length)) {
      description += 'Fee waiver conditions: ';
      (discount.eligibility).forEach((e) => {
        description += `${e} `;
      });
      description += idx < ((discounts ?? []).length - 1) ? '\n\n' : '';
    }
  });

  return description;
};

export const getTimeZone = () => {
  const timezoneOffset = new Date().getTimezoneOffset()
  const offset = Math.abs(timezoneOffset)
  const offsetOperator = timezoneOffset < 0 ? '+' : '-'
  const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0')
  const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0')

  return `${offsetOperator}${offsetHours}:${offsetMinutes}`
};

export const isDateValid = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    if (isNaN(date)) {
      // date object is not valid
      return false;
    } else {
      // date object is valid
      return true;
    }
  } else {
    // not a date object
    return false;
  }
};

export const formatMobileString = (mobile) => {
  if (!mobile || mobile.length < 9 || mobile.length > 10) return '';

  let formatted = `+61${mobile.substr(0, 1) === '0' ? mobile.substr(1, 9) : mobile}`.split('');
  formatted.splice(9, 0, '\xa0');
  formatted.splice(6, 0, '\xa0');
  formatted.splice(3, 0, '\xa0');
  formatted = formatted.join('');

  return formatted;
};