import { combineReducers } from 'redux';
import modules from './modules';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import config from './config';
import { all } from 'redux-saga/effects';
import { MOBILE_CHECK_REGEX_1, MOBILE_CHECK_REGEX_2, SAFARI_CHECK_REGEX, TABLET_DEVICE_CHECK_REGEX, MOBILE_DEVICE_CHECK_REGEX } from './vars';

export const initializeDeviceAndBrowserCheck = () => {
  window.mobileCheck = () => {
    let check = false;
    ((a) => {
      if (MOBILE_CHECK_REGEX_1.test(a) || MOBILE_CHECK_REGEX_2.test(a.substr(0,4))) check = true;
    })(navigator.userAgent||navigator.vendor||window.opera);
    return check;
  };

  window.safariCheck = () => {
    let check = false;
    ((a) => {if(SAFARI_CHECK_REGEX.test(a)) check = true;})(navigator.userAgent||navigator.vendor||window.opera);
    return check;
  };

  window.deviceTypeCheck = () => {
    const ua = navigator.userAgent;
    if (TABLET_DEVICE_CHECK_REGEX.test(ua)) {
      return "tablet";
    }
    else if (MOBILE_DEVICE_CHECK_REGEX.test(ua)) {
      return "mobile";
    }
    return "desktop";
  };
};

export const initializeSagaMiddlewareAndGetStore = () => {
  const appReducer = combineReducers(modules.reducers);

  const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      state = undefined;
    }

    return appReducer(state, action);
  };

  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(sagaMiddleware),
    devTools: !!config.isLocalhost
  });

  sagaMiddleware.run(function* rootSaga() {
    yield all(modules.sagas);
  });

  return store;
};