import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Grid, Paper } from '@mui/material';

import { useSelector } from 'react-redux';
import { withDimensions } from '../../HOC';
import styles from './styles.module.scss';
import PageHeader from '../PageHeader';

const HeaderedPageLayout = ({
                              title,
                              aboveCardTitle,
                              headerTitle,
                              displayAskSpecialistWidget = false,
                              noXSPaddings = false,
                              noContentPaddings = false,
                              children,
                              backHidden = false,
                              backAction = (() => {}),
                              backCaption,
                              backDisabled = false,
                              nextHidden = false,
                              nextAction = (() => {}),
                              nextCaption,
                              nextDisabled,
                              progress,
                              beigeContentBg = false,
                              appendix,
                              noHeader = false,
                              noNarrowScreenButtonsFullWidth = false,
                              inProgress,
                              noProgressBar = false
}) => {
  const dimensions = useSelector(state => state.dimensions);

  return (
    <Box className={`${styles.pageWrapper} ${noXSPaddings ? styles.noXSPaddings : ''}`}>
      <Box sx={{ mt: dimensions?.isScreenXS && noXSPaddings ? 0 : 1, mb: 0 }} className={styles.dashboardContainer}>
        {!noHeader && (<PageHeader title={headerTitle} displayWidget={displayAskSpecialistWidget} />)}
        {(!dimensions?.isScreenBiggerXS && !noProgressBar) && (
          <div className={`${styles.progressBarContainer} ${!progress && progress !== 0 ? styles.transparent : ''}`}>
            <div className={styles.progressBar} style={{ width: `${progress}%` }} />
          </div>
        )}
        {(!!aboveCardTitle || aboveCardTitle === '') && (
          <Box className={`${styles.aboveCardTitleSection} ${noHeader ? styles.noHeader : ''} ${dimensions?.isScreenBiggerSM ? '' : styles.mobile}`}>
            {aboveCardTitle}
          </Box>
        )}
        <Paper className={`${styles.wizardPaper} ${noHeader ? styles.movedBottom : ''}`}>
          <Grid direction="column" justifyContent="space-between" container className={styles.mainGrid}>
            <Box>
              {(title || progress || title === '' || progress === 0) && (
                <Box className={`${styles.titleSection} ${dimensions?.isScreenBiggerSM ? '' : styles.mobile}`}>
                  {(dimensions?.isScreenBiggerXS && !noProgressBar) && (
                    <div className={`${styles.progressBarContainer} ${!progress && progress !== 0 ? styles.transparent : ''}`}>
                      <div className={styles.progressBar} style={{ width: `${progress}%` }} />
                    </div>
                  )}
                  {title}
                </Box>
              )}
              <Box className={`${styles.contentSection} ${beigeContentBg ? styles.beige : ''} ${noContentPaddings ? styles.noContentPaddings : ''}`}>
                {children}
              </Box>
              {appendix && (
                <Box className={`${styles.contentSection} ${styles.appendix} ${noContentPaddings ? styles.noContentPaddings : ''}`}>
                  {appendix}
                </Box>
              )}
            </Box>
            <Grid direction="row" justifyContent="space-between" container item className={styles.buttonWrapper}>
              {!backHidden ? (
                <Button
                  className={`${styles.actionButton} ${noNarrowScreenButtonsFullWidth ? styles.noNarrowScreenButtonsFullWidth : ''}`}
                  onClick={backAction}
                  color="secondary"
                  variant="outlined"
                  type="button"
                  disableElevation
                  disabled={backDisabled}
                >
                  {backCaption}
                </Button>
              ) : (<Grid item />)}
              {!nextHidden ? (
                <Button
                  className={`${styles.actionButton} ${noNarrowScreenButtonsFullWidth ? styles.noNarrowScreenButtonsFullWidth : ''}`}
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={nextDisabled}
                  onClick={nextAction}
                >
                  {inProgress && <CircularProgress size={14} className={styles.buttonProgress} />}{nextCaption}
                </Button>
              ) : (<Grid item />)}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  )
};

HeaderedPageLayout.propTypes = {
  title: PropTypes.any,
  headerTitle: PropTypes.string,
  displayAskSpecialistWidget: PropTypes.bool,
  noXSPaddings: PropTypes.bool,
  noContentPaddings: PropTypes.bool,
  backHidden: PropTypes.bool,
  backAction: PropTypes.func,
  backCaption: PropTypes.string,
  nextHidden: PropTypes.bool,
  nextAction: PropTypes.func,
  nextCaption: PropTypes.string,
  nextDisabled: PropTypes.bool,
  backDisabled: PropTypes.bool,
  progress: PropTypes.number,
  beigeContentBg: PropTypes.bool,
  inProgress: PropTypes.bool
};

export default React.memo(withDimensions(HeaderedPageLayout));