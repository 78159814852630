import React from 'react';
import { Route } from 'react-router-dom';

import { NoRoute } from './components';

const module404 = {
  routes: [<Route key="*" path="*" exact component={NoRoute} />]
};

export default module404;
