import React, {useEffect, useState} from 'react';
import {
  Box,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  CircularProgress,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import styles from './styles.module.scss';
import './styles.global.scss';
import DashboardLayout from '../../common/components/DashboardLayout';
import { displayErrorMessage } from '../../../utils/displayErrorMessage';
import history from '../../../utils/history';
import { clearSelectedUser, getAllowedUsersWithDebounce, getSpecialists } from '../../user/sagas';
import CustomPaginator from '../../common/components/CustomPaginator';
import { getUserRole, isJustDataOperations, USER_ROLE } from '../../../utils/authorisation';
import { getCaseTypes, listTimeLogs, listTimeLogsCSV } from '../sagas';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import auLocale from "date-fns/locale/en-AU";
import StandardDatePicker from "../../common/components/StandardDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";


const ActionsTimecard = () => {
  const { user, selectedUser } = useSelector(state => state.user);
  const dimensions = useSelector(state => state.dimensions);
  const { rowsPerPage } = useSelector(state => state.pagination);
  const dispatch = useDispatch();

  const [userRole, setUserRole] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [timeLogs, setTimeLogs] = useState(undefined);
  const [owners, setOwners] = useState([]);
  const [chosenOwner, setChosenOwner] = useState(undefined);
  const [specialists, setSpecialists] = useState([]);
  const [chosenSpecialist, setChosenSpecialist] = useState(null);
  const [chosenEntryLogger, setChosenEntryLogger] = useState(null);
  const [actionTypes, setActionTypes] = useState([]);
  const [chosenActionType, setChosenActionType] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [isAdditionalFiltersOpened, setIsAdditionalFiltersOpened] = useState(false);
  const [isAdditionalFiltersLinkDisabled, setIsAdditionalFiltersLinkDisabled] = useState(true);

  const [sortBy, setSortBy] = useState('');

  useEffect(() => {
    dispatch(getSpecialists({
      onSuccess: (data) => { setSpecialists(data.elements ?? []); },
      onError: (data) => { displayErrorMessage(data, 'Unable to retrieve specialists'); }
    }));

    dispatch(getCaseTypes({
      onSuccess: (data) => {
        setActionTypes(data);
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to retrieve action types');
      }
    }));

    const sorting = localStorage.getItem('ACTIONS-TIMECARD-SORTING');
    if (sorting) setSortBy(sorting);
    else setSortBy('createdAt');

    const ownerOption = localStorage.getItem('ACTIONS-TIMECARD-CHOSEN-OWNER-FILTER');
    const specialistOption = localStorage.getItem('ACTIONS-TIMECARD-CHOSEN-SPECIALIST-FILTER');
    const actionTypeOption = localStorage.getItem('ACTIONS-TIMECARD-CHOSEN-TYPE-FILTER');
    const entryLoggerOption = localStorage.getItem('ACTIONS-TIMECARD-CHOSEN-ENTRY-LOGGER-FILTER');

    if (ownerOption) {
      setOwners([JSON.parse(ownerOption)]);
      setChosenOwner(JSON.parse(ownerOption));
    }
    if (specialistOption) setChosenSpecialist(JSON.parse(specialistOption));
    if (actionTypeOption) setChosenActionType(JSON.parse(actionTypeOption));
    if (entryLoggerOption) setChosenEntryLogger(JSON.parse(entryLoggerOption));

    const currentDate = new Date();
    setFromDate(currentDate);
    setToDate(currentDate);
  }, []);//eslint-disable-line

  useEffect(() => {
    if (!!chosenOwner) {
      localStorage.setItem('ACTIONS-TIMECARD-CHOSEN-OWNER-FILTER', JSON.stringify({_id: chosenOwner._id, name: chosenOwner.name, surname: chosenOwner.surname }));
    } else {
      localStorage.removeItem('ACTIONS-TIMECARD-CHOSEN-OWNER-FILTER');
    }
  }, [chosenOwner]);//eslint-disable-line

  useEffect(() => {
    if (!!chosenSpecialist) {
      localStorage.setItem(
        'ACTIONS-TIMECARD-CHOSEN-SPECIALIST-FILTER',
        JSON.stringify({id: chosenSpecialist.id, name: chosenSpecialist.name, surname: chosenSpecialist.surname })
      );
    } else {
      localStorage.removeItem('ACTIONS-TIMECARD-CHOSEN-SPECIALIST-FILTER');
    }
  }, [chosenSpecialist]);//eslint-disable-line

  useEffect(() => {
    if (!!chosenActionType) {
      localStorage.setItem('ACTIONS-TIMECARD-CHOSEN-TYPE-FILTER', JSON.stringify({id: chosenActionType.id, name: chosenActionType.name, uid: chosenActionType.uid }));
    } else {
      localStorage.removeItem('ACTIONS-TIMECARD-CHOSEN-TYPE-FILTER');
    }
  }, [chosenActionType]);//eslint-disable-line

  useEffect(() => {
    if (!!chosenEntryLogger) {
      localStorage.setItem(
        'ACTIONS-TIMECARD-CHOSEN-ENTRY-LOGGER-FILTER',
        JSON.stringify({id: chosenEntryLogger.id, name: chosenEntryLogger.name, surname: chosenEntryLogger.surname })
      );
    } else {
      localStorage.removeItem('ACTIONS-TIMECARD-CHOSEN-ENTRY-LOGGER-FILTER');
    }
  }, [chosenEntryLogger]);//eslint-disable-line

  useEffect(() => {
    if (!!sortBy) {
      localStorage.setItem('ACTIONS-TIMECARD-SORTING', sortBy);
    } else {
      localStorage.removeItem('ACTIONS-TIMECARD-SORTING');
    }
  }, [sortBy]);//eslint-disable-line

  useEffect(() => {
    dispatch(clearSelectedUser({
      onSuccess: () => {},
      onError: () => { displayErrorMessage(null, 'Unable to clear selected user'); }
    }));
  }, [selectedUser]);//eslint-disable-line

  useEffect(() => {
    if (!!chosenSpecialist || !!chosenActionType || !!chosenOwner) {
      setIsAdditionalFiltersOpened(true);
      setIsAdditionalFiltersLinkDisabled(true);
    } else {
      setIsAdditionalFiltersLinkDisabled(false);
    }

    retrieveTimeLogs(1);
  }, [chosenOwner, sortBy, chosenSpecialist, chosenActionType, chosenEntryLogger, fromDate, toDate]);//eslint-disable-line

  useEffect(() => {
    if (!!chosenSpecialist && !!specialists?.length && !specialists.includes(chosenSpecialist)) {
      const correctSpecialist = specialists.find((s) => s.id === chosenSpecialist?.id);
      if (correctSpecialist) setChosenSpecialist(correctSpecialist);
    }
  }, [chosenSpecialist, specialists]);//eslint-disable-line

  useEffect(() => {
    if (!!chosenEntryLogger && !!specialists?.length && !specialists.includes(chosenEntryLogger)) {
      const correctEntryLogger = specialists.find((s) => s.id === chosenEntryLogger?.id);
      if (correctEntryLogger) setChosenEntryLogger(correctEntryLogger);
    }
  }, [chosenEntryLogger, specialists]);//eslint-disable-line

  useEffect(() => {
    if (!!chosenActionType && !!actionTypes?.length && !actionTypes.includes(chosenActionType)) {
      const correctActionType = actionTypes.find((s) => s.uid === chosenActionType?.uid);
      if (correctActionType) setChosenActionType(correctActionType);
    }
  }, [chosenActionType, actionTypes]);//eslint-disable-line

  useEffect(() => {
    if (isJustDataOperations(user)) history.push('/deals-management');

    setUserRole(getUserRole(user));
  }, [user]);//eslint-disable-line

  useEffect(() => {
    if (userRole === USER_ROLE.Customer) {
      history.push('/dashboard');
    }
  }, [userRole]);//eslint-disable-line

  useEffect(() => {
    retrieveTimeLogs(1);
  }, [rowsPerPage]);//eslint-disable-line

  const retrieveTimeLogs = (page = 1) => {
    if (currentPage !== page) setCurrentPage(page);

    let options = {
      limit: rowsPerPage,
      page,
      onSuccess: (data) => {
        setTimeLogs(data);
        setIsLoading(false);
      },
      onError: (data) => {
        setIsLoading(false);
        displayErrorMessage(data, 'Unable to retrieve actions');
      }
    };

    if (sortBy) options.sort = sortBy;
    if (chosenEntryLogger) options.entryLogger = chosenEntryLogger.id;
    if (chosenOwner?._id) options.journeyOwner = chosenOwner._id;
    if (chosenSpecialist) options.specialist = chosenSpecialist?.id;
    if (chosenActionType) options.type = chosenActionType.uid;
    if (fromDate) options.fromDate = fromDate;
    if (toDate) options.toDate = toDate;

    setIsLoading(true);
    dispatch(listTimeLogs(options));
  };

  const getTimeStringFromMinutes = (minutes) => {
    const h = Math.floor((minutes ?? 0) / 60);
    const m = (minutes ?? 0) % 60;

    return `${h > 0 ? `${h}h ` : ''}${m < 10 ? '0' : ''}${m}m`;
  };

  const onOwnerSearchStringChanged = (newValue) => {
    let options = {
      limit: 10,
      onSuccess: (data) => {
        setOwners(data?.elements ?? []);
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to retrieve allowed users');
      }
    };
    if (newValue) options.search = newValue;

    dispatch(getAllowedUsersWithDebounce(options));
  };

  const updateSorting = (field) => {
    setSortBy(sortBy === field ? `-${field}` : sortBy === ('-' + field) ? '' : `${field}`);
  };

  const doDownload = () => {
    setIsDownloading(true);

    let options = {
      onSuccess: (data) => {
        const csv = data;
        const link = window.document.createElement('a');
        link.href = 'data:text/csv,' + encodeURIComponent(csv);
        link.download = 'ActionsTimecard.csv';
        window.document.body.appendChild(link);

        setTimeout(() => {
          link.click();
          try { window.document.body.removeChild(link); } catch {}
          setIsDownloading(false);
        }, 10);
      },
      onError: (data) => {
        setIsDownloading(false);
        displayErrorMessage(data, 'Unable to download the file');
      }
    };

    if (sortBy) options.sort = sortBy;
    if (chosenEntryLogger) options.entryLogger = chosenEntryLogger.id;
    if (chosenOwner?._id) options.journeyOwner = chosenOwner._id;
    if (chosenSpecialist) options.specialist = chosenSpecialist?.id;
    if (chosenActionType) options.type = chosenActionType.uid;
    if (fromDate) options.fromDate = fromDate;
    if (toDate) options.toDate = toDate;

    dispatch(listTimeLogsCSV(options))
  };

  const renderDownloadButton = (isSmall = false) => {
    return (
      <IconButton
        aria-label="download"
        onClick={() => { if (!isLoading && !isDownloading) doDownload(); }}
        className={`${styles.downloadIconButton} ${!!isLoading || !!isDownloading || !timeLogs?.total ? styles.disabled : ''} ${isSmall ? styles.small : ''}`}
        disabled={!!isLoading || !!isDownloading || !timeLogs?.total}
      >
        <CloudDownloadIcon />
      </IconButton>
    );
  };

  const renderToolbar = () => {
    let minDate = new Date();
    minDate.setDate(minDate.getDate() - 90);

    return (
      <Box className={styles.toolbar}>
        <Grid container justifyContent="space-between" alignItems="center" direction="row" spacing={2}>
          <Grid container item spacing={dimensions.isScreenBiggerSM ? 2 : 0} className={styles.selectorsWrapper} xs={12} direction={dimensions.isScreenBiggerSM ? 'row' : 'column'}>
            <Grid item xs={12} md={3} className={`${styles.toolbarSelectContainer} SMALL`}>
              <FormControl variant="outlined" className={styles.selectWrapper}>
                <Select value={chosenEntryLogger} displayEmpty style={{ backgroundColor: "white" }}>
                  <MenuItem value={null} style={ !chosenEntryLogger ? { display: 'none' } : {} } onClick={() => setChosenEntryLogger(null)}>
                    <div style={{ textTransform: "none", color: '#8B90A0' }}>{chosenEntryLogger ? ('Clear filtration') : ('Filter by entry logger')}</div>
                  </MenuItem>
                  {(specialists ?? []).map((option, idx) => {
                    return (
                      <MenuItem value={option} key={idx} onClick={() => setChosenEntryLogger(option)}>
                        <div style={{ textTransform: "none" }}>{option.name} {option.surname}</div>
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} className={`${styles.toolbarSelectContainer} SMALL WHITE-BG`}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={auLocale}>
                <StandardDatePicker
                  value={fromDate}
                  onChange={(date) => {
                    setFromDate(date);
                  }}
                  style={{ width: '100%'}}
                  disableFuture={!toDate}
                  limitInFuture={toDate}
                  limitInPast={minDate}
                  placeholder={'FROM dd/mm/yyyy'}
                  label={'FROM'}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3} className={`${styles.toolbarSelectContainer} SMALL WHITE-BG`}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={auLocale}>
                <StandardDatePicker
                  value={toDate}
                  onChange={(date) => {
                    setToDate(date);
                  }}
                  style={{ width: '100%'}}
                  disableFuture
                  limitInPast={fromDate ?? minDate}
                  placeholder={'TO dd/mm/yyyy'}
                  label={'TO'}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3} container justifyContent={'space-between'} alignContent={'center'}>
              <Grid item>
                <span
                  className={`${styles.link} ${isAdditionalFiltersLinkDisabled ? styles.disabled : ''}`}
                  onClick={() => {
                    if (!isAdditionalFiltersLinkDisabled) setIsAdditionalFiltersOpened(!isAdditionalFiltersOpened);
                  }}
                >
                  {isAdditionalFiltersOpened ? 'Hide' : 'Show'} additional filters
                </span>
              </Grid>
              <Grid item>
                {!!dimensions.isScreenBiggerSM && renderDownloadButton(true)}
              </Grid>
            </Grid>

            {isAdditionalFiltersOpened && (<>
              <Grid item xs={12} md={3} className={`${styles.toolbarSelectContainer} SMALL`}>
                <FormControl variant="outlined" className={styles.selectWrapper}>
                  <Select value={chosenActionType} displayEmpty style={{ backgroundColor: "white" }}>
                    <MenuItem value={null} style={ !chosenActionType ? { display: 'none' } : {} } onClick={() => setChosenActionType(null)}>
                      <div style={{ textTransform: "none", color: '#8B90A0' }}>{chosenActionType ? 'Clear filtration' : 'Filter by action'}</div>
                    </MenuItem>
                    {(actionTypes ?? []).map((option, idx) => {
                      return (
                        <MenuItem value={option} key={idx} onClick={() => setChosenActionType(option)}>
                          <div style={{ textTransform: "none" }}>{option?.name}</div>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3} className={`${styles.toolbarSelectContainer} SMALL`}>
                <Autocomplete
                  options={owners}
                  getOptionLabel={(option) => `${option?.name}${option?.surname ? ' ' + option?.surname : ''}` }
                  fullWidth
                  filterOptions={(options) => options}
                  value={chosenOwner ?? null }
                  onChange={(event, newValue) => {
                    setChosenOwner(newValue);
                  }}
                  renderInput={(params) => <TextField
                    {...params}
                    style={{ backgroundColor: "white" }}
                    onChange={(event) => {
                      onOwnerSearchStringChanged(event.target.value);
                    }}
                    onFocus={(event) => {
                      if (!event.target.value) onOwnerSearchStringChanged(event.target.value);
                    }}
                    placeholder="Filter by customer"
                    variant="outlined"
                    maxLength="100"
                  />}
                />
              </Grid>

              <Grid item xs={12} md={3} className={`${styles.toolbarSelectContainer} SMALL`}>
                <FormControl variant="outlined" className={styles.selectWrapper}>
                  <Select value={chosenSpecialist} displayEmpty style={{ backgroundColor: "white" }}>
                    <MenuItem value={null} style={ !chosenSpecialist ? { display: 'none' } : {} } onClick={() => setChosenSpecialist(null)}>
                      <div style={{ textTransform: "none", color: '#8B90A0' }}>{chosenSpecialist ? ('Clear filtration') : ('Filter by specialist')}</div>
                    </MenuItem>
                    {(specialists ?? []).map((option, idx) => {
                      return (
                        <MenuItem value={option} key={idx} onClick={() => setChosenSpecialist(option)}>
                          <div style={{ textTransform: "none" }}>{option.name} {option.surname}</div>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>)}

          </Grid>
        </Grid>
      </Box>
    );
  };

  return <DashboardLayout team={[]} hideExtraWidgets>
    <Grid container justifyContent="space-between" alignItems="center" alignContent="center">
      <Grid item className={`${styles.pageTitle} ${dimensions.isScreenBiggerSM ? '' : styles.mobile}`}>Actions timecard</Grid>
      <Grid item>
        {!dimensions.isScreenBiggerSM && renderDownloadButton()}
      </Grid>
    </Grid>

    <Box sx={{ mt: 2 }} className={'ActionsDashboardTableWrapper'}>
      {renderToolbar()}
      <TableContainer className={`${styles.tableContainer} ${styles.fixedWidth} ActionsDashboardTableWrapper`}>
        <Table size={dimensions.isScreenBiggerSM ? "medium" : "small"} stickyHeader>
          <TableHead>
            <TableRow className={styles.tableHead}>
              <TableCell
                align={'left'}
                style={{ minWidth: 104 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('case.type.name')}>
                  ACTION{sortBy === 'case.type.name' ? (<> &#9650;</>) : sortBy === '-case.type.name' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'left'}
                style={{ minWidth: 144, maxWidth: 304 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('customer.name')}>
                  CUSTOMER / JOURNEY{sortBy === 'customer.name' ? (<> &#9650;</>) : sortBy === '-customer.name' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'left'}
                style={{ minWidth: 96 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('specialist.name')}>
                  SPECIALIST{sortBy === 'specialist.name' ? (<> &#9650;</>) : sortBy === '-specialist.name' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'left'}
                style={{ minWidth: 104 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('user.name')}>
                  ENTRY LOGGER{sortBy === 'user.name' ? (<> &#9650;</>) : sortBy === '-user.name' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'left'}
                style={{ minWidth: 104 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('createdAt')}>
                  LOG DATE{sortBy === 'createdAt' ? (<> &#9650;</>) : sortBy === '-createdAt' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'left'}
                style={{ minWidth: 92, maxWidth: 92 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('minutes')}>
                  LOGGED TIME{sortBy === '-minutes' ? (<> &#9650;</>) : sortBy === 'minutes' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={styles.tableBody}>
            {(timeLogs?.elements ?? []).map((timeLog, idx) => {
              return (
                <TableRow className={`${styles.tableItem}`} key={idx}>
                  <TableCell
                    align={'left'}
                  >
                    <div className={styles.name}>
                      <a
                        href={`/action-edit/${timeLog.case?._id}?source=actions-timecard`}
                        onClick={(e) => {
                          e.preventDefault(); history.push(`/action-edit/${timeLog.case._id}?source=actions-timecard`);
                        }}
                      >{timeLog.case?.typeObj?.name}</a>
                    </div>
                    {timeLog?.case?.typeObj?.description && (
                      <div className={styles.caption}>
                        {timeLog.case?.typeObj?.description}
                      </div>
                    )}
                  </TableCell>

                  <TableCell
                    align={'left'}
                    className={`${styles.name} ${styles.clickable}`}
                  >
                    <div className={`${styles.username} ${styles.clickable}`}>
                      <a
                        href={`/profile/${timeLog.customer?._id}`}
                        onClick={(e) => { e.preventDefault(); history.push(`/profile/${timeLog.customer?._id}`); }}
                      >{timeLog.customer?.name} {timeLog.customer?.surname}</a>
                    </div>
                    <div className={`${styles.caption} ${styles.clickable}`}>
                      <a
                        href={`/journey-details/${timeLog.journey?._id}`}
                        onClick={(e) => { e.preventDefault(); history.push(`/journey-details/${timeLog.journey?._id}`); }}
                      >{timeLog.journey?.name}</a>
                    </div>
                  </TableCell>

                  <TableCell
                    align={'left'}
                    className={`${styles.username}`}
                  >
                    <span>{timeLog.specialist?.name ? `${timeLog.specialist?.name} ${timeLog.specialist?.surname}` : '-'}</span>
                  </TableCell>

                  <TableCell
                    align={'left'}
                    className={`${styles.username}`}
                  >
                    <span>{timeLog.user?.name ? `${timeLog.user?.name} ${timeLog.user?.surname}` : '-'}</span>
                  </TableCell>

                  <TableCell align={'left'} className={styles.date}>
                    {timeLog?.createdAt ? format(new Date(timeLog.createdAt), 'dd.MM.yy HH:mm') : '???'}
                  </TableCell>

                  <TableCell align={'left'} className={styles.date}>
                    {getTimeStringFromMinutes(timeLog.minutes ?? 0)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {(!timeLogs?.elements?.length) && (
          <Grid container className={styles.emptyTable} justifyContent={"center"} alignItems={"center"}>
            <Grid item>{isLoading ? 'Loading...' : 'No time logs found'}</Grid>
          </Grid>
        )}
        {(isLoading) && (<CircularProgress size={80} className={styles.cardProgress} />)}
      </TableContainer>
      {(timeLogs?.total && timeLogs?.total > 10) && (
        <CustomPaginator
          count={timeLogs?.total && timeLogs?.limit ? Math.ceil(timeLogs.total / timeLogs.limit) : 1}
          page={currentPage}
          onChange={(e, page) => retrieveTimeLogs(page)}
          className={styles.pagination}
        />
      )}
    </Box>
  </DashboardLayout>;
};

export default ActionsTimecard;