import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';


const ValidationErrorLabel = ({ errorMessage, width, success }) => {

  return (
    errorMessage
      ? (<>
        <div className={!!success ? styles.successLabel : styles.errorLabel} style={width ? { width: width } : {}}>{errorMessage}</div>
      </>)
      : null
  );
};

ValidationErrorLabel.propTypes = {
  errorMessage: PropTypes.any,
  width: PropTypes.string
};

export default ValidationErrorLabel;