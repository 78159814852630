import React, { lazy } from 'react';
import { FreeRoute } from '../auth/components';

//import { ShortRedirects } from './pages';
import sagas from './sagas';


const ShortRedirects = lazy(() => import('./pages/ShortRedirects'));

const shortRedirectModule = {
  routes: [<FreeRoute key="help" exact path="/s/:shortId" component={ShortRedirects} headerType={'wizard'} />],
  sagas
};

export default shortRedirectModule;
