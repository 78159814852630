import { call, takeLatest, takeEvery, debounce } from 'redux-saga/effects';
import axios from 'axios';

import { isFunc } from '@utils/functions';

const Api = {
  getNotes: ({ page, limit, title, sort, type, customerId, journeyId }) => {
    let params = {
      limit: limit ?? 10
    };
    if (page || page === 0) params.page = page;
    if (title || title === '') params.title = title;
    if (sort) params.sort = sort;
    if (type) params.type = type;
    if (customerId) params.customerId = customerId;
    if (journeyId) params.journeyId = journeyId;

    return axios.request({
      url: '/notes',
      method: 'get',
      params
    });
  },
  getNote: (noteId) => axios.get(`/notes/${noteId}`),
  createNote: (body) => axios.post('/notes', body),
  updateNote: ({noteId, body}) => axios.put(`/notes/${noteId}`, body),
  deleteNote: (noteId) => axios.delete(`/notes/${noteId}`),
};

export const actionTypes = {
  GET_NOTES: 'GET_NOTES',
  GET_NOTES_WITH_DEBOUNCE: 'GET_NOTES_WITH_DEBOUNCE',
  GET_NOTE: 'GET_NOTE',
  CREATE_NOTE: 'CREATE_NOTE',
  UPDATE_NOTE: 'UPDATE_NOTE',
  DELETE_NOTE: 'DELETE_NOTE',
};

function* sagaGetNotes({ payload: { page, limit, title, sort, type, customerId, journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getNotes, {page, limit, title, sort, type, customerId, journeyId});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetNote({ payload: { noteId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getNote, noteId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateNote({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createNote, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateNote({ payload: { noteId, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateNote, { noteId, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteNote({ payload: { noteId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteNote, noteId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess();
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getNotes = payload => ({ type: actionTypes.GET_NOTES, payload });
export const getNotesWithDebounce = payload => ({ type: actionTypes.GET_NOTES_WITH_DEBOUNCE, payload });
export const getNote = payload => ({ type: actionTypes.GET_NOTE, payload });
export const createNote = payload => ({ type: actionTypes.CREATE_NOTE, payload });
export const updateNote = payload => ({ type: actionTypes.UPDATE_NOTE, payload });
export const deleteNote = payload => ({ type: actionTypes.DELETE_NOTE, payload });

const notesSagas = [
  takeLatest(actionTypes.GET_NOTES, sagaGetNotes),
  debounce(900, actionTypes.GET_NOTES_WITH_DEBOUNCE, sagaGetNotes),
  takeEvery(actionTypes.GET_NOTE, sagaGetNote),
  takeEvery(actionTypes.CREATE_NOTE, sagaCreateNote),
  takeEvery(actionTypes.UPDATE_NOTE, sagaUpdateNote),
  takeEvery(actionTypes.DELETE_NOTE, sagaDeleteNote),
];

export default notesSagas;