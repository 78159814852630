import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import styles from './styles.module.scss';

const StandardDatePicker = ({ value, onChange = () => {}, onBlur = () => {}, disableFuture = false, disablePast = false, errorText = '', style, limitInPast, limitInFuture, placeholder, disabled = false }) => {

  return (<>
    <DesktopDatePicker
      autoOk
      required
      variant="outlined"
      inputVariant="outlined"
      format="dd/MM/yyyy"
      mask={'__/__/____'}
      value={value}
      InputAdornmentProps={{ position: "end" }}
      onChange={date => onChange(date)}
      onBlur={onBlur}
      style={style}
      disabled={disabled}
      className={`${!!errorText ? styles.error : ''}`}
      minDate={disablePast ? new Date() : (limitInPast ?? undefined)}
      maxDate={disableFuture ? new Date() : (limitInFuture ?? undefined)}
      renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps, placeholder: placeholder ?? 'dd/mm/yyyy'}} />}
    />
    {!!errorText && (<div className={styles.errorLabel}>{errorText}</div>)}
  </>);
};

StandardDatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  errorText: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default StandardDatePicker;
