import mainLayoutAppearance from './mainLayoutAppearance';
import dimensions from './dimensions';
import pagination from './pagination';
import blockingDialogs from './blockingDialogs';

const commonModules = {
  mainLayoutAppearance,
  dimensions,
  pagination,
  blockingDialogs
};

export default commonModules;

export * from './mainLayoutAppearance';
export * from './dimensions';
export * from './pagination';
export * from './blockingDialogs';
