import React from 'react';
import { Button, Dialog, Grid, Box } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styles from '../ConfirmDeletionModal/styles.module.scss';
import '../ConfirmDeletionModal/styles.module.scss';
import { withDimensions } from '../../../common/HOC';
import { isFunc } from '../../../../utils/functions';


const ConfirmActionModal = ({ open, setOpen, title, content, yesLabel, noLabel, parameters, hideNoButton = false }) => {
  const dimensions = useSelector(state => state.dimensions);

  return (
    <>
      <Dialog open={open} className={'ConfirmDeletionDialog'} onClose={() => {}} fullScreen={dimensions.isScreenLessSM}>
        <MuiDialogTitle>
          <Box className={`${styles.modalTitle} ${dimensions.isScreenLessMD ? styles.mobile : ''}`}>{parameters?.title ?? title}</Box>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className={styles.text}>
            {!!content && (<p>{content}</p>)}
            {(!content && !!parameters?.content) && (<>{parameters?.content}</>)}
          </Box>
        </MuiDialogContent>
        <MuiDialogActions>
          <Grid container justifyContent="space-between">
            {!!hideNoButton ? (
              <Grid item />
            ) : (
              <Button
                className={`${styles.actionButton} ${styles.secondary}`}
                onClick={() => { if (isFunc(parameters?.noAction)) parameters.noAction(); setOpen(false); }}
                color="secondary"
                variant="outlined"
                type="button"
                disableElevation
              >
                {noLabel ?? 'NO'}
              </Button>
            )}
            <Button
              className={`${styles.actionButton}`}
              type="button"
              variant="contained"
              color="primary"
              onClick={() => { if (isFunc(parameters?.yesAction)) parameters.yesAction(); setOpen(false); }}
            >
              {yesLabel ?? 'YES'}
            </Button>
          </Grid>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

ConfirmActionModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  parameters: PropTypes.object,
  hideNoButton: PropTypes.bool
};

export default withDimensions(ConfirmActionModal);

