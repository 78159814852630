import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, FormControl, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import { withDimensions } from '../../HOC';

const RadioButtonGroup = ({ options, onChange, defaultValue, isSmall = false, minBtnWidth, convertToSelectorOnWideScreen = false, disabled = false }) => {
  const dimensions = useSelector(state => state.dimensions);
  const [currentValue, setCurrentValue] = useState(undefined);
  const [isSplit, setIsSplit] = useState(undefined);
  const groupRef = useRef(null);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);//eslint-disable-line

  const selectOption = (option) => {
    setCurrentValue(option.value);
    onChange(option.value);
  };

  useEffect(() => {
    setIsSplit(minBtnWidth
      && groupRef?.current?.clientWidth
      && groupRef.current.offsetWidth < (minBtnWidth - 2) * options.length);
  }, [options, dimensions]);//eslint-disable-line

  return (
    <div ref={groupRef} style={{ width: '100%' }}>
      {options.length <= 2 || !isSplit || (dimensions.isScreenBiggerSM && !convertToSelectorOnWideScreen)
        ? (
          <ButtonGroup
            orientation={isSplit ? "vertical" : "horizontal"}
            style={minBtnWidth ? { display: "inline-block" } : {}}
            color="primary"
            variant="outlined"
            disableElevation
            fullWidth
          >
            {options.map((option, idx) => {
              const btnStyle = { height: '48px', textTransform: 'none', border: '1px solid #1b1f3b' };
              if (option.size && dimensions.isScreenBiggerSM) btnStyle.width = `${option.size}px`;
              if (isSmall) btnStyle.height = `32px`;
              if (minBtnWidth) btnStyle.minWidth = `${minBtnWidth}px`;
              if (dimensions.isScreenLessMD) {
                btnStyle.width = options.length <= 2 && isSplit ? '100%' : `${100 / options.length}%`;
              }
              return (
                <Button
                  key={idx}
                  color="primary"
                  variant={currentValue === option.value ? 'contained' : 'outlined'}
                  onClick={() => selectOption(option)}
                  style={btnStyle}
                  disabled={!!option.disabled || !!disabled}
                >
                  {option.label ?? option.value}
                </Button>
              );
            })}
          </ButtonGroup>
        ) : (
          <FormControl variant="outlined" className={styles.selectWrapper}>
            <Select value={currentValue ?? ""} displayEmpty>
              <MenuItem disabled value={""} style={{ display: "none" }}>
                <div style={{ textTransform: "none", color: '#D3D4D8' }}>Choose option</div>
              </MenuItem>
              {options.map((option) => {
                return (
                  <MenuItem value={option.value} key={option.value} onClick={() => selectOption(option)} disabled={!!option.disabled}>
                    <div style={{ textTransform: "none" }}>{option.label ?? option.value}</div>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
    </div>
  );
};

RadioButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.any,
  isSmall: PropTypes.bool,
  minBtnWidth: PropTypes.number,
  disabled: PropTypes.bool,
};

export default withDimensions(RadioButtonGroup);
