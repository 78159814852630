import React, { Suspense } from 'react';
import { Router, BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { SnackbarProvider, MaterialDesignContent  } from 'notistack';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import modules from './modules';
import history from './utils/history';
import MainLayout from './modules/common/components/MainLayout';
import createTheme from './modules/common/components/createTheme';
import { refreshAccessToken } from '@utils/refresher';
import './utils/interceptors';
import './assets/styles/index.scss'
import { SnackbarUtilsConfigurator } from '@utils/snackbarUtils';
import config from './config';
import { initializeDeviceAndBrowserCheck, initializeSagaMiddlewareAndGetStore } from './initializations';
import styled from "@emotion/styled";
import styles from "./styles.module.scss";
import { CircularProgress } from "@mui/material";


let isRefresherStarted = false;

function App() {
  const isBrowserSupportsHistory = 'pushState' in window.history;

  initializeDeviceAndBrowserCheck();
  const store = initializeSagaMiddlewareAndGetStore();

  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const notistackFont = {
    fontFamily: `Open Sans, sans-serif`,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    textTransform: 'none',
    textDecorationLine: 'none'
  };

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: '#FF7700',
      ...notistackFont
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: '#EE2A2C',
    ...notistackFont
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: '#FBEFDD',
      ...notistackFont,
      color: '#505565'
    },
  }));

  if (!isRefresherStarted) {
    refreshAccessToken();
    isRefresherStarted = true;
  }

  const theme = createTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
    }
  );
  return (
      <Provider store={store}>
        <BrowserRouter forceRefresh={!isBrowserSupportsHistory}>
          <Router history={history}>
            <Helmet>
              <title>Domium</title>
              {config.ENV === 'prod' && (
                <script type="text/javascript">
                  {`
                  window._mfq = window._mfq || [];
                  (function() {
                    var mf = document.createElement("script");
                    mf.type = "text/javascript"; mf.defer = true;
                    mf.src = "//cdn.mouseflow.com/projects/4a95d66b-360c-4326-a6c0-ed66ce670038.js";
                    document.getElementsByTagName("head")[0].appendChild(mf);
                  })();
                `}
                </script>
              )}
            </Helmet>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                Components={{
                  success: StyledMaterialDesignContent,
                  error: StyledMaterialDesignContent,
                }}
                maxSnack={3}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                classes={{
                  root: "SnackbarContainer",
                  anchorOriginTopCenter: "anchorOriginPositioning"
                }}
                iconVariant={
                  {
                    error: <><ErrorRoundedIcon className={"SnackbarIconError"} />{'  '}</>,
                    success: <><CheckCircleRoundedIcon  className={"SnackbarIconSuccess"} />{'  '}</>
                  }
                }
                ref={notistackRef}
                action={(key) => (<CloseRoundedIcon onClick={onClickDismiss(key)} className="SnackbarCloseButton" />)}
              >
                <SnackbarUtilsConfigurator />
                  <MainLayout>
                    <Suspense
                      fallback={
                        <div className={styles.spinnerWrapper}>
                          <CircularProgress size={120} className={styles.cardProgress} />
                        </div>
                      }
                    >
                      <Switch>{modules.routes}</Switch>
                    </Suspense>
                  </MainLayout>
              </SnackbarProvider>
            </ThemeProvider>
          </Router>
        </BrowserRouter>
      </Provider>
  );
}

export default App;
