import { createTheme } from '@mui/material/styles'

const createMuiTheme = () => createTheme({
  MuiButton: {
    variant: "contained",
    color: "primary",
    root: {
      height: '48px',
      padding: '12px 32px'
    },
  },
  TextField: {
    variant:"outlined"
  },
  palette: {
    primary: {
      main: '#1B1F3B'
    },
    secondary: {
      main: '#FFFFFF'
    }
  },
  typography : {
    h1: {
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '40px',
      lineHeight: '64px',
      color: '#232735'
    },
    h2: {
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '48px',
      color: '#232735'
    },
    h3: {
      fontFamily: 'Rubik',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '32px',
      color: '#232735'
    },
    h4: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
      color: '#232735'
    },
    h5: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#232735'
    },
    h6: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#232735',
      letterSpacing: '0.75px',
      textTransform: 'uppercase'
    },
    body1: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#505565'
    },
    body2: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '24px',
      color: '#505565'
    },
    caption: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#505565'
    },
    button: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#1B1F3B',
      letterSpacing: '0.5px'
    },
  }
});

export default createMuiTheme;