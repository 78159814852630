import {call, takeLatest, takeEvery, debounce} from 'redux-saga/effects';
import axios from 'axios';
import queryString from 'query-string';

import { isFunc } from '../../../utils/functions';

const Api = {
  getCommunicationLogs: ({
                           limit,
                           page,
                           search,
                           sort,
                           type,
                           journeyId,
                           messageId,
                           sender,
                           status,
                           recipientUserId
  }) => {
    let params = {
      limit: limit ?? 10
    };
    if (journeyId) params.journey = journeyId;
    if (type) params.type = type;
    if (page || page === 0) params.page = page;
    if (search) params.search = search;
    if (sort) params.sort = sort;
    if (messageId) params.messageId = messageId;
    if (sender) params.sender = sender;
    if (status) params.status = status;
    if (recipientUserId) params.recipientUserId = recipientUserId;

    return axios.request({
      url: '/commlogs',
      method: 'get',
      params,
      paramsSerializer: params => {
        return queryString.stringify(params)
      }
    });
  },
  getCommunicationLog: ({ id }) => axios.get(`/commlogs/${id}`),
  getCommunicationEmailBody: (blobId) => axios.get(`/documents/${blobId}/download-saved-from-string`),
};

export const actionTypes = {
  GET_COMMUNICATION_LOGS: 'GET_COMMUNICATION_LOGS',
  GET_COMMUNICATION_LOGS_WITH_DEBOUNCE: 'GET_COMMUNICATION_LOGS_WITH_DEBOUNCE',
  GET_COMMUNICATION_LOG: 'GET_COMMUNICATION_LOG',
  GET_COMMUNICATION_EMAIL_BODY: 'GET_COMMUNICATION_EMAIL_BODY',
};

function* sagaGetCommunicationLogs({ payload: {
  limit,
  page,
  search,
  sort,
  type,
  journeyId,
  messageId,
  sender,
  status,
  recipientUserId,
  onSuccess,
  onError
} }) {
  try {
    const { data } = yield call(Api.getCommunicationLogs, {
      limit,
      page,
      search,
      sort,
      type,
      journeyId,
      messageId,
      sender,
      status,
      recipientUserId
    });

    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetCommunicationLog({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getCommunicationLog, {id});
    if (result.data?._id) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetCommunicationEmailBody({ payload: { blobId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getCommunicationEmailBody, blobId);
    if (result.data) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getCommunicationLogs = payload => ({ type: actionTypes.GET_COMMUNICATION_LOGS, payload });
export const getCommunicationLogsWithDebounce = payload => ({ type: actionTypes.GET_COMMUNICATION_LOGS_WITH_DEBOUNCE, payload });
export const getCommunicationLog = payload => ({ type: actionTypes.GET_COMMUNICATION_LOG, payload });
export const getCommunicationEmailBody = payload => ({ type: actionTypes.GET_COMMUNICATION_EMAIL_BODY, payload });

const casesSagas = [
  takeLatest(actionTypes.GET_COMMUNICATION_LOGS, sagaGetCommunicationLogs),
  debounce(900, actionTypes.GET_COMMUNICATION_LOGS_WITH_DEBOUNCE, sagaGetCommunicationLogs),
  takeEvery(actionTypes.GET_COMMUNICATION_LOG, sagaGetCommunicationLog),
  takeLatest(actionTypes.GET_COMMUNICATION_EMAIL_BODY, sagaGetCommunicationEmailBody),
];

export default casesSagas;
