import React, {lazy} from 'react';
import { AuthRoute } from '../auth/components';

//import { TeamPage } from './pages';


const TeamPage = lazy(() => import('./pages/TeamPage'));

const teamModule = {
  routes: [<AuthRoute key="team" exact path="/team" component={TeamPage} headerType={'private'} />],
  navMenuItems: [
    {
      name: 'Team',
      path: '/team',
      order: 10
    }
  ],
};
export default teamModule;
