const getLocationInfo = () => {
  const availableEnvironments = [
    {
      host: 'dev-app.domium.com.au',
      hostname: 'dev-app.domium.com.au',
      origin: 'https://dev-app.domium.com.au',
      appServerUrl: 'https://dev-app.domium.com.au',
      serverHost: 'dev-api.domium.com.au',
      serverUrl: 'https://dev-api.domium.com.au',
      apiUrl: 'https://dev-api.domium.com.au/service/v1',
      env: 'dev',
      isLocalhost: false,
      timeLoggerPeriodSeconds: 10,
      timeLoggerResetTimeSeconds: 1200,
      propertyListingYears: 2,
      propertyListingRadiusKilometers: 2,
      performanceStatisticsYears: 10,
      maxRetryAttempts: 3,
      defaultReportsPerMonth: 5,
      maxSplitLoans: 6,
      contractOfSaleReviewDays: 2,
      //eslint-disable-next-line
      client_id: 'NopI98FxyONC7RtCBojLfsp6xX6FL4OqZa6B1fbqfBU8puN0xziECwawY8JiT86QZPUF4hI1lrXKqER8y3nPjFfO8148LzoUG2gpEVew1D9n4E7I19xqSkhH8WlTYCq60R4AnPV3Ty87onJSZv641WTnqYJxwEJtu15THhsXGgIJLpt0R8Ing0HMqezD3xq7NleeLBYDJA6drlqbHlJcX4dAEJ3b6OKlxBdMZnu9Sf8rq2lJK0ygO783AB',
      deploymentRelationsAndDependantsTime: '2023-04-27T14:30:00.000Z',
      dlExamplesUrl: 'https://domium-public.s3.ap-southeast-2.amazonaws.com/dvs/',
      conveyancingAndDisbursementAmount: 2000,
      defaultLoanTermYears: 30,
      minLMIPremiumSavings: 1000,
      stripePublicKey: 'pk_test_51PTkA7S4PO5Js3SVOky1Bk9ZzGAjyLkQMANIyLftkqHdBqAjLxU0B8sGLNSSmmaKNSK7cMoQyxWH3pNCDs71BqJF00l9IxjEHH',
    },
    {
      host: 'domiumtestspa.s3-website-ap-southeast-2.amazonaws.com',
      hostname: 'domiumtestspa.s3-website-ap-southeast-2.amazonaws.com',
      origin: 'http://domiumtestspa.s3-website-ap-southeast-2.amazonaws.com',
      appServerUrl: 'http://domiumtestspa.s3-website-ap-southeast-2.amazonaws.com',
      serverHost: 'dev-api.domium.com.au',
      serverUrl: 'https://dev-api.domium.com.au',
      apiUrl: 'https://dev-api.domium.com.au/service/v1',
      env: 'dev',
      isLocalhost: false,
      timeLoggerPeriodSeconds: 10,
      timeLoggerResetTimeSeconds: 120,
      maxSplitLoans: 6,
      contractOfSaleReviewDays: 2,
      //eslint-disable-next-line
      client_id: 'NopI98FxyONC7RtCBojLfsp6xX6FL4OqZa6B1fbqfBU8puN0xziECwawY8JiT86QZPUF4hI1lrXKqER8y3nPjFfO8148LzoUG2gpEVew1D9n4E7I19xqSkhH8WlTYCq60R4AnPV3Ty87onJSZv641WTnqYJxwEJtu15THhsXGgIJLpt0R8Ing0HMqezD3xq7NleeLBYDJA6drlqbHlJcX4dAEJ3b6OKlxBdMZnu9Sf8rq2lJK0ygO783AB',
      deploymentRelationsAndDependantsTime: '2023-04-27T14:30:00.000Z',
      dlExamplesUrl: 'https://domium-public.s3.ap-southeast-2.amazonaws.com/dvs/',
      conveyancingAndDisbursementAmount: 2000,
      defaultLoanTermYears: 30,
      minLMIPremiumSavings: 1000,
      stripePublicKey: 'pk_test_51PTkA7S4PO5Js3SVOky1Bk9ZzGAjyLkQMANIyLftkqHdBqAjLxU0B8sGLNSSmmaKNSK7cMoQyxWH3pNCDs71BqJF00l9IxjEHH',
    },
    {
      host: 'domium-spa.s3-website-ap-southeast-2.amazonaws.com',
      hostname: 'domium-spa.s3-website-ap-southeast-2.amazonaws.com',
      origin: 'http://domium-spa.s3-website-ap-southeast-2.amazonaws.com',
      appServerUrl: 'http://domium-spa.s3-website-ap-southeast-2.amazonaws.com',
      serverHost: 'dev-api.domium.com.au',
      serverUrl: 'https://dev-api.domium.com.au',
      apiUrl: 'https://dev-api.domium.com.au/service/v1',
      env: 'dev',
      isLocalhost: false,
      timeLoggerPeriodSeconds: 10,
      timeLoggerResetTimeSeconds: 120,
      maxSplitLoans: 6,
      contractOfSaleReviewDays: 2,
      //eslint-disable-next-line
      client_id: 'NopI98FxyONC7RtCBojLfsp6xX6FL4OqZa6B1fbqfBU8puN0xziECwawY8JiT86QZPUF4hI1lrXKqER8y3nPjFfO8148LzoUG2gpEVew1D9n4E7I19xqSkhH8WlTYCq60R4AnPV3Ty87onJSZv641WTnqYJxwEJtu15THhsXGgIJLpt0R8Ing0HMqezD3xq7NleeLBYDJA6drlqbHlJcX4dAEJ3b6OKlxBdMZnu9Sf8rq2lJK0ygO783AB',
      deploymentRelationsAndDependantsTime: '2023-04-27T14:30:00.000Z',
      dlExamplesUrl: 'https://domium-public.s3.ap-southeast-2.amazonaws.com/dvs/',
      conveyancingAndDisbursementAmount: 2000,
      defaultLoanTermYears: 30,
      minLMIPremiumSavings: 1000,
      stripePublicKey: 'pk_test_51PTkA7S4PO5Js3SVOky1Bk9ZzGAjyLkQMANIyLftkqHdBqAjLxU0B8sGLNSSmmaKNSK7cMoQyxWH3pNCDs71BqJF00l9IxjEHH',
    },
    {
      host: 'app.domium.com.au',
      hostname: 'app.domium.com.au',
      origin: 'https://app.domium.com.au',
      appServerUrl: 'https://app.domium.com.au',
      serverHost: 'api.domium.com.au',
      serverUrl: 'https://api.domium.com.au',
      apiUrl: 'https://api.domium.com.au/service/v1',
      env: 'prod',
      isLocalhost: false,
      timeLoggerPeriodSeconds: 10,
      timeLoggerResetTimeSeconds: 1200,
      propertyListingYears: 2,
      propertyListingRadiusKilometers: 2,
      performanceStatisticsYears: 10,
      maxRetryAttempts: 3,
      defaultReportsPerMonth: 5,
      maxSplitLoans: 6,
      contractOfSaleReviewDays: 2,
      //eslint-disable-next-line
      client_id: 'NopI98FxyONC7RtCBojLfsp6xX6FL4OqZa6B1fbqfBU8puN0xziECwawY8JiT86QZPUF4hI1lrXKqER8y3nPjFfO8148LzoUG2gpEVew1D9n4E7I19xqSkhH8WlTYCq60R4AnPV3Ty87onJSZv641WTnqYJxwEJtu15THhsXGgIJLpt0R8Ing0HMqezD3xq7NleeLBYDJA6drlqbHlJcX4dAEJ3b6OKlxBdMZnu9Sf8rq2lJK0ygO783AB',
      deploymentRelationsAndDependantsTime: '2023-04-27T14:30:00.000Z',
      dlExamplesUrl: 'https://domium-public.s3.ap-southeast-2.amazonaws.com/dvs/',
      conveyancingAndDisbursementAmount: 2000,
      defaultLoanTermYears: 30,
      minLMIPremiumSavings: 1000,
      stripePublicKey: 'pk_live_51Pl3NSImubcQrVrbrrvtraGcTAxcUYT9h1toFFHSrC6qC0qiJd1p86gIARRyetnUuNuMw0ejNOdzKU9sMXlRZQDa00Q3krqL8y',
    }
  ];

  const { hostname, host, origin } = window.location;
  const currentEnvironment = availableEnvironments.find((env) => env.origin === origin);
  if (currentEnvironment) { return currentEnvironment; }

  return {
    env: 'dev',
    host,
    isLocalhost: hostname === 'localhost',
    hostname,
    origin,
    timeLoggerPeriodSeconds: 10,
    timeLoggerResetTimeSeconds: 1200,
    performanceStatisticsYears: 10,
    propertyListingYears: 2,
    propertyListingRadiusKilometers: 2,
    maxRetryAttempts: 3,
    defaultReportsPerMonth: 5,
    maxSplitLoans: 6,
    contractOfSaleReviewDays: 2,
    appServerUrl: 'http://localhost:8080',
    serverHost: 'localhost:3000',
    serverUrl: 'http://localhost:3000',
    apiUrl: 'http://localhost:3000/service/v1',
    //eslint-disable-next-line
    client_id: 'NopI98FxyONC7RtCBojLfsp6xX6FL4OqZa6B1fbqfBU8puN0xziECwawY8JiT86QZPUF4hI1lrXKqER8y3nPjFfO8148LzoUG2gpEVew1D9n4E7I19xqSkhH8WlTYCq60R4AnPV3Ty87onJSZv641WTnqYJxwEJtu15THhsXGgIJLpt0R8Ing0HMqezD3xq7NleeLBYDJA6drlqbHlJcX4dAEJ3b6OKlxBdMZnu9Sf8rq2lJK0ygO783AB',
    deploymentRelationsAndDependantsTime: '2023-04-27T14:30:00.000Z',
    dlExamplesUrl: 'https://domium-public.s3.ap-southeast-2.amazonaws.com/dvs/',
    conveyancingAndDisbursementAmount: 2000,
    defaultLoanTermYears: 30,
    minLMIPremiumSavings: 1000,
    stripePublicKey: 'pk_test_51PTkA7S4PO5Js3SVOky1Bk9ZzGAjyLkQMANIyLftkqHdBqAjLxU0B8sGLNSSmmaKNSK7cMoQyxWH3pNCDs71BqJF00l9IxjEHH',
  };
};

const {
  env,
  host,
  hostname,
  serverUrl,
  apiUrl,
  isLocalhost,
  serverHost,
  client_id,
  origin,
  appServerUrl,
  timeLoggerPeriodSeconds,
  timeLoggerResetTimeSeconds,
  deploymentRelationsAndDependantsTime,
  propertyListingYears,
  propertyListingRadiusKilometers,
  performanceStatisticsYears,
  maxRetryAttempts,
  defaultReportsPerMonth,
  maxSplitLoans,
  dlExamplesUrl,
  contractOfSaleReviewDays,
  conveyancingAndDisbursementAmount,
  defaultLoanTermYears,
  minLMIPremiumSavings,
  stripePublicKey
} = getLocationInfo();

const commonConfig = {
  ENV: env,
  HOST: host,
  HOSTNAME: hostname,
  SERVER_URL: serverUrl,
  API_URL: apiUrl,
  SERVER_HOST: serverHost,
  isLocalhost,
  CLIENT_ID: client_id,
  ORIGIN: origin,
  APP_SERVER_URL: appServerUrl,
  EXTERNAL_INFO_SITE: 'https://domium.com.au',
  TIME_LOGGER_PERIOD_SECONDS: timeLoggerPeriodSeconds,
  TIME_LOGGER_RESET_TIME_SECONDS: timeLoggerResetTimeSeconds,
  PROPERTY_LISTING_YEARS: propertyListingYears,
  PROPERTY_LISTING_RADIUS_KILOMETERS: propertyListingRadiusKilometers,
  PROPERTY_MAX_RETRY_ATTEMPTS: maxRetryAttempts,
  PROPERTY_DEFAULT_REPORTS_PER_MONTH: defaultReportsPerMonth,
  PERFORMANCE_STATISTICS_YEARS: performanceStatisticsYears,
  MAX_SPLIT_LOANS: maxSplitLoans,
  DL_EXAMPLES_URL: dlExamplesUrl,
  CONTRACT_OF_SALE_REVIEW_DAYS: contractOfSaleReviewDays,
  deploymentRelationsAndDependantsTime,
  CONVEYANCING_AND_DISBURSEMENT_AMOUNT: conveyancingAndDisbursementAmount,
  DEFAULT_LOAN_TERM_YEARS: defaultLoanTermYears,
  MIN_LMI_PREMIUM_SAVINGS: minLMIPremiumSavings,
  STRIPE_PUBLIC_KEY: stripePublicKey
};

const config = { ...commonConfig };

export default config;
