import React, { useEffect } from 'react';
import { FormControl, Grid, MenuItem, Pagination, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.scss';
import { setRowsPerPage } from '../../reducers';
import { setRowsPerPageLimit } from '../../../../utils/helpers';


const LIMIT_OPTIONS = [10, 50, 100];

const CustomPaginator = ({ count, page, onChange, className, disabled }) => {
  const dimensions = useSelector(state => state.dimensions);
  const { rowsPerPage } = useSelector(state => state.pagination);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNaN(rowsPerPage) || !LIMIT_OPTIONS.includes(rowsPerPage)) {
      setRowsPerPageLimit(rowsPerPage, setNewLimit);
    }
  }, []);//eslint-disable-line

  const setNewLimit = (value) => {
    dispatch(setRowsPerPage(value));
    localStorage.setItem('ROWS-PER-PAGE', value.toString());
  };

  return <Grid container justifyContent="center" className={className} spacing={2}>
    <Grid item className={`${dimensions.isScreenBiggerSM ? '' : 'EXTRA-'}SMALL Paginator_global`}>
      <span className={styles.label}>Rows per page:</span>
      <FormControl variant="outlined" style={{ width: 72 }}>
        <Select
          value={rowsPerPage}
          onChange={(e) => setNewLimit(e.target.value)}
          placeholder="Select status"
          style={{ backgroundColor: "white", }}
        >
          {LIMIT_OPTIONS.map((option) => {
            return (
              <MenuItem value={option} key={option}>
                <Grid container className={styles.option}>
                  <Grid item xs={12}>{option}</Grid>
                </Grid>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
    <Grid item>
      <Pagination
        count={count}
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={(e, page) => onChange(e, page)}
        size={dimensions.isScreenBiggerSM ? 'medium' : 'small'}
        disabled={disabled}
      />
    </Grid>
  </Grid>;
};

export default CustomPaginator;