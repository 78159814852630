import React, { lazy } from 'react';
import { AuthRoute } from '../auth/components';

//import { CommunicationLogsDashboard } from './pages';
import sagas from './sagas';


const CommunicationLogsDashboard = lazy(() => import('./pages/CommunicationLogsDashboard'));

const communicationsModule = {
  routes: [
    <AuthRoute key="journey-communications" exact path="/journey-communications/:journeyId" component={CommunicationLogsDashboard} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="customer-communications" exact path="/customer-communications/:recipientUserId" component={CommunicationLogsDashboard} headerType={'private'} isSpecialistOnly />,
  ],
  /*navMenuItems: [
    {
      name: 'Communications',
      path: '/communications',
      isForSpecialist: true,
      order: 1
    },
  ],*/
  sagas
};

export default communicationsModule;
