export const USER_ROLE = {
  Customer: 'Customer',
  Specialist: 'Specialist',
  Conveyancer: 'Conveyancer',
  Admin: 'Admin',
  Supervisor: 'Supervisor',
  OperationsManager: 'OperationsManager',
  OperationsManagerWithCurrentJourneyEditRights: 'OperationsManagerWithCurrentJourneyEditRights',
  Audit: 'Audit',
  DataOperations: 'DataOperations'
}

export const getUserRole = (user) => {
  if (!user?.roles?.length) return undefined;
  if ((user.roles).includes(USER_ROLE.Admin)) return USER_ROLE.Admin;
  if ((user.roles).includes(USER_ROLE.OperationsManager) && !!((user.roles).find((role) => [USER_ROLE.Conveyancer, USER_ROLE.Specialist, USER_ROLE.Supervisor].includes(role)))) return USER_ROLE.OperationsManagerWithCurrentJourneyEditRights;
  if ((user.roles).includes(USER_ROLE.OperationsManager)) return USER_ROLE.OperationsManager;
  if ((user.roles).includes(USER_ROLE.Supervisor)) return USER_ROLE.Supervisor;
  if ((user.roles).includes(USER_ROLE.Conveyancer)) return USER_ROLE.Conveyancer;
  if ((user.roles).includes(USER_ROLE.Specialist)) return USER_ROLE.Specialist;
  if ((user.roles).includes(USER_ROLE.DataOperations)) return USER_ROLE.DataOperations;
  if ((user.roles).includes(USER_ROLE.Audit)) return USER_ROLE.Audit;
  return USER_ROLE.Customer;
};

export const isReadOnlyAudit = (user) => {
  return (user?.roles?.length === 1 && user.roles[0] === USER_ROLE.Audit);
};

export const isCustomerAuditor = (user) => {
  return isReadOnlyAudit(user) || ((user?.roles ?? []).includes(USER_ROLE.Audit) && getUserRole(user) === USER_ROLE.Audit);
};

export const hasOperationsManagerRole = (user) => {
  return (user?.roles ?? []).includes(USER_ROLE.OperationsManager);
};

export const hasSupervisorRole = (user) => {
  return (user?.roles ?? []).includes(USER_ROLE.Supervisor);
};

export const hasAdminRole = (user) => {
  return (user?.roles ?? []).includes(USER_ROLE.Admin);
};

export const hasConveyancerRole = (user) => {
  return (user?.roles ?? []).includes(USER_ROLE.Conveyancer);
};

export const hasSpecialistRole = (user) => {
  return (user?.roles ?? []).includes(USER_ROLE.Specialist);
};

export const hasCustomerRole = (user) => {
  return (user?.roles ?? []).includes(USER_ROLE.Customer);
};

export const hasDataOperationsRole = (user) => {
  return (user?.roles ?? []).includes(USER_ROLE.DataOperations);
};

export const isJourneyEditAllowed = (user, journey) => {
  if (!user?.id || !journey?.id) return false;

  const userRole = getUserRole(user);
  if (isReadOnlyAudit(user)) return false;

  if (userRole !== USER_ROLE.Customer && userRole !== USER_ROLE.OperationsManager) return true;
  else {
    //OperationsManagers can edit inactive or settled journeys
    if (userRole === USER_ROLE.OperationsManager) {
      if (journey?.status !== 'Active') return true;
      return !!(journey.steps ?? []).find((step) => step.step?.uid === 'settlement_completed');
    }

    //Customers can't edit inactive or settled journeys
    if (userRole === USER_ROLE.Customer) {
      if (journey?.status !== 'Active') return false;
      return !(journey.steps ?? []).find((step) => step.step?.uid === 'settlement_completed');
    }

    return false;
  }
};

export const isAllowedByRoles = (user, allowedRoles) => {
  if (!user?.roles?.length) return false;
  if (!allowedRoles?.length) return true;

  return user.roles.some((role) => allowedRoles.indexOf(role) !== -1);
};

export const isJustDataOperations = (user, isCustomerAllowed = false) => {
  let alteringRoles = [USER_ROLE.Audit, USER_ROLE.OperationsManager, USER_ROLE.Supervisor, USER_ROLE.Conveyancer, USER_ROLE.Specialist, USER_ROLE.Admin];
  if (isCustomerAllowed) alteringRoles.push(USER_ROLE.Customer);

  return !!user
    && isAllowedByRoles(user, [USER_ROLE.DataOperations])
    && !isAllowedByRoles(user, alteringRoles)
}