import { call, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { isFunc } from '../../../utils/functions';

const Api = {
  getFullURL: (shortId) => axios.get(`/services/url/${shortId}`)
};

export const actionTypes = {
  GET_FULL_URL: 'GET_FULL_URL'
};

function* sagaGetFullUrl({ payload: { shortId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getFullURL, shortId);
    if (result.status < 400 && result.data?.url) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getFullURL = payload => ({ type: actionTypes.GET_FULL_URL, payload });

const shortRedirectSagas = [
  takeLatest(actionTypes.GET_FULL_URL, sagaGetFullUrl)
];

export default shortRedirectSagas;
