const defaultState = {
  mobile: ''
};

export const actionTypes = {
  MOBILE: 'MOBILE'
};

const loginActions = function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.MOBILE:
      return { ...state, mobile: payload };
    default:
      return state;
  }
};
export default loginActions;

// ACTIONS
export const setMobile = payload => ({ type: actionTypes.MOBILE, payload });

