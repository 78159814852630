import { computeSizes } from '../HOC/withDimensions/computeSizes';

const defaultState = computeSizes(window);

const actionTypes = {
  SET_DIMENSIONS: 'SET_DIMENSIONS'
};

const dimensionsAction = function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_DIMENSIONS:
      return {
        ...payload
      };

    default:
      return state;
  }
};

export default dimensionsAction;

export const setDimensions = payload => ({ type: actionTypes.SET_DIMENSIONS, payload });
