import React, {useEffect, useRef, useState} from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

import styles from './styles.module.scss';
import { getNotifications, updateNotification } from '../../../notifications/sagas';
import ScrollIndicator from '../ScrollIndicator';
import { displayErrorMessage } from '../../../../utils/displayErrorMessage';


const NotificationsBlock = () => {
  const { user, selectedUser } = useSelector(state => state.user);

  const [removingNotifications, setRemovingNotifications] = useState([]);
  const [effectiveUser, setEffectiveUser] = useState(undefined);
  const notificationsMobileRef = useRef(null);

  const { notifications } = useSelector(state => state.notifications);
  const dimensions = useSelector(state => state.dimensions);
  const dispatch = useDispatch();

  useEffect(() => { setEffectiveUser(selectedUser ?? user) }, [user, selectedUser]); //eslint-disable-line

  const doCloseAction = (notification) => {
    setRemovingNotifications([...removingNotifications, notification.id]);
    dispatch(updateNotification({
      id: notification.id,
      body: { state: 'Read' },
      onSuccess: () => {
        dispatch(getNotifications({
          userId: effectiveUser?.id,
          onSuccess: () => {},
          onError: (data) => {
            displayErrorMessage(data, 'Unable to retrieve notifications');
          }
        }));
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to update notification');
        setRemovingNotifications([]);
      }
    }));
  };

  const doNotificationAction = (notification) => {
    if (!notification.action || !notification.actionURL) {
      doCloseAction(notification);
    } else {
      window.open(notification.actionURL,"_self")
    }
  };

  const renderNotification = (notification) => {
    return (<Box className={styles.notificationsListBlock} key={notification.id}>
      <Box>{notification.summary}</Box>
      {!!notification.summaryElements?.length && (
        <Box sx={{ mt: 2 }} className={styles.summaryElements}>
          {notification.summaryElements.map((se) => <Box key={se}>{se}</Box>)}
        </Box>
      )}
      {!!notification.action && (
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => doNotificationAction(notification)}
            className={styles.actionBtn}
          >
            {notification.action.text}
          </Button>
        </Box>
      )}
      {!selectedUser?.id && (
        <CloseIcon className={styles.closeIcon} onClick={() => doCloseAction(notification)} />
      )}
    </Box>)
  };

  return (<>
    {!!notifications?.elements && (<>
      {dimensions.isScreenBiggerSM ? (
        <Box sx={{ mb: 2 }} className={styles.notificationsBlock}>
          <Box sx={{ mb: 1 }} className={styles.blockHeader}>Notifications</Box>
          {!notifications.elements.length ? (
            <Box className={styles.emptyBlock}>No pending notifications since your last login</Box>
          ) : (<>
            {notifications.elements.sort((a, b) => (`${b.updatedAt}`).localeCompare(a.updatedAt)).map((notification) => {
              return !removingNotifications.includes(notification.id) ? renderNotification(notification) : null;
            })}
          </>)}
        </Box>
      ) : (<>
        {!!notifications.elements.length && (
          <Box sx={{ mb: 2 }}>
            <Grid className={styles.notificationsMobileRoot} container>
              <ScrollIndicator childRef={notificationsMobileRef} colorScheme="contrast">
                <Grid className={styles.notificationsMobileWrapper} container item justifyContent="flex-start" alignItems="stretch" alignContent="stretch" ref={notificationsMobileRef}>
                  {notifications.elements.sort((a, b) => (`${b.updatedAt}`).localeCompare(a.updatedAt)).map((notification) => {
                    return !removingNotifications.includes(notification.id)
                      ? <Grid item key={notification.id} className={styles.notificationWrapper}>{renderNotification(notification)}</Grid>
                      : null;
                  })}
                </Grid>
              </ScrollIndicator>
            </Grid>
          </Box>
        )}
      </>)}
    </>)}
  </>)
};

export default NotificationsBlock;