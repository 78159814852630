import reducers from './reducers';

const commonModule = {
  reducers: {
    common: reducers.mainLayoutAppearance,
    dimensions: reducers.dimensions,
    pagination: reducers.pagination,
    blockingDialogs: reducers.blockingDialogs,
  }
};

export default commonModule;
