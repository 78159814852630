const defaultState = {
  isHiddenMainLayoutWrapper: false,
  headerType: 'public' //public|private|wizard|broker
};

const actionTypes = {
  SET_IS_HIDDEN_MAIN_LAYOUT_WRAPPER: 'SET_IS_HIDDEN_MAIN_LAYOUT_WRAPPER',
  HEADER_TYPE: 'HEADER_TYPE'
};

const commonActions = function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_IS_HIDDEN_MAIN_LAYOUT_WRAPPER:
      return {
        ...state,
        isHiddenMainLayoutWrapper: payload
      };
    case actionTypes.HEADER_TYPE:
      return ['public', 'private', 'wizard', 'registrationWizard', 'broker', 'scheduled'].indexOf(payload) >= 0
        ? { ...state, headerType: payload }
        : state;
    default:
      return state;
  }
};

export default commonActions;

// ACTIONS
export const setIsHiddenMainLayoutWrapper = payload => ({
  type: actionTypes.SET_IS_HIDDEN_MAIN_LAYOUT_WRAPPER,
  payload
});

export const setHeaderType = payload => ({
  type: actionTypes.HEADER_TYPE,
  payload
});

