import {call, takeLatest, takeEvery, put} from 'redux-saga/effects';
import axios from 'axios';

import { isFunc } from '../../../utils/functions';
import { actionTypes as notificationsActionType } from '../reducers';

const Api = {
  getNotifications: ({page, limit, userId}) => axios.get(`/notifications?state=Unread${!!limit ? `&limit=${limit}`: ''}&page=${page ?? '1'}${!!userId ? `&user=${userId}` : ''}`),
  updateNotification: ({id, body}) => axios.put(`/notifications/${id}`, body),
  createNotification: (body) => axios.post('/notifications', body),
  sendEmail: ({ body, templateUID }) => axios.post(`/notifications/email/${templateUID}`, body),
  sendSms: ({ body, templateUID }) => axios.post(`/notifications/sms/${templateUID}`, body),
  notifyAboutLetterOfDisbursement: (journeyId) => axios.post(`/notifications/notifyAboutLetterOfDisbursement/${journeyId}`, {}),
};

export const actionTypes = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  SEND_EMAIL: 'SEND_EMAIL',
  SEND_SMS: 'SEND_SMS',
  NOTIFY_ABOUT_LETTER_OF_DISBURSEMENT: 'NOTIFY_ABOUT_LETTER_OF_DISBURSEMENT'
};

function* sagaGetNotifications({ payload: { page, limit, userId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getNotifications, { page, limit, userId });
    if (result.status < 400) {
      yield put({ type: notificationsActionType.NOTIFICATIONS, payload: result.data });
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateNotification({ payload: { id, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateNotification, { id, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateNotification({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createNotification, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaSendEmail({ payload: { body, templateUID, onSuccess, onError } }) {
  try {
    const result = yield call(Api.sendEmail, { body, templateUID });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaSendSms({ payload: { body, templateUID, onSuccess, onError } }) {
  try {
    const result = yield call(Api.sendSms, { body, templateUID });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaNotifyAboutLetterOfDisbursement({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.notifyAboutLetterOfDisbursement, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getNotifications = payload => ({ type: actionTypes.GET_NOTIFICATIONS, payload });
export const updateNotification = payload => ({ type: actionTypes.UPDATE_NOTIFICATION, payload });
export const createNotification = payload => ({ type: actionTypes.CREATE_NOTIFICATION, payload });
export const sendEmail = payload => ({ type: actionTypes.SEND_EMAIL, payload });
export const sendSms = payload => ({ type: actionTypes.SEND_SMS, payload });
export const notifyAboutLetterOfDisbursement = payload => ({ type: actionTypes.NOTIFY_ABOUT_LETTER_OF_DISBURSEMENT, payload });

const notificationsSagas = [
  takeLatest(actionTypes.GET_NOTIFICATIONS, sagaGetNotifications),
  takeEvery( actionTypes.UPDATE_NOTIFICATION, sagaUpdateNotification),
  takeEvery( actionTypes.CREATE_NOTIFICATION, sagaCreateNotification),
  takeEvery( actionTypes.SEND_EMAIL, sagaSendEmail),
  takeEvery( actionTypes.SEND_SMS, sagaSendSms),
  takeEvery( actionTypes.NOTIFY_ABOUT_LETTER_OF_DISBURSEMENT, sagaNotifyAboutLetterOfDisbursement),
];

export default notificationsSagas;
