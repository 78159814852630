import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import MainHeader from './MainHeader';
import { useSelector } from 'react-redux';
import { withDimensions } from '../../HOC';
import styles from './styles.module.scss';
import modules from '../../..';
import { processActionTimeTrackerTick } from '../../../../utils/helpers';
import config from '../../../../config';


let intervalId;

const MainLayout = ({ children }) => {
  const { user, selectedUser } = useSelector(state => state.user);
  const { isHiddenMainLayoutWrapper, headerType } = useSelector(state => state.common);

  const [navMenuItems, setNavMenuItems] = useState([]);
  const [userMenuItems, setUserMenuItems] = useState([]);

  /*const filterOutUnauthorized = (menuItems) => {
    return menuItems
      .filter((item) => !item.authorization?.length || item.authorization.find((a) => (user.roles ?? []).includes(a)));
  }*/

  useEffect(() => {
    if (!!intervalId) clearInterval(intervalId);
    intervalId = setInterval(() => {
      processActionTimeTrackerTick(user);
    }, config.TIME_LOGGER_PERIOD_SECONDS * 1000);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [user]);//eslint-disable-line

  useEffect(() => {
    setNavMenuItems(modules.navMenuItems);
    setUserMenuItems(modules.userMenuItems);
  }, [user, selectedUser]); // eslint-disable-line



  return (
      <Box className={`${styles.globalContainer} ${isHiddenMainLayoutWrapper ? styles.noBottomPadding : ''}`}>
        {!isHiddenMainLayoutWrapper && (<>
          <MainHeader navMenuItems={navMenuItems} userMenuItems={userMenuItems} />
          {headerType === 'registrationWizard' && (<Box className={styles.substrate} />)}
        </>)}
        {children}
      </Box>
  )
};

export default withDimensions(MainLayout);