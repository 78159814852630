const defaultState = {
  documents: null,//contains pagination data
  isLoadedDocuments: false
};

export const actionTypes = {
  DOCUMENTS: 'DOCUMENTS',
  IS_LOADED_DOCUMENTS: 'IS_LOADED_DOCUMENTS'
};

const documentsAction = function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.DOCUMENTS:
      return {
        ...state,
        documents: payload
      };
    case actionTypes.IS_LOADED_DOCUMENTS:
      return {
        ...state,
        isLoadedDocuments: payload
      };
    default:
      return state;
  }
};

export default documentsAction;

export const setDocuments = payload => ({ type: actionTypes.DOCUMENTS, payload });
export const setIsLoadedDocuments = payload => ({ type: actionTypes.IS_LOADED_DOCUMENTS, payload });
