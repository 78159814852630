import React, { lazy } from 'react';
import { AuthRoute } from '../auth/components';

import sagas from './sagas';
import { USER_ROLE } from '../../utils/authorisation';


const AccountsDashboard = lazy(() => import('./pages/AccountsDashboard'));
const AccountCreatePage = lazy(() => import('./pages/AccountCreatePage'));
const AccountEditPage = lazy(() => import('./pages/AccountEditPage'));

const accountsModule = {
  routes: [
    <AuthRoute key="accounts" exact path="/accounts" component={AccountsDashboard} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="account-create" exact path="/account-create" component={AccountCreatePage} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="account-edit" exact path="/account-edit/:accountId" component={AccountEditPage} headerType={'private'} isSpecialistOnly />
  ],
  userMenuItems: [
    {
      name: 'Accounts',
      path: '/accounts',
      allowedRoles: [USER_ROLE.Admin],
      isForSpecialist: true,
      order: 21
    }
  ],
  sagas
};

export default accountsModule;
