import axios from 'axios';
import config from '../config';
import history from './history';

const appConfig = config;

const commonHeaders = {};

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const addHeader = (config) => {
  return new Promise(async (resolve) => {
    const atCookie = localStorage.getItem('X-ACCESS-TOKEN');
    const baseHeaders = { ...config.headers, ...commonHeaders };
    const jwtHeader = atCookie?.length > 2 && config.url?.indexOf('.amazonaws.com/') < 0 ? { 'Authorization': `Bearer ${atCookie}` } : null;
    const headers = Object.assign({}, baseHeaders, jwtHeader);
    return resolve({ ...config, headers });
  });
};

axios.defaults.baseURL = `${appConfig.API_URL}`;

axios.interceptors.request.use(
    async (config) => {
      return addHeader(config).catch((config) => config);
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => response,
    function (error) {
      if (error.response) {
        try {
          const resp = error.response;

          if (resp?.status === 401) {
            return resetTokenAndReattemptRequest(error);
          } else {
            return resp;
          }

        } catch (e) {
          console.log(e);
        }
      }
    }
);

export const getRefreshedTokens = async (refreshToken) => {
  const body = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    scope: 'domium:write',
    client_id: config.CLIENT_ID
  };

  return axios.post('oauth/token', body);
};

export const resetTokenAndReattemptRequest = async (error = {}) => {
  try {
    const path = window.location?.pathname;
    const { response: errorResponse } = error;
    const refreshToken = localStorage.getItem('X-REFRESH-TOKEN');

    if (!refreshToken) {
      await performLogOut(path, true);
    } else {
      const retryOriginalRequest = !!errorResponse ? new Promise(resolve => {
        addSubscriber(access_token => {
          errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
          resolve(axios(errorResponse.config));
        });
      }) : null;

      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;

        const response = await getRefreshedTokens(refreshToken);

        if (!response.data?.access_token) {
          await performLogOut(path, true);
        } else {
          const newAccessToken = response.data.access_token;
          const newRefreshToken = response.data.refresh_token;
          localStorage.setItem('X-ACCESS-TOKEN', newAccessToken);
          if (newRefreshToken) localStorage.setItem('X-REFRESH-TOKEN', newRefreshToken);
          isAlreadyFetchingAccessToken = false;
          onAccessTokenFetched(newAccessToken);
          if (!retryOriginalRequest) {
            window.location.reload()
          }
        }
      }
      return retryOriginalRequest;
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

const onAccessTokenFetched = (access_token) => {
  subscribers.forEach(callback => callback(access_token));
  subscribers = [];
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const performLogOut = async (path, hasExpiredToken = false) => {
  if (path && !['/login', '/login/phone', '/login/pin', '/incorrect-link'].includes(path.toString())) {
    localStorage.setItem('LOG-OUT-PATH', path.toString());
  }
  if (hasExpiredToken) {
    isAlreadyFetchingAccessToken = false;
    localStorage.setItem('REFRESH-TOKEN-EXPIRED-OR-INCORRECT', JSON.stringify({ value: true, date: Date.now() }));
  } else {
    localStorage.removeItem('X-REFRESH-TOKEN');
    localStorage.removeItem('X-ACCESS-TOKEN');
    history.push('/');
  }
}