import React, {useEffect, useRef, useState} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { isFunc } from '../../../../utils/functions';
import {displayErrorMessage} from "../../../../utils/displayErrorMessage";
import {useSelector} from "react-redux";
import {withDimensions} from "../../HOC";

const standardToolbar = 'preview fontfamily fontsize blocks bold italic underline numlist bullist | alignleft aligncenter alignright alignjustify | outdent indent | forecolor backcolor removeformat | pagebreak | charmap emoticons | print | insertfile image link codesample strikethrough | table | undo redo';
const mobileToolbar = 'fontfamily fontsize bold italic underline | preview blocks numlist bullist | alignleft aligncenter alignright alignjustify | outdent indent | forecolor backcolor removeformat | pagebreak | charmap emoticons | print | insertfile image link codesample strikethrough | table | undo redo';
const narrowToolbar = 'fontfamily fontsize | blocks bold italic underline preview | numlist bullist | alignleft aligncenter alignright alignjustify | outdent indent | forecolor backcolor removeformat | pagebreak | charmap emoticons | print | insertfile image link codesample strikethrough | table | undo redo';

const TinyMCEditor = ({ state , updateState, shouldUpdate = false, setShouldUpdate }) => {
  const editorRef = useRef(null);
  const dimensions = useSelector(state => state.dimensions);

  const [initialState, setInitialState] = useState('');

  useEffect(() => {
    if ((!!state || state === '') && shouldUpdate) {
      setInitialState(state);
      setShouldUpdate(false);
    }
  }, [state, shouldUpdate, setShouldUpdate]); //eslint-disable-line

  const onChangeHandler = (inst) => {
    //console.log('Context menus:', inst.ui?.registry?.getAll?.()?.contextMenus);
    if (isFunc(updateState)) updateState(editorRef?.current?.getContent());
  };

  return (
    <>
      <Editor
        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={initialState}
        init={{
          plugins: 'preview importcss searchreplace autolink save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons autoresize',
          editimage_cors_hosts: ['picsum.photos'],
          menubar: false,
          contextmenu: 'link list',
          toolbar: dimensions.isScreenBiggerSM ? standardToolbar : dimensions.isScreenBiggerXS ? mobileToolbar : narrowToolbar,
          content_style: 'body, p { font-family:Open Sans,Helvetica,Arial,sans-serif; font-size:12pt } h1 { font-family:Rubik,sans-serif; font-size:18pt; font-weight: bold; } h2 {font-family:Rubik,sans-serif; font-size:16pt; font-weight: bold;} h3 {font-family:Rubik,sans-serif; font-size:14pt; font-weight: bold;} h4 {font-family:Open Sans,Helvetica,sans-serif; font-size:12pt; font-weight: bold;}',
          font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 30pt',
          font_family_formats: 'Rubik=rubik,sans-serif; Open Sans=open sans,sans-serif; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
          block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4',
          statusbar: false,
          autoresize_bottom_margin: 32,
          autoresize_on_init: true,
          min_height: 280,
          browser_spellcheck: true,
          setup: function (ed) {
            ed.on("change", function () {
              onChangeHandler(ed);
            });
            ed.on('BeforeSetContent', e => {
              if (e.content && e.content.includes('blob:')) {
                const s = e.content
                  .substr(e.content.indexOf('blob'), e.content.length)
                  .replace('/>', '')
                  .replace('>', '')
                  .replace('"', '')
                  .trim();
                if (e.target.editorUpload.blobCache.getByUri(s)) {
                  let size = e.target.editorUpload.blobCache.getByUri(s).blob().size;
                  const allowedSize = 100; // KB
                  size = size / 1024; // KB
                  if (size > allowedSize) {
                    displayErrorMessage(null, `Image size (${Math.ceil(size)} KB) is too high. Maximum allowed size is ${allowedSize} KB.`);
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }
              }
            });
          }
        }}
      />
    </>
  );

};

export default withDimensions(TinyMCEditor);