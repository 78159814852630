const defaultState = {
  rowsPerPage: null
};

const actionTypes = {
  ROWS_PER_PAGE: 'ROWS_PER_PAGE'
};

const paginationActions = function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: payload
      };
    default:
      return state;
  }
};

export default paginationActions;

// ACTIONS
export const setRowsPerPage = payload => ({
  type: actionTypes.ROWS_PER_PAGE,
  payload
});
