import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import styles from './styles.module.scss';

import { withDimensions } from '../../../common/HOC';
import history from '../../../../utils/history';
import { displayErrorMessage } from '../../../../utils/displayErrorMessage';
import { clearTimeTrackingInLocalStorage } from '../../../../utils/helpers';
import { getUserRole, USER_ROLE } from '../../../../utils/authorisation';
import { createCaseTimeLog, getCase } from '../../../actions/sagas';
import ConfirmActionModal from '../ConfirmActionModal';


let intervalId;

const ActionTimer = ({ noMargin = false }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);

  const [isTimerActive, setIsTimerActive] = useState(false);
  const [actionId, setActionId] = useState(undefined);
  const [actionName, setActionName] = useState(undefined);
  const [action, setAction] = useState(undefined);
  const [timeString, setTimeString] = useState(undefined);
  const [userRole, setUserRole] = useState(undefined);
  const [userTracking, setUserTracking] = useState(undefined);
  const [stopConfirmationOpen, setStopConfirmationOpen] = useState(false);
  const [stopConfirmationParameters, setStopConfirmationParameters] = useState({});
  const [prevTimeMillis, setPrevTimeMillis] = useState('');
  const [newTimeMillis, setNewTimeMillis] = useState(0);

  const getTimeStringFromSecondString = (seconds) => {
    try {
      let deltaTSeconds = (!isNaN(Number(prevTimeMillis)) && newTimeMillis) ? Math.floor((newTimeMillis - Number(prevTimeMillis)) / 1000) : 0;
      const correctedSeconds = deltaTSeconds + Number(seconds);

      const minutes = Math.floor(correctedSeconds / 60);
      const h = Math.floor((minutes ?? 0) / 60);
      const m = (minutes ?? 0) % 60;
      const s = Math.floor((correctedSeconds ?? 0) % 60);

      return `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`;
    } catch {
      return '';
    }
  };

  const getFinalTimeString = (seconds) => {
    try {
      const minutes = Math.floor(Number(seconds) / 60);
      const h = Math.floor((minutes ?? 0) / 60);
      const m = (minutes ?? 0) % 60;

      return h === 0 && m === 0 ? '' :`${h > 0 ? `${h}h ` : ''}${(m < 10 && h > 0) ? '0' : ''}${m}m`;
    } catch {
      return '';
    }
  };

  useEffect(() => {
    setActionId(localStorage.getItem('timeTrackingAction') ?? undefined);
    setUserTracking(localStorage.getItem('timeTrackingUser') ?? undefined);
    setTimeString(localStorage.getItem('timeTrackingSeconds') ?? '0');
    setPrevTimeMillis(localStorage.getItem('timeTrackingLastUpdate') ?? '0');
    setNewTimeMillis(Date.now());

    intervalId = setInterval(() => {
      setActionId(localStorage.getItem('timeTrackingAction') ?? undefined);
      setUserTracking(localStorage.getItem('timeTrackingUser') ?? undefined);
      setTimeString(localStorage.getItem('timeTrackingSeconds') ?? '0');
      setPrevTimeMillis(localStorage.getItem('timeTrackingLastUpdate') ?? '');
      setNewTimeMillis(Date.now());
    }, 1000);

    return () => { if (intervalId) clearInterval(intervalId) };
  }, []);//eslint-disable-line

  useEffect(() => {
    setUserRole(getUserRole(user));
  }, [user]);//eslint-disable-line


  useEffect(() => {
    if (!!actionId && action?.id !== actionId && !!userRole && userRole !== USER_ROLE.Customer) {

      dispatch(getCase({
        id: actionId,
        onSuccess: (data) => {
          setAction(data);
          setActionName(data?.typeObj?.name);
        },
        onError: (data) => {
          displayErrorMessage(data, 'Unable to retrieve action');
          setAction(undefined);
          setActionName(undefined);
        }
      }));
    } else {
      setAction(undefined);
    }
  }, [actionId, userRole]);//eslint-disable-line

  useEffect(() => {
    setIsTimerActive(!!getTimeStringFromSecondString(timeString) && user?.id === userTracking && !!actionName && !!userRole && userRole !== USER_ROLE.Customer && !!actionId);
  }, [actionId, user, userTracking, actionName, userRole, timeString]);//eslint-disable-line

  const logTime = () => {
    if (!localStorage.getItem('timeTrackingSeconds') || !localStorage.getItem('timeTrackingAction') || !localStorage.getItem('timeTrackingUser')) {
      clearTimeTrackingInLocalStorage();
      setActionId(undefined);
      setUserTracking(undefined);
      setIsTimerActive(false);
    } else {
      const minutes = Math.floor(Number(localStorage.getItem('timeTrackingSeconds') ?? 0) / 60);

      const clearAndRedirect = () => {
        clearTimeTrackingInLocalStorage();
        setActionId(undefined);
        setAction(undefined);
        setUserTracking(undefined);
        setIsTimerActive(false);
        setIsTimerActive(false);
        history.push(`/action-edit/${actionId}`);
      };

      if (minutes > 0) {
        dispatch(createCaseTimeLog({
          body: {
            minutes,
            case: localStorage.getItem('timeTrackingAction'),
            user: localStorage.getItem('timeTrackingUser'),
            journey: localStorage.getItem('timeTrackingJourney') ?? undefined,
            customer: localStorage.getItem('timeTrackingCustomer') ?? undefined,
          },
          onSuccess: () => {
            clearAndRedirect();
          },
          onError: (data) => {
            displayErrorMessage(data, 'Unable to create the time log');
          },
        }));
      } else {
        clearAndRedirect();
      }


    }
  };

  const stopAndLogTime = () => {
    setStopConfirmationParameters({
      entityType: 'action',
      entityName: actionName,
      content: (<>
        {!!getFinalTimeString(timeString) ? (
          <p>
            Are you sure you want to stop and log <b>{getFinalTimeString(timeString)}</b> to the action <b>{actionName}</b>?
          </p>
        ) : (
          <p>
            Are you sure you want to stop the timer of the action <b>{actionName}</b>?
          </p>
        )}
        <p>
          You will be redirected to the above action if you click YES.
        </p>
      </>),
      yesAction: () => {
        logTime();
      }
    });

    setStopConfirmationOpen(true);
  };

  return (<>
    {!!isTimerActive && (
      <Grid
        container
        item
        direction={'column'}
        justifyContent={'flex-start'}
        alignContent={'flex-end'}
        alignItems={'flex-end'}
        className={`${styles.actionTimerWrapper} ${!!noMargin ? styles.noMargin : ''}`}
      >
        <Grid item className={styles.actionName} onClick={() => history.push(`/action-edit/${actionId}`)}>
          {actionName}
        </Grid>
        <Grid item className={styles.trackedTime}>
          <div className={`${styles.beforeTimeStop}`} onClick={() => stopAndLogTime()} />
          <span className={styles.notSelectable}>{getTimeStringFromSecondString(timeString)}</span>
        </Grid>
        <ConfirmActionModal
          open={stopConfirmationOpen}
          setOpen={setStopConfirmationOpen}
          parameters={stopConfirmationParameters}
          title={'Stop timer'}
        />
      </Grid>
    )}
  </>);
};

export default withDimensions(withRouter(ActionTimer));
