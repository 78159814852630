import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  CircularProgress,
  MenuItem,
  Menu,
  IconButton
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import styles from './styles.module.scss';
import './styles.global.scss';
import DashboardLayout from '../../common/components/DashboardLayout';
import { displayErrorMessage } from '../../../utils/displayErrorMessage';
import history from '../../../utils/history';
import { clearSelectedUser, getSpecialists } from '../../user/sagas';
import ConfirmDeletionModal from '../../common/components/ConfirmDeletionModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getUserRole, isJustDataOperations, USER_ROLE } from '../../../utils/authorisation';
import { deleteCaseTimeLog, getCase, getCaseTimeLogs, updateCaseTimeLog } from '../sagas';
import { useParams } from 'react-router-dom';
import { deepClone } from '../../../utils/deepClone';
import ProvideElapsedTimeModal from "../../common/components/ProvideElapsedTimeModal";


const ActionTimeLog = () => {
  const { user, selectedUser } = useSelector(state => state.user);
  const dimensions = useSelector(state => state.dimensions);
  const { caseId } = useParams();
  const dispatch = useDispatch();

  const [userRole, setUserRole] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState(undefined);
  const [timeLogs, setTimeLogs] = useState([]);
  const [sortedTimeLogs, setSortedTimeLogs] = useState([]);
  const [specialists, setSpecialists] = useState([]);

  const [sortBy, setSortBy] = useState('-createdAt');
  const [menuPosition, setMenuPosition] = useState(null);
  const [currentLog, setCurrentLog] = useState(undefined);
  const [actionLogId, setActionLogId] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationParameters, setConfirmationParameters] = useState({});
  const [editTimeLogOpen, setEditTimeLogOpen] = useState(false);
  const [editTimeLogParameters, setEditTimeLogParameters] = useState({});

  useEffect(() => {
    dispatch(getSpecialists({
      onSuccess: (data) => { setSpecialists(data.elements ?? []); },
      onError: (data) => { displayErrorMessage(data, 'Unable to retrieve specialists'); }
    }));

    const sorting = localStorage.getItem('SPECIALIST-ACTION-TIME-LOG-SORTING');
    if (sorting) {
      localStorage.removeItem('SPECIALIST-ACTION-TIME-LOG-SORTING');
      setSortBy(sorting);
    }
  }, []);//eslint-disable-line

  useEffect(() => {
    if (user) {
      if (isJustDataOperations(user)) history.push('/deals-management');

      if (caseId && specialists?.length) {
        dispatch(getCaseTimeLogs({
          caseId,
          onSuccess: (data) => {
            setTimeLogs(addUserNameToTimeLogs(data ?? []));
          },
          onError: (data) => displayErrorMessage(data, 'Unable to get the time logs')
        }));

        dispatch(getCase({
          id: caseId,
          onSuccess: (data) => {
            setAction(data);
          },
          onError: (data) => displayErrorMessage(data, 'Unable to get the action')
        }));
      } else {
        setTimeLogs([]);
        setAction(undefined);
      }
    }
  }, [caseId, user, specialists]); //eslint-disable-line

  useEffect(() => {
    dispatch(clearSelectedUser({
      onSuccess: () => {},
      onError: () => { displayErrorMessage(null, 'Unable to clear selected user'); }
    }));
  }, [selectedUser]);//eslint-disable-line

  useEffect(() => {
    if (!sortBy) {
      setSortedTimeLogs(timeLogs);
    } else {
      const sorted = deepClone((timeLogs ?? []))?.sort((a, b) => sortBy.startsWith("-")
        ? (sortBy.substring(1) === 'minutes' ? b[sortBy.substring(1)] - a[sortBy.substring(1)] : (`${b[sortBy.substring(1)]}`).localeCompare(a[sortBy.substring(1)]))
        :  (sortBy === 'minutes' ? a[sortBy] - b[sortBy] : (`${a[sortBy]}`).localeCompare(b[sortBy]))
      );
      setSortedTimeLogs(sorted);
    }
  }, [sortBy, timeLogs]);//eslint-disable-line

  useEffect(() => {
    setUserRole(getUserRole(user));
  }, [user]);//eslint-disable-line

  useEffect(() => {
    if (userRole === USER_ROLE.Customer) {
      history.push('/dashboard');
    }
  }, [userRole]);//eslint-disable-line

  const addUserNameToTimeLogs = (logs) => {
    if (!specialists?.length) return logs;
    else {
      return logs.map((log) => {
        return addUserNameToTimeLog(log);
      });
    }
  };

  const addUserNameToTimeLog = (log) => {
    const specialist = specialists.find((s) => s.id === log.user);
    const userName = !!specialist ? `${specialist.name} ${specialist.surname}` : 'undefined';

    return {...log, userName};
  };

  const updateSorting = (field) => {
    setSortBy(sortBy === field ? `-${field}` : sortBy === ('-' + field) ? '' : `${field}`);
  };

  const handleMenuOpen = (event, log) => {
    if (menuPosition) {
      return;
    }

    event.preventDefault();
    setCurrentLog(log);
    setActionLogId(log?.id)

    setMenuPosition({
      top: event.clientY + 12,
      left: event.clientX + 12
    });
  };

  const doDeleteLog = () => {
    const updateLogsList = () => {
      dispatch(getCaseTimeLogs({
        caseId,
        onSuccess: (data) => {
          setTimeLogs(addUserNameToTimeLogs(data ?? []));
          setIsLoading(false);
        },
        onError: (data) => {
          displayErrorMessage(data, 'Unable to retrieve action\'s time logs');
          setIsLoading(false);
        }
      }));
    }

    setMenuPosition(null);
    setActionLogId(null);
    if (actionLogId) {
      setConfirmationParameters({
        entityType: 'time log',
        entityName: null,
        action: () => {
          setIsLoading(true);
          dispatch(deleteCaseTimeLog({
            logId: currentLog?.id,
            onSuccess: () => {
              updateLogsList();
            },
            onError: (data) => {
              displayErrorMessage(data, 'Unable to delete the journey');
              updateLogsList();
            }
          }));
        }
      });

      setConfirmationOpen(true);
    }
  };

  const onEditTimeLog = () => {
    let currentTimeLogs = deepClone(timeLogs);
    const currentIndex = currentTimeLogs.findIndex((ctl) => ctl.id === actionLogId);
    const currentLog = currentIndex >= 0 ? currentTimeLogs[currentIndex] : undefined;
    const hours = Math.floor(currentLog.minutes / 60);
    const minutes = currentLog.minutes % 60;

    setEditTimeLogParameters({
      yesAction: (time) => {
        const minutes = Number(time.minutes ?? 0) + 60 * Number(time.hours ?? 0);
        dispatch(updateCaseTimeLog({
          minutes,
          logId: actionLogId,
          onSuccess: (data) => {
            if (currentIndex >= 0) {
              currentTimeLogs[currentIndex] = addUserNameToTimeLog(data);
            }

            setTimeLogs(currentTimeLogs);
            setEditTimeLogOpen(false);
          },
          onError: (data) => {
            displayErrorMessage(data, 'Unable to update the time log entry');
          },
        }));
      },
      initialHours: hours > 0 ? hours.toString() : '',
      initialMinutes: minutes > 0 ? minutes.toString() : '',
    });

    setEditTimeLogOpen(true);
  };

  const getTimeStringFromMinutes = (minutes) => {
    const h = Math.floor((minutes ?? 0) / 60);
    const m = (minutes ?? 0) % 60;

    return `${h > 0 ? `${h}h ` : ''}${m < 10 ? '0' : ''}${m}m`;
  };

  const renderActionMenu = (log) => {
    return (
      <Menu
        open={!!menuPosition && log.id === actionLogId}
        onClose={() => { setMenuPosition(null); setActionLogId(null); }}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
        {([USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.OperationsManagerWithCurrentJourneyEditRights].includes(userRole) || log.user === user?.id) && (<>
          <MenuItem
            onClick={() => { setMenuPosition(null); setActionLogId(null); onEditTimeLog();  }}
            disabled={!actionLogId}
          >
            Edit entry
          </MenuItem>

          <MenuItem className={styles.deleteMenuItem} onClick={() => doDeleteLog()}>Delete</MenuItem>
        </>)}
      </Menu>
    );
  };

  return <DashboardLayout team={[]} hideExtraWidgets>
    <Grid container justifyContent="flex-start" direction={'column'}>
      <Grid item xs={12} className={`${styles.pageTitle} ${dimensions.isScreenBiggerSM ? '' : styles.mobile}`}>Time logged</Grid>
      {!!action && (
        <Grid item xs={12}>
          <div className={styles.journeyNameTitle} onClick={() => history.push(`/action-edit/${caseId}`)}>{' '}Action: {action.typeObj?.name}</div>
        </Grid>
      )}
    </Grid>

    <Box sx={{ mt: 2 }} className={'ActionsTimeLogTableWrapper'}>
      <TableContainer className={`${styles.tableContainer} ${styles.fixedWidth} ActionsTimeLogTableWrapper`}>
        <Table size={dimensions.isScreenBiggerSM ? "medium" : "small"} stickyHeader>
          <TableHead>
            <TableRow className={styles.tableHead}>
              <TableCell
                align={'left'}
                style={{ minWidth: dimensions.isScreenBiggerSM ? 240 : 120 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('userName')}>
                  USER{sortBy === 'userName' ? (<> &#9650;</>) : sortBy === '-userName' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'left'}
                style={{ minWidth: dimensions.isScreenBiggerSM ? 104 : 88 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('createdAt')}>
                  LOG DATE{sortBy === 'createdAt' ? (<> &#9650;</>) : sortBy === '-createdAt' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'left'}
                style={{ minWidth: dimensions.isScreenBiggerSM ? 104 : 88 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('minutes')}>
                  LOGGED TIME{sortBy === 'minutes' ? (<> &#9650;</>) : sortBy === '-minutes' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'right'}
                style={{ minWidth: 10, width: 10 }}
              />
            </TableRow>
          </TableHead>

          <TableBody className={styles.tableBody}>
            {(sortedTimeLogs ?? []).map((log, idx) => {
              return (
                <TableRow className={`${styles.tableItem}`} key={idx}>
                  <TableCell align={'left'} className={styles.name}>
                    {log.userName}
                  </TableCell>

                  <TableCell align={'left'} className={styles.date}>
                    {log?.createdAt ? format(new Date(log.createdAt), 'dd.MM.yyyy HH:mm') : '???'}
                  </TableCell>

                  <TableCell align={'left'} className={styles.date}>
                    {getTimeStringFromMinutes(log.minutes ?? 0)}
                  </TableCell>

                  <TableCell align={'left'}>
                    {([USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.OperationsManagerWithCurrentJourneyEditRights].includes(userRole) || log.user === user?.id) && (<>
                      <IconButton
                        className={`${styles.iconBtn} ${styles.noPadding}`}
                        onClick={(event) => { handleMenuOpen(event, log); }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {renderActionMenu(log)}
                    </>)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {(!sortedTimeLogs?.length) && (
          <Grid container className={styles.emptyTable} justifyContent={"center"} alignItems={"center"}>
            <Grid item>{isLoading ? 'Loading...' : 'No actions found'}</Grid>
          </Grid>
        )}
        {(isLoading) && (<CircularProgress size={80} className={styles.cardProgress} />)}
      </TableContainer>
    </Box>
    <ConfirmDeletionModal open={confirmationOpen} setOpen={setConfirmationOpen} parameters={confirmationParameters}/>
    <ProvideElapsedTimeModal
      open={editTimeLogOpen}
      setOpen={setEditTimeLogOpen}
      parameters={editTimeLogParameters}
      closeOnSubmit
      title={'Edit logged time'}
      description={'How much time you spent working on this action?'}
    />
  </DashboardLayout>;
};

export default ActionTimeLog;