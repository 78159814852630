import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const PositiveIntegerFormat = React.forwardRef((props, ref) => {
  const { onChange, name, maxValue, allowLeadingZeros, maxLength, ...other } = props;

  const setRef = React.useCallback(
    (maskedInputRef) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;

      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    },
    [ref],
  );

  return (
    <NumberFormat
      {...other}
      getInputRef={setRef}
      allowLeadingZeros={allowLeadingZeros}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      isNumericString
      decimalScale={0}
      isAllowed={(values) => {
        const { floatValue, value } = values;
        return (floatValue <= (maxValue ?? 999999999999999) || isNaN(floatValue)) && (!maxLength || value?.length <= maxLength);
      }}
    />
  );
});

PositiveIntegerFormat.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  maxValue: PropTypes.number,
  allowLeadingZeros: PropTypes.bool,
  maxLength: PropTypes.number
};

export default PositiveIntegerFormat;