import { call, takeLatest, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import { isFunc } from '../../../utils/functions';

const Api = {
  getHelpContents: ({ page, limit, userId }) =>
    axios.get(`/contents/personalised?limit=${limit ?? 3}${page && page > 1 ? '&page=' + page : ''}${userId ? '&user=' + userId : ''}`
    ),
  getStepsByJourneyId: (journeyId) => axios.get(`/journeys/${journeyId}/steps`)
};

export const actionTypes = {
  GET_HELP_CONTENTS: 'GET_HELP_CONTENTS',
  GET_STEPS_BY_JOURNEY_ID: 'GET_STEPS_BY_JOURNEY_ID'
};

function* sagaHelpContents({ payload: { page, limit, userId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getHelpContents, { page, limit, userId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetStepsByJourneyId({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getStepsByJourneyId, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getHelpContents = payload => ({ type: actionTypes.GET_HELP_CONTENTS, payload });
export const getStepsByJourneyId = payload => ({ type: actionTypes.GET_STEPS_BY_JOURNEY_ID, payload });

const dashboardSagas = [
  takeLatest(actionTypes.GET_HELP_CONTENTS, sagaHelpContents),
  takeEvery( actionTypes.GET_STEPS_BY_JOURNEY_ID, sagaGetStepsByJourneyId)
];

export default dashboardSagas;
