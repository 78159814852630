import React, {useEffect, useRef, useState} from 'react';
import {
  Box,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  CircularProgress,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  Menu,
  IconButton,
  Checkbox,
  ListItemText,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { differenceInMinutes, format } from 'date-fns';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import UpdateIcon from '@mui/icons-material/Update';

import styles from './styles.module.scss';
import './styles.global.scss';
import DashboardLayout from '../../common/components/DashboardLayout';
import { displayErrorMessage } from '../../../utils/displayErrorMessage';
import history from '../../../utils/history';
import { clearSelectedUser, getAllowedUsersWithDebounce, getSpecialists } from '../../user/sagas';
import ConfirmDeletionModal from '../../common/components/ConfirmDeletionModal';
import CustomPaginator from '../../common/components/CustomPaginator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getUserRole, isJustDataOperations, USER_ROLE } from '../../../utils/authorisation';
import { getJourney } from '../../journeys/sagas';
import { deleteCase, getCasesWithDebounce, getCaseTypes } from '../sagas';
import ErrorIcon from '@mui/icons-material/Error';
import { useParams } from 'react-router-dom';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";


let intervalId;
let actionsRefreshIntervalId;
const ACTION_STATUSES = [ 'IN PROGRESS', 'ON HOLD', 'COMPLETED' ];
const TABS = ['IN QUEUE', 'ASSIGNED TO ME', 'ALL OPEN', 'ALL'];
const nullOption = { id: 'NULL', name: 'NOT ASSIGNED', surname: '' };
const notNullOption = { id: 'NOT_NULL', name: 'ASSIGNED', surname: '' };

const ActionsDashboard = () => {
  const { user, selectedUser } = useSelector(state => state.user);
  const dimensions = useSelector(state => state.dimensions);
  const { rowsPerPage } = useSelector(state => state.pagination);
  const { journeyId } = useParams();
  const dispatch = useDispatch();

  //const [team, setTeam] = useState([]);
  const [userRole, setUserRole] = useState(undefined);
  const [actionSearchString, setActionSearchString] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState(undefined);
  const [owners, setOwners] = useState([]);
  const [chosenOwner, setChosenOwner] = useState(undefined);
  const [specialists, setSpecialists] = useState([]);
  const [chosenSpecialist, setChosenSpecialist] = useState(null);
  const [chosenAssignee, setChosenAssignee] = useState(null);
  const [chosenStatuses, setChosenStatuses] = useState([]);
  const [actionTypes, setActionTypes] = useState([]);
  const [chosenActionType, setChosenActionType] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentJourney, setCurrentJourney] = useState(undefined);
  const [actionAutoUpdate, setActionAutoUpdate] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(undefined);

  const [isAdditionalFiltersOpened, setIsAdditionalFiltersOpened] = useState(false);
  const [isAdditionalFiltersLinkDisabled, setIsAdditionalFiltersLinkDisabled] = useState(true);

  const [sortBy, setSortBy] = useState('');
  const [menuPosition, setMenuPosition] = useState(null);
  const [currentAction, setCurrentAction] = useState(undefined);
  const [selectedTab, setSelectedTab] = useState('ALL');
  const [isSetAllForTheFirstTime, setIsSetAllForTheFirstTime] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationParameters, setConfirmationParameters] = useState({});
  const [actionId, setActionId] = useState(null);

  const [moreOpen, setMoreOpen] = useState(false);

  const anchorMoreRef = useRef(null);

  useEffect(() => {
    dispatch(getSpecialists({
      onSuccess: (data) => { setSpecialists(data.elements ?? []); },
      onError: (data) => { displayErrorMessage(data, 'Unable to retrieve specialists'); }
    }));

    dispatch(getCaseTypes({
      onSuccess: (data) => {
        setActionTypes(data);
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to retrieve action types');
      }
    }));

    const sorting = localStorage.getItem('SPECIALIST-ACTIONS-SORTING');
    if (sorting) {
      setSortBy(sorting);
      localStorage.removeItem('SPECIALIST-ACTIONS-SORTING');
    }

    const searchString = localStorage.getItem('ACTIONS-SPECIALIST-ACTION-SEARCH-STRING');
    const ownerOption = localStorage.getItem('ACTIONS-SPECIALIST-CHOSEN-OWNER-FILTER');
    const specialistOption = localStorage.getItem('ACTIONS-SPECIALIST-CHOSEN-SPECIALIST-FILTER');
    const assigneeOption = localStorage.getItem('ACTIONS-SPECIALIST-CHOSEN-ASSIGNEE-FILTER');
    const statusOption = localStorage.getItem('ACTIONS-SPECIALIST-CHOSEN-STATUSES-FILTER');
    const actionTypeOption = localStorage.getItem('ACTIONS-SPECIALIST-CHOSEN-TYPE-FILTER');
    const restoredSelectedTab = localStorage.getItem('ACTIONS-SPECIALIST-SELECTED-TAB');

    if (searchString) setActionSearchString(searchString);
    if (ownerOption) {
      setOwners([JSON.parse(ownerOption)]);
      setChosenOwner(JSON.parse(ownerOption));
    }
    if (specialistOption) setChosenSpecialist(JSON.parse(specialistOption));
    if (assigneeOption) setChosenAssignee(JSON.parse(assigneeOption));
    if (statusOption) setChosenStatuses(statusOption.split(','));
    if (actionTypeOption) setChosenActionType(JSON.parse(actionTypeOption));
    if (restoredSelectedTab) setSelectedTab(restoredSelectedTab);

    intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 20000);

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (actionsRefreshIntervalId) clearInterval(actionsRefreshIntervalId);
    };
  }, []);//eslint-disable-line

  useEffect(() => {
    if (user) {
      if (journeyId) {
        clearFiltration('ALL');

        dispatch(getJourney({
          id: journeyId,
          onSuccess: (data) => {
            setCurrentJourney(data);
            //retrieveActions(1);
          },
          onError: (data) => displayErrorMessage(data, 'Unable to get the journey')
        }));
      } else {
        setCurrentJourney(undefined);
      }
    }
  }, [journeyId, user]); //eslint-disable-line

  useEffect(() => {
    if (!journeyId) {
      if (!!actionSearchString) {
        localStorage.setItem('ACTIONS-SPECIALIST-ACTION-SEARCH-STRING', actionSearchString);
      } else {
        localStorage.removeItem('ACTIONS-SPECIALIST-ACTION-SEARCH-STRING');
      }
    }
  }, [actionSearchString]);//eslint-disable-line

  useEffect(() => {
    if (!journeyId) {
      if (!!chosenOwner) {
        localStorage.setItem('ACTIONS-SPECIALIST-CHOSEN-OWNER-FILTER', JSON.stringify({_id: chosenOwner._id, name: chosenOwner.name, surname: chosenOwner.surname }));
      } else {
        localStorage.removeItem('ACTIONS-SPECIALIST-CHOSEN-OWNER-FILTER');
      }
    }
  }, [chosenOwner]);//eslint-disable-line

  useEffect(() => {
    if (!journeyId) {
      if (!!chosenSpecialist) {
        localStorage.setItem(
          'ACTIONS-SPECIALIST-CHOSEN-SPECIALIST-FILTER',
          JSON.stringify({id: chosenSpecialist.id, name: chosenSpecialist.name, surname: chosenSpecialist.surname })
        );
      } else {
        localStorage.removeItem('ACTIONS-SPECIALIST-CHOSEN-SPECIALIST-FILTER');
      }
    }
  }, [chosenSpecialist]);//eslint-disable-line

  useEffect(() => {
    if (!journeyId) {
      if (!!chosenAssignee) {
        localStorage.setItem(
          'ACTIONS-SPECIALIST-CHOSEN-ASSIGNEE-FILTER',
          JSON.stringify({id: chosenAssignee.id, name: chosenAssignee.name, surname: chosenAssignee.surname })
        );
      } else if (isSetAllForTheFirstTime) {
        localStorage.removeItem('ACTIONS-SPECIALIST-CHOSEN-ASSIGNEE-FILTER');
      }
    }
  }, [chosenAssignee]);//eslint-disable-line

  useEffect(() => {
    if (!journeyId) {
      if (!!chosenStatuses?.length) {
        localStorage.setItem('ACTIONS-SPECIALIST-CHOSEN-STATUSES-FILTER', chosenStatuses.join(','));
      } else {
        localStorage.removeItem('ACTIONS-SPECIALIST-CHOSEN-STATUSES-FILTER');
      }
    }
  }, [chosenStatuses]);//eslint-disable-line

  useEffect(() => {
    if (!journeyId) {
      if (!!chosenActionType) {
        localStorage.setItem('ACTIONS-SPECIALIST-CHOSEN-TYPE-FILTER', JSON.stringify(chosenActionType));
      } else {
        localStorage.removeItem('ACTIONS-SPECIALIST-CHOSEN-TYPE-FILTER');
      }
    }
  }, [chosenActionType]);//eslint-disable-line

  useEffect(() => {
    if (!journeyId) {
      if (!!selectedTab) {
        localStorage.setItem('ACTIONS-SPECIALIST-SELECTED-TAB', selectedTab);
      } else {
        localStorage.removeItem('ACTIONS-SPECIALIST-SELECTED-TAB');
      }

      setTabFilters();
    }
  }, [selectedTab, user]);//eslint-disable-line

  useEffect(() => {
    dispatch(clearSelectedUser({
      onSuccess: () => {},
      onError: () => { displayErrorMessage(null, 'Unable to clear selected user'); }
    }));
  }, [selectedUser]);//eslint-disable-line

  useEffect(() => {
    if (!!actionsRefreshIntervalId) clearInterval(actionsRefreshIntervalId);
    actionsRefreshIntervalId = setInterval(() => {
      if (actionAutoUpdate) retrieveActions(actions?.page, actions?.limit, true);
    }, 20000);

    return () => {
      if (actionsRefreshIntervalId) clearInterval(actionsRefreshIntervalId);
    };
  }, [actions, actionAutoUpdate]);//eslint-disable-line

  useEffect(() => {
    if (!!chosenSpecialist || !!chosenActionType) {
      setIsAdditionalFiltersOpened(true);
      setIsAdditionalFiltersLinkDisabled(true);
    } else {
      setIsAdditionalFiltersLinkDisabled(false);
    }

    retrieveActions(1);
  }, [actionSearchString, chosenOwner, sortBy, chosenSpecialist, chosenActionType, chosenAssignee, chosenStatuses]);//eslint-disable-line

  useEffect(() => {
    if (!!chosenSpecialist && !!specialists?.length && !specialists.includes(chosenSpecialist)) {
      const correctSpecialist = specialists.find((s) => s.id === chosenSpecialist?.id);
      if (correctSpecialist) setChosenSpecialist(correctSpecialist);
    }
  }, [chosenSpecialist, specialists]);//eslint-disable-line

  useEffect(() => {
    if (!!chosenAssignee && !!specialists?.length && !specialists.includes(chosenAssignee)) {
      const correctAssignee = specialists.find((s) => s.id === chosenAssignee?.id);
      if (correctAssignee) setChosenAssignee(correctAssignee);
    }
  }, [chosenAssignee, specialists]);//eslint-disable-line

  useEffect(() => {
    if (!!chosenActionType && !!actionTypes?.length && !actionTypes.includes(chosenActionType)) {
      const correctActionType = actionTypes.find((s) => s.uid === chosenActionType?.uid);
      if (correctActionType) setChosenActionType(correctActionType);
    }
  }, [chosenActionType, actionTypes]);//eslint-disable-line

  useEffect(() => {
    if (isJustDataOperations(user)) history.push('/deals-management');

    setUserRole(getUserRole(user));
  }, [user]);//eslint-disable-line

  useEffect(() => {
    if (userRole === USER_ROLE.Customer) {
      history.push('/dashboard');
    }
  }, [userRole]);//eslint-disable-line

  useEffect(() => {
    retrieveActions(1);
  }, [rowsPerPage]);//eslint-disable-line

  const retrieveActions = (page = 1, limit, isOnlyIfUpdated = false) => {
    if (currentPage !== page) setCurrentPage(page);

    if (!limit) setIsLoading(true);
    let options = {
      limit: limit ?? rowsPerPage,
      page,
      onSuccess: (data) => {
        if (!data?.notModified) {
          setActions(data);
          const currentTime = new Date();
          const formattedDate = format(currentTime, "yyyy-MM-dd'T'HH:mm:ss.sssxxx");
          setLastUpdateTime(formattedDate);
        }

        setIsLoading(false);
      },
      onError: (data) => {
        setIsLoading(false);
        displayErrorMessage(data, 'Unable to retrieve actions');
      }
    };
    if (!!journeyId) options.journeyId = journeyId;
    if (sortBy) options.sort = sortBy;

    if (!journeyId) {
      if (actionSearchString) options.search = actionSearchString;
      if (chosenOwner?._id) options.journeyOwner = chosenOwner._id;
      if (chosenSpecialist) options.specialist = chosenSpecialist?.id;
      if (chosenAssignee) options.assignee = chosenAssignee?.id;
      if (chosenActionType) options.type = chosenActionType.uid;

      if (chosenStatuses?.length) options.caseStatus = chosenStatuses;
      else if (selectedTab === 'IN QUEUE') options.caseStatus = ['IN PROGRESS', 'ON HOLD'];
    }
    if (isOnlyIfUpdated && !!lastUpdateTime) options.onlyIfUpdatedSince = lastUpdateTime;

    dispatch(getCasesWithDebounce(options));
  };

  const setTabFilters = (forcedTab) => {
    if (user) {
      switch (forcedTab ?? selectedTab) {
        case 'IN QUEUE':
          setSortBy('createdAt');
          setChosenAssignee(nullOption);
          setChosenStatuses([]);
          setActionAutoUpdate(true);
          break;
        case 'ASSIGNED TO ME':
          setSortBy('');
          setChosenAssignee(user);
          setChosenStatuses(['IN PROGRESS', 'ON HOLD']);
          setActionAutoUpdate(true);
          break;
        case 'ALL OPEN':
          setSortBy('');
          setChosenAssignee(notNullOption);
          setChosenStatuses(['IN PROGRESS', 'ON HOLD']);
          setActionAutoUpdate(false);
          break;
        case 'ALL':
          setActionAutoUpdate(false);
          if (isSetAllForTheFirstTime) {
            setSortBy('');
            setChosenAssignee(null);
            setChosenStatuses([]);
          }
          break;
        default:
          break;
      }
      setIsSetAllForTheFirstTime(true);
    }
  };

  const clearFiltration = (forcedTab) => {
    setChosenOwner(undefined);
    setChosenSpecialist(null);
    setChosenActionType(null);
    setTabFilters(forcedTab);
  };

  const onOwnerSearchStringChanged = (newValue) => {
    let options = {
      limit: 10,
      onSuccess: (data) => {
        setOwners(data?.elements ?? []);
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to retrieve allowed users');
      }
    };
    if (newValue) options.search = newValue;

    dispatch(getAllowedUsersWithDebounce(options));
  };

  const updateSorting = (field) => {
    setSortBy(sortBy === field ? `-${field}` : sortBy === ('-' + field) ? '' : `${field}`);
  };

  const handleMenuOpen = (event, action) => {
    if (menuPosition) {
      return;
    }

    event.preventDefault();
    setCurrentAction(action);
    setActionId(action._id);
    setMenuPosition({
      top: event.clientY + 12,
      left: event.clientX + 12
    });
  };

  const doDeleteAction = () => {
    setMenuPosition(null);
    setActionId(null);
    if (currentAction?._id) {
      setConfirmationParameters({
        entityType: 'action',
        entityName: null,
        action: () => {
          dispatch(deleteCase({
            id: currentAction._id,
            onSuccess: () => {
              retrieveActions(1);
            },
            onError: (data) => {
              displayErrorMessage(data, 'Unable to delete the journey');
              retrieveActions(1);
            }
          }));
        }
      });
      setConfirmationOpen(true);
    }
  };

  const updateChosenStatuses = (event) => {
    const {
      target: { value },
    } = event;
    if (!!value && value.indexOf('') < 0) {
      setChosenStatuses(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',').map((val) => val.trim()) : value,
      );
    }
  };

  const onCreateAction = () => {
    history.push(`/action-create?source=${!!journeyId ? `journey-actions&journeyId=${journeyId}` : 'actions'}`);
  };

  const getElapsedTimeString = (prevDate, currentDate) => {
    if (!prevDate || !currentDate) return '';

    let timeDiffMinutes = differenceInMinutes(currentDate, prevDate);
    const minutes = timeDiffMinutes % 60;
    const hours = Math.floor(timeDiffMinutes / 60) % 24;
    const days = Math.floor(timeDiffMinutes / (24 * 60));

    if (days >= 100) {
      return `${days}d`;
    } else if (days > 0) {
      return `${days}d ${hours}h`;
    } else {
      return `${hours}h ${minutes}m`;
    }
  };

  const isTimeOutAlarm = (prevDate, currentDate, timeLimitMinutes) => {
    if (!prevDate || !currentDate || !timeLimitMinutes) return false;

    let timeDiffMinutes = differenceInMinutes(currentDate, prevDate);

    return timeDiffMinutes > timeLimitMinutes;
  };

  const getSpecialistIdentifier = (journey) => {
    const specialist = journey?.assigned_specialist ?? '';
    return typeof specialist === 'string' ? specialist : `${specialist.name ?? ''} ${specialist.surname ? specialist.surname?.substring(0, 1) + '.' : ''}`
  };

  const handleMoreToggle = () => {
    setMoreOpen((prevOpen) => !prevOpen);
  };

  const handleMoreClose = (event, shouldRedirect = false, isMine = false) => {
    if (anchorMoreRef.current && anchorMoreRef.current.contains(event.target)) {
      return;
    }

    if (shouldRedirect) {
      if (isMine) {
        localStorage.setItem(
          'ACTIONS-TIMECARD-CHOSEN-ENTRY-LOGGER-FILTER',
          JSON.stringify({ id: user.id, name: user.name, surname: user.surname })
        );
      } else {
        localStorage.removeItem('ACTIONS-TIMECARD-CHOSEN-ENTRY-LOGGER-FILTER');
      }
      history.push('/actions-timecard');
    }

    setMoreOpen(false);
  };

  const handleMoreListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMoreOpen(false);
    }
  };

  const renderToolbar = () => {
    if (!!journeyId) return null;

    return (
      <Box className={styles.toolbar}>
        <Grid container justifyContent="space-between" alignItems="center" direction="row" spacing={2}>
          <Grid container item spacing={dimensions.isScreenBiggerSM ? 2 : 0} className={styles.selectorsWrapper} xs={12} direction={dimensions.isScreenBiggerSM ? 'row' : 'column'}>
            <Grid item xs={12} md={3} className={`${styles.toolbarSelectContainer} SMALL`}>
              <Autocomplete
                options={owners}
                getOptionLabel={(option) => `${option?.name}${option?.surname ? ' ' + option?.surname : ''}` }
                fullWidth
                value={chosenOwner ?? null }
                filterOptions={(options) => options}
                onChange={(event, newValue) => {
                  setChosenOwner(newValue);
                }}
                renderInput={(params) => <TextField
                  {...params}
                  style={{ backgroundColor: "white" }}
                  onChange={(event) => {
                    onOwnerSearchStringChanged(event.target.value);
                  }}
                  onFocus={(event) => {
                    if (!event.target.value) onOwnerSearchStringChanged(event.target.value);
                  }}
                  placeholder="Filter by customer"
                  variant="outlined"
                  maxLength="100"
                />}
              />
            </Grid>
            <Grid item xs={12} md={3} className={`${styles.toolbarSelectContainer} SMALL`}>
              <FormControl variant="outlined" className={styles.selectWrapper}>
                <Select value={chosenAssignee} displayEmpty style={{ backgroundColor: "white" }} disabled={selectedTab !== 'ALL'}>
                  <MenuItem value={null} style={ !chosenAssignee ? { display: 'none' } : {} } onClick={() => setChosenAssignee(null)}>
                    <div style={{ textTransform: "none", color: '#8B90A0' }}>{chosenAssignee ? ('Clear filtration') : ('Filter by assignee')}</div>
                  </MenuItem>
                  <MenuItem value={nullOption} onClick={() => setChosenAssignee(nullOption)}>
                    <div style={{ textTransform: "none" }}>NOT ASSIGNED</div>
                  </MenuItem>
                  <MenuItem value={notNullOption} onClick={() => setChosenAssignee(notNullOption)}>
                    <div style={{ textTransform: "none" }}>ASSIGNED</div>
                  </MenuItem>
                  {(specialists ?? []).map((option, idx) => {
                    return (
                      <MenuItem value={option} key={idx} onClick={() => setChosenAssignee(option)}>
                        <div style={{ textTransform: "none" }}>{option.name} {option.surname}</div>
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} className={`${styles.toolbarSelectContainer} SMALL`}>
              <FormControl variant="outlined" className={styles.selectWrapper}>
                <Select
                  value={chosenStatuses ?? []}
                  displayEmpty
                  style={{ backgroundColor: "white" }}
                  multiple
                  className={`MULTIPLE_SELECTOR`}
                  onChange={updateChosenStatuses}
                  renderValue={(selected) =>
                    !!selected?.length ? selected.join(', ') : <span style={{ color: '#8B90A0' }}>Filter by status</span>
                  }
                  disabled={!!['ALL OPEN', 'ASSIGNED TO ME'].includes(selectedTab)}
                  MenuProps={{ PaperProps: { className: 'MENU_WITH_CHECKMARKS' } }}
                >
                  {ACTION_STATUSES.map((option, idx) => {
                    return (
                      <MenuItem value={option} key={idx} disabled={option === 'COMPLETED' && selectedTab === 'IN QUEUE'}>
                        <Checkbox checked={chosenStatuses.indexOf(option) > -1} disabled={option === 'COMPLETED' && selectedTab === 'IN QUEUE'} />
                        <ListItemText primary={option} />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3} container justifyContent={'space-between'} alignContent={'center'}>
              <Grid item>
                <span
                  className={`${styles.link} ${isAdditionalFiltersLinkDisabled ? styles.disabled : ''}`}
                  onClick={() => {
                    if (!isAdditionalFiltersLinkDisabled) setIsAdditionalFiltersOpened(!isAdditionalFiltersOpened);
                  }}
                >
                  {isAdditionalFiltersOpened ? 'Hide' : 'Show'} additional filters
                </span>
              </Grid>
              <Grid item>
                <Grid container justifyContent={'flex-start'} spacing={1}>
                  <Grid item>
                    <IconButton
                      className={`${styles.iconBtn} ${styles.noPadding} ${actionAutoUpdate ? styles.linkColor : styles.linkDisableColor} ${styles.smaller}`}
                      onClick={() => setActionAutoUpdate(!actionAutoUpdate)}
                    >
                      <UpdateIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      className={`${styles.iconBtn} ${styles.noPadding} ${styles.linkColor} ${styles.smaller}`}
                      onClick={() => clearFiltration()}
                    >
                      <FilterAltOffIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {isAdditionalFiltersOpened && (<>
              <Grid item xs={12} md={3} className={`${styles.toolbarSelectContainer} SMALL`}>
                <FormControl variant="outlined" className={styles.selectWrapper}>
                  <Select value={chosenActionType} displayEmpty style={{ backgroundColor: "white" }}>
                    <MenuItem value={null} style={ !chosenActionType ? { display: 'none' } : {} } onClick={() => setChosenActionType(null)}>
                      <div style={{ textTransform: "none", color: '#8B90A0' }}>{chosenActionType ? 'Clear filtration' : 'Filter by action type'}</div>
                    </MenuItem>
                    {(actionTypes ?? []).map((option, idx) => {
                      return (
                        <MenuItem value={option} key={idx} onClick={() => setChosenActionType(option)}>
                          <div style={{ textTransform: "none" }}>{option?.name}</div>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3} className={`${styles.toolbarSelectContainer} SMALL`}>
                <FormControl variant="outlined" className={styles.selectWrapper}>
                  <Select value={chosenSpecialist} displayEmpty style={{ backgroundColor: "white" }}>
                    <MenuItem value={null} style={ !chosenSpecialist ? { display: 'none' } : {} } onClick={() => setChosenSpecialist(null)}>
                      <div style={{ textTransform: "none", color: '#8B90A0' }}>{chosenSpecialist ? ('Clear filtration') : ('Filter by specialist')}</div>
                    </MenuItem>
                    {(specialists ?? []).map((option, idx) => {
                      return (
                        <MenuItem value={option} key={idx} onClick={() => setChosenSpecialist(option)}>
                          <div style={{ textTransform: "none" }}>{option.name} {option.surname}</div>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>)}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderMenu = (action) => {
    return (
      <Menu
        open={!!menuPosition && action._id === actionId}
        onClose={() => { setMenuPosition(null); setActionId(null) }}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
        <MenuItem
          onClick={(e) => { e.preventDefault(); setMenuPosition(null); history.push(`/action-edit/${currentAction?._id}?source=actions`); }}
          disabled={!currentAction?._id}
        >
          <a
            href={`/action-edit/${currentAction?._id}?source=${!journeyId ? 'actions' : `journey-actions&journeyId=${journeyId}`}`}
            onClick={(e) => { e.preventDefault(); }}
            className={styles.anchor}
          >
            View/Edit
          </a>
        </MenuItem>

        {[USER_ROLE.Admin, USER_ROLE.Supervisor].includes(userRole) && (
          <MenuItem className={styles.deleteMenuItem} onClick={() => doDeleteAction()}>Delete</MenuItem>
        )}
      </Menu>
    );
  };

  return <DashboardLayout team={[]} hideExtraWidgets>
    <Grid container justifyContent="space-between" alignItems="center" alignContent="center">
      <Grid item className={`${styles.pageTitle} ${dimensions.isScreenBiggerSM ? '' : styles.mobile}`}>{!!journeyId ? 'Journey actions' : 'Actions'}</Grid>
      <Grid item>
        <Button color="primary" variant={"contained"} onClick={() => onCreateAction()}>
          CREATE ACTION
        </Button>
        {dimensions?.isScreenBiggerSM ? (
          <Button color="secondary" variant="outlined" className={styles.moreActionsButton} onClick={handleMoreToggle} ref={anchorMoreRef}>
            <MoreHorizIcon /> MORE
          </Button>
        ) : (
          <IconButton className={`${styles.iconBtn} ${styles.ml1}`} onClick={handleMoreToggle} ref={anchorMoreRef}><MoreVertIcon /></IconButton>
        )}
        <Popper
          open={moreOpen}
          anchorEl={anchorMoreRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom"
          className={styles.moreMenu}
          arrow={{ enabled: true }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center top' }}
            >
              <Paper className={styles.moreActionsMenuPaper} elevation={1}>
                <ClickAwayListener onClickAway={handleMoreClose}>
                  <MenuList id="menu-list-grow" onKeyDown={handleMoreListKeyDown}>
                    <MenuItem className={styles.menuItem} onClick={(e) => handleMoreClose(e, true, true)}>
                      <span>My timecard</span>
                    </MenuItem>
                    <MenuItem className={styles.menuItem} onClick={(e) => handleMoreClose(e, true, false)}>
                      <span>All timecards</span>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>

    {!journeyId && (<>
      {dimensions.isScreenBiggerSM ? (
        <Grid container alignItems={"flex-start"} direction={"row"} justifyContent={"flex-start"}>
          {TABS.map((tab, idx) => { return (
            <Grid
              item
              container
              direction={"column"}
              alignItems={"center"}
              className={`${styles.milestoneItem} ${tab === selectedTab ? styles.selected : ''}`}
              onClick={() => { if (selectedTab !== tab) setSelectedTab(tab); }}
              key={idx}
            >
              <Grid item>
                {tab}
              </Grid>
            </Grid>
          ); })}
        </Grid>
      ) : (
        <Box sx={{ mt: 4 }}>
          <FormControl variant="outlined" className={styles.selectWrapper}>
            <Select value={selectedTab ?? ''}>
              {TABS.map((tab, idx) => {
                return (
                  <MenuItem
                    value={tab}
                    key={idx}
                    onClick={() => { if (selectedTab !== tab) setSelectedTab(tab); }}
                  >
                    <div style={{ textTransform: "uppercase" }}>
                      {tab}
                    </div>
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
      )}
    </>)}

    {!!currentJourney?.name && (
      <Box sx={{ mt: 2 }}>
        <div className={styles.journeyNameTitle} onClick={() => history.push(`/journey-details/${journeyId}`)}>{currentJourney?.name}</div>
      </Box>
    )}

    <Box sx={{ mt: 2 }} className={'ActionsDashboardTableWrapper'}>
      {renderToolbar()}
      <TableContainer className={`${styles.tableContainer} ${styles.fixedWidth} ActionsDashboardTableWrapper`}>
        <Table size={dimensions.isScreenBiggerSM ? "medium" : "small"} stickyHeader>
          <TableHead>
            <TableRow className={styles.tableHead}>
              <TableCell
                align={'left'}
                style={{ minWidth: 104 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('typeObj.name')}>
                  ACTION{sortBy === 'typeObj.name' ? (<> &#9650;</>) : sortBy === '-typeObj.name' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>
              {!journeyId ? (
                <TableCell
                  align={'left'}
                  style={{ minWidth: 144, maxWidth: 304 }}
                >
                  <div className={styles.clickable} onClick={() => updateSorting('journey.user.name')}>
                    CUSTOMER / JOURNEY{sortBy === 'journey.user.name' ? (<> &#9650;</>) : sortBy === '-journey.user.name' ? (<> &#9660;</>) : (<></>)}
                  </div>
                </TableCell>
              ) : (
                <TableCell
                  align={'left'}
                  style={{ minWidth: 96 }}
                >
                  <div>
                    CUSTOMER
                  </div>
                </TableCell>
              )}

              <TableCell
                align={'left'}
                style={{ minWidth: 96 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('journey.assigned_specialist.name')}>
                  SPECIALIST{sortBy === 'journey.assigned_specialist.name' ? (<> &#9650;</>) : sortBy === '-journey.assigned_specialist.name' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'left'}
                style={{ minWidth: 104 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('assignee.name')}>
                  ASSIGNED TO{sortBy === 'assignee.name' ? (<> &#9650;</>) : sortBy === '-assignee.name' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'left'}
                style={{ minWidth: 104 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('createdAt')}>
                  CREATE DATE{sortBy === 'createdAt' ? (<> &#9650;</>) : sortBy === '-createdAt' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'left'}
                style={{ minWidth: 104 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('updatedAt')}>
                  UPDATE DATE{sortBy === 'updatedAt' ? (<> &#9650;</>) : sortBy === '-updatedAt' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>

              <TableCell
                align={'left'}
                style={{ minWidth: 92, maxWidth: 92 }}
              >
                <div className={styles.clickable} onClick={() => updateSorting('createdAt')}>
                  ELAPSED TIME{sortBy === '-createdAt' ? (<> &#9650;</>) : sortBy === 'createdAt' ? (<> &#9660;</>) : (<></>)}
                </div>
              </TableCell>
              <TableCell
                align={'right'}
                style={{ minWidth: 10, width: 10 }}
              />
            </TableRow>
          </TableHead>

          <TableBody className={styles.tableBody}>
            {(actions?.elements ?? []).map((action, idx) => {
              const actionCustomer = action.journey?.user ?? action.customer;

              return (
                <TableRow className={`${styles.tableItem}`} key={idx}>
                  <TableCell
                    align={'left'}
                  >
                    <div className={styles.name}>
                      <a
                        href={`/action-edit/${action._id}?source=${!!journeyId ? `journey-actions&journeyId=${journeyId}` : 'actions'}`}
                        onClick={(e) => {
                          e.preventDefault(); history.push(`/action-edit/${action._id}?source=${!!journeyId ? `journey-actions&journeyId=${journeyId}` : 'actions'}`);
                        }}
                      >{action.typeObj?.name}</a>
                    </div>
                    {action?.description && (
                      <div className={styles.caption}>
                        {action.description}
                      </div>
                    )}
                  </TableCell>
                  {!journeyId ? (
                    <TableCell
                      align={'left'}
                      className={`${styles.name} ${styles.clickable}`}
                    >
                      <div className={`${styles.username} ${styles.clickable}`}>
                        <a
                          href={`/profile/${action.journey?.user?._id}`}
                          onClick={(e) => { e.preventDefault(); history.push(`/profile/${actionCustomer?._id}`); }}
                        >{actionCustomer?.name} {actionCustomer?.surname}</a>
                      </div>
                      <div className={`${styles.caption} ${styles.clickable}`}>
                        <a
                          href={`/journey-details/${action.journey?._id}`}
                          onClick={(e) => { e.preventDefault(); history.push(`/journey-details/${action.journey?._id}`); }}
                        >{action.journey?.name}</a>
                      </div>
                    </TableCell>
                  ) : (
                    <TableCell
                      align={'left'}
                      className={`${styles.username} ${styles.clickable}`}
                    >
                      <a
                        href={`/profile/${action.journey?.user?._id}`}
                        onClick={(e) => { e.preventDefault(); history.push(`/profile/${actionCustomer?._id}`); }}
                      >{actionCustomer?.name} {actionCustomer?.surname}</a>
                    </TableCell>
                  )}

                  <TableCell
                    align={'left'}
                    className={`${styles.username}`}
                  >
                    <span>{getSpecialistIdentifier(action.journey)}</span>
                  </TableCell>

                  <TableCell
                    align={'left'}
                    className={`${styles.username}`}
                  >
                    {!!action.assignee?._id && (
                      <span>{action.assignee?.name} {action.assignee?.surname?.substring(0, 1)}{!!action.assignee?.surname ? '.' : ''}</span>
                    )}
                  </TableCell>

                  <TableCell align={'left'} className={styles.date}>
                    {action?.createdAt ? format(new Date(action.createdAt), 'dd.MM.yy HH:mm') : '???'}
                  </TableCell>

                  <TableCell align={'left'} className={styles.date}>
                    {action?.updatedAt ? format(new Date(action.updatedAt), 'dd.MM.yy HH:mm') : '???'}
                  </TableCell>

                  {action?.caseStatus === 'IN PROGRESS' ? (
                    <TableCell align={'right'} className={styles.date}>
                      <Grid container justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
                        <Grid item>
                          {isTimeOutAlarm(
                            !!action?.createdAt ? new Date(action.createdAt) : undefined,
                            currentTime,
                            Number(action?.typeObj?.minutesToAlarm)
                          ) && (<><ErrorIcon className={styles.errorIcon} />{' '}</>)}
                        </Grid>
                        <Grid item>
                          {getElapsedTimeString(
                            !!action?.createdAt ? new Date(action.createdAt) : undefined, currentTime
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : (
                    <TableCell align={'center'}>
                      <Grid container direction={'column'}>
                        <Grid item className={action?.caseStatus === 'ON HOLD' ? styles.onHold : styles.done}>
                          {action?.caseStatus === 'COMPLETED' ? 'DONE' : action.caseStatus}
                        </Grid>
                      </Grid>
                    </TableCell>
                  )}


                  <TableCell align={'left'}>
                    <IconButton
                      className={`${styles.iconBtn} ${styles.noPadding}`}
                      onClick={(event) => { handleMenuOpen(event, action); }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    {renderMenu(action)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {(!actions?.elements?.length) && (
          <Grid container className={styles.emptyTable} justifyContent={"center"} alignItems={"center"}>
            <Grid item>{isLoading ? 'Loading...' : 'No actions found'}</Grid>
          </Grid>
        )}
        {(isLoading) && (<CircularProgress size={80} className={styles.cardProgress} />)}
      </TableContainer>
      {(actions?.total && actions?.total > 10) && (
        <CustomPaginator
          count={actions?.total && actions?.limit ? Math.ceil(actions.total / actions.limit) : 1}
          page={currentPage}
          onChange={(e, page) => retrieveActions(page)}
          className={styles.pagination}
        />
      )}
    </Box>
    <ConfirmDeletionModal open={confirmationOpen} setOpen={setConfirmationOpen} parameters={confirmationParameters}/>
  </DashboardLayout>;
};

export default ActionsDashboard;