const minScreenXL = 1920;
const minScreenLG = 1280;
const minScreenMD = 960;
const minScreenSM = 600;
const minScreenXS = 0;

const matchScreenWidth = (min, max) => innerWidth => {
  if (!min) {
    return innerWidth < max;
  }

  if (!max) {
    return innerWidth >= min;
  }

  return innerWidth >= min && innerWidth < max;
};

const isScreenXL = matchScreenWidth(minScreenXL);
const isScreenLG = matchScreenWidth(minScreenLG, minScreenXL);
const isScreenMD = matchScreenWidth(minScreenMD, minScreenLG);
const isScreenSM = matchScreenWidth(minScreenSM, minScreenMD);
const isScreenXS = matchScreenWidth(minScreenXS, minScreenSM);

const isScreenLessXL = matchScreenWidth(minScreenXS, minScreenXL);
const isScreenLessLG = matchScreenWidth(minScreenXS, minScreenLG);
const isScreenLessMD = matchScreenWidth(minScreenXS, minScreenMD);
const isScreenLessSM = matchScreenWidth(minScreenXS, minScreenSM);
const isScreenLessXS = false;
const isScreenLess800 = matchScreenWidth(0, 800);

const isScreenBiggerXL = false;
const isScreenBiggerLG = matchScreenWidth(minScreenXL);
const isScreenBiggerMD = matchScreenWidth(minScreenLG);
const isScreenBiggerSM = matchScreenWidth(minScreenMD);
const isScreenBiggerXS = matchScreenWidth(minScreenSM);
const isScreenBigger800 = matchScreenWidth(800);

export const computeSizes = ({ innerWidth, innerHeight }) => {
  return {
    innerWidth,
    innerHeight,
    isScreenXL: isScreenXL(innerWidth),
    isScreenLG: isScreenLG(innerWidth),
    isScreenMD: isScreenMD(innerWidth),
    isScreenSM: isScreenSM(innerWidth),
    isScreenXS: isScreenXS(innerWidth),
    isScreenLessXL: isScreenLessXL(innerWidth),
    isScreenLessLG: isScreenLessLG(innerWidth),
    isScreenLessMD: isScreenLessMD(innerWidth),
    isScreenLessSM: isScreenLessSM(innerWidth),
    isScreenLessXS: isScreenLessXS,
    isScreenBiggerXL: isScreenBiggerXL,
    isScreenBiggerLG: isScreenBiggerLG(innerWidth),
    isScreenBiggerMD: isScreenBiggerMD(innerWidth),
    isScreenBiggerSM: isScreenBiggerSM(innerWidth),
    isScreenBiggerXS: isScreenBiggerXS(innerWidth),
    isScreenBigger800: isScreenBigger800(innerWidth),
    isScreenLess800: isScreenLess800(innerWidth)
  };
};
