import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useSelector } from 'react-redux';
import { withDimensions } from '../../HOC';
import styles from './styles.module.scss';
import history from '../../../../utils/history';
import BookingMeetingModal from '../BookingMeetingModal';
import { withRouter } from 'react-router-dom';
import ScheduleMeetingModal from "../ScheduleMeetingModal";


const TeamBlock = ({ team, location }) => {
  const { user, selectedUser } = useSelector(state => state.user);
  const dimensions = useSelector(state => state.dimensions);

  const [open, setOpen] = useState(false);
  const [mate, setMate] = useState(undefined);
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [effectiveUser, setEffectiveUser] = useState(undefined);
  const [eventType, setEventType] = useState(undefined);

  const anchorRef = useRef(null);

  useEffect(() => { setEffectiveUser(selectedUser ?? user) }, [user, selectedUser]); //eslint-disable-line

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event, member) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (member) doSchedule(member)

    setOpen(false);
  };
  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const doSchedule = (member) => {
    setMate(member);
    setOpenBookingModal(true);
  };

  const scheduleMeeting = (eventType) => {
    localStorage.setItem('CALENDAR_REDIRECT_DESTINATION', location.pathname ?? '/dashboard');
    localStorage.setItem('CALENDAR_REDIRECT_USER', effectiveUser?.id);
    localStorage.setItem('CALENDAR_REDIRECT_SPECIALIST_NAME', mate?.name);
    localStorage.setItem('CALENDAR_REDIRECT_EVENT_TYPE', eventType);
    if (selectedUser?.id) localStorage.setItem('CALENDAR_REDIRECT_SELECTED_USER', selectedUser?.id);
    setEventType(eventType);

    setOpenScheduleModal(true);
  };

  const renderAvatar = (member, left, top, size, idx) => {
    return (
      <div key={idx}>
        {member.photo ? (
          <img
            width={size}
            height={size}
            className={styles.avatar}
            src={member.photo}
            alt="team member's avatar"
            style={{ left: `${left}px`, top: `${top}px` }}
            onClick={() => history.push('/team')}
          />
        ) : (
          <div
            className={styles.avatar}
            style={{ backgroundColor: '#1B1F3B', left: `${left}px`, top: `${top}px` }}
            onClick={() => history.push('/team')}
          >
            <PersonIcon style={{ fontSize: 64 }} />
          </div>
        )}
      </div>
    );
  };

  const renderAvatarsWrapper = (team) => {
    const size = 72;
    const width = 128;
    const overlapping = 2 * size - width;
    const centerLeft = (width - size) / 2;
    const height = (Math.ceil(team.length / 2) - 1) * (size - overlapping) + size;
    return (
      <Box sx={{ height: `${height}px` }} className={styles.avatarsWrapper}>
        {team.map((member, idx) => {
          const isEven = idx % 2 !== 0; //because first idx is 0
          const left = isEven ? size - overlapping : idx === team.length - 1 ? centerLeft : 0;
          const top = Math.floor(idx / 2) * (size - overlapping);
          return renderAvatar(member, left, top, size, idx);
        })}
      </Box>
    );
  };

  return (<>
    {!!team?.length && (
      <Box sx={{ mb: 2, mt: dimensions?.isScreenBiggerSM ? 0 : 2 }} className={styles.teamBlock}>
        <Box className={styles.blockHeader}>{team.length > 1 ? 'Your Team' : 'Your Home Loan Specialist'}</Box>
        <Grid container justifyContent="center">
          <Grid item className={styles.photosBlock}>{renderAvatarsWrapper(team)}</Grid>
        </Grid>
        <Box sx={{ mb: 2, mt: 2 }} className={styles.description}>
          <Box>Do you have questions?</Box>
          <Box>{`${team.length > 1 ? 'We are ' : 'I’m '}here to help`}</Box>
        </Box>
        {team.length === 1 && (
          <Button color="secondary" variant="outlined" className={styles.scheduleButton} fullWidth onClick={() => { doSchedule(team[0])}}>
            SCHEDULE
          </Button>
        )}
        {team.length > 1 && (<>
          <Button color="secondary" variant="outlined" className={styles.scheduleButton} fullWidth onClick={handleToggle} ref={anchorRef}>
            SCHEDULE <ArrowDropDownIcon className={styles.ddIcon} />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom"
            className={styles.actionMenu}
            arrow={{ enabled: true }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: 'center top' }}
              >
                <Paper className={styles.actionMenuPaper} elevation={1}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      {team.map((member, idx) => (
                        <MenuItem className={styles.menuItem} onClick={(e) => handleClose(e, member)} key={idx}>
                          <span>SCHEDULE WITH {member.name}</span>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>)}
      </Box>
    )}
    <BookingMeetingModal open={openBookingModal} setOpen={setOpenBookingModal} mate={mate} scheduleMeeting={scheduleMeeting} />
    <ScheduleMeetingModal
      open={openScheduleModal}
      setOpen={setOpenScheduleModal}
      specialistUser={mate}
      eventType={eventType}
      yesAction={() => {
        setOpenBookingModal(false);
      }}
    />
  </>)
};

TeamBlock.propTypes = {
  team: PropTypes.arrayOf(PropTypes.object)
};

export default withDimensions(withRouter(TeamBlock));