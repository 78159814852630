const defaultState = {
  isDisplayedSessionExpiredDialog: false
};

export const actionTypes = {
  SET_IS_DISPLAYED_SESSION_EXPIRED_DIALOG: 'SET_IS_DISPLAYED_SESSION_EXPIRED_DIALOG',
};

const blockingDialogsActions = function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_IS_DISPLAYED_SESSION_EXPIRED_DIALOG:
      return {
        ...state,
        isDisplayedSessionExpiredDialog: payload
      };
    default:
      return state;
  }
};

export default blockingDialogsActions;

// ACTIONS
export const setIsDisplayedSessionExpiredDialog = payload => ({
  type: actionTypes.SET_IS_DISPLAYED_SESSION_EXPIRED_DIALOG,
  payload
});


