import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

import { useSelector } from 'react-redux';

import { withDimensions } from '../../HOC';
import styles from './styles.module.scss';
import { ReactComponent as Homes } from './homes.svg';
import { ReactComponent as GirlCall } from './girl-call-desk.svg';
import AskSpecialistWidget from '../AskSpecialistWidget';

const PageHeader = ({ title, displayWidget = false }) => {
  const dimensions = useSelector(state => state.dimensions);

  //TODO restore displaying AskSpecialistWidget when we are ready by removing "false"
  return (
    <Box className={styles.headerContainer}>
      <Homes className={styles.homesBackground} />
      <GirlCall className={styles.girlCallBackground} />
      {dimensions.isScreenBiggerSM && displayWidget && false && <AskSpecialistWidget style={{ position: 'absolute', top: '32px', right: '32px' }} /> }
      <Grid container direction="column" justifyContent="center" className={styles.titleWrapper}>
        <Grid className={`${dimensions.isScreenBiggerSM ? styles.title : styles.titleMobile}`} item>{title}</Grid>
      </Grid>
    </Box>
  )
};

PageHeader.propTypes = {
  title: PropTypes.string,
  displayWidget: PropTypes.bool
};

export default withDimensions(PageHeader);