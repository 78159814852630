import {call, takeLatest, takeEvery, debounce, all, takeLeading} from 'redux-saga/effects';
import axios from 'axios';
import { isFunc } from '@utils/functions';


const Api = {
  getJourneys: ({ userId, sort, limit, statuses }) => {
    let params = {
      limit: limit ?? 100
    };
    if (userId) params.user = userId;
    if (sort) params.sort = sort;
    if (statuses) params.statuses = statuses;

    return axios.request({
      url: '/journeys',
      method: 'get',
      params
    });

  },
  getAllowedJourneys: ({ limit, page, userId, search, sort, milestone, currentStepUid, specialist, referredBy, account, reference, createdLastDays, updatedLastDays, statuses }) => {
    let params = {
      limit: limit ?? 10
    };
    if (userId) params.user = userId;
    if (search) params.search = search;
    if (sort) params.sort = sort;
    if (milestone) params.milestone = milestone;
    if (currentStepUid) params.currentStepUid = currentStepUid;
    if (specialist) params.specialist = specialist;
    if (referredBy) params.referredBy = referredBy;
    if (account) params.account = account;
    if (reference) params.reference = reference;
    if (page || page === 0) params.page = page;
    if (createdLastDays) params.createdLastDays = createdLastDays;
    if (updatedLastDays) params.updatedLastDays = updatedLastDays;
    if (statuses) params.statuses = statuses;

    return axios.request({
      url: '/allowedJourneys',
      method: 'get',
      params
    });
  },
  getJourney: (id) => axios.get(`/journeys/${id}`),
  getAllowedJourney: (id) => axios.get(`/allowedJourney/${id}`),
  updateJourney: ({id, body, notify, stepId, milestone, section, substatusNameStatus, metaAddress, metaDuration, metaValuationAmount}) => {
    let params = {};
    if (notify === 'yes') {
      params.notify = notify;
      if (stepId) params.stepId = stepId;
      if (milestone) params.milestone = milestone;
      if (section) params.section = section;
      if (substatusNameStatus) params.substatusNameStatus = substatusNameStatus;
      if (metaAddress) params.metaAddress = metaAddress;
      if (metaDuration) params.metaDuration = metaDuration;
      if (metaValuationAmount) params.metaValuationAmount = metaValuationAmount;
    }

    return axios.request({
      url: `/journeys/${id}`,
      method: 'put',
      data: body,
      params
    });
  },
  goNextJourneyStep: ({id, category, command, applicantId, notify}) => {
    let params = {};
    if (applicantId) params.applicant = applicantId;
    if (command) params.command = command;
    if (notify) params.notify = notify;

    return axios.request({
      url: `journeys/${id}/next/${category}`,
      method: 'post',
      params,
      data: {}
    });
  },
  rollbackJourneyStep: ({ journeyId, category, stepId }) => axios.post(`/journeys/${journeyId}/rollback/${category}/${stepId}`, {}),
  getBorrowingCapacity: (journeyId) => axios.get(`/services/borrowingcapacity/journeys/${journeyId}`),
  getHouseholdTypes: ({ journeyId, applicantId }) => axios.get(`/journeys/${journeyId}/applicants/${applicantId}/household`),
  verifyIdentity: ({ journeyId, applicantId, body }) => axios.post(`/journeys/${journeyId}/applicants/${applicantId}/identity`, body),
  getMilestones: () => axios.get(`milestones`),
  deleteJourney: (id) => axios.delete(`/journeys/${id}`),
  cloneJourney: ({ id, body }) => axios.post(`/journeys/${id}/clone`, body),
  createJourney: (body) => axios.post('/journeys', body),
  createAssessment: ({ body, journeyId }) => axios.post(`/journeys/${journeyId}/assessments`, body),
  signAssessment: ({ journeyId }) => axios.post(`/journeys/${journeyId}/assessment/sign`, {}),
  getJourneyAssessments: (journeyId) => axios.get(`/journeys/${journeyId}/assessments`),
  getJourneyAssessmentTemplate: (journeyId) => axios.get(`/journeys/${journeyId}/assessment/template`),
  saveJourneyAssessmentTemplate: ({ journeyId, body }) => axios.put(`/journeys/${journeyId}/assessment/template`, body),
  getPreliminaryAssessment: (journeyId) => axios.get(`/journeys/${journeyId}/assessment/prelim`),
  getPreliminaryAssessmentPdf: (journeyId) => axios.get(`/journeys/${journeyId}/assessment/prelim/pdf`, {responseType: 'arraybuffer'}),
  calculatePaymentAndLmi: ({ price, deposit, rate, purpose, firstHomeBuyer }) =>
    axios.post(`/services/basicrepayments`, { price, deposit, rate, purpose, firstHomeBuyer }),
  getAssessment: (journeyId) => axios.get(`/journeys/${journeyId}/assessment`),
  getAssessmentPdf: (journeyId) => axios.get(`/journeys/${journeyId}/assessment/pdf`, {responseType: 'arraybuffer'}),
  getStepsListByVersion: ({ version, journeyId }) => axios.get(`/steps?version=${version}${!!journeyId ? `&journeyId=${journeyId}` : ''}`),
  calculateAssessmentParameters: ({ body, journeyId }) => axios.post(`/journeys/${journeyId}/assessment/calculate`, body),
  calculateStampDutyInJourney: ({ body, journeyId }) => axios.post(`/journeys/${journeyId}/assessment/calculateStampDuty`, body),
  getSubstatuses: () => axios.get('/dictionary/substatuses'),
  submitApplication: ({journeyId, displayIdentifier}) => axios.post('/services/nextgen/application', { journeyId, displayIdentifier }),
  archiveJourneys: ({ ids, setDatesIds }) => axios.post('/journeys/archive', { ids, setDatesIds }),
  withdrawJourney: ({ id, reason }) => axios.post('/journeys/withdraw', { id, reason }),
  checkAutoConveyancing: () => axios.get('/journeys/isAutoConveyancingEnabled'),
  getDomiumAddresses: () => axios.get('/dictionary/domiumAddresses'),
  getJourneyPayments: ({ journeyId, userId, paymentStatus, sessionStatus, product, productId }) => {
    let params = {};
    if (userId) params.user = userId;
    if (paymentStatus) params.paymentStatus = paymentStatus;
    if (sessionStatus) params.sessionStatus = sessionStatus;
    if (product) params.product = product;
    if (productId) params.productId = productId;

    return axios.request({
      url: `/journeys/${journeyId}/payments`,
      method: 'get',
      params
    });

  },
  removeConveyancingFromJourney: (journeyId) => axios.get(`/journeys/${journeyId}/removeConveyancing`),
};

export const actionTypes = {
  GET_JOURNEYS: 'GET_JOURNEYS',
  GET_JOURNEYS_BY_ID: 'GET_JOURNEYS_BY_ID',
  GET_ALLOWED_JOURNEYS: 'GET_ALLOWED_JOURNEYS',
  GET_ALLOWED_JOURNEYS_WITH_DEBOUNCE: 'GET_ALLOWED_JOURNEYS_WITH_DEBOUNCE',
  GET_ALLOWED_JOURNEYS_BY_MILESTONES: 'GET_ALLOWED_JOURNEYS_BY_MILESTONES',
  GET_ALLOWED_JOURNEYS_BY_MILESTONES_WITH_DEBOUNCE: 'GET_ALLOWED_JOURNEYS_BY_MILESTONES_WITH_DEBOUNCE',
  GET_JOURNEY: 'GET_JOURNEY',
  UPDATE_JOURNEY: 'UPDATE_JOURNEY',
  GO_NEXT_JOURNEY_STEP: 'GO_NEXT_JOURNEY_STEP',
  ROLLBACK_JOURNEY_STEP: 'ROLLBACK_JOURNEY_STEP',
  GET_BORROWING_CAPACITY: 'GET_BORROWING_CAPACITY',
  GET_HOUSEHOLD_TYPES: 'GET_HOUSEHOLD_TYPES',
  GET_HOUSEHOLDS_TYPES: 'GET_HOUSEHOLDS_TYPES',
  VERIFY_IDENTITY: 'VERIFY_IDENTITY',
  GET_MILESTONES: 'GET_MILESTONES',
  DELETE_JOURNEY: 'DELETE_JOURNEY',
  CLONE_JOURNEY: 'CLONE_JOURNEY',
  CREATE_JOURNEY: 'CREATE_JOURNEY',
  CREATE_ASSESSMENT: 'CREATE_ASSESSMENT',
  SIGN_ASSESSMENT: 'SIGN_ASSESSMENT',
  GET_JOURNEY_ASSESSMENTS: 'GET_JOURNEY_ASSESSMENTS',
  GET_JOURNEY_ASSESSMENT_TEMPLATE: 'GET_JOURNEY_ASSESSMENT_TEMPLATE',
  SAVE_JOURNEY_ASSESSMENT_TEMPLATE: 'SAVE_JOURNEY_ASSESSMENT_TEMPLATE',
  GET_PRELIMINARY_ASSESSMENT: 'GET_PRELIMINARY_ASSESSMENT',
  GET_PRELIMINARY_ASSESSMENT_PDF: 'GET_PRELIMINARY_ASSESSMENT_PDF',
  CALCULATE_PAYMENT_AND_LMI: 'CALCULATE_PAYMENT_AND_LMI',
  GET_ASSESSMENT: 'GET_ASSESSMENT',
  GET_ASSESSMENT_PDF: 'GET_ASSESSMENT_PDF',
  GET_STEPS_LIST_BY_VERSION: 'GET_STEPS_LIST_BY_VERSION',
  CALCULATE_ASSESSMENT_PARAMETERS: 'CALCULATE_ASSESSMENT_PARAMETERS',
  CALCULATE_ASSESSMENT_PARAMETERS_WITH_DEBOUNCE: 'CALCULATE_ASSESSMENT_PARAMETERS_WITH_DEBOUNCE',
  CALCULATE_STAMP_DUTY_IN_JOURNEY: 'CALCULATE_STAMP_DUTY_IN_JOURNEY',
  CALCULATE_STAMP_DUTY_IN_JOURNEY_WITH_DEBOUNCE: 'CALCULATE_STAMP_DUTY_IN_JOURNEY_WITH_DEBOUNCE',
  GET_SUBSTATUSES: 'GET_SUBSTATUSES',
  SUBMIT_APPLICATION: 'SUBMIT_APPLICATION',
  ARCHIVE_JOURNEYS: 'ARCHIVE_JOURNEYS',
  WITHDRAW_JOURNEY: 'WITHDRAW_JOURNEY',
  CHECK_AUTO_CONVEYANCING: 'CHECK_AUTO_CONVEYANCING',
  GET_DOMIUM_ADDRESSES: 'GET_DOMIUM_ADDRESSES',
  GET_JOURNEY_PAYMENTS: 'GET_JOURNEY_PAYMENTS',
  REMOVE_CONVEYANCING_FROM_JOURNEY: 'REMOVE_CONVEYANCING_FROM_JOURNEY'
};

function* sagaGetJourneys({ payload: { userId, sort, limit, statuses, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getJourneys, { userId, sort, limit, statuses });
    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetJourneysById({ payload: { ids, onSuccess, onError } }) {
  try {
    const requests = [];
    ids.forEach((id) => requests.push(call(Api.getJourney, id)));
    const results = yield all(requests);
    if (results.every((result) => result.status < 400 || result.status === 404)) {
      if (isFunc(onSuccess)) onSuccess(results.map((result) => result.data?.id ? result.data : undefined));
    } else if (isFunc(onError)) onError();
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAllowedJourneys({ payload: {
  limit, page, userId, search, sort, milestone, specialist, referredBy, account, reference, createdLastDays, updatedLastDays, currentStepUid, statuses, onSuccess, onError
} }) {
  try {
    const updMilestone = milestone === 'Settled' ? 'Settlement' : milestone;
    const updCurrentStepUid = milestone === 'Settled' ? 'settlement_completed' : (milestone === 'Settlement' ? '-settlement_completed' : currentStepUid);
    const { data } = yield call(Api.getAllowedJourneys, {
      limit, page, userId, search, sort, milestone: updMilestone, currentStepUid: updCurrentStepUid, specialist, referredBy, account, reference, createdLastDays, updatedLastDays, statuses
    });
    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAllowedJourneysByMilestones({ payload: { limit, userId, search, sort, milestones, statuses, specialist, referredBy, account, reference, onSuccess, onError } }) {
  try {
    const requests = [];
    milestones.forEach((milestone) => {
      const updMilestone = milestone === 'Settled' ? 'Settlement' : milestone;
      const currentStepUid = milestone === 'Settled' ? 'settlement_completed' : (milestone === 'Settlement' ? '-settlement_completed' : undefined);
      requests.push(call(
        Api.getAllowedJourneys,
        { limit, userId, search, sort, milestone: updMilestone, statuses, currentStepUid, specialist, referredBy, account, reference }
      ))
    });
    const results = yield all(requests);
    if (results.every((result) => result.status < 400)) {
      if (isFunc(onSuccess)) onSuccess(results.map((result) => result.data));
    } else if (isFunc(onError)) onError();
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetMilestones({ payload: { onSuccess, onError } }) {
  try {
    const result = yield call(Api.getMilestones);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetJourney({ payload: { id, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getJourney, id);
    if (data.id) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateJourney({ payload: { id, body, notify, stepId, milestone, section, substatusNameStatus, metaAddress, metaDuration, metaValuationAmount, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateJourney, {id, body, notify, stepId, milestone, section, substatusNameStatus, metaAddress, metaDuration, metaValuationAmount});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaSubmitApplication({ payload: { journeyId, displayIdentifier, onSuccess, onError } }) {
  try {
    const result = yield call(Api.submitApplication, { journeyId, displayIdentifier: displayIdentifier ?? journeyId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateJourney({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createJourney, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGoNextJourneyStep({ payload: { id, category, applicantId, command, notify, onSuccess, onError } }) {
  try {
    const result = yield call(Api.goNextJourneyStep, {id, category, applicantId, command, notify});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaRollbackJourneyStep({ payload: { journeyId, category, stepId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.rollbackJourneyStep, {journeyId, category, stepId});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetBorrowingCapacity({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getBorrowingCapacity, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetHouseholdTypes({ payload: { journeyId, applicantId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getHouseholdTypes, { journeyId, applicantId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetHouseholdsTypes({ payload: { journeyId, applicantsIds, onSuccess, onError } }) {
  try {
    const requests = [];
    applicantsIds.forEach((applicantId) => requests.push(call(Api.getHouseholdTypes, { journeyId, applicantId })));
    const results = yield all(requests);

    if (results.every((result) => result.status < 400)) {
      if (isFunc(onSuccess)) onSuccess(results.map((result) => result.data));
    } else if (isFunc(onError)) onError();

  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaVerifyIdentity({ payload: { journeyId, applicantId, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.verifyIdentity, { journeyId, applicantId, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteJourney({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteJourney, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCloneJourney({ payload: { id, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.cloneJourney, { id, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateAssessment({ payload: { journeyId, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createAssessment, {journeyId, body});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaSignAssessment({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.signAssessment, { journeyId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetJourneyAssessments({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getJourneyAssessments, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetJourneyAssessmentTemplate({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getJourneyAssessmentTemplate, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaSaveJourneyAssessmentTemplate({ payload: { journeyId, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.saveJourneyAssessmentTemplate, {journeyId, body});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetPreliminaryAssessment({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getPreliminaryAssessment, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetPreliminaryAssessmentPdf({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getPreliminaryAssessmentPdf, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCalculatePaymentAndLmi({ payload: { price, deposit, rate, purpose, firstHomeBuyer, onSuccess, onError } }) {
  try {
    const result = yield call(Api.calculatePaymentAndLmi, { price, deposit, rate, purpose, firstHomeBuyer });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAssessment({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getAssessment, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetAssessmentPdf({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getAssessmentPdf, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetStepsListByVersion({ payload: { version, journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getStepsListByVersion, { version, journeyId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCalculateAssessmentParameters({ payload: { journeyId, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.calculateAssessmentParameters, { journeyId, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCalculateStampDutyInJourney({ payload: { journeyId, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.calculateStampDutyInJourney, { journeyId, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetSubstatuses({ payload: { onSuccess, onError } }) {
  try {
    const result = yield call(Api.getSubstatuses);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetDomiumAddresses({ payload: { onSuccess, onError } }) {
  try {
    const result = yield call(Api.getDomiumAddresses);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaArchiveJourneys({ payload: { ids, setDatesIds, onSuccess, onError } }) {
  try {
    const result = yield call(Api.archiveJourneys, { ids, setDatesIds });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess();
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaWithdrawJourney({ payload: { id, reason, onSuccess, onError } }) {
  try {
    const result = yield call(Api.withdrawJourney, { id, reason });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCheckAutoConveyancing({ payload: { onSuccess, onError } }) {
  try {
    const result = yield call(Api.checkAutoConveyancing);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetJourneyPayments({ payload: { journeyId, userId, paymentStatus, sessionStatus, product, productId, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.getJourneyPayments, { journeyId, userId, paymentStatus, sessionStatus, product, productId });
    if (data) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaRemoveConveyancingFromJourney({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const { data } = yield call(Api.removeConveyancingFromJourney, journeyId);
    if (data) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getJourneys = payload => ({ type: actionTypes.GET_JOURNEYS, payload });
export const getJourneysById = payload => ({ type: actionTypes.GET_JOURNEYS_BY_ID, payload });
export const getJourney = payload => ({ type: actionTypes.GET_JOURNEY, payload });
export const updateJourney = payload => ({ type: actionTypes.UPDATE_JOURNEY, payload });
export const goNextJourneyStep = payload => ({ type: actionTypes.GO_NEXT_JOURNEY_STEP, payload });
export const rollbackJourneyStep = payload => ({ type: actionTypes.ROLLBACK_JOURNEY_STEP, payload });
export const getBorrowingCapacity = payload => ({ type: actionTypes.GET_BORROWING_CAPACITY, payload });
export const getHouseholdTypes = payload => ({ type: actionTypes.GET_HOUSEHOLD_TYPES, payload });
export const getHouseholdsTypes = payload => ({ type: actionTypes.GET_HOUSEHOLDS_TYPES, payload });
export const verifyIdentity = payload => ({ type: actionTypes.VERIFY_IDENTITY, payload });
export const getAllowedJourneys = payload => ({ type: actionTypes.GET_ALLOWED_JOURNEYS, payload });
export const getAllowedJourneysWithDebounce = payload => ({ type: actionTypes.GET_ALLOWED_JOURNEYS_WITH_DEBOUNCE, payload });
export const getAllowedJourneysByMilestones = payload => ({ type: actionTypes.GET_ALLOWED_JOURNEYS_BY_MILESTONES, payload });
export const getAllowedJourneysByMilestonesWithDebounce = payload => ({ type: actionTypes.GET_ALLOWED_JOURNEYS_BY_MILESTONES_WITH_DEBOUNCE, payload });
export const getMilestones = payload => ({ type: actionTypes.GET_MILESTONES, payload });
export const deleteJourney = payload => ({ type: actionTypes.DELETE_JOURNEY, payload });
export const cloneJourney = payload => ({ type: actionTypes.CLONE_JOURNEY, payload });
export const createJourney = payload => ({ type: actionTypes.CREATE_JOURNEY, payload });
export const createAssessment = payload => ({ type: actionTypes.CREATE_ASSESSMENT, payload });
export const signAssessment = payload => ({ type: actionTypes.SIGN_ASSESSMENT, payload });
export const getJourneyAssessments = payload => ({ type: actionTypes.GET_JOURNEY_ASSESSMENTS, payload });
export const getJourneyAssessmentTemplate = payload => ({ type: actionTypes.GET_JOURNEY_ASSESSMENT_TEMPLATE, payload });
export const saveJourneyAssessmentTemplate = payload => ({ type: actionTypes.SAVE_JOURNEY_ASSESSMENT_TEMPLATE, payload });
export const getPreliminaryAssessment = payload => ({ type: actionTypes.GET_PRELIMINARY_ASSESSMENT, payload });
export const getPreliminaryAssessmentPdf = payload => ({ type: actionTypes.GET_PRELIMINARY_ASSESSMENT_PDF, payload });
export const calculatePaymentAndLmi = payload => ({ type: actionTypes.CALCULATE_PAYMENT_AND_LMI, payload });
export const getAssessment = payload => ({ type: actionTypes.GET_ASSESSMENT, payload });
export const getAssessmentPdf = payload => ({ type: actionTypes.GET_ASSESSMENT_PDF, payload });
export const getStepsListByVersion = payload => ({ type: actionTypes.GET_STEPS_LIST_BY_VERSION, payload });
export const calculateAssessmentParameters = payload => ({ type: actionTypes.CALCULATE_ASSESSMENT_PARAMETERS, payload });
export const calculateAssessmentParametersWithDebounce = payload => ({ type: actionTypes.CALCULATE_ASSESSMENT_PARAMETERS_WITH_DEBOUNCE, payload });
export const calculateStampDutyInJourney = payload => ({ type: actionTypes.CALCULATE_STAMP_DUTY_IN_JOURNEY, payload });
export const calculateStampDutyInJourneyWithDebounce = payload => ({ type: actionTypes.CALCULATE_STAMP_DUTY_IN_JOURNEY_WITH_DEBOUNCE, payload });
export const getSubstatuses = payload => ({ type: actionTypes.GET_SUBSTATUSES, payload });
export const submitApplication = payload => ({ type: actionTypes.SUBMIT_APPLICATION, payload });
export const archiveJourneys = payload => ({ type: actionTypes.ARCHIVE_JOURNEYS, payload });
export const withdrawJourney = payload => ({ type: actionTypes.WITHDRAW_JOURNEY, payload });
export const checkAutoConveyancing = payload => ({ type: actionTypes.CHECK_AUTO_CONVEYANCING, payload });
export const getDomiumAddresses = payload => ({ type: actionTypes.GET_DOMIUM_ADDRESSES, payload });
export const getJourneyPayments = payload => ({ type: actionTypes.GET_JOURNEY_PAYMENTS, payload });
export const removeConveyancingFromJourney = payload => ({ type: actionTypes.REMOVE_CONVEYANCING_FROM_JOURNEY, payload });

const journeysSagas = [
  takeEvery(actionTypes.GET_JOURNEY, sagaGetJourney),
  takeEvery(actionTypes.GET_JOURNEYS, sagaGetJourneys),
  takeEvery(actionTypes.GET_JOURNEYS_BY_ID, sagaGetJourneysById),
  takeEvery(actionTypes.UPDATE_JOURNEY, sagaUpdateJourney),
  takeEvery(actionTypes.GO_NEXT_JOURNEY_STEP, sagaGoNextJourneyStep),
  takeEvery(actionTypes.ROLLBACK_JOURNEY_STEP, sagaRollbackJourneyStep),
  takeLatest(actionTypes.GET_BORROWING_CAPACITY, sagaGetBorrowingCapacity),
  takeLatest(actionTypes.GET_HOUSEHOLD_TYPES, sagaGetHouseholdTypes),
  takeLatest(actionTypes.GET_HOUSEHOLDS_TYPES, sagaGetHouseholdsTypes),
  takeEvery(actionTypes.VERIFY_IDENTITY, sagaVerifyIdentity),
  debounce(500, actionTypes.GET_ALLOWED_JOURNEYS_WITH_DEBOUNCE, sagaGetAllowedJourneys),
  takeLatest(actionTypes.GET_ALLOWED_JOURNEYS_BY_MILESTONES, sagaGetAllowedJourneysByMilestones),
  debounce(500, actionTypes.GET_ALLOWED_JOURNEYS_BY_MILESTONES_WITH_DEBOUNCE, sagaGetAllowedJourneysByMilestones),
  takeEvery(actionTypes.GET_ALLOWED_JOURNEYS, sagaGetAllowedJourneys),
  takeLatest(actionTypes.GET_MILESTONES, sagaGetMilestones),
  takeLeading(actionTypes.DELETE_JOURNEY, sagaDeleteJourney),
  takeEvery(actionTypes.CLONE_JOURNEY, sagaCloneJourney),
  takeEvery(actionTypes.CREATE_JOURNEY, sagaCreateJourney),
  takeEvery(actionTypes.CREATE_ASSESSMENT, sagaCreateAssessment),
  takeEvery(actionTypes.SIGN_ASSESSMENT, sagaSignAssessment),
  takeEvery(actionTypes.GET_JOURNEY_ASSESSMENTS, sagaGetJourneyAssessments),
  takeLatest(actionTypes.GET_JOURNEY_ASSESSMENT_TEMPLATE, sagaGetJourneyAssessmentTemplate),
  takeEvery(actionTypes.SAVE_JOURNEY_ASSESSMENT_TEMPLATE, sagaSaveJourneyAssessmentTemplate),
  takeLatest(actionTypes.GET_PRELIMINARY_ASSESSMENT, sagaGetPreliminaryAssessment),
  takeLatest(actionTypes.GET_PRELIMINARY_ASSESSMENT_PDF, sagaGetPreliminaryAssessmentPdf),
  takeLatest(actionTypes.CALCULATE_PAYMENT_AND_LMI, sagaCalculatePaymentAndLmi),
  takeLatest(actionTypes.GET_ASSESSMENT, sagaGetAssessment),
  takeLatest(actionTypes.GET_ASSESSMENT_PDF, sagaGetAssessmentPdf),
  takeLatest(actionTypes.GET_STEPS_LIST_BY_VERSION, sagaGetStepsListByVersion),
  takeLatest(actionTypes.CALCULATE_ASSESSMENT_PARAMETERS, sagaCalculateAssessmentParameters),
  debounce(900, actionTypes.CALCULATE_ASSESSMENT_PARAMETERS_WITH_DEBOUNCE, sagaCalculateAssessmentParameters),
  takeLatest(actionTypes.CALCULATE_STAMP_DUTY_IN_JOURNEY, sagaCalculateStampDutyInJourney),
  debounce(900, actionTypes.CALCULATE_STAMP_DUTY_IN_JOURNEY_WITH_DEBOUNCE, sagaCalculateStampDutyInJourney),
  takeLatest(actionTypes.GET_SUBSTATUSES, sagaGetSubstatuses),
  takeLatest(actionTypes.SUBMIT_APPLICATION, sagaSubmitApplication),
  takeEvery(actionTypes.ARCHIVE_JOURNEYS, sagaArchiveJourneys),
  takeEvery(actionTypes.WITHDRAW_JOURNEY, sagaWithdrawJourney),
  takeLatest(actionTypes.CHECK_AUTO_CONVEYANCING, sagaCheckAutoConveyancing),
  takeLatest(actionTypes.GET_DOMIUM_ADDRESSES, sagaGetDomiumAddresses),
  takeLatest(actionTypes.GET_JOURNEY_PAYMENTS, sagaGetJourneyPayments),
  takeLeading(actionTypes.REMOVE_CONVEYANCING_FROM_JOURNEY, sagaRemoveConveyancingFromJourney),
];

export default journeysSagas;
