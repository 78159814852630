const defaultState = {
  notifications: undefined //paginated object, entities in elements array
};

export const actionTypes = {
  NOTIFICATIONS: 'NOTIFICATIONS'
};

const notificationsAction = function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.NOTIFICATIONS:
      return {
        ...state,
        notifications: payload
      };
    default:
      return state;
  }
};

export default notificationsAction;
