import React, {lazy} from 'react';
import { AuthRoute } from '../auth/components';

//import { JourneysPage, JourneyDetailsPage, JourneysDashboard, JourneySettings } from './pages';
import sagas from './sagas';
import reducers from './reducers';
import { ActionsDashboard } from '../actions/pages';
import { USER_ROLE } from '../../utils/authorisation';


const JourneysPage = lazy(() => import('./pages/JourneysPage'));
const JourneyDetailsPage = lazy(() => import('./pages/JourneyDetailsPage'));
const JourneysDashboard = lazy(() => import('./pages/JourneysDashboard'));
const JourneySettings = lazy(() => import('./pages/JourneySettings'));

const journeysModule = {
  routes: [
    <AuthRoute key="journeys" exact path="/journeys" component={JourneysPage} headerType={'private'} />,
    <AuthRoute key="journey-details" exact path="/journey-details/:journeyId" component={JourneyDetailsPage} headerType={'private'} />,
    <AuthRoute key="journeys-dashboard" exact path="/journeys-dashboard" component={JourneysDashboard} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="journey-settings" exact path="/journey-settings/:journeyId" component={JourneySettings} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="journey-actions" exact path="/journey-actions/:journeyId" component={ActionsDashboard} headerType={'private'} isSpecialistOnly />
  ],
  navMenuItems: [
    {
      name: 'Journeys',
      path: '/journeys',
      order: 10
    },
    {
      name: 'Journeys',
      path: '/journeys',
      isForSelectedUser: true,
      allowedRoles: [USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.Supervisor, USER_ROLE.Conveyancer, USER_ROLE.Specialist, USER_ROLE.Audit],
      order: 10
    },
    {
      name: 'Journeys',
      path: '/journeys-dashboard',
      isForSpecialist: true,
      allowedRoles: [USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.Supervisor, USER_ROLE.Conveyancer, USER_ROLE.Specialist, USER_ROLE.Audit],
      order: 4
    },
  ],
  sagas,
  reducers: { journeys: reducers }
};

export default journeysModule;
