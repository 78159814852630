import React from 'react';
import { Button, Dialog, Grid, Box } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import './styles.global.scss';
import { withDimensions } from '../../../common/HOC';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";


const ConfirmDeletionModal = ({ open, setOpen, parameters }) => {
  const dimensions = useSelector(state => state.dimensions);

  return (
    <>
      <Dialog open={open} className={'ConfirmDeletionDialog'} onClose={() => {}} fullScreen={dimensions.isScreenLessSM}>
        <MuiDialogTitle>
          <Box className={`${styles.modalTitle} ${dimensions.isScreenLessMD ? styles.mobile : ''}`}>Confirm {parameters?.actionName ?? 'deletion'}</Box>
          <IconButton aria-label="close" className={styles.closeButton} onClick={() => setOpen(false)}>
            <CloseRoundedIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box className={styles.text}>
            {!!parameters?.content ? (
              <>{parameters.content}</>
            ) : (<>
              <p>The {parameters?.entityType ?? 'entity'} {parameters?.entityName ? `"${parameters?.entityName}" `: ''}will be removed. Are you sure you want to continue?</p>
            </>)}
          </Box>
        </MuiDialogContent>
        <MuiDialogActions>
          <Grid container justifyContent="space-between">
            <Button
              className={`${styles.actionButton} ${styles.secondary}`}
              onClick={() => setOpen(false)}
              color="secondary"
              variant="outlined"
              type="button"
              disableElevation
            >
              CANCEL
            </Button>
            <Button
              className={`${styles.actionButton}`}
              type="button"
              variant="contained"
              color="primary"
              onClick={() => { if (!!parameters?.action) parameters.action(); setOpen(false); }}
            >
              OK
            </Button>
          </Grid>
        </MuiDialogActions>
      </Dialog>
    </>
  );
};

ConfirmDeletionModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  parameters: PropTypes.object
};

export default withDimensions(ConfirmDeletionModal);

