import { getRefreshedTokens, resetTokenAndReattemptRequest } from './interceptors';

export const refreshAccessToken = () => {
  const refresh = async () => {
    const refreshToken = localStorage.getItem('X-REFRESH-TOKEN');
    if (!refreshToken) return;

    const result = await getRefreshedTokens(refreshToken);

    if (!result || result.status >= 400) {
      await resetTokenAndReattemptRequest();
    } else if (result?.data?.access_token) {
      localStorage.setItem('X-ACCESS-TOKEN', result.data.access_token);
      if (result?.data?.refresh_token) localStorage.setItem('X-REFRESH-TOKEN', result.data.refresh_token);
    }
  }

  refresh().then();
  setInterval(() => {
    refresh().then();
  }, 50 * 60 * 1000)

};