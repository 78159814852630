import React, {useLayoutEffect, useState} from 'react';
import { ChevronLeft, ChevronRight, HourglassEmpty } from '@mui/icons-material';
import PropTypes from 'prop-types';

import styles from './styles.module.scss'
import { withDimensions } from '../../../common/HOC';

// Container should have style { 'overflow-x': 'hidden', position: 'relative' },
// children should have style { 'overflow-x': 'auto', 'flex-wrap': 'nowrap' } and ref
const ScrollIndicator = ({ children, displayForDesktop = false, isLoading = false, childRef, colorScheme }) => {
  const [leftIndicator, setLeftIndicator] = useState(false);
  const [rightIndicator, setRightIndicator] = useState(false);

  useLayoutEffect(() => {
    const scrollCheckingDebounce = 100;
    let scrollCheckTimeout = null;
    let isListenersSet = false;
    let element;

    const scrollCheck = () => {
      if (scrollCheckTimeout) clearTimeout(scrollCheckTimeout);
      scrollCheckTimeout = setTimeout(() => {
        if (!element) element = childRef?.current;
        if (element && !isListenersSet) {
          element.addEventListener('scroll', scrollCheck);
          window.addEventListener('resize', scrollCheck);
          isListenersSet = true;
        }
        if (element) {
          const scrollLeftMax = element.scrollWidth - element.clientWidth;
          setLeftIndicator(element.scrollLeft > 0);
          setRightIndicator(element.scrollLeft !== scrollLeftMax);
        }

      }, scrollCheckingDebounce);
    };

    if (window.mobileCheck() || displayForDesktop) scrollCheck();

    return () => {
      if (isListenersSet) {
        element.removeEventListener('scroll', scrollCheck);
        window.removeEventListener('resize', scrollCheck);
      }
    };
  }, [displayForDesktop, childRef]);

  return (
    <>
      {children}
      {!!leftIndicator && (<div className={`${styles.leftIndicator} ${colorScheme ? styles[colorScheme] : ''}`}><ChevronLeft /></div>)}
      {!!rightIndicator && (<div className={`${styles.rightIndicator} ${colorScheme ? styles[colorScheme] : ''}`}><ChevronRight /></div>)}
      {(!!leftIndicator && !rightIndicator && isLoading) && (<div className={styles.rightIndicator}><HourglassEmpty /></div>)}
    </>
  )
};

ScrollIndicator.propTypes = {
  children: PropTypes.node,
  displayForDesktop: PropTypes.bool,
  isLoading: PropTypes.bool,
  childRef: PropTypes.any,
  colorScheme: PropTypes.string //contrast
};

export default withDimensions(ScrollIndicator);