const mergeModules = (...modules) => {
  return {
    routes: modules.reduce((acc, { routes }) => (routes ? [...acc, ...routes] : acc), []),
    reducers: modules.reduce((acc, { reducers }) => (reducers ? { ...acc, ...reducers } : acc), {}),
    sagas: modules.reduce((acc, { sagas }) => (sagas ? [...acc, ...sagas] : acc), []),
    navMenuItems: modules.reduce((acc, { navMenuItems }) => (navMenuItems ? [...acc, ...navMenuItems] : acc), []),
    userMenuItems: modules.reduce((acc, { userMenuItems }) => (userMenuItems ? [...acc, ...userMenuItems] : acc), [])
  };
};

export default mergeModules;
