import React, { lazy } from 'react';
import { AuthRoute } from '../auth/components';

//import { ActionsDashboard, ActionCreatePage, ActionEditPage, ActionTimeLog, ActionsTimecard } from './pages';
import sagas from './sagas';
import {USER_ROLE} from "../../utils/authorisation";


const ActionsDashboard = lazy(() => import('./pages/ActionsDashboard'));
const ActionCreatePage = lazy(() => import('./pages/ActionCreatePage'));
const ActionEditPage = lazy(() => import('./pages/ActionEditPage'));
const ActionTimeLog = lazy(() => import('./pages/ActionTimeLog'));
const ActionsTimecard = lazy(() => import('./pages/ActionsTimecard'));

const actionsModule = {
  routes: [
    <AuthRoute key="actions" exact path="/actions" component={ActionsDashboard} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="actions-timecard" exact path="/actions-timecard" component={ActionsTimecard} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="action-time-log" exact path="/action-time-log/:caseId" component={ActionTimeLog} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="action-create" exact path="/action-create" component={ActionCreatePage} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="action-edit" exact path="/action-edit/:caseId" component={ActionEditPage} headerType={'private'} isSpecialistOnly />
  ],
  navMenuItems: [
    {
      name: 'Actions',
      path: '/actions',
      isForSpecialist: true,
      allowedRoles: [USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.Supervisor, USER_ROLE.Conveyancer, USER_ROLE.Specialist, USER_ROLE.Audit],
      order: 1
    },
  ],
  sagas
};

export default actionsModule;
