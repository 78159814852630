import React, {lazy} from 'react';
import { AuthRoute } from '../auth/components';
import { USER_ROLE } from '../../utils/authorisation';

//import { LendersDashboard, LenderEditPage } from './pages';


const LendersDashboard = lazy(() => import('./pages/LendersDashboard'));
const LenderEditPage = lazy(() => import('./pages/LenderEditPage'));
const allowedRoles = [USER_ROLE.Admin, USER_ROLE.OperationsManager, USER_ROLE.Supervisor, USER_ROLE.Specialist, USER_ROLE.Audit];

const actionsModule = {
  routes: [
    <AuthRoute key="lenders" exact path="/lenders" component={LendersDashboard} headerType={'private'} isSpecialistOnly authorization={allowedRoles} />,
    <AuthRoute key="lender-edit" exact path="/lender-edit/:lenderId" component={LenderEditPage} headerType={'private'} isSpecialistOnly authorization={allowedRoles} />
  ],
  userMenuItems: [
    {
      name: 'Lenders',
      path: '/lenders',
      allowedRoles,
      isForSpecialist: true,
    }
  ],
};

export default actionsModule;
