import React, { lazy } from 'react';

import { NoLoggedInRoute } from '../auth/components';
//import { LoginPage } from './pages';
import reducers from './reducers';


const LoginPage = lazy(() => import('./pages/LoginPage'));

const loginModule = {
  routes: [
    <NoLoggedInRoute key="home-mode" exact path="/login/:mode" component={LoginPage} isHiddenMainLayoutWrapper={false} headerType={'registrationWizard'} />,
    <NoLoggedInRoute key="home" exact path="/login" component={LoginPage} isHiddenMainLayoutWrapper={false} headerType={'registrationWizard'} />,
  ],
  reducers: { login: reducers }
};

export default loginModule;
