import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { isFunc } from '@utils/functions';

const Logo = ({ to, clickAction, externalLink, height, width }) => {
  const renderImg = () => (
    <img
      className={styles.logo}
      src="/logo.svg"
      alt="Domium logo"
      style={ height ? { height: `${height}px`, width: 'auto' } : width ? { width: `${width}px`, height: 'auto' } : {} }
    />
  );

  return (<>
    { !!externalLink ? (
      <a href={externalLink}>
        {renderImg()}
      </a>
    ) : (!!to ? (
      <Link to={to} onClick={() => { if (isFunc(clickAction)) clickAction(); }}>
        {renderImg()}
      </Link>
    ) : (<>
      {renderImg()}
    </>))}
  </>);
};

Logo.propTypes = {
  to: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  clickAction: PropTypes.func
};

export default Logo;
