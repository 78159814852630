import React, {lazy} from 'react';
import { AuthRoute } from '../auth/components';

//import { ProfilePage } from './pages';


const ProfilePage = lazy(() => import('./pages/ProfilePage'));

const profileModule = {
  routes: [
    <AuthRoute key="profile" exact path="/profile" component={ProfilePage} headerType={'private'} />,
    <AuthRoute key="profile-with-user" exact path="/profile/:userId" component={ProfilePage} headerType={'private'} />
  ],
  userMenuItems: [
    {
      name: 'Profile',
      path: '/profile'
    }
  ],
};

export default profileModule;
