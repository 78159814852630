import { call, takeLatest, takeEvery, debounce } from 'redux-saga/effects';
import axios from 'axios';
import queryString from 'query-string';
import { isFunc } from '../../../utils/functions';


const Api = {
  getProducts: ({
    limit,
    page,
    search,
    sort,
    lender, //ID
    isActive,
    validFrom,
    validTo,
    externalProductId, //not needed here
    statusNames,
    dealsStatuses,
    creator,
    approver,
    ids
  }) => {
    let params = {
      limit: limit ?? 10
    };
    if (search) params.search = search;
    if (sort) params.sort = sort;
    if (page || page === 0) params.page = page;

    if (lender) params.lender = lender;
    if (isActive) params.isActive = isActive;
    if (validFrom) params.validFrom = validFrom;
    if (validTo) params.validTo = validTo;
    if (externalProductId) params.externalProductId = externalProductId;
    if (dealsStatuses) params.dealsStatuses = dealsStatuses;
    if (!!statusNames?.length) params.statusNames = statusNames;
    if (creator) params.creator = creator;
    if (approver) params.approver = approver;

    const queryParameters = queryString.stringify(params);
    const idsArray = !!ids?.length ? ids.reduce((acc, dId) => `${acc}&ids[]=${dId}`, '') : '';

    return axios.get(`products?${queryParameters}${idsArray}`);
  },
  getProduct: (id) => axios.get(`/products/${id}`),
  createProduct: (body) => axios.post(`/products`, body),
  updateProduct: ({ id, body }) => axios.put(`/products/${id}`, body),
  deleteProduct: (id) => axios.delete(`/products/${id}`),
  bulkApproveProducts: (IDs) => axios.post(`/products/bulkApproval`, { IDs }),
  bulkArchiveProducts: (IDs) => axios.post(`/products/bulkArchiving`, { IDs }),

  getProductPackages: ({
                  limit,
                  page,
                  search,
                  sort,
                  lender, //ID
                  isActive,
                  creator,
                  approver
                }) => {
    let params = {
      limit: limit ?? 10
    };
    if (search) params.search = search;
    if (sort) params.sort = sort;
    if (page || page === 0) params.page = page;

    if (lender) params.lender = lender;
    if (creator) params.creator = creator;
    if (approver) params.approver = approver;
    if (isActive) params.isActive = isActive;

    return axios.request({
      url: '/product-packages',
      method: 'get',
      params,
      paramsSerializer: params => {
        return queryString.stringify(params)
      }
    });
  },
  getProductPackage: (id) => axios.get(`/product-packages/${id}`),
  createProductPackage: (body) => axios.post(`/product-packages`, body),
  updateProductPackage: ({ id, body }) => axios.put(`/product-packages/${id}`, body),
  deleteProductPackage: (id) => axios.delete(`/product-packages/${id}`),
  bulkApproveProductPackages: (IDs) => axios.post(`/product-packages/bulkApproval`, { IDs }),
  bulkArchiveProductPackages: (IDs) => axios.post(`/product-packages/bulkArchiving`, { IDs }),

  getSpecialOffers: ({
    limit,
    page,
    search,
    sort,
    lender, //ID
    isActive,
    relevantNow,
    relevantByDate,
    minAmount,
    product,
    loanReason,
    loanPurpose,
    borrowerType, //['All','Bank','NonBank']
    rateType,
    repaymentOptions,
    loanSize,
    lvr,
    statusNames,
    creator,
    approver
  }) => {
    let params = {
      limit: limit ?? 10
    };
    if (search) params.search = search;
    if (sort) params.sort = sort;
    if (page || page === 0) params.page = page;
    if (lender) params.lender = lender;
    if (isActive) params.isActive = isActive;
    if (relevantNow) params.relevantNow = relevantNow;
    if (relevantByDate) params.relevantByDate = relevantByDate;
    if (minAmount) params.minAmount = minAmount;
    if (product) params.product = product;
    if (loanReason) params.loanReason = loanReason;
    if (loanPurpose) params.loanPurpose = loanPurpose;
    if (borrowerType) params.borrowerType = borrowerType;
    if (rateType) params.rateType = rateType;
    if (repaymentOptions) params.repaymentOptions = repaymentOptions;
    if (loanSize) params.loanSize = loanSize;
    if (lvr) params.lvr = lvr;
    if (!!statusNames?.length) params.statusNames = statusNames;
    if (creator) params.creator = creator;
    if (approver) params.approver = approver;

    return axios.request({
      url: '/special-offers',
      method: 'get',
      params,
      paramsSerializer: params => {
        return queryString.stringify(params)
      }
    });
  },
  getSpecialOffer: (id) => axios.get(`/special-offers/${id}`),
  createSpecialOffer: (body) => axios.post(`/special-offers`, body),
  updateSpecialOffer: ({ id, body }) => axios.put(`/special-offers/${id}`, body),
  deleteSpecialOffer: (id) => axios.delete(`/special-offers/${id}`),
  bulkApproveSpecialOffers: (IDs) => axios.post(`/special-offers/bulkApproval`, { IDs }),
  bulkArchiveSpecialOffers: (IDs) => axios.post(`/special-offers/bulkArchiving`, { IDs }),

  getDeals: ({ lenderType, rateType, repaymentOptions, features, price, deposit, purpose, reason, firstHomeBuyer, page, sort, lender,
                fixedTermMonths, bankType, preferenceInterestRate, preferenceCashBack, limit, product,
                isActive, statusNames, creator, approver }) => {
    const queryParams = queryString.stringify({
      limit: limit ?? 10,
      page,
      sort,
      lender,
      lenderType,
      rateType,
      repaymentOptions,
      price: (!!price || price === 0) ? +Number(price).toFixed(2) : undefined,
      deposit: (!!deposit || deposit === 0) ? +Number(deposit).toFixed(2) : undefined,
      purpose,
      reason,
      firstHomeBuyer,
      fixedTermMonths,
      bankType,
      preferenceInterestRate,
      preferenceCashBack,
      isActive,
      creator,
      approver,
      product
    });
    const featuresArray = features?.length ? features.reduce((acc, f) => `${acc}&features[]=${encodeURI(f)}`, '') : '';
    const statusNamesArray = statusNames?.length ? statusNames.reduce((acc, sn) => `${acc}&statusNames[]=${sn}`, '') : '';

    return axios.get(`/deals?${queryParams}${featuresArray}${statusNamesArray}`);
  },
  getDeal: (id) => axios.get(`/deals/${id}`),
  createDeal: (body) => axios.post(`/deals`, body),
  updateDeal: ({ id, body }) => axios.put(`/deals/${id}`, body),
  deleteDeal: (id) => axios.delete(`/deals/${id}`),
  bulkApproveDeals: (IDs) => axios.post(`/deals/bulkApproval`, { IDs }),
  bulkArchiveDeals: (IDs) => axios.post(`/deals/bulkArchiving`, { IDs }),
  refreshProductsAndDeals: (lenderIDs) =>  axios.post(`/services/refreshDeals`, lenderIDs?.length ? { lenders: lenderIDs } : {}),
};

export const actionTypes = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCTS_WITH_DEBOUNCE: 'GET_PRODUCTS_WITH_DEBOUNCE',
  GET_PRODUCT: 'GET_PRODUCT',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  BULK_APPROVE_PRODUCTS: 'BULK_APPROVE_PRODUCTS',
  BULK_ARCHIVE_PRODUCTS: 'BULK_ARCHIVE_PRODUCTS',

  GET_PRODUCT_PACKAGES: 'GET_PRODUCT_PACKAGES',
  GET_PRODUCT_PACKAGES_WITH_DEBOUNCE: 'GET_PRODUCT_PACKAGES_WITH_DEBOUNCE',
  GET_PRODUCT_PACKAGE: 'GET_PRODUCT_PACKAGE',
  CREATE_PRODUCT_PACKAGE: 'CREATE_PRODUCT_PACKAGE',
  UPDATE_PRODUCT_PACKAGE: 'UPDATE_PRODUCT_PACKAGE',
  DELETE_PRODUCT_PACKAGE: 'DELETE_PRODUCT_PACKAGE',
  BULK_APPROVE_PRODUCT_PACKAGES: 'BULK_APPROVE_PRODUCT_PACKAGES',
  BULK_ARCHIVE_PRODUCT_PACKAGES: 'BULK_ARCHIVE_PRODUCT_PACKAGES',

  GET_SPECIAL_OFFERS: 'GET_SPECIAL_OFFERS',
  GET_SPECIAL_OFFERS_WITH_DEBOUNCE: 'GET_SPECIAL_OFFERS_WITH_DEBOUNCE',
  GET_SPECIAL_OFFER: 'GET_SPECIAL_OFFER',
  CREATE_SPECIAL_OFFER: 'CREATE_SPECIAL_OFFER',
  UPDATE_SPECIAL_OFFER: 'UPDATE_SPECIAL_OFFER',
  DELETE_SPECIAL_OFFER: 'DELETE_SPECIAL_OFFER',
  BULK_APPROVE_SPECIAL_OFFERS: 'BULK_APPROVE_SPECIAL_OFFERS',
  BULK_ARCHIVE_SPECIAL_OFFERS: 'BULK_ARCHIVE_SPECIAL_OFFERS',

  GET_DEALS: 'GET_DEALS',
  GET_DEALS_WITH_DEBOUNCE: 'GET_DEALS_WITH_DEBOUNCE',
  GET_DEAL: 'GET_DEAL',
  CREATE_DEAL: 'CREATE_DEAL',
  UPDATE_DEAL: 'UPDATE_DEAL',
  DELETE_DEAL: 'DELETE_DEAL',
  BULK_APPROVE_DEALS: 'BULK_APPROVE_DEALS',
  BULK_ARCHIVE_DEALS: 'BULK_ARCHIVE_DEALS',

  REFRESH_PRODUCTS_AND_DEALS: 'REFRESH_PRODUCTS_AND_DEALS',
};


function* sagaGetProducts({ payload: {
  limit,
  page,
  search,
  sort,
  lender, //ID
  isActive,
  validFrom,
  validTo,
  externalProductId, //not needed here
  statusNames,
  dealsStatuses,
  creator,
  approver,
  ids,
  onSuccess,
  onError
} }) {
  try {
    const { data } = yield call(Api.getProducts, {
      limit,
      page,
      search,
      sort,
      lender,
      isActive,
      validFrom,
      validTo,
      externalProductId,
      statusNames,
      creator,
      approver,
      dealsStatuses,
      ids
    });

    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetProduct({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getProduct, id);
    if (result.data?.id) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateProduct({ payload: { id, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateProduct, { id, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateProduct({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createProduct, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteProduct({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteProduct, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaBulkApproveProducts({ payload: { IDs, onSuccess, onError } }) {
  try {
    const result = yield call(Api.bulkApproveProducts, IDs);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaBulkArchiveProducts({ payload: { IDs, onSuccess, onError } }) {
  try {
    const result = yield call(Api.bulkArchiveProducts, IDs);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}



function* sagaGetProductPackages({ payload: {
  limit,
  page,
  search,
  sort,
  lender, //ID
  creator,
  onSuccess,
  onError
} }) {
  try {
    const { data } = yield call(Api.getProductPackages, {
      limit,
      page,
      search,
      sort,
      lender,
      creator
    });

    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetProductPackage({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getProductPackage, id);
    if (result.data?.id) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateProductPackage({ payload: { id, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateProductPackage, { id, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateProductPackage({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createProductPackage, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteProductPackage({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteProductPackage, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaBulkApproveProductPackages({ payload: { IDs, onSuccess, onError } }) {
  try {
    const result = yield call(Api.bulkApproveProductPackages, IDs);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaBulkArchiveProductPackages({ payload: { IDs, onSuccess, onError } }) {
  try {
    const result = yield call(Api.bulkArchiveProductPackages, IDs);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}



function* sagaGetSpecialOffers({ payload: {
  limit,
  page,
  search,
  sort,
  lender, //ID
  isActive,
  relevantNow,
  relevantByDate,
  minAmount,
  product,
  loanReason,
  loanPurpose,
  borrowerType, //['All','Bank','NonBank']
  rateType,
  repaymentOptions,
  loanSize,
  lvr,
  statusNames,
  creator,
  approver,
  onSuccess,
  onError
} }) {
  try {
    const { data } = yield call(Api.getSpecialOffers, {
      limit,
      page,
      search,
      sort,
      lender,
      isActive,
      relevantNow,
      relevantByDate,
      minAmount,
      product,
      loanReason,
      loanPurpose,
      borrowerType,
      rateType,
      repaymentOptions,
      loanSize,
      lvr,
      statusNames,
      creator,
      approver,
    });

    if (data.elements) {
      if (isFunc(onSuccess)) onSuccess(data);
    } else if (isFunc(onError)) onError(data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetSpecialOffer({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getSpecialOffer, id);
    if (result.data?.id) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateSpecialOffer({ payload: { id, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateSpecialOffer, { id, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateSpecialOffer({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createSpecialOffer, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteSpecialOffer({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteSpecialOffer, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaBulkApproveSpecialOffers({ payload: { IDs, onSuccess, onError } }) {
  try {
    const result = yield call(Api.bulkApproveSpecialOffers, IDs);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaBulkArchiveSpecialOffers({ payload: { IDs, onSuccess, onError } }) {
  try {
    const result = yield call(Api.bulkArchiveSpecialOffers, IDs);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}



function* sagaGetDeals({ payload: {
  lenderType, rateType, repaymentOptions, features, price, deposit, purpose, reason, firstHomeBuyer, page, sort, lender, product,
  fixedTermMonths, bankType, preferenceInterestRate, preferenceCashBack, limit, isActive, statusNames, creator, approver, onSuccess, onError
} }) {
  try {
    const result = yield call(Api.getDeals,
      {
        lenderType, rateType, repaymentOptions, features, price, deposit, purpose, reason, firstHomeBuyer, page, sort, lender, product,
        limit, fixedTermMonths, bankType, preferenceInterestRate, preferenceCashBack, isActive, statusNames, creator, approver
      }
    );

    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);

  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetDeal({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getDeal, id);
    if (result.data?.id) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateDeal({ payload: { id, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateDeal, { id, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateDeal({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createDeal, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteDeal({ payload: { id, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteDeal, id);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaBulkApproveDeals({ payload: { IDs, onSuccess, onError } }) {
  try {
    const result = yield call(Api.bulkApproveDeals, IDs);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaBulkArchiveDeals({ payload: { IDs, onSuccess, onError } }) {
  try {
    const result = yield call(Api.bulkArchiveDeals, IDs);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}



function* sagaRefreshProductsAndDeals({ payload: { lenderIDs, onSuccess, onError } }) {
  try {
    const result = yield call(Api.refreshProductsAndDeals, lenderIDs);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}


export const getProducts = payload => ({ type: actionTypes.GET_PRODUCTS, payload });
export const getProductsWithDebounce = payload => ({ type: actionTypes.GET_PRODUCTS_WITH_DEBOUNCE, payload });
export const getProduct = payload => ({ type: actionTypes.GET_PRODUCT, payload });
export const createProduct = payload => ({ type: actionTypes.CREATE_PRODUCT, payload });
export const updateProduct = payload => ({ type: actionTypes.UPDATE_PRODUCT, payload });
export const deleteProduct = payload => ({ type: actionTypes.DELETE_PRODUCT, payload });
export const bulkApproveProducts = payload => ({ type: actionTypes.BULK_APPROVE_PRODUCTS, payload });
export const bulkArchiveProducts = payload => ({ type: actionTypes.BULK_ARCHIVE_PRODUCTS, payload });

export const getProductPackages = payload => ({ type: actionTypes.GET_PRODUCT_PACKAGES, payload });
export const getProductPackagesWithDebounce = payload => ({ type: actionTypes.GET_PRODUCT_PACKAGES_WITH_DEBOUNCE, payload });
export const getProductPackage = payload => ({ type: actionTypes.GET_PRODUCT_PACKAGE, payload });
export const createProductPackage = payload => ({ type: actionTypes.CREATE_PRODUCT_PACKAGE, payload });
export const updateProductPackage = payload => ({ type: actionTypes.UPDATE_PRODUCT_PACKAGE, payload });
export const deleteProductPackage = payload => ({ type: actionTypes.DELETE_PRODUCT_PACKAGE, payload });
export const bulkApproveProductPackages = payload => ({ type: actionTypes.BULK_APPROVE_PRODUCT_PACKAGES, payload });
export const bulkArchiveProductPackages = payload => ({ type: actionTypes.BULK_ARCHIVE_PRODUCT_PACKAGES, payload });

export const getSpecialOffers = payload => ({ type: actionTypes.GET_SPECIAL_OFFERS, payload });
export const getSpecialOffersWithDebounce = payload => ({ type: actionTypes.GET_SPECIAL_OFFERS_WITH_DEBOUNCE, payload });
export const getSpecialOffer = payload => ({ type: actionTypes.GET_SPECIAL_OFFER, payload });
export const createSpecialOffer = payload => ({ type: actionTypes.CREATE_SPECIAL_OFFER, payload });
export const updateSpecialOffer = payload => ({ type: actionTypes.UPDATE_SPECIAL_OFFER, payload });
export const deleteSpecialOffer = payload => ({ type: actionTypes.DELETE_SPECIAL_OFFER, payload });
export const bulkApproveSpecialOffers = payload => ({ type: actionTypes.BULK_APPROVE_SPECIAL_OFFERS, payload });
export const bulkArchiveSpecialOffers = payload => ({ type: actionTypes.BULK_ARCHIVE_SPECIAL_OFFERS, payload });

export const getDeals = payload => ({ type: actionTypes.GET_DEALS, payload });
export const getDealsWithDebounce = payload => ({ type: actionTypes.GET_DEALS_WITH_DEBOUNCE, payload });
export const getDeal = payload => ({ type: actionTypes.GET_DEAL, payload });
export const createDeal = payload => ({ type: actionTypes.CREATE_DEAL, payload });
export const updateDeal = payload => ({ type: actionTypes.UPDATE_DEAL, payload });
export const deleteDeal = payload => ({ type: actionTypes.DELETE_DEAL, payload });
export const bulkApproveDeals = payload => ({ type: actionTypes.BULK_APPROVE_DEALS, payload });
export const bulkArchiveDeals = payload => ({ type: actionTypes.BULK_ARCHIVE_DEALS, payload });

export const refreshProductsAndDeals = payload => ({ type: actionTypes.REFRESH_PRODUCTS_AND_DEALS, payload });

const dealsManagementSagas = [
  takeLatest(actionTypes.GET_PRODUCTS, sagaGetProducts),
  debounce(900, actionTypes.GET_PRODUCTS_WITH_DEBOUNCE, sagaGetProducts),
  takeEvery(actionTypes.GET_PRODUCT, sagaGetProduct),
  takeEvery(actionTypes.CREATE_PRODUCT, sagaCreateProduct),
  takeEvery(actionTypes.UPDATE_PRODUCT, sagaUpdateProduct),
  takeEvery(actionTypes.DELETE_PRODUCT, sagaDeleteProduct),
  takeEvery(actionTypes.BULK_APPROVE_PRODUCTS, sagaBulkApproveProducts),
  takeEvery(actionTypes.BULK_ARCHIVE_PRODUCTS, sagaBulkArchiveProducts),

  takeLatest(actionTypes.GET_PRODUCT_PACKAGES, sagaGetProductPackages),
  debounce(900, actionTypes.GET_PRODUCT_PACKAGES_WITH_DEBOUNCE, sagaGetProductPackages),
  takeEvery(actionTypes.GET_PRODUCT_PACKAGE, sagaGetProductPackage),
  takeEvery(actionTypes.CREATE_PRODUCT_PACKAGE, sagaCreateProductPackage),
  takeEvery(actionTypes.UPDATE_PRODUCT_PACKAGE, sagaUpdateProductPackage),
  takeEvery(actionTypes.DELETE_PRODUCT_PACKAGE, sagaDeleteProductPackage),
  takeEvery(actionTypes.BULK_APPROVE_PRODUCT_PACKAGES, sagaBulkApproveProductPackages),
  takeEvery(actionTypes.BULK_ARCHIVE_PRODUCT_PACKAGES, sagaBulkArchiveProductPackages),

  takeLatest(actionTypes.GET_SPECIAL_OFFERS, sagaGetSpecialOffers),
  debounce(900, actionTypes.GET_SPECIAL_OFFERS_WITH_DEBOUNCE, sagaGetSpecialOffers),
  takeEvery(actionTypes.GET_SPECIAL_OFFER, sagaGetSpecialOffer),
  takeEvery(actionTypes.CREATE_SPECIAL_OFFER, sagaCreateSpecialOffer),
  takeEvery(actionTypes.UPDATE_SPECIAL_OFFER, sagaUpdateSpecialOffer),
  takeEvery(actionTypes.DELETE_SPECIAL_OFFER, sagaDeleteSpecialOffer),
  takeEvery(actionTypes.BULK_APPROVE_SPECIAL_OFFERS, sagaBulkApproveSpecialOffers),
  takeEvery(actionTypes.BULK_ARCHIVE_SPECIAL_OFFERS, sagaBulkArchiveSpecialOffers),

  takeEvery(actionTypes.GET_DEALS, sagaGetDeals),
  debounce(900, actionTypes.GET_DEALS_WITH_DEBOUNCE, sagaGetDeals),
  takeEvery(actionTypes.GET_DEAL, sagaGetDeal),
  takeEvery(actionTypes.CREATE_DEAL, sagaCreateDeal),
  takeEvery(actionTypes.UPDATE_DEAL, sagaUpdateDeal),
  takeEvery(actionTypes.DELETE_DEAL, sagaDeleteDeal),
  takeEvery(actionTypes.BULK_APPROVE_DEALS, sagaBulkApproveDeals),
  takeEvery(actionTypes.BULK_ARCHIVE_DEALS, sagaBulkArchiveDeals),

  takeEvery(actionTypes.REFRESH_PRODUCTS_AND_DEALS, sagaRefreshProductsAndDeals),
];

export default dealsManagementSagas;