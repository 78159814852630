import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Grid } from '@mui/material';

import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import NotificationsBlock from './NotificationsBlock';
import TeamBlock from './TeamBlock';
import { withDimensions } from '../../HOC';

const DashboardLayout = ({
                           children,
                           team,
                           hideNotifications = false,
                           hideExtraWidgets = false,
                           noXSPaddings = false,
                           flexCenterContent = false,
                           inProgress
}) => {
  const { notifications } = useSelector(state => state.notifications);
  const dimensions = useSelector(state => state.dimensions);

  return (
    <Box className={`${styles.pageWrapper} ${noXSPaddings ? styles.noXSPaddings : ''}`}>
      <Box sx={{ mb: 1, mt: 0 }} className={styles.dashboardContainer}>
        <Grid container justifyContent="space-between" alignItems="flex-start" alignContent="flex-start">
          <Grid
            item
            container={!!flexCenterContent}
            className={`${styles.dashboardContent} ${hideExtraWidgets ? styles.fullWidth : ''}`}
            justifyContent={flexCenterContent ? 'center' : undefined}
          >
            {(!dimensions?.isScreenBiggerSM && !!notifications?.elements?.length && !hideNotifications && !hideExtraWidgets) && (
              <NotificationsBlock />
            )}
            {children}
            {inProgress && (<CircularProgress size={80} className={styles.cardProgress} />)}
          </Grid>
          {(dimensions?.isScreenBiggerSM && !hideExtraWidgets) && (
            <Grid item className={styles.rightBlock}>
              <TeamBlock team={team ?? []} />
              {!hideNotifications && <NotificationsBlock />}
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
};

DashboardLayout.propTypes = {
  team: PropTypes.arrayOf(PropTypes.object),
  hideNotifications: PropTypes.bool,
  hideExtraWidgets: PropTypes.bool
};

export default withDimensions(DashboardLayout);