import common from './common';
import applicants from './applicants';
import noRoute from './404';
import registration from './registration';
import login from './login';
import user from './user';
import dashboard from './dashboard';
import journeys from './journeys';
import actions from './actions';
import communications from './communications';
import documents from './documents';
import team from './team';
import notifications from './notifications';
import notes from './notes';
import profile from './profile';
import lenders from './lenders';
import accounts from './accounts';
import mainJourneyFlow from './mainJourneyFlow';
import propertyReports from './propertyReports';
import shortRedirects from './shortRedirects';
import dealsManagementModule from './dealsManagement';

import mergeModules from '../utils/mergeModules';

// noRoute should be at the end!
export default mergeModules(
  common,
  shortRedirects,
  applicants,
  user,
  login,
  dashboard,
  mainJourneyFlow,
  registration,
  journeys,
  actions,
  communications,
  documents,
  team,
  notifications,
  notes,
  profile,
  lenders,
  accounts,
  propertyReports,
  dealsManagementModule,
  noRoute
);
