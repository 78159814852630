import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';

import { setIsHiddenMainLayoutWrapper, setHeaderType, setRowsPerPage } from '../../../common/reducers';
import { getUser, logOut } from '../../../user/sagas';
import config from '../../../../config';
import BlockedLogOutModal from '../../../common/components/BlockedLogOutModal';
import { setRowsPerPageLimit } from '../../../../utils/helpers';


const NoLoggedInRoute = ({
  component: Component,
  shouldHideMainLayoutWrapper,
  headerType,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { rowsPerPage } = useSelector(state => state.pagination);
  const [isLogOutChecked, setIsLogOutChecked] = useState(false);
  const { user, isLoadedUser } = useSelector(state => state.user);

  useEffect(() => {
    const atCookie = localStorage.getItem('X-ACCESS-TOKEN'); // eslint-disable-line
    dispatch(setIsHiddenMainLayoutWrapper(!!shouldHideMainLayoutWrapper));
    dispatch(setHeaderType(headerType ?? 'public'));
    if (user && !atCookie) {
      dispatch(logOut({
        onSuccess: () => { window.location.reload(false); }
      }));
    } else if (!user?.mobile || !user.id) {
      setIsLogOutChecked(true);
      dispatch(getUser({
        onSuccess: () => dispatch(setHeaderType(headerType ?? 'public')),
        onError: () => dispatch(setHeaderType(headerType ?? 'public'))
      }));
    } else {
      setIsLogOutChecked(true);
    }

    if (!rowsPerPage) {
      setRowsPerPageLimit(rowsPerPage, setNewLimit);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (config.ENV === 'prod') {
      window._mfq = window._mfq || [];
      window._mfq.push(["setVariable", "userPhone", user?.mobile ?? '']);
    }
  }, [user]); // eslint-disable-line

  const setNewLimit = (value) => {
    dispatch(setRowsPerPage(value));
    localStorage.setItem('ROWS-PER-PAGE', value.toString());
  };

  return (
    <>
      {isLoadedUser && isLogOutChecked ? (
        <Route {...rest} render={props => (!user
          ? <Component {...props} />
          : <Redirect to={'/dashboard'} />)}
        />
      ) : (
        <Box sx={{ mt: 12, ml: 10 }}>
          <Typography variant="body1" component="p">
            Loading...
          </Typography>
        </Box>
      )}
      <BlockedLogOutModal quickExit />
    </>
  );
};

NoLoggedInRoute.propTypes = {
  component: PropTypes.any,
  headerType: PropTypes.string
};

export default NoLoggedInRoute;
