import { call, put, takeLatest, takeEvery, all, debounce } from 'redux-saga/effects';
import axios from 'axios';

//import config from '../../../config';
import { actionTypes as documentsActionType } from '../reducers';
import { isFunc } from '@utils/functions';
//import history from '../../../utils/history';

const Api = {
  getDocuments: ({ journeyId, category, type, userId, page, limit, statuses, requiredUIDs, sort }) =>
    axios.get(`/documents${journeyId ? '?journey=' + journeyId : ''}${journeyId ? '&' : '?'}limit=${limit ?? '100'}`
      + `${page ? '&page=' + page : ''}${category ? '&category=' + category : ''}${type ? '&type=' + type : ''}`
      + `${userId ? '&requiredUser=' + userId : ''}${statuses?.length ? statuses.reduce((acc, status) => acc + '&statuses[]=' + status, '') : ''}`
      + `${requiredUIDs?.length ? requiredUIDs.reduce((acc, requiredUID) => acc + '&requiredUID[]=' + requiredUID, '') : ''}`
      + `${sort ? '&sort=' + sort : ''}`
    ),
  getDocumentById: (id) => axios.get(`/documents/${id}`),
  getDocumentTypes: (category) => axios.get(`/dictionary/documents${category ? '?category=' + category : ''}`),
  getDocumentType: (typeId) => axios.get(`/dictionary/documents`),
  getConveyancerAgreement: ({ version }) => axios.get(`/dictionary/agreements?type=Conveyancer&current=true${version ? `&version=${version}` : ''}`),
  getConveyancerAgreementPDF: (journeyId) => axios.get(`/journeys/${journeyId}/conveyancing-agreement-pdf`, {responseType: 'arraybuffer'}),
  signConveyancingAgreement: (journeyId) => axios.post(`/journeys/${journeyId}/signConveyancing`),
  uploadDocumentFiles: ({formData, documentId, config}) =>
    axios.post(`/documents/${documentId}/upload`, formData, { ...config, headers: { 'Content-Type': 'multipart/form-data' } }),
  uploadDocumentOriginalFiles: ({formData, documentId, config}) =>
    axios.post(`/documents/${documentId}/upload-original`, formData, { ...config, headers: { 'Content-Type': 'multipart/form-data' } }),
  updateDocument: ({documentId, body}) => axios.put(`/documents/${documentId}`, body),
  removeDocumentFile: ({ documentId, fileId }) => axios.delete(`/documents/${documentId}/${fileId}/remove`),
  removeDocumentOriginalFile: ({ documentId, fileId }) => axios.delete(`/documents/${documentId}/${fileId}/remove-original`),
  deleteDocument: (documentId) => axios.delete(`/documents/${documentId}`),
  createDocument: (body) => axios.post(`/documents`, body),
  downloadFile: (fileKey) => axios.get(`/documents/${fileKey}/download`),
  downloadOriginalFile: (fileKey) => axios.get(`/documents/${fileKey}/download-original`),
  getContracts: ({journeyId, limit, page}) => axios.get(`/journeys/${journeyId}/contracts?limit=${limit ?? '10'}${page ? `&page=${page}` : ''}`),
  getContract: ({journeyId, contractId}) => axios.get(`/journeys/${journeyId}/contracts/${contractId}`),
  createContract: ({journeyId, body}) => axios.post(`/journeys/${journeyId}/contracts`, body),
  updateContract: ({journeyId, contractId, body}) => axios.put(`/journeys/${journeyId}/contracts/${contractId}`, body),
  deleteContract: ({journeyId, contractId}) => axios.delete(`/journeys/${journeyId}/contracts/${contractId}`),
  addComplimentaryContractReview: (journeyId) => axios.post(`/journeys/${journeyId}/incrementContractReviews`, {}),
  getStripeSession: (journeyId) => axios.get(`/journeys/${journeyId}/stripe/checkoutSession`),
  expireStripeSession: (sessionId) => axios.get(`/stripe/session/${sessionId}/expire`),
  getQuestions: ({ journey, user, requiredUser, requiredUIDs, sort }) => {
    let paramAdded = false;
    let url = '/questions';

    if (journey) { url += `${paramAdded ? '&' : '?'}journey=${journey}`; paramAdded = true; }
    if (user) { url += `${paramAdded ? '&' : '?'}user=${user}`; paramAdded = true; }
    if (requiredUser) { url += `${paramAdded ? '&' : '?'}requiredUser=${requiredUser}`; paramAdded = true; }
    if (!!requiredUIDs?.length && !!requiredUIDs?.[0]) {
      url += `${paramAdded ? '&' : '?'}requiredUID[]=${requiredUIDs[0]}`;
      requiredUIDs.forEach((uid, index) => {
        if (index > 0 && !!uid) {
          url += `&requiredUID[]=${uid}`;
        }
      });
      paramAdded = true;
    }
    if (sort) { url += `${paramAdded ? '&' : '?'}sort=${sort}`; paramAdded = true; }

    return axios.get(url);
  },
  getQuestion: (questionId) => axios.get(`/questions/${questionId}`),
  createQuestion: (body) => axios.post(`/questions`, body),
  editQuestion: ({questionId, body}) => axios.put(`/questions/${questionId}`, body),
  deleteQuestion: (questionId) => axios.delete(`/questions/${questionId}`),
};

export const actionTypes = {
  GET_DOCUMENTS: 'GET_DOCUMENTS',
  GET_DOCUMENT_BY_ID: 'GET_DOCUMENT_BY_ID',
  GET_DOCUMENTS_BY_IDS: 'GET_DOCUMENTS_BY_IDS',
  GET_DOCUMENT_TYPES: 'GET_DOCUMENT_TYPES',
  GET_DOCUMENT_TYPE: 'GET_DOCUMENT_TYPE',
  UPLOAD_DOCUMENT_FILES: 'UPLOAD_DOCUMENT_FILES',
  UPLOAD_DOCUMENT_ORIGINAL_FILES: 'UPLOAD_DOCUMENT_ORIGINAL_FILES',
  UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
  UPDATE_DOCUMENTS: 'UPDATE_DOCUMENTS',
  REMOVE_DOCUMENT_FILE: 'REMOVE_DOCUMENT_FILE',
  REMOVE_DOCUMENT_ORIGINAL_FILE: 'REMOVE_DOCUMENT_ORIGINAL_FILE',
  DELETE_DOCUMENT: 'DELETE_DOCUMENT',
  DELETE_DOCUMENTS: 'DELETE_DOCUMENTS',
  CREATE_DOCUMENT: 'CREATE_DOCUMENT',
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  DOWNLOAD_ORIGINAL_FILE: 'DOWNLOAD_ORIGINAL_FILE',
  GET_CONVEYANCER_AGREEMENT: 'GET_CONVEYANCER_AGREEMENT',
  GET_CONVEYANCER_AGREEMENT_PDF: 'GET_CONVEYANCER_AGREEMENT_PDF',
  SIGN_CONVEYANCING_AGREEMENT: 'SIGN_CONVEYANCING_AGREEMENT',
  GET_CONTRACTS: 'GET_CONTRACTS',
  GET_CONTRACT: 'GET_CONTRACT',
  CREATE_CONTRACT: 'CREATE_CONTRACT',
  UPDATE_CONTRACT: 'UPDATE_CONTRACT',
  DELETE_CONTRACT: 'DELETE_CONTRACT',
  ADD_COMPLIMENTARY_CONTRACT_REVIEW: 'ADD_COMPLIMENTARY_CONTRACT_REVIEW',
  GET_STRIPE_SESSION: 'GET_STRIPE_SESSION',
  EXPIRE_STRIPE_SESSION: 'EXPIRE_STRIPE_SESSION',
  GET_QUESTIONS: 'GET_QUESTIONS',
  GET_QUESTION: 'GET_QUESTION',
  CREATE_QUESTION: 'CREATE_QUESTION',
  EDIT_QUESTION: 'EDIT_QUESTION',
  DELETE_QUESTION: 'DELETE_QUESTION',
  EDIT_QUESTIONS: 'EDIT_QUESTIONS'
};

//const cookies = new Cookies();

function* sagaGetDocuments({ payload: { journeyId, category, type, page, userId, statuses, sort, onSuccess, onError, limit, requiredUIDs } }) {
  try {
    const result = yield call(Api.getDocuments, { journeyId, category, type, page, limit, userId, statuses, sort, requiredUIDs });
    if (result.status < 400) {
      yield put({ type: documentsActionType.DOCUMENTS, payload: result.data });
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetDocumentById({ payload: { documentId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getDocumentById, documentId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetDocumentsByIds({ payload: { documentIds, onSuccess, onError } }) {
  try {
    const results = yield all(documentIds.map((id) => call(Api.getDocumentById, id)));
    if (results.every((result) => result.status < 400)) {
      if (isFunc(onSuccess)) onSuccess(results.map((result) => result.data));
    } else if (isFunc(onError)) {
      const errorResult = results.find((result) => result.status >= 400);
      onError(errorResult.data);
    }
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetDocumentTypes({ payload: { category, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getDocumentTypes, category);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetDocumentType({ payload: { typeId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getDocumentType, typeId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess((result.data ?? []).find((type) => type.id === typeId));
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetConveyancerAgreement({ payload: { version, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getConveyancerAgreement, { version });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetConveyancerAgreementPDF({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getConveyancerAgreementPDF, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaSignConveyancingAgreement({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.signConveyancingAgreement, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDownloadFile({ payload: { fileKey, onSuccess, onError } }) {
  try {
    const result = yield call(Api.downloadFile, fileKey);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDownloadOriginalFile({ payload: { fileKey, onSuccess, onError } }) {
  try {
    const result = yield call(Api.downloadOriginalFile, fileKey);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUploadDocumentFiles({ payload: { files, documentId, onSuccess, onError, onProgressEvent } }) {
  try {
    const config = {
      onUploadProgress: function(progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (isFunc(onProgressEvent)) onProgressEvent(percentCompleted);
      }
    };

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    const result = yield call(Api.uploadDocumentFiles, { formData, documentId, config });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUploadDocumentOriginalFiles({ payload: { files, documentId, onSuccess, onError, onProgressEvent } }) {
  try {
    const config = {
      onUploadProgress: function(progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (isFunc(onProgressEvent)) onProgressEvent(percentCompleted);
      }
    };

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    const result = yield call(Api.uploadDocumentOriginalFiles, { formData, documentId, config });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateDocument({ payload: { documentId, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateDocument, { documentId, body });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateDocument({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createDocument, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateDocuments({ payload: { documentIds, bodies, onSuccess, onError } }) {
  try {
    const results = yield all(bodies.map((body, idx) => call(Api.updateDocument, { documentId: documentIds[idx], body })));
    if (results.every((result) => result.status < 400)) {
      if (isFunc(onSuccess)) onSuccess(results.map((result) => result.data));
    } else if (isFunc(onError)) {
      const errorResult = results.find((result) => result.status >= 400);
      onError(errorResult.data);
    }
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaRemoveDocumentFile({ payload: { documentId, fileId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.removeDocumentFile, { documentId, fileId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaRemoveDocumentOriginalFile({ payload: { documentId, fileId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.removeDocumentOriginalFile, { documentId, fileId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteDocuments({ payload: { documentIds, onSuccess, onError } }) {
  try {
    const results = yield all(documentIds.map((documentId) => call(Api.deleteDocument, documentId)));
    if (results.every((result) => result.status < 400)) {
      if (isFunc(onSuccess)) onSuccess(results.map((result) => result.data));
    } else if (isFunc(onError)) {
      const errorResult = results.find((result) => result.status >= 400);
      onError(errorResult.data);
    }
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetContracts({ payload: { journeyId, limit, page, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getContracts, {journeyId, limit, page});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetContract({ payload: { journeyId, contractId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getContract, {journeyId, contractId});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateContract({ payload: { journeyId, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createContract, {journeyId, body});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaUpdateContract({ payload: { journeyId, contractId, body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.updateContract, {journeyId, contractId, body});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteContract({ payload: { journeyId, contractId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteContract, {journeyId, contractId});
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaAddComplimentaryContractReview({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.addComplimentaryContractReview, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetStripeSession({ payload: { journeyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getStripeSession, journeyId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaExpireStripeSession({ payload: { sessionId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.expireStripeSession, sessionId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetQuestions({ payload: { journey, user, requiredUser, requiredUIDs, sort, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getQuestions, { journey, user, requiredUser, requiredUIDs, sort });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetQuestion({ payload: { questionId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getQuestion, questionId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreateQuestion({ payload: { body, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createQuestion, body);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaEditQuestion({ payload: { body, questionId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.editQuestion, { body, questionId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeleteQuestion({ payload: { questionId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deleteQuestion, questionId);
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaEditQuestions({ payload: { questionIds, bodies, onSuccess, onError } }) {
  try {
    const results = yield all(bodies.map((body, idx) => call(Api.editQuestion, { questionId: questionIds[idx], body })));
    if (results.every((result) => result.status < 400)) {
      if (isFunc(onSuccess)) onSuccess(results.map((result) => result.data));
    } else if (isFunc(onError)) {
      const errorResult = results.find((result) => result.status >= 400);
      onError(errorResult.data);
    }
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getDocuments = payload => ({ type: actionTypes.GET_DOCUMENTS, payload });
export const getDocumentById = payload => ({ type: actionTypes.GET_DOCUMENT_BY_ID, payload });
export const getDocumentsByIds = payload => ({ type: actionTypes.GET_DOCUMENTS_BY_IDS, payload });
export const getDocumentTypes = payload => ({ type: actionTypes.GET_DOCUMENT_TYPES, payload });
export const getDocumentType = payload => ({ type: actionTypes.GET_DOCUMENT_TYPE, payload });
export const uploadDocumentFiles = payload => ({ type: actionTypes.UPLOAD_DOCUMENT_FILES, payload });
export const uploadDocumentOriginalFiles = payload => ({ type: actionTypes.UPLOAD_DOCUMENT_ORIGINAL_FILES, payload });
export const updateDocument = payload => ({ type: actionTypes.UPDATE_DOCUMENT, payload });
export const updateDocuments = payload => ({ type: actionTypes.UPDATE_DOCUMENTS, payload });
export const removeDocumentFile = payload => ({ type: actionTypes.REMOVE_DOCUMENT_FILE, payload });
export const removeDocumentOriginalFile = payload => ({ type: actionTypes.REMOVE_DOCUMENT_ORIGINAL_FILE, payload });
export const deleteDocuments = payload => ({ type: actionTypes.DELETE_DOCUMENTS, payload });
export const createDocument = payload => ({ type: actionTypes.CREATE_DOCUMENT, payload });
export const downloadFile = payload => ({ type: actionTypes.DOWNLOAD_FILE, payload });
export const downloadOriginalFile = payload => ({ type: actionTypes.DOWNLOAD_ORIGINAL_FILE, payload });
export const getConveyancerAgreement = payload => ({ type: actionTypes.GET_CONVEYANCER_AGREEMENT, payload });
export const getConveyancerAgreementPDF = payload => ({ type: actionTypes.GET_CONVEYANCER_AGREEMENT_PDF, payload });
export const signConveyancingAgreement = payload => ({ type: actionTypes.SIGN_CONVEYANCING_AGREEMENT, payload });
export const getContracts = payload => ({ type: actionTypes.GET_CONTRACTS, payload });
export const getContract = payload => ({ type: actionTypes.GET_CONTRACT, payload });
export const createContract = payload => ({ type: actionTypes.CREATE_CONTRACT, payload });
export const updateContract = payload => ({ type: actionTypes.UPDATE_CONTRACT, payload });
export const deleteContract = payload => ({ type: actionTypes.DELETE_CONTRACT, payload });
export const addComplimentaryContractReview = payload => ({ type: actionTypes.ADD_COMPLIMENTARY_CONTRACT_REVIEW, payload });
export const getStripeSession = payload => ({ type: actionTypes.GET_STRIPE_SESSION, payload });
export const expireStripeSession = payload => ({ type: actionTypes.EXPIRE_STRIPE_SESSION, payload });
export const getQuestions = payload => ({ type: actionTypes.GET_QUESTIONS, payload });
export const getQuestion = payload => ({ type: actionTypes.GET_QUESTION, payload });
export const createQuestion = payload => ({ type: actionTypes.CREATE_QUESTION, payload });
export const editQuestion = payload => ({ type: actionTypes.EDIT_QUESTION, payload });
export const deleteQuestion = payload => ({ type: actionTypes.DELETE_QUESTION, payload });
export const editQuestions = payload => ({ type: actionTypes.EDIT_QUESTIONS, payload });

const documentsSagas = [
  debounce(350, actionTypes.GET_DOCUMENTS, sagaGetDocuments),
  takeEvery(actionTypes.GET_DOCUMENT_BY_ID, sagaGetDocumentById),
  debounce(350, actionTypes.GET_DOCUMENTS_BY_IDS, sagaGetDocumentsByIds),
  takeEvery(actionTypes.GET_DOCUMENT_TYPES, sagaGetDocumentTypes),
  takeLatest(actionTypes.GET_DOCUMENT_TYPE, sagaGetDocumentType),
  takeEvery(actionTypes.UPLOAD_DOCUMENT_FILES, sagaUploadDocumentFiles),
  takeEvery(actionTypes.UPLOAD_DOCUMENT_ORIGINAL_FILES, sagaUploadDocumentOriginalFiles),
  takeEvery(actionTypes.UPDATE_DOCUMENT, sagaUpdateDocument),
  takeEvery(actionTypes.UPDATE_DOCUMENTS, sagaUpdateDocuments),
  takeEvery(actionTypes.REMOVE_DOCUMENT_FILE, sagaRemoveDocumentFile),
  takeEvery(actionTypes.REMOVE_DOCUMENT_ORIGINAL_FILE, sagaRemoveDocumentOriginalFile),
  takeEvery(actionTypes.DELETE_DOCUMENTS, sagaDeleteDocuments),
  takeEvery(actionTypes.CREATE_DOCUMENT, sagaCreateDocument),
  takeLatest(actionTypes.DOWNLOAD_FILE, sagaDownloadFile),
  takeLatest(actionTypes.DOWNLOAD_ORIGINAL_FILE, sagaDownloadOriginalFile),
  takeLatest(actionTypes.GET_CONVEYANCER_AGREEMENT, sagaGetConveyancerAgreement),
  takeLatest(actionTypes.GET_CONVEYANCER_AGREEMENT_PDF, sagaGetConveyancerAgreementPDF),
  takeLatest(actionTypes.SIGN_CONVEYANCING_AGREEMENT, sagaSignConveyancingAgreement),
  takeLatest(actionTypes.GET_CONTRACTS, sagaGetContracts),
  takeLatest(actionTypes.GET_CONTRACT, sagaGetContract),
  takeEvery(actionTypes.CREATE_CONTRACT, sagaCreateContract),
  takeEvery(actionTypes.UPDATE_CONTRACT, sagaUpdateContract),
  takeEvery(actionTypes.DELETE_CONTRACT, sagaDeleteContract),
  takeEvery(actionTypes.ADD_COMPLIMENTARY_CONTRACT_REVIEW, sagaAddComplimentaryContractReview),
  takeLatest(actionTypes.GET_STRIPE_SESSION, sagaGetStripeSession),
  takeEvery(actionTypes.EXPIRE_STRIPE_SESSION, sagaExpireStripeSession),
  takeEvery(actionTypes.GET_QUESTIONS, sagaGetQuestions),
  takeEvery(actionTypes.GET_QUESTION, sagaGetQuestion),
  takeEvery(actionTypes.CREATE_QUESTION, sagaCreateQuestion),
  takeEvery(actionTypes.EDIT_QUESTION, sagaEditQuestion),
  takeEvery(actionTypes.EDIT_QUESTIONS, sagaEditQuestions),
  takeEvery(actionTypes.DELETE_QUESTION, sagaDeleteQuestion)
];

export default documentsSagas;
