import React from 'react';
import { Dialog, Box } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import './styles.global.scss';
import { withDimensions } from '../../../common/HOC';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";


const ProgressModal = ({ open, setOpen, filesNumber, progress }) => {
  const dimensions = useSelector(state => state.dimensions);

  return (
    <>
      <Dialog open={open} className={'ProgressDialog'} onClose={() => {}} fullScreen={dimensions.isScreenLessSM}>
        <MuiDialogTitle>
          <Box className={`${styles.modalTitle} ${dimensions.isScreenLessMD ? styles.mobile : ''}`}>
            Uploading {filesNumber ?? ''} file{filesNumber === 1 ? '' : 's'}
          </Box>
          <IconButton aria-label="close" className={styles.closeButton} onClick={() => setOpen(false)}>
            <CloseRoundedIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent>
          <div className={`${styles.progressBarContainer} ${!progress && progress !== 0 ? styles.transparent : ''}`}>
            <div className={styles.progressBar} style={{ width: `${progress}%` }} />
          </div>
          <span className={styles.percentageText}>{progress ?? 0}%</span>
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

ProgressModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  filesNumber: PropTypes.number,
  progress: PropTypes.number
};

export default withDimensions(ProgressModal);

