import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  CircularProgress,
  TextField,
  Autocomplete,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch, useSelector } from 'react-redux';
import { format, differenceInDays } from 'date-fns';

import styles from './styles.module.scss';
import DashboardLayout from '../../common/components/DashboardLayout';
import { displayErrorMessage } from '../../../utils/displayErrorMessage';
import { getNotifications } from '../../notifications/sagas';
import { setJourneys, setIsLoadedJourneys } from '../../journeys/reducers';
import { getJourneys } from '../../journeys/sagas';
import {
  getPropertySuggestionsWithDebounce,
  getUsersPropertyReportsList,
  createPropertyReport,
  getPropertyReport,
  deletePropertyReport,
  getPropertyReportsByIds
} from '../sagas';
import history from '../../../utils/history';
import {getUserRole, isJustDataOperations, isReadOnlyAudit, USER_ROLE} from '../../../utils/authorisation';
import config from '../../../config';


let timeoutId;
let intervalId;

const PropertyReports = () => {
  const { user, selectedUser } = useSelector(state => state.user);
  const dimensions = useSelector(state => state.dimensions);
  const { journeys, isLoadedJourneys } = useSelector(state => state.journeys);
  const dispatch = useDispatch();

  const [userRole, setUserRole] = useState(undefined);
  const [effectiveUser, setEffectiveUser] = useState(undefined);
  const [userReports, setUserReports] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [propertyAddress, setPropertyAddress] = useState(null);
  const [suggestedProperties, setSuggestedProperties] = useState([]);
  const [reportsLastMonth, setReportsLastMonth] = useState(-1);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const [team, setTeam] = useState([]);
  //const [inputString, setInputString] = useState('');

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);//eslint-disable-line

  useEffect(() => {
    if (!!intervalId) clearInterval(intervalId);
    if (!!userReports) {
      intervalId = setInterval(() => {
        const reportsInProgress = (userReports.requestedPropertyReports ?? []).filter((report) => report.status === 'In progress' || report.status === 'Created');

        if (reportsInProgress?.length) {
          setIsLoading(true);
          const reportIds = reportsInProgress.map((report) => report.reportId);
          dispatch(getPropertyReportsByIds({
            reportIds,
            userId: selectedUser?.id ?? undefined,
            onSuccess: () => {
              updateUsersPropertyReports();
            },
            onError: (data) => {
              displayErrorMessage(data, 'Refreshing failed');
              setIsLoading(false);
            }
          }));
        }
      }, 8000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [userReports]);//eslint-disable-line

  useEffect(() => {
    if (effectiveUser?.id) {
      updateUsersPropertyReports();
    }
  }, [effectiveUser]);//eslint-disable-line

  useEffect(() => {
    if (isJustDataOperations(user, true)) history.push('/deals-management');

    setUserRole(getUserRole(user));
  }, [user]);//eslint-disable-line

  useEffect(() => {
    if (!!userReports && !!effectiveUser) {
      if (userRole !== USER_ROLE.Customer) {
        setReportsLastMonth(-1);
      } else {
        const reports = userReports.requestedPropertyReports ?? [];
        const lastMonthReports = reports.filter((report) => differenceInDays(new Date(), new Date(report.created)) <= 30);
        setReportsLastMonth(lastMonthReports.length);
      }
    }
  }, [effectiveUser, userRole, userReports]);//eslint-disable-line

  useEffect(() => {
    if (!!effectiveUser) {
      setIsLimitExceeded(reportsLastMonth >= (effectiveUser?.allowedPropertyReportsPerMonth ?? config.PROPERTY_DEFAULT_REPORTS_PER_MONTH ?? 0));
    }
  }, [effectiveUser, reportsLastMonth]);//eslint-disable-line

  useEffect(() => {
    if (!!effectiveUser) {
      dispatch(getNotifications({
        userId: effectiveUser?.id,
        onSuccess: () => {},
        onError: (data) => {
          displayErrorMessage(data, 'Unable to retrieve notifications');
        }
      }));

      if (userRole ===  USER_ROLE.Customer || !!selectedUser) {
        dispatch(getJourneys({
          userId: effectiveUser?.id,
          onSuccess: (data) => {
            dispatch(setJourneys(data));
            dispatch(setIsLoadedJourneys(true));
          },
          onError: (data) => {
            displayErrorMessage(data, 'Unable to retrieve your journeys');
          }
        }));
      }
    }
  }, [effectiveUser, userRole, selectedUser]);//eslint-disable-line

  useEffect(() => {
    if (isLoadedJourneys) {

      if (!journeys) setTeam([]);
      else {
        const updatedTeam = journeys.elements
          .filter((journey) => journey.steps?.length)
          .reduce((acc, journey) => [...acc, ...(journey.team || [])], []);
        let distinct = [];
        let cleanedTeam = [];
        updatedTeam.forEach((member, idx) => {
          if (!!member.user?.id && !distinct.includes(member.user.id)) {
            distinct.push(member.user.id);
            cleanedTeam.push({...member.user, role: member.role});
          }
        });
        setTeam(cleanedTeam);
      }
    }
  }, [journeys, isLoadedJourneys, effectiveUser]);//eslint-disable-line

  useEffect(() => { setEffectiveUser(selectedUser ?? user) }, [user, selectedUser]); //eslint-disable-line

  const generatePropertyReport = () => {
    if (!!propertyAddress) {
      setIsLoading(true);
      dispatch(createPropertyReport({
        address: propertyAddress.address,
        userId: selectedUser?.id ?? undefined,
        propertyId: propertyAddress.id,
        onSuccess: (data) => {
          setUserReports(data);
          setPropertyAddress(null);
          setSuggestedProperties([]);
          setIsLoading(false);
        },
        onError: (data) => {
          displayErrorMessage(data, 'Error occurred during property report\'s creation');
          setIsLoading(false);
        }
      }));
    }
  };

  const updateUsersPropertyReports = () => {
    if (userRole) {
      setIsLoading(true);
      let options = {
        userId: selectedUser?.id ?? undefined,
        onSuccess: (data) => {
          setUserReports(data);
          setIsLoading(false);
        },
        onError: (data) => {
          displayErrorMessage(data, 'Unable to retrieve requested property reports');
          setIsLoading(false);
        }
      };

      dispatch(getUsersPropertyReportsList(options));
    }
  };

  const onPropertyAddressChanged = (addressString) => {
    //setInputString(addressString);
    if (addressString?.length > 3) {
      setIsSuggestionsLoading(true);
      dispatch(getPropertySuggestionsWithDebounce({
        address: addressString,
        onSuccess: (data) => {
          console.log('Suggested properties:', data);
          setSuggestedProperties(data);
          //setInputString(addressString);
          setIsSuggestionsLoading(false);
        },
        onError: (data) => {
          displayErrorMessage(data, 'Unable to retrieve requested property reports');
          //setInputString(addressString);
          setIsSuggestionsLoading(false);
        }
      }));
    }
  };

  const refreshReport = (reportId) => {
    setIsLoading(true);
    dispatch(getPropertyReport({
      reportId,
      userId: selectedUser?.id ?? undefined,
      onSuccess: () => {
        updateUsersPropertyReports();
      },
      onError: (data) => {
        displayErrorMessage(data, 'Refreshing failed');
        setIsLoading(false);
      }
    }));
  };

  const doDeleteReport = (reportId) => {
    setIsLoading(true);
    dispatch(deletePropertyReport({
      reportId,
      userId: selectedUser?.id ?? undefined,
      onSuccess: (data) => {
        setUserReports(data);
        setIsLoading(false);
        //updateUsersPropertyReports();
      },
      onError: (data) => {
        displayErrorMessage(data, 'Removing failed');
        setIsLoading(false);
      }
    }));
  };

  return <DashboardLayout team={team ?? []}>
    <Grid container justifyContent="space-between" alignItems="center" alignContent="center">
      <Grid item className={`${styles.pageTitle} ${dimensions.isScreenBiggerSM ? '' : styles.mobile}`}>Property reports</Grid>
    </Grid>

    <Box sx={{ mt: 4, mb: 2 }} className={styles.title}>
      <Grid container justifyItems={'space-between'} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
        <Grid item>
          Request a report
        </Grid>
      </Grid>
    </Box>

    <Box className={styles.searchingPropertiesBlock}>
      <Grid container justifyItems={'space-between'} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'} spacing={dimensions.isScreenBiggerXS ? 0 : 2}>
        <Grid item className={styles.inputWrapper}>
          <Autocomplete
            autoComplete
            openOnFocus={false}

            loading={isLoading || isSuggestionsLoading}
            options={suggestedProperties ?? []}
            filterOptions={(options) => options}
            getOptionLabel={(option) => option.address ?? ''}
            value={propertyAddress ?? null}
            disabled={isLoading/* || isSuggestionsLoading*/ || isLimitExceeded}
            onChange={(event, newValue) => {
              setPropertyAddress(newValue);
            }}
            renderInput={(params) => <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSuggestionsLoading ? (
                      <CircularProgress color="primary" size={20} style={{marginRight: 7}} />
                    ) : (
                      <SearchIcon style={{marginRight: 7}} />
                    )}
                  </>
                ),
              }}
              onChange={(event) => {
                onPropertyAddressChanged(event.target.value);
              }}
              placeholder="Type address here to generate report..."
              variant="outlined"
              maxLength="500"
              fullWidth
              className={styles.searchInput}
            />}
          />
        </Grid>

        <Grid item className={styles.btnWrapper}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => { generatePropertyReport() }}
            disabled={isLoading || isSuggestionsLoading || isLimitExceeded || !propertyAddress}
            fullWidth
          >
            GENERATE REPORT
          </Button>
        </Grid>
      </Grid>
    </Box>

    {reportsLastMonth >= 0 && (<>
      {reportsLastMonth >= (effectiveUser?.allowedPropertyReportsPerMonth ?? config.PROPERTY_DEFAULT_REPORTS_PER_MONTH ?? 0) ? (
        <Box sx={{ mt: 0 }} className={`${styles.usedReport}`}>
          You are exceeded of {effectiveUser?.allowedPropertyReportsPerMonth ?? config.PROPERTY_DEFAULT_REPORTS_PER_MONTH ?? 0} allowed property reports per month. Need more?{' '}
          <span className={styles.link}>
            <a href={`/team`} onClick={(e) => { e.preventDefault(); history.push(`/team`); }}>
              Contact us
            </a>
          </span>
        </Box>
      ) : (
        <Box sx={{ mt: 0 }} className={`${styles.usedReport} ${styles.light}`}>
          You used {reportsLastMonth} of allowed {effectiveUser?.allowedPropertyReportsPerMonth ?? config.PROPERTY_DEFAULT_REPORTS_PER_MONTH ?? 0} property reports per month.
        </Box>
      )}
    </>)}



    <Box sx={{ mt: 4, mb: 2 }} className={styles.title}>
      <Grid container justifyItems={'space-between'} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
        <Grid item>
          Generated reports
        </Grid>
      </Grid>
    </Box>

    <Box sx={{ mt: 2 }}>
      <TableContainer className={styles.tableContainer}>
        <Table size={dimensions.isScreenBiggerSM ? "medium" : "small"} stickyHeader>
          <TableHead>
            <TableRow className={styles.tableHead}>
              <TableCell
                key={'address'}
                align={'left'}
                style={{ minWidth: dimensions.isScreenBiggerSM ? 200 : 160 }}
              >
                ADDRESS
              </TableCell>
              <TableCell
                key={'lastUpdate'}
                align={'left'}
                style={{ minWidth: 88 }}
              >
                GENERATED ON
              </TableCell>
              <TableCell
                key={'action'}
                align={'left'}
                style={{ minWidth: 88, maxWidth: 88 }}
              >

              </TableCell>
              {userRole !== USER_ROLE.Customer && !isReadOnlyAudit(user) && (
                <TableCell
                  key={'delete'}
                  align={'left'}
                  style={{ minWidth: 24, maxWidth: 24 }}
                >

                </TableCell>
              )}

            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            {(userReports?.requestedPropertyReports ?? []).map((report, idx) => {
              return (
                <TableRow className={styles.tableItem} key={idx}>
                  <TableCell align={'left'} className={styles.type}>
                    <div>{report.address}</div>
                    {report.status === 'Error' && (
                      <div className={styles.errorCaption}>
                        Failed report generation attempt
                      </div>
                    )}
                  </TableCell>
                  <TableCell align={'left'} className={styles.date}>
                    <div>{(report.created) ? format(new Date(report.created), 'dd MMM yyyy') : '-'}</div>
                  </TableCell>

                  <TableCell align={'left'} className={styles.status}>
                    { (report.status === 'Success' && !report.willBeUpdated) ? (
                      <Button
                        color="primary"
                        variant="contained"
                        className={styles.smallButton}
                        onClick={() => history.push(`/property-report/${report.reportId}`)}
                        disabled={isLoading || isSuggestionsLoading}
                        fullWidth
                      >
                        VIEW
                      </Button>
                    ) : (
                      (report.status === 'Error') ? (
                        <Button
                          color="secondary"
                          variant="outlined"
                          className={styles.smallButton}
                          onClick={() => { refreshReport(report.reportId); }}
                          disabled={isLoading || isSuggestionsLoading || ((report?.attempts ?? 0) >= (config.PROPERTY_MAX_RETRY_ATTEMPTS ?? 0))}
                          fullWidth
                        >
                          RE-RUN
                        </Button>
                      ) : (
                        <div>
                          <CircularProgress size={18} className={styles.tableProgress} /> Generating...
                        </div>
                      )
                    )}
                  </TableCell>

                  {userRole !== USER_ROLE.Customer && !isReadOnlyAudit(user) && (
                    <TableCell align={'right'} className={styles.status}>
                      <IconButton
                        className={`${styles.iconBtn} ${styles.noPadding}`}
                        onClick={() => { doDeleteReport(report.reportId); }}
                        disabled={isLoading || isSuggestionsLoading}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!(userReports?.requestedPropertyReports ?? []).length && (
          <Grid container className={styles.emptyTable} justifyContent={"center"} alignItems={"center"}>
            <Grid item>No reports found</Grid>
          </Grid>
        )}
        {isLoading && (<CircularProgress size={80} className={styles.cardProgress} />)}
      </TableContainer>
    </Box>
  </DashboardLayout>;
};

export default PropertyReports;
