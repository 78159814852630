import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { setIsHiddenMainLayoutWrapper, setHeaderType, setRowsPerPage } from '../../../common/reducers';
import config from '../../../../config';
import BlockedLogOutModal from '../../../common/components/BlockedLogOutModal';
import { setRowsPerPageLimit } from '../../../../utils/helpers';


const FreeRoute = ({
                     component: Component,
                     shouldHideMainLayoutWrapper,
                     headerType,
                     ...rest
}) => {
  const dispatch = useDispatch();
  const { rowsPerPage } = useSelector(state => state.pagination);
  const { user } = useSelector(state => state.user);

  const [isPrepared, setIsPrepared] = useState(false);

  useEffect(() => {
    localStorage.removeItem('TARGET-URL');

    dispatch(setIsHiddenMainLayoutWrapper(!!shouldHideMainLayoutWrapper));
    if (!rowsPerPage) {
      setRowsPerPageLimit(rowsPerPage, setNewLimit);
      setIsPrepared(true);
    } else {
      setIsPrepared(true);
    }

  }, []); // eslint-disable-line

  useEffect(() => {
    if (config.ENV === 'prod') {
      window._mfq = window._mfq || [];
      window._mfq.push(["setVariable", "userPhone", user?.mobile ?? '']);
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    dispatch(setHeaderType(headerType ?? 'public'));
  }, [headerType]); // eslint-disable-line

  const setNewLimit = (value) => {
    dispatch(setRowsPerPage(value));
    localStorage.setItem('ROWS-PER-PAGE', value.toString());
  };

  return (<>
    {isPrepared ? <Route {...rest} render={(props) => <Component {...props} />} /> : <></>}
    <BlockedLogOutModal />
  </>);
};

FreeRoute.propTypes = {
  component: PropTypes.any,
  shouldHideMainLayoutWrapper: PropTypes.bool,
  headerType: PropTypes.string
};

export default FreeRoute;