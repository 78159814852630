import {call, takeLatest, takeEvery, debounce, all} from 'redux-saga/effects';
import axios from 'axios';
import { isFunc } from '../../../utils/functions';
import queryString from "query-string";


const Api = {
  getUsersPropertyReportsList: ({ userId }) => axios.get(`/property/reports/list${userId ? '?userId=' + userId : ''}`),
  getPropertySuggestions: ({ address }) => {
    const queryParams = queryString.stringify({ address });
    return axios.get(`/property/find?${queryParams}`);
  },
  getPropertySuggestionsOpen: ({ address }) => {
    const queryParams = queryString.stringify({ address });
    return axios.get(`/property/get-suggestions-open?${queryParams}`);
  },
  createPropertyReport: ({ address, userId, propertyId }) => axios.post(`/property/report`, { address, userId, propertyId }),
  getPropertyReport: ({ userId, reportId }) => axios.get(`/property/reports/${reportId}${userId ? '?userId=' + userId : ''}`),
  deletePropertyReport: ({ userId, reportId }) => axios.delete(`/property/reports/${reportId}${userId ? '?userId=' + userId : ''}`),
  getPropertyEstimationOpen: (propertyId) => {
    return axios.get(`/property/get-property-estimation-open/${propertyId}`);
  },
};

export const actionTypes = {
  GET_USERS_PROPERTY_REPORTS_LIST: 'GET_USERS_PROPERTY_REPORTS_LIST',
  GET_PROPERTY_SUGGESTIONS: 'GET_PROPERTY_SUGGESTIONS',
  GET_PROPERTY_SUGGESTIONS_WITH_DEBOUNCE: 'GET_PROPERTY_SUGGESTIONS_WITH_DEBOUNCE',
  GET_PROPERTY_SUGGESTIONS_OPEN_WITH_DEBOUNCE: 'GET_PROPERTY_SUGGESTIONS_OPEN_WITH_DEBOUNCE',
  GET_PROPERTY_ESTIMATION_OPEN: 'GET_PROPERTY_ESTIMATION_OPEN',
  CREATE_PROPERTY_REPORT: 'CREATE_PROPERTY_REPORT',
  GET_PROPERTY_REPORT: 'GET_PROPERTY_REPORT',
  DELETE_PROPERTY_REPORT: 'DELETE_PROPERTY_REPORT',
  GET_PROPERTY_REPORTS_BY_IDS: 'GET_PROPERTY_REPORTS_BY_IDS',
};

function* sagaGetUsersPropertyReportsList({ payload: { userId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getUsersPropertyReportsList, { userId });
    if (result.status === 204) {
      if (isFunc(onSuccess)) onSuccess(null);
    } else if (result.data && result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetPropertySuggestions({ payload: { address, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getPropertySuggestions, { address });
    if (result.data && result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetPropertySuggestionsOpen({ payload: { address, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getPropertySuggestionsOpen, { address });
    if (result.data && result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaCreatePropertyReport({ payload: { address, userId, propertyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.createPropertyReport, { address, userId, propertyId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetPropertyReport({ payload: { userId, reportId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getPropertyReport, { userId, reportId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaDeletePropertyReport({ payload: { userId, reportId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.deletePropertyReport, { userId, reportId });
    if (result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetPropertyReportsByIds({ payload: { userId, reportIds, onSuccess, onError } }) {
  try {
    const results = yield all(reportIds.map((reportId) => call(Api.getPropertyReport, { userId, reportId })));
    if (results.every((result) => result.status < 400)) {
      if (isFunc(onSuccess)) onSuccess(results.map((result) => result.data));
    } else if (isFunc(onError)) {
      const errorResult = results.find((result) => result.status >= 400);
      onError(errorResult.data);
    }
  } catch {
    if (isFunc(onError)) onError();
  }
}

function* sagaGetPropertyEstimationOpen({ payload: { propertyId, onSuccess, onError } }) {
  try {
    const result = yield call(Api.getPropertyEstimationOpen, propertyId);
    if (result.data && result.status < 400) {
      if (isFunc(onSuccess)) onSuccess(result.data);
    } else if (isFunc(onError)) onError(result.data);
  } catch {
    if (isFunc(onError)) onError();
  }
}

export const getUsersPropertyReportsList = payload => ({ type: actionTypes.GET_USERS_PROPERTY_REPORTS_LIST, payload });
export const getPropertySuggestions = payload => ({ type: actionTypes.GET_PROPERTY_SUGGESTIONS, payload });
export const getPropertySuggestionsWithDebounce = payload => ({ type: actionTypes.GET_PROPERTY_SUGGESTIONS_WITH_DEBOUNCE, payload });
export const getPropertySuggestionsOpenWithDebounce = payload => ({ type: actionTypes.GET_PROPERTY_SUGGESTIONS_OPEN_WITH_DEBOUNCE, payload });
export const createPropertyReport = payload => ({ type: actionTypes.CREATE_PROPERTY_REPORT, payload });
export const getPropertyReport = payload => ({ type: actionTypes.GET_PROPERTY_REPORT, payload });
export const deletePropertyReport = payload => ({ type: actionTypes.DELETE_PROPERTY_REPORT, payload });
export const getPropertyReportsByIds = payload => ({ type: actionTypes.GET_PROPERTY_REPORTS_BY_IDS, payload });
export const getPropertyEstimationOpen = payload => ({ type: actionTypes.GET_PROPERTY_ESTIMATION_OPEN, payload });

const propertyReportsSagas = [
  takeLatest(actionTypes.GET_USERS_PROPERTY_REPORTS_LIST, sagaGetUsersPropertyReportsList),
  takeLatest(actionTypes.GET_PROPERTY_SUGGESTIONS, sagaGetPropertySuggestions),
  debounce(2000, actionTypes.GET_PROPERTY_SUGGESTIONS_WITH_DEBOUNCE, sagaGetPropertySuggestions),
  debounce(2000, actionTypes.GET_PROPERTY_SUGGESTIONS_OPEN_WITH_DEBOUNCE, sagaGetPropertySuggestionsOpen),
  takeEvery(actionTypes.CREATE_PROPERTY_REPORT, sagaCreatePropertyReport),
  takeLatest(actionTypes.GET_PROPERTY_REPORT, sagaGetPropertyReport),
  takeEvery(actionTypes.DELETE_PROPERTY_REPORT, sagaDeletePropertyReport),
  takeLatest(actionTypes.GET_PROPERTY_REPORTS_BY_IDS, sagaGetPropertyReportsByIds),
  takeLatest(actionTypes.GET_PROPERTY_ESTIMATION_OPEN, sagaGetPropertyEstimationOpen),
];

export default propertyReportsSagas;