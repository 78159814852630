import React, { lazy } from 'react';
import { AuthRoute } from '../auth/components';
import sagas from './sagas';
//import { DashboardPage } from './pages';


const DashboardPage = lazy(() => import('./pages/DashboardPage'));

const dashboardModule = {
  routes: [<AuthRoute key="dashboard" exact path="/dashboard" component={DashboardPage} headerType={'private'} />],
  sagas
};

export default dashboardModule;
