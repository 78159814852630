import React, { lazy } from 'react';
import { AuthRoute } from '../auth/components';

import sagas from './sagas';
import { USER_ROLE } from '../../utils/authorisation';


const DealsManagement = lazy(() => import('./pages/DealsManagement'));
const ProductDetails = lazy(() => import('./pages/ProductDetails'));
const DealDetails = lazy(() => import('./pages/DealDetails'));
const SpecialOfferDetails = lazy(() => import('./pages/SpecialOfferDetails'));
const CreateProductForm = lazy(() => import('./pages/CreateProductForm'));
const CreateDealForm = lazy(() => import('./pages/CreateDealForm'));
const CreateSpecialOfferForm = lazy(() => import('./pages/CreateSpecialOfferForm'));

const dealsManagementModule = {
  routes: [
    <AuthRoute key="deals-management" exact path="/deals-management" component={DealsManagement} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="deals-management-product" exact path="/deals-management/product/:itemId" component={ProductDetails} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="deals-management-deal" exact path="/deals-management/deal/:itemId" component={DealDetails} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="deals-management-special-offer" exact path="/deals-management/special-offer/:itemId" component={SpecialOfferDetails} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="deals-management-create-product" exact path="/deals-management/create-product" component={CreateProductForm} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="deals-management-create-deal" exact path="/deals-management/create-deal/:productId" component={CreateDealForm} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="deals-management-create-special-offer" exact path="/deals-management/create-special-offer" component={CreateSpecialOfferForm} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="deals-management-create-product" exact path="/deals-management/edit-product/:itemId" component={CreateProductForm} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="deals-management-create-deal" exact path="/deals-management/edit-deal/:itemId" component={CreateDealForm} headerType={'private'} isSpecialistOnly />,
    <AuthRoute key="deals-management-create-special-offer" exact path="/deals-management/edit-special-offer/:itemId" component={CreateSpecialOfferForm} headerType={'private'} isSpecialistOnly />,
  ],
  userMenuItems: [
    {
      name: 'Deals management',
      path: '/deals-management',
      allowedRoles: [USER_ROLE.Admin, USER_ROLE.DataOperations],
      isForSpecialist: true,
      order: 20
    }
  ],
  sagas
};

export default dealsManagementModule;
