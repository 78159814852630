const defaultState = {
  journeys: null,//contains pagination data
  isLoadedJourneys: false
};

export const actionTypes = {
  JOURNEYS: 'JOURNEYS',
  IS_LOADED_JOURNEYS: 'IS_LOADED_JOURNEYS'
};

const journeysActions = function (state = defaultState, { type, payload }) {
  switch (type) {
    case actionTypes.JOURNEYS:
      return {
        ...state,
        journeys: payload
      };
    case actionTypes.IS_LOADED_JOURNEYS:
      return {
        ...state,
        isLoadedJourneys: payload
      };
    default:
      return state;
  }
};

export default journeysActions;

export const setJourneys = payload => ({ type: actionTypes.JOURNEYS, payload });
export const setIsLoadedJourneys = payload => ({ type: actionTypes.IS_LOADED_JOURNEYS, payload });
