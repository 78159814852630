import React, {useEffect, useRef, useState} from 'react';
import {
  Box,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  CircularProgress, Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import 'chartist/dist/index.scss';
import 'chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.scss';
import { LineChart, AutoScaleAxis, Interpolation, BarChart } from 'chartist';
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';

import './styles.global.scss';
import styles from './styles.module.scss';
import { displayErrorMessage } from '@utils/displayErrorMessage';
import { getPropertyReport } from '../sagas';
import history from '@utils/history';
import HeaderedPageLayout from '@common/components/HeaderedPageLayout';
import { addCommas } from '@utils/helpers';
import { ReactComponent as BedW } from './BedW.svg';
import { ReactComponent as BathW } from './BathW.svg';
import { ReactComponent as CarW } from './CarW.svg';
import { ReactComponent as Bed } from './Bed.svg';
import { ReactComponent as Bath } from './Bath.svg';
import { ReactComponent as Car } from './Car.svg';
import config from '@src/config';
import IconButton from "@mui/material/IconButton";
import {isJustDataOperations} from "@utils/authorisation";


let timeoutId;
const CHART_PADDING = { right: 10, left: 10, top: 10, bottom: 10 };
const MAX_DEMOGRAPHICS_ITEMS = 8;
const OTHER_LABEL = 'Other';
const BORN_ELSEWHERE = 'Born Elsewhere';
const HISTORY_OPTIONS = ['ALL', 'SOLD', 'RENTED'];

const SpacerT = ({ s, np, po }) => { return (<Box sx={{ mt: s ?? 0, width: '100%' }} className={`${np ? styles.notToPrint : ''} ${po ? styles.toPrintOnly : ''}`} />) };
const SpacerB = ({ s, np, po }) => { return (<Box sx={{ mb: s ?? 0, width: '100%' }} className={`${np ? styles.notToPrint : ''} ${po ? styles.toPrintOnly : ''}`} />) };

const PropertyReport = () => {
  const { selectedUser, user } = useSelector(state => state.user);
  const dimensions = useSelector(state => state.dimensions);
  const dispatch = useDispatch();
  const { reportId } = useParams();

  const [report, setReport] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [mainPhotoIndex, setMainPhotoIndex] = useState(0); //eslint-disable-line
  const [selectedHistoryOption, setSelectedHistoryOption] = useState('ALL');
  const [isChartsBuilt, setIsChartsBuilt] = useState(false);

  const componentRef = useRef();

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    }
  }, []);//eslint-disable-line

  useEffect(() => {
    if (reportId) {
      getReport();
    }
  }, [reportId]);//eslint-disable-line

  useEffect(() => {
    if (isJustDataOperations(user, true)) history.push('/deals-management');
  }, [user]);//eslint-disable-line

  const getReport = () => {
    setIsLoading(true);
    dispatch(getPropertyReport({
      reportId,
      userId: selectedUser?.id ?? undefined,
      onSuccess: (data) => {
        if (data.reportStatus !== 'Success') {
          displayErrorMessage(data, 'The property report hasn\'t been prepared yet');
          history.push(`/property-reports`)
        }
        setReport(data);
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          buildGraphics(data);
        }, 1500);
        setIsLoading(false);
      },
      onError: (data) => {
        displayErrorMessage(data, 'Unable to retrieve property report');
        setIsLoading(false);
      }
    }));
  };

  const getFirstNumber = (label, isAsc) => {
    const regexp = /(\d+|[a-z-+ /,.]+)/g;
    const tokens = [...label.match(regexp)];
    return isAsc ? (!isNaN(Number(tokens[0])) ? Number(tokens[0]) : +1e10) : (!isNaN(Number(tokens[0])) ? Number(tokens[0]) : -1);
  }

  const prepareDemographicValues = (demItem) => {
    let result = { series: [], labels: [] };
    let entry = demItem.data;
    let items = entry.items ?? [];
    if (demItem.sortByLabel) {
      if (!!demItem.ascendant) {
        items = items.sort((a, b) => getFirstNumber(a.label, true) - getFirstNumber(b.label, true));
      } else {
        items = items.sort((a, b) => getFirstNumber(b.label) - getFirstNumber(a.label));
      }
    }

    const itemsOriginalLength = items.length;
    const total = entry.total;

    if (itemsOriginalLength > MAX_DEMOGRAPHICS_ITEMS) {
      items = !demItem.sortByLabel
        ? (items.filter((it) => it.label !== OTHER_LABEL && it.label !== BORN_ELSEWHERE).sort((a, b) => b.value - a.value).slice(0, MAX_DEMOGRAPHICS_ITEMS - 1))
        : (items.slice(0, MAX_DEMOGRAPHICS_ITEMS - 1));
      const other = items.reduce((acc, it) => acc - it.value, total);
      if (other > 0) items.push({ value: other, label: OTHER_LABEL });
    }

    result.labels = items.map((it) => it.label);
    result.series.push({data: items.map((it) => it.value), className: styles[`pie_chart_series_color_0`], year: entry.year, meta: 'value is:'});

    return result;
  };

  const getShortenedAmount = (amount, emptyLabel = '-') => {
    if (typeof amount !== 'number') return emptyLabel;
    const sign = amount < 0 ? '-' : '';
    const absAmount = Math.abs(amount);

    if (absAmount >= 1000000) {
      return `${sign}$${(Math.floor(absAmount / 10000) / 100).toFixed(2)}M`;
    } else if (absAmount >= 100000) {
      return `${sign}$${(Math.floor(absAmount / 1000)).toFixed(0)}k`;
    } else {
      return `${sign}$${addCommas((Number(absAmount)).toFixed(0))}`
    }
  };

  const buildGraphics = (report) => {
    //const priceEstimateObj = report.report.priceEstimate ?? {};
    const demographicsArray = report.report.demographics ?? [];
    const suburbPerformanceStatisticsHouse = report.report.suburbPerformanceStatisticsHouse?.series ?? [];
    const suburbPerformanceStatisticsUnit = report.report.suburbPerformanceStatisticsUnit?.series ?? [];

    /*if (!!priceEstimateObj?.history?.length > 1) {
      new LineChart(
        '#estimatedPriceHistory',
        {
          series: [
            {
              name: 'lower',
              data: priceEstimateObj?.history.map((it) => { return { x: new Date(it.date), y: it.lowerPrice }; }),
              className: 'tertiaryColor_chart'
            },
            {
              name: 'mid',
              data: priceEstimateObj?.history.map((it) => { return { x: new Date(it.date), y: it.midPrice }; }),
              className: 'tertiaryColor_chart'
            },
            {
              name: 'upper',
              data: priceEstimateObj?.history.map((it) => { return { x: new Date(it.date), y: it.upperPrice }; }),
              className: 'tertiaryColor_chart'
            }
          ]
        },
        {
          axisX: {
            type: AutoScaleAxis,
            divisor: 3,
            labelInterpolationFnc: value =>
              format(new Date(value), 'dd.MM.yy')
          },
          chartPadding: CHART_PADDING,
        }
      );
    }*/

    let formattedDemographicsArray = getFormattedDemographicsArray(demographicsArray ?? []);

    if (!!formattedDemographicsArray?.length) {
      formattedDemographicsArray.forEach((demItem, index) => {

        const demData = prepareDemographicValues(demItem);
        const data = {
          labels: demData.labels,
          series: demData.series,
        };

        const options = {
          seriesBarDistance: 28,
          axisX: {
            offset: 30
          },
          axisY: {
            offset: 20,
          },
          plugins: [
            ChartistTooltip({
              //anchorToPoint: true,
              appendToBody: true,
              /*transformTooltipTextFnc: (tooltip) => {
                console.log('!!!Tooltip:', tooltip);
                /!*const xy = tooltip.split(",");
                return `$${addCommas((Number(xy[1])).toFixed(0))} (year: ${xy[0]})`;*!/
                return tooltip;
              },*/
            })
          ]
        };

        const optionsPrint = {
          seriesBarDistance: 40,
          axisX: {
            offset: 30
          },
          axisY: {
            offset: 20,
          },
        };

        const responsiveOptions = [
          ['screen and (min-width: 883px) and (max-width: 960px)', { seriesBarDistance: 15 }],
          ['screen and (min-width: 481px) and (max-width: 882px)', { seriesBarDistance: 20 }],
          ['screen and (min-width: 321px) and (max-width: 480px)', { seriesBarDistance: 15, axisX: { offset: 40 } }],
          ['screen and (max-width: 320px)', { seriesBarDistance: 10, axisX: { offset: 50 } }]
        ];

        new BarChart(`#demographics_${index}_chartWrapper`, data, options, responsiveOptions).on('draw', data => {
          if (data.type === 'bar') {
            data.element.attr({
              style: 'stroke-width: 28px'
            });
          }
        });
        new BarChart(`#print_demographics_${index}_chartWrapper`, data, optionsPrint).on('draw', data => {
          if (data.type === 'bar') {
            data.element.attr({
              style: 'stroke-width: 40px'
            });
          }
        });
      });
    }


    if (suburbPerformanceStatisticsHouse?.length > 1 || suburbPerformanceStatisticsUnit?.length > 1) {
      if (suburbPerformanceStatisticsHouse.filter((s) => !!s.values?.medianSoldPrice)?.length > 1 || suburbPerformanceStatisticsUnit.filter((s) => !!s.values?.medianSoldPrice)?.length > 1) {
        const data = {
          series: [
            {
              name: 'Median sold prices (house)',
              data: suburbPerformanceStatisticsHouse.map((it) => {
                return { x: it.year, y: it.values?.medianSoldPrice };
              }),
              className: styles['pie_chart_series_color_0']
            },
            {
              name: 'Median sold prices (unit)',
              data: suburbPerformanceStatisticsUnit.map((it) => {
                return { x: it.year, y: it.values?.medianSoldPrice };
              }),
              className: styles['pie_chart_series_color_1']
            }
          ]
        };
        const options = {
          axisX: {
            type: AutoScaleAxis,
            onlyInteger: true,
            labelInterpolationFnc: value => value
          },
          axisY: {
            onlyInteger: true,
            type: AutoScaleAxis,
            labelInterpolationFnc: value => getShortenedAmount(value)
          },
          chartPadding: CHART_PADDING,
          lineSmooth: Interpolation.monotoneCubic({
            fillHoles: true
          }),
          plugins: [
            ChartistTooltip({
              anchorToPoint: true,
              appendToBody: true,
              transformTooltipTextFnc: (tooltip) => {
                const xy = tooltip.split(",");
                return `$${addCommas((Number(xy[1])).toFixed(0))} (year: ${xy[0]})`;
              },
            })
          ]
        };

        new LineChart('#suburbPerformance_SoldPrices', data, options);
        new LineChart('#print_suburbPerformance_SoldPrices', data, options);
      }

      if (suburbPerformanceStatisticsHouse.filter((s) => !!s.values?.numberSold)?.length > 1 || suburbPerformanceStatisticsUnit.filter((s) => !!s.values?.numberSold)?.length > 1) {
        const data = {
          series: [
            {
              name: 'Houses sold',
              data: suburbPerformanceStatisticsHouse.map((it) => {
                return { x: it.year, y: it.values?.numberSold };
              }),
              className: styles['pie_chart_series_color_0']
            },
            {
              name: 'Units sold',
              data: suburbPerformanceStatisticsUnit.map((it) => {
                return { x: it.year, y: it.values?.numberSold };
              }),
              className: styles['pie_chart_series_color_1']
            }
          ]
        };
        const options = {
          axisX: {
            type: AutoScaleAxis,
            onlyInteger: true,
            labelInterpolationFnc: value => value
          },
          axisY: {
            onlyInteger: true,
            type: AutoScaleAxis,
            labelInterpolationFnc: value => value
          },
          chartPadding: CHART_PADDING,
          lineSmooth: Interpolation.monotoneCubic({
            fillHoles: true
          }),
          plugins: [
            ChartistTooltip({
              anchorToPoint: true,
              appendToBody: true,
              transformTooltipTextFnc: (tooltip) => {
                const xy = tooltip.split(",");
                return `${xy[1]} (year: ${xy[0]})`;
              },
            })
          ]
        };

        new LineChart('#suburbPerformance_PropertiesSold', data, options);
        new LineChart('#print_suburbPerformance_PropertiesSold', data, options);
      }

      if (suburbPerformanceStatisticsHouse.filter((s) => !!s.values?.auctionNumberAuctioned)?.length > 1 || suburbPerformanceStatisticsUnit.filter((s) => !!s.values?.auctionNumberAuctioned)?.length > 1) {
        const data = {
          series: [
            {
              name: 'Auction clearance (house)',
              data: suburbPerformanceStatisticsHouse.map((it) => {
                return { x: it.year, y: !!it.values?.auctionNumberAuctioned ? 100 * (it.values.auctionNumberSold ?? 0) / it.values.auctionNumberAuctioned : undefined };
              }),
              className: styles['pie_chart_series_color_0']
            },
            {
              name: 'Auction clearance (unit)',
              data: suburbPerformanceStatisticsUnit.map((it) => {
                return { x: it.year, y: !!it.values?.auctionNumberAuctioned ? 100 * (it.values.auctionNumberSold ?? 0) / it.values.auctionNumberAuctioned : undefined };
              }),
              className: styles['pie_chart_series_color_1']
            }
          ]
        };
        const options = {
          axisX: {
            type: AutoScaleAxis,
            onlyInteger: true,
            labelInterpolationFnc: value => value
          },
          axisY: {
            type: AutoScaleAxis,
            labelInterpolationFnc: value => `${value}%`
          },
          chartPadding: CHART_PADDING,
          lineSmooth: Interpolation.monotoneCubic({
            fillHoles: true
          }),
          plugins: [
            ChartistTooltip({
              anchorToPoint: true,
              appendToBody: true,
              transformTooltipTextFnc: (tooltip) => {
                const xy = tooltip.split(",");
                return `${Number(xy[1]).toFixed(2)}% (year: ${xy[0]})`;
              },
            })
          ]
        };

        new LineChart('#suburbPerformance_AuctionClearance', data, options);
        new LineChart('#print_suburbPerformance_AuctionClearance', data, options);
      }

      if (suburbPerformanceStatisticsHouse.filter((s) => !!s.values?.daysOnMarket)?.length > 1 || suburbPerformanceStatisticsUnit.filter((s) => !!s.values?.daysOnMarket)?.length > 1) {
        const data = {
          series: [
            {
              name: 'Days on market (house)',
              data: suburbPerformanceStatisticsHouse.map((it) => {
                return { x: it.year, y: it.values?.daysOnMarket };
              }),
              className: styles['pie_chart_series_color_0']
            },
            {
              name: 'Days on market (unit)',
              data: suburbPerformanceStatisticsUnit.map((it) => {
                return { x: it.year, y: it.values?.daysOnMarket };
              }),
              className: styles['pie_chart_series_color_1']
            }
          ]
        };
        const options = {
          axisX: {
            type: AutoScaleAxis,
            onlyInteger: true,
            labelInterpolationFnc: value => value
          },
          axisY: {
            onlyInteger: true,
            type: AutoScaleAxis,
            labelInterpolationFnc: value => value
          },
          chartPadding: CHART_PADDING,
          lineSmooth: Interpolation.monotoneCubic({
            fillHoles: true
          }),
          plugins: [
            ChartistTooltip({
              anchorToPoint: true,
              appendToBody: true,
              transformTooltipTextFnc: (tooltip) => {
                const xy = tooltip.split(",");
                return `${xy[1]} day${xy[1] !== '1' ? 's' : ''} (year: ${xy[0]})`;
              },
            })
          ]
        };

        new LineChart('#suburbPerformance_DaysOnMarket', data, options);
        new LineChart('#print_suburbPerformance_DaysOnMarket', data, options);
      }
    }

    setIsChartsBuilt(true);
  };

  const getPopulation = (demographicsArray) => {
    return demographicsArray.sort((a, b) => b.year - a.year).find((it) => it.type === 'AgeGroupOfPopulation');
  };

  const getFormattedDemographicsArray = (demographicsArray) => {
    let chartsToDisplay = [];
    const sortedArray = demographicsArray.sort((a, b) => b.year - a.year);

    const dataAgeGroupOfPopulation = sortedArray.find((it) => it.type === 'AgeGroupOfPopulation');
    if (!!dataAgeGroupOfPopulation) chartsToDisplay.push({ type: 'AgeGroupOfPopulation', title: 'Age group of population', data: dataAgeGroupOfPopulation, sortByLabel: true, ascendant: true });

    const dataHouseholdIncome = sortedArray.find((it) => it.type === 'HouseholdIncome');
    if (!!dataHouseholdIncome) chartsToDisplay.push({ type: 'HouseholdIncome', title: 'Household income', data: dataHouseholdIncome, sortByLabel: true });

    const dataFamilyComposition = sortedArray.find((it) => it.type === 'FamilyComposition');
    if (!!dataFamilyComposition) chartsToDisplay.push({ type: 'FamilyComposition', title: 'Family composition', data: dataFamilyComposition });

    const dataDwellingStructure = sortedArray.find((it) => it.type === 'DwellingStructure');
    if (!!dataDwellingStructure) chartsToDisplay.push({ type: 'DwellingStructure', title: 'Dwelling structure', data: dataDwellingStructure });

    const dataRent = sortedArray.find((it) => it.type === 'Rent');
    if (!!dataRent) chartsToDisplay.push({ type: 'Rent', title: 'Rent', data: dataRent, sortByLabel: true });

    const dataTransportToWork = sortedArray.find((it) => it.type === 'TransportToWork');
    if (!!dataTransportToWork) chartsToDisplay.push({ type: 'TransportToWork', title: 'Transport to work', data: dataTransportToWork });

    const dataOccupation = sortedArray.find((it) => it.type === 'Occupation');
    if (!!dataOccupation) chartsToDisplay.push({ type: 'Occupation', title: 'Occupation', data: dataOccupation });

    const dataNatureOfOccupancy = sortedArray.find((it) => it.type === 'NatureOfOccupancy');
    if (!!dataNatureOfOccupancy) chartsToDisplay.push({ type: 'NatureOfOccupancy', title: 'Nature of occupancy', data: dataNatureOfOccupancy });

    return chartsToDisplay;
  }

  const renderMarketPerformance = (suburbPerformanceStatisticsHouse, suburbPerformanceStatisticsUnit) => {
    const propertyObj = report.report.property;
    let series = [];

    const generateDescription = (type, field, textBeginning) => {
      const filteredHouse = suburbPerformanceStatisticsHouse.filter((s) => !!s.values?.[field]);
      const filteredUnit = suburbPerformanceStatisticsUnit.filter((s) => !!s.values?.[field]);
      const houseLength = filteredHouse?.length;
      const unitLength = filteredUnit?.length;
      let description = {};

      const putDescriptionField = (lastItem, prevItem, propertyType) => {
        let lastValue, diff;

        if (type !== 'clearance') {
          lastValue = type === 'amount' ? getShortenedAmount(lastItem.values[field]) : type === 'value' ? lastItem.values[field] : lastItem.values[field];
          diff = (((lastItem.values[field] - prevItem.values[field]) / prevItem.values[field]) * 100).toFixed(2);
        } else {
          const lastClearance = 100 * (lastItem.values.auctionNumberSold ?? 0) / lastItem.values[field];
          const prevClearance = 100 * (prevItem.values.auctionNumberSold ?? 0) / prevItem.values[field];
          lastValue = `${lastClearance.toFixed(2)}%`;
          diff = (((lastClearance - prevClearance) / prevClearance) * 100).toFixed(2);
        }

        const diffValue = `${diff}%`;
        description[propertyType] = {
          lastValue,
          diffValue,
          text: isNaN(diff) ? '' : `${textBeginning} for ${propertyType}s in ${propertyObj.address?.locality} went ${Number(diff) < 0 ? 'down' : 'up'} ${Math.abs(Number(diff))}% in the last year.`
        };
      };

      if (houseLength > 1) {
        const lastItem = filteredHouse[houseLength - 1];
        const prevItem = filteredHouse[houseLength - 2];
        putDescriptionField(lastItem, prevItem, 'house');
      }

      if (unitLength > 1) {
        const lastItem = filteredUnit[unitLength - 1];
        const prevItem = filteredUnit[unitLength - 2];
        putDescriptionField(lastItem, prevItem, 'unit');
      }

      return description;
    }

    let descriptionSoldPrices, descriptionPropertiesSold, descriptionAuctionClearance, descriptionDaysOnMarket;

    if (suburbPerformanceStatisticsHouse.filter((s) => !!s.values?.medianSoldPrice)?.length > 1 || suburbPerformanceStatisticsUnit.filter((s) => !!s.values?.medianSoldPrice)?.length > 1) {
      descriptionSoldPrices = generateDescription('amount', 'medianSoldPrice', 'Median sale price');
      series.push({ id: 'suburbPerformance_SoldPrices', title: 'Median sale price', description: descriptionSoldPrices });
    }
    if (suburbPerformanceStatisticsHouse.filter((s) => !!s.values?.numberSold)?.length > 1 || suburbPerformanceStatisticsUnit.filter((s) => !!s.values?.numberSold)?.length > 1) {
      descriptionPropertiesSold = generateDescription('value', 'numberSold', 'Properties sold');
      series.push({ id: 'suburbPerformance_PropertiesSold', title: 'Properties sold', description: descriptionPropertiesSold });
    }
    if (suburbPerformanceStatisticsHouse.filter((s) => !!s.values?.auctionNumberAuctioned)?.length > 1 || suburbPerformanceStatisticsUnit.filter((s) => !!s.values?.auctionNumberAuctioned)?.length > 1) {
      descriptionAuctionClearance = generateDescription('clearance', 'auctionNumberAuctioned', 'Auction clearance');
      series.push({ id: 'suburbPerformance_AuctionClearance', title: 'Auction clearance', description: descriptionAuctionClearance });
    }
    if (suburbPerformanceStatisticsHouse.filter((s) => !!s.values?.daysOnMarket)?.length > 1 || suburbPerformanceStatisticsUnit.filter((s) => !!s.values?.daysOnMarket)?.length > 1) {
      descriptionDaysOnMarket = generateDescription('value', 'daysOnMarket', 'Average days on market');
      series.push({ id: 'suburbPerformance_DaysOnMarket', title: 'Average days on market', description: descriptionDaysOnMarket });
    }

    let insightsString = `Here you can see the historical performance of Oyster bary properties over the past ${config.PERFORMANCE_STATISTICS_YEARS} years.`
    + ` Let's take a look at the data for the most recent quarter. `;

    if (!!descriptionSoldPrices?.house) {
      const diffPercentage = Number(descriptionSoldPrices.house.diffValue.slice(0, -1));
      insightsString += `The house market ${diffPercentage < 0 ? 'decreased' : 'increased'} ${diffPercentage < 0 ? descriptionSoldPrices.house.diffValue.slice(1) : descriptionSoldPrices.house.diffValue} with a median sale price of ${descriptionSoldPrices.house.lastValue}`;
      if (!!descriptionPropertiesSold?.house) {
        insightsString += ` and a total of ${descriptionPropertiesSold.house.lastValue} house${descriptionPropertiesSold.house.lastValue === 1 ? '' : 's'} were sold`
      }
      insightsString += !!descriptionSoldPrices.unit ? ', while ' : '. ';
    } else if (!!descriptionPropertiesSold?.house) {
      insightsString += `A total of ${descriptionPropertiesSold.house.lastValue} unit${descriptionPropertiesSold.house.lastValue === 1 ? '' : 's'} were sold. `
    }

    if (!!descriptionSoldPrices?.unit) {
      const diffPercentage = Number(descriptionSoldPrices.unit.diffValue.slice(0, -1));
      insightsString += `${!!descriptionSoldPrices.house ? 't' : 'T'}he unit market ${diffPercentage < 0 ? 'decreased' : 'increased'} ${diffPercentage < 0 ? descriptionSoldPrices.unit.diffValue.slice(1) : descriptionSoldPrices.unit.diffValue} with a median sale price of ${descriptionSoldPrices.unit.lastValue}`;
      if (!!descriptionPropertiesSold?.unit) {
        insightsString += ` and a total of ${descriptionPropertiesSold.unit.lastValue} unit${descriptionPropertiesSold.unit.lastValue === 1 ? '' : 's'} were sold. `
      }
    } else if (!!descriptionPropertiesSold?.unit) {
      insightsString += `A total of ${descriptionPropertiesSold.unit.lastValue} unit${descriptionPropertiesSold.unit.lastValue === 1 ? '' : 's'} were sold. `
    }

    if (!!descriptionDaysOnMarket?.house) {
      insightsString += `The average days on market for houses was ${descriptionDaysOnMarket.house?.lastValue}`;
      insightsString += !!descriptionDaysOnMarket.unit ? ', while ' : '. ';
    }

    if (!!descriptionDaysOnMarket?.unit) {
      insightsString += `${!!descriptionDaysOnMarket.house ? 't' : 'T'}he average days on market for units was ${descriptionDaysOnMarket.house?.lastValue}. `;
    }

    if (!!descriptionAuctionClearance?.house) {
      insightsString += `The auction clearance rate for the year for houses was an average of ${descriptionAuctionClearance.house?.lastValue}`;
      insightsString += !!descriptionAuctionClearance.unit ? ', while ' : '. ';
    }

    if (!!descriptionAuctionClearance?.unit) {
      insightsString += `${!!descriptionAuctionClearance.house ? 't' : 'T'}he auction clearance rate for the year for units was an average of ${descriptionAuctionClearance.unit?.lastValue}.`;
    }

    const renderNoChartData = () => {
      return (<>
        <SpacerT s={ !!dimensions.isScreenBiggerSM ? 2 : 1 } />
        <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
          <Grid item className={styles.value}>NA</Grid>
        </Grid>
        <SpacerT s={ !!dimensions.isScreenBiggerSM ? 2 : 1 } />
        <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
          <Grid item className={styles.legendLabel}>Data is not available for this type.</Grid>
        </Grid>
      </>);
    };

    const renderNoChartDataPrint = () => {
      return (<>
        <SpacerT s={ 1 } />
        <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
          <Grid item className={styles.value}>NA</Grid>
        </Grid>
        <SpacerT s={ 1 } />
        <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
          <Grid item className={styles.legendLabel}>Data is not available for this type.</Grid>
        </Grid>
      </>);
    };

    return (<>
      <SpacerT s={2} />
      <Grid container justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
        <Grid item className={`${styles.label}`}>
          {insightsString}
        </Grid>
      </Grid>

      {series.map((it, idx) => { return (<React.Fragment key={idx}>
        <SpacerT s={3} np={idx !== 0} />
        {idx !== 0 && (<div className="page-break" />)}
        <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
          <Grid item className={`${styles.blockSubTitle}`}>
            {it.title}
          </Grid>
        </Grid>

        <SpacerT s={ !!dimensions.isScreenBiggerSM ? 2 : 1 } np />
        <div className={styles.notToPrintBlock}>
          <Grid container justifyContent={'flex-start'} alignItems={'flex-start'} alignContent={'flex-start'} spacing={3} direction={dimensions.isScreenBiggerSM ? 'row' : 'column-reverse'}>
            <Grid item container xs={12} md={5} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
              <SpacerT s={ !!dimensions.isScreenBiggerSM ? 2 : 1 } />

              <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
                <Grid item className={styles.houseTitle}>House</Grid>
              </Grid>
              {!!it.description?.house ? (<>
                <SpacerT s={ !!dimensions.isScreenBiggerSM ? 2 : 1 } />
                <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                  <Grid item className={styles.value}>{it.description.house.lastValue}</Grid><Grid item className={styles.value}>{
                    it.description.house.diffValue !== 'NaN%' ? it.description.house.diffValue : ''
                  }</Grid>
                </Grid>
                <SpacerT s={ !!dimensions.isScreenBiggerSM ? 2 : 1 } />
                <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
                  <Grid item className={styles.legendLabel}>{it.description.house.text}</Grid>
                </Grid>
              </>) : (<>
                {renderNoChartData()}
              </>)}

              <SpacerT s={ !!dimensions.isScreenBiggerSM ? 2 : 1 } />
              <SpacerT s={ !!dimensions.isScreenBiggerSM ? 2 : 1 } np />
              <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
                <Grid item className={styles.unitTitle}>Unit</Grid>
              </Grid>
              {!!it.description?.unit ? (<>
                <SpacerT s={ !!dimensions.isScreenBiggerSM ? 2 : 1 } />
                <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                  <Grid item className={styles.value}>{it.description.unit.lastValue}</Grid><Grid item className={styles.value}>{
                    it.description.unit.diffValue !== 'NaN%' ? it.description.unit.diffValue : ''
                  }</Grid>
                </Grid>
                <SpacerT s={ !!dimensions.isScreenBiggerSM ? 2 : 1 } />
                <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
                  <Grid item className={styles.legendLabel}>{it.description.unit.text}</Grid>
                </Grid>
              </>) : (<>
                {renderNoChartData()}
              </>)}
            </Grid>

            <Grid item xs={12} md={7} className={'marketPerformance_chartWrapper'}>
              <div id={it.id} className={styles.fullWidthChart} />
              <Grid container justifyContent={'center'} alignItems={'center'} alignContent={'center'} spacing={1}>
                {!!it.description?.house && (<>
                  <Grid item><div className={`${styles.colorRect} ${styles[`pie_chart_series_color_0`]}`}/></Grid>
                  <Grid item><span className={styles.legendLabel}>House</span></Grid>
                </>)}
                {!!it.description?.unit && (<>
                  <Grid item><div className={`${styles.colorRect} ${styles[`pie_chart_series_color_1`]} ${styles.ml}`}/></Grid>
                  <Grid item><span className={styles.legendLabel}>Unit</span></Grid>
                </>)}
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={styles.toPrintOnlyBlock}>
          <Grid container justifyContent={'center'} alignItems={'center'} alignContent={'center'} spacing={3} direction={'column-reverse'}>
            <Grid item container xs={12} justifyContent={'space-between'} alignContent={'center'} alignItems={'center'}>
              <SpacerT s={ 1 } />

              <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
                <Grid item className={styles.houseTitle}>House</Grid>
              </Grid>
              {!!it.description?.house ? (<>
                <SpacerT s={ 1 } />
                <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                  <Grid item className={styles.value}>{it.description.house.lastValue}</Grid><Grid item className={styles.value}>{
                    it.description.house.diffValue !== 'NaN%' ? it.description.house.diffValue : ''
                  }</Grid>
                </Grid>
                <SpacerT s={ 1 } />
                <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
                  <Grid item className={styles.legendLabel}>{it.description.house.text}</Grid>
                </Grid>
              </>) : (<>
                {renderNoChartDataPrint()}
              </>)}

              <SpacerT s={ 1 } />
              <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
                <Grid item className={styles.unitTitle}>Unit</Grid>
              </Grid>
              {!!it.description?.unit ? (<>
                <SpacerT s={ 1 } />
                <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                  <Grid item className={styles.value}>{it.description.unit.lastValue}</Grid><Grid item className={styles.value}>{
                    it.description.unit.diffValue !== 'NaN%' ? it.description.unit.diffValue : ''
                  }</Grid>
                </Grid>
                <SpacerT s={ 1 } />
                <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
                  <Grid item className={styles.legendLabel}>{it.description.unit.text}</Grid>
                </Grid>
              </>) : (<>
                {renderNoChartDataPrint()}
              </>)}
            </Grid>

            <Grid item xs={12} className={'marketPerformance_chartWrapper'}>
              <div id={`print_${it.id}`} className={styles.fullWidthChartPrint} />
              <Grid container justifyContent={'center'} alignItems={'center'} alignContent={'center'} spacing={1}>
                {!!it.description?.house && (<>
                  <Grid item><div className={`${styles.colorRect} ${styles[`pie_chart_series_color_0`]}`}/></Grid>
                  <Grid item><span className={styles.legendLabel}>House</span></Grid>
                </>)}
                {!!it.description?.unit && (<>
                  <Grid item><div className={`${styles.colorRect} ${styles[`pie_chart_series_color_1`]} ${styles.ml}`}/></Grid>
                  <Grid item><span className={styles.legendLabel}>Unit</span></Grid>
                </>)}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>); })}
    </>);
  };

  const renderSchool = (school, key) => {
    const profile = school.school.profile;

    return (<Grid container justifyContent={'space-between'} key={key} className={styles.schoolWrapper}>
      <Grid item xs={12} className={`${styles.value} ${!!profile?.url ? styles.link : ''}`}>
        {!!profile?.url ? (
          <a href={profile.url} target={'_blank'} rel="noreferrer">{school.school.name}</a>
        ) : (<>
          {school.school.name}
        </>)}
      </Grid>

      <Grid item container xs={12} justifyContent={'space-between'} spacing={2} className={styles.mt0}>
        <Grid item className={styles.label} xs={4}>{school.school?.suburb ? `${school.school.suburb}, ` : ''}{school.school?.state ?? ''} {school.school?.postcode ?? ''}</Grid>
      </Grid>
      <Grid item container xs={12} justifyContent={'space-between'} spacing={2} className={styles.mt1}>
        <Grid item xs={4}><span className={styles.label}>Type:</span> <span className={styles.value}>{school.school?.schoolType ?? '-'}</span></Grid>
        <Grid item xs={4}><span className={styles.label}>Sector:</span> <span className={styles.value}>{school.school?.schoolSector ?? '-'}</span></Grid>
        <Grid item xs={4}><span className={styles.label}>Distance:</span> <span className={styles.value}>{school.distance ? `${Math.round(school.distance)} m` : '-'}</span></Grid>
      </Grid>
      <Grid item container xs={12} justifyContent={'space-between'} spacing={2} >
        <Grid item xs={4}><span className={styles.label}>Year range:</span> <span className={styles.value}>{profile?.yearRange ?? '-'}</span></Grid>
        <Grid item xs={4}><span className={styles.label}>Gender:</span> <span className={styles.value}>{school.school?.gender ?? '-'}</span></Grid>
        <Grid item xs={4}><span className={styles.label}>School rating (ICSEA):</span> <span className={styles.value}>{profile?.icsea ?? '-'}</span></Grid>
      </Grid>
    </Grid>);
  };

  const renderPriceLine = () => {
    if (!dimensions.isScreenBiggerSM) return null;

    return (
      <Grid item xs={1}>
        <div className={styles.lineHolder} />
      </Grid>
    );
  };

  const renderListingTable = (listing, type) => {

    return (<>
      <Grid container justifyContent="center">
        <Grid item xs={12} className={`SalesHistoryTableInPropertyReport`}>
          {!listing.length ? (
            <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
              <Grid item className={styles.notDefined}>
                No {type === 'SOLD' ? 'sales' : 'rental'} history available
              </Grid>
            </Grid>
          ) : (
            <TableContainer className={`${styles.tableContainer} ${styles.noMinHeight}`}>
              <Table size={dimensions.isScreenBiggerSM ? "medium" : "small"} stickyHeader>
                <TableHead>
                  <TableRow className={styles.tableHead}>
                    <TableCell align={'left'} style={{ minWidth: 140, maxWidth: 140 }}>PHOTO</TableCell>
                    <TableCell align={'left'} style={{ minWidth: 56 }}>DATE</TableCell>
                    <TableCell align={'left'} style={{ minWidth: 70 }}>TYPE</TableCell>
                    <TableCell align={'left'} style={{ minWidth: 140 }}>ADDRESS</TableCell>
                    <TableCell align={'left'} style={{ minWidth: 64 }}>PRICE</TableCell>
                    <TableCell align={'left'} className={styles.notToPrint} style={{ minWidth: 168 }}>DETAILS</TableCell>
                    <TableCell align={'left'} className={styles.toPrintOnly} style={{ minWidth: 48 }}>DETAILS</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={styles.tableBody}>
                  {listing.map((it, idx) => {
                    return (
                      <TableRow className={styles.tableItem} key={idx}>
                        <TableCell align={'left'} className={styles.photo}>
                          <img src={it.photoURLs?.[0]} alt={'home'} />
                        </TableCell>
                        <TableCell align={'left'} className={styles.value}>
                          {!!it.dateListed ? format(new Date(it.dateListed), 'dd.MM.yyyy') : '-'}
                        </TableCell>
                        <TableCell align={'left'}>
                          <div className={type === 'SOLD' ? styles.sold : styles.rented}>{type}</div>
                        </TableCell>
                        <TableCell align={'left'} className={styles.value}>
                          {it?.propertyDetails?.displayableAddress}
                        </TableCell>
                        <TableCell align={'left'} className={styles.value}>
                          {getShortenedAmount(it.priceDetails?.price, it.priceDetails?.displayPrice ?? 'Not disclosed')}{it.hType === 'RENTED' && !!it.priceDetails?.price ? ' per week' : ''}
                        </TableCell>
                        <TableCell align={'left'} className={styles.label}>
                          <span className={styles.notToPrint}>{it.propertyDetails?.bedrooms ?? '-'} <Bed className={`${styles.iconSmall}`} /> | {it.propertyDetails?.bathrooms ?? '-'} <Bath className={`${styles.iconSmall} ${styles.bath}`} /> | {it.propertyDetails?.carspaces ?? '-'} <Car className={`${styles.iconSmall} ${styles.car}`} /></span>
                          <span className={styles.toPrintOnly}><div>{it.propertyDetails?.bedrooms ?? '-'} <Bed className={`${styles.iconSmall}`} /></div><div>{it.propertyDetails?.bathrooms ?? '-'} <Bath className={`${styles.iconSmall} ${styles.bath}`} /></div><div>{it.propertyDetails?.carspaces ?? '-'} <Car className={`${styles.iconSmall} ${styles.car}`} /></div></span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </>);
  };

  const renderDemCharts = (demographicsArray) => {
    const formattedArray = getFormattedDemographicsArray(demographicsArray ?? []);
    const halfLength = Math.ceil(formattedArray?.length / 2);
    let pairedArrays = [];
    for (let i = 0; i < halfLength; i++) {
      let pair = [];
      if (formattedArray[2 * i]) pair.push(formattedArray[2 * i]);
      if (formattedArray[2 * i + 1]) pair.push(formattedArray[2 * i + 1]);
      pairedArrays.push(pair);
    }

    return (<>
      <SpacerT s={3} />
      {pairedArrays.map((pair, idx1) => {
        if (!pair[0]) return null;

        return (<React.Fragment key={idx1}>
          {idx1 > 0 && (<div className="page-break" />)}

          <div className={styles.notToPrintBlock}>
            <Grid container justifyContent="flex-start" alignItems={'flex-start'} alignContent={'flex-start'} spacing={2} key={idx1}>
              {pair.map((demItem, idx2) => {
                /*const demData = prepareDemographicValues(demItem);

                const data = {
                  labels: demData.labels,
                  series: demData.series,
                };

                const options = {
                  seriesBarDistance: 20,
                  axisX: {
                    offset: 20
                  },
                  axisY: {
                    offset: 40,
                  },
                  plugins: [
                    ChartistTooltip({
                      //anchorToPoint: true,
                      appendToBody: true,
                      /!*transformTooltipTextFnc: (tooltip) => {
                        console.log('!!!Tooltip:', tooltip);
                        /!*const xy = tooltip.split(",");
                        return `$${addCommas((Number(xy[1])).toFixed(0))} (year: ${xy[0]})`;*!/
                        return tooltip;
                      },*!/
                    })
                  ]
                };

                const responsiveOptions = [
                  ['screen and (min-width: 883px) and (max-width: 960px)', { seriesBarDistance: 15 }],
                  ['screen and (min-width: 481px) and (max-width: 882px)', { seriesBarDistance: 20 }],
                  ['screen and (min-width: 321px) and (max-width: 480px)', { seriesBarDistance: 15, axisY: { offset: 60 } }],
                  ['screen and (max-width: 320px)', { seriesBarDistance: 10, axisY: { offset: 60 } }]
                ];*/

                return (<React.Fragment key={idx2}>
                  <Grid item container xs={12} md={6}>
                    <Grid item xs={12} className={`${styles.label} ${styles.centered}`}>
                      {demItem.title} ({demItem.data.year})
                    </Grid>
                    <Grid item xs={12} className={'demographics_chartWrapper'}>
                      {/*<ChartistGraph data={data} options={options} type={"Bar"} responsiveOptions={responsiveOptions} />*/}
                      <div id={`demographics_${idx1 * 2 + idx2}_chartWrapper`} className={styles.fullWidthPieChart} />
                      <SpacerB s={5} />
                    </Grid>
                  </Grid>
                </React.Fragment>);
              })}
            </Grid>
          </div>

          <div className={styles.toPrintOnlyBlock}>
            <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'} spacing={2} key={idx1}>
              {pair.map((demItem, idx2) => {
                return (<React.Fragment key={idx2}>
                  <Grid item container xs={12}>
                    <Grid item xs={12} className={`${styles.label} ${styles.centered}`}>
                      {demItem.title} ({demItem.data.year})
                    </Grid>
                    <Grid item xs={12} className={'demographics_chartWrapper'}>
                      <div id={`print_demographics_${idx1 * 2 + idx2}_chartWrapper`} className={styles.fullWidthPieChartPrint} />
                      <SpacerB s={4} />
                    </Grid>
                  </Grid>
                </React.Fragment>);
              })}
            </Grid>
          </div>
        </React.Fragment>);
      })}
    </>);
  };

  const renderContent = () => {
    const propertyObj = report.report.property;

    const dateSorting = (a, b) => (`${b.date}`).localeCompare(a.date);
    const dateListedSorting = (a, b) => (`${b.dateListed}`).localeCompare(a.dateListed);
    const salesHistory = (propertyObj.sales ?? []).filter((it) => !!it.reportedAsSold || !!it.documentedAsSold).map((it) => { return { hType: 'SOLD', ...it }; }).sort(dateSorting);
    const rentalsHistory = (propertyObj.rentals ?? []).map((it) => { return { hType: 'RENTED', ...it }; }).sort(dateSorting);
    const combinedHistory = [...rentalsHistory, ...salesHistory].sort(dateSorting);

    const priceEstimateObj = report.report.priceEstimate ?? {};
    const demographicsArray = report.report.demographics ?? [];
    const propertyCreateDate = report.updatedAt ?? report.createdAt;
    const isPriceEstimated = priceEstimateObj?.lowerPrice || priceEstimateObj?.midPrice || priceEstimateObj?.upperPrice;
    const suburbPerformanceStatisticsHouse = report.report.suburbPerformanceStatisticsHouse?.series ?? [];
    const suburbPerformanceStatisticsUnit = report.report.suburbPerformanceStatisticsUnit?.series ?? [];

    const schoolsArray = report.report.schools ?? [];
    const rentListingsArray = (report.report.rentListings ?? []).sort(dateListedSorting).slice(0, 10);
    const soldListingsArray = (report.report.soldListings ?? []).sort(dateListedSorting).slice(0, 10);

    const population = getPopulation(demographicsArray);

    const renderHistoryTableHeader = () => {
      return (
        <TableHead>
          <TableRow className={styles.tableHead}>
            <TableCell className={styles.fixedQuarter} align={'left'}>DATE</TableCell>
            <TableCell className={styles.fixedQuarter} align={'left'}>TYPE</TableCell>
            <TableCell className={styles.fixedQuarter} align={'left'}>PRICE</TableCell>
            <TableCell className={styles.fixedQuarter} align={'left'}>DAYS ON MARKET</TableCell>
          </TableRow>
        </TableHead>
      );
    };

    const renderHistoryTableRow = (it) => {
      return (
        <TableRow className={styles.tableItem}>
          <TableCell align={'left'} className={styles.value}>
            {!!it.date ? format(new Date(it.date), 'dd.MM.yyyy') : '-'}
          </TableCell>
          <TableCell align={'left'}>
            <div className={it.hType === 'SOLD' ? styles.sold : styles.rented}>{it.hType}</div>
          </TableCell>
          <TableCell align={'left'} className={styles.value}>
            {getShortenedAmount(it.price, 'Not disclosed')}{it.hType === 'RENTED' && !!it.price ? ' per week' : ''}
          </TableCell>
          <TableCell align={'left'} className={styles.value}>
            {!!it.daysOnMarket ? it.daysOnMarket : '-'}
          </TableCell>
        </TableRow>
      );
    };

    return (<>
      <div
        className={styles.mainImageWrapper}
        style={{ backgroundImage: !!propertyObj.photos?.[mainPhotoIndex]?.url ? `url(${propertyObj.photos[mainPhotoIndex].url})` : 'none' }}
      >
        <div className={styles.mainImageOverlay}>
          <img className={styles.logo} src="/logo.svg" alt="Domium logo" />

          {(!!propertyObj.bathrooms || !!propertyObj.bedrooms || !!propertyObj.carSpaces || propertyObj.carSpaces === 0 || !!propertyObj.propertyType) && (
            <Grid container justifyContent={'center'} alignItems={'center'} alignContent={'center'} className={styles.propertyInfoBar}>
              {!!propertyObj.bedrooms && (<Grid item className={styles.propertyInfoItem}>
                {propertyObj.bedrooms}{'  '}<BedW className={`${styles.imageIcon} ${styles.bed}`} />
              </Grid>)}
              {!!propertyObj.bathrooms && (<Grid item className={styles.propertyInfoItem}>
                {propertyObj.bathrooms}{'  '}<BathW className={styles.imageIcon} />
              </Grid>)}
              {(!!propertyObj.carSpaces || propertyObj.carSpaces === 0) && (<Grid item className={styles.propertyInfoItem}>
                {propertyObj.carSpaces}{'  '}<CarW className={`${styles.imageIcon} ${styles.car}`} />
              </Grid>)}
              {!!propertyObj.propertyType && (<Grid item className={`${styles.propertyInfoItem} ${styles.noBorder}`}>
                {propertyObj.propertyType}
              </Grid>)}
            </Grid>
          )}
        </div>

        <div className={styles.mainImageContent}>
          <Grid container item xs={12}>
            <Grid item xs={12} className={styles.title}>
              Property report
            </Grid>
            <Grid item xs={12} className={styles.imageAddress}>
              {propertyObj.address?.fullAddress?.split(', ')?.[0]}
            </Grid>
            <Grid item xs={12} className={styles.imageAddress2}>
              {propertyObj.address?.locality} {propertyObj.address?.state} {propertyObj.address?.postcode}
            </Grid>
            {!!propertyCreateDate && (
              <Grid item xs={12} className={styles.imageDate}>
                Prepared on: {format(new Date(propertyCreateDate), 'dd.MM.yyyy')}
              </Grid>
            )}
          </Grid>
        </div>
      </div>

      <div className={styles.mainContentWrapper}>
        <Grid container justifyContent="center">
          <Grid item className={styles.blockTitle}>
            Price estimate
          </Grid>
        </Grid>

        {!!isPriceEstimated ? (<>
          <Grid container justifyContent="center" alignItems={'flex-start'} alignContent={'flex-start'}>
            <Grid item container direction={'column'} spacing={1} className={styles.price} alignItems={'center'} alignContent={'center'} xs={4} sm={3} md={2}>
              <Grid item className={styles.subHeader}>
                LOW
              </Grid>
              <Grid item className={styles.valueBig}>
                {getShortenedAmount(priceEstimateObj?.lowerPrice)}
              </Grid>
            </Grid>
            {renderPriceLine()}
            <Grid item container direction={'column'} spacing={1} className={styles.price} alignItems={'center'} alignContent={'center'} xs={4} sm={3} md={2}>
              <Grid item className={`${styles.subHeader} ${styles.bold}`}>
                MID
              </Grid>
              <Grid item className={`${styles.valueBig} ${styles.bold}`}>
                {getShortenedAmount(priceEstimateObj?.midPrice)}
              </Grid>
            </Grid>
            {renderPriceLine()}
            <Grid item container direction={'column'} spacing={1} className={styles.price} alignItems={'center'} alignContent={'center'} xs={4} sm={3} md={2}>
              <Grid item className={styles.subHeader}>
                HIGH
              </Grid>
              <Grid item className={styles.valueBig}>
                {getShortenedAmount(priceEstimateObj?.upperPrice)}
              </Grid>
            </Grid>
          </Grid>

          {!!priceEstimateObj.priceConfidence && (<>
            <SpacerT s={2} />
            <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
              <Grid item className={styles.label}>
                Estimate confidence: <span className={`${styles.confidence} ${priceEstimateObj.priceConfidence === 'low' ? styles.bad : ''}`}>
              {priceEstimateObj.priceConfidence.toUpperCase()}
            </span>
              </Grid>
            </Grid>
          </>)}

          {!!priceEstimateObj.source && (<>
            <SpacerT s={1} />
            <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
              <Grid item className={`${styles.label} ${styles.light}`}>
                Source: <span className={`${styles.notDefined}`}>
              {priceEstimateObj.source.toUpperCase() === 'APM' ? 'Australian property monitors' : priceEstimateObj.source}
            </span>
              </Grid>
            </Grid>
          </>)}

          <SpacerT s={2} />
          <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
            <Grid item className={`${styles.caption}`}>
              This estimate is powered by Domain, a property analytics company. This estimate is based recent comparable sales in the area and can be outdated or inaccurate.
            </Grid>
          </Grid>

          {/*{!!priceEstimateObj?.history?.length > 1 && (
            <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
              <Grid item xs={6} className={'estimatedPriceHistory_chartWrapper'}>
                <div id={'estimatedPriceHistory'} className={styles.fullWidthChart} />
              </Grid>
            </Grid>
          )}*/}

        </>) : (
          <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
            <Grid item className={styles.notDefined}>
              No price estimation available
            </Grid>
          </Grid>
        )}

        <div className="page-break" />
        <Grid container justifyContent="center">
          <Grid item className={styles.blockTitle}>
            Property history
          </Grid>
        </Grid>

        {!!propertyObj.address?.fullAddress && (<>
          <SpacerT s={1} />
          <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
            <Grid item className={`${styles.value}`}>
              {propertyObj.address.fullAddress}
            </Grid>
          </Grid>
        </>)}

        {(!!propertyObj.propertyCategory || !!propertyObj.propertyType) && (<>
          <SpacerT s={1} />
          <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
            <Grid item className={`${styles.value}`}>
              <span className={styles.label}>Property type:</span> {!!propertyObj.propertyCategory ? propertyObj.propertyCategory : ''}{!!propertyObj.propertyCategory && !!propertyObj.propertyType ? '/' : ''}{propertyObj.propertyType}
            </Grid>
          </Grid>
        </>)}

        {(!!propertyObj.areaSize) && (<>
          <SpacerT s={1} />
          <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
            <Grid item className={`${styles.value}`}>
              <span className={styles.label}>Area size:</span> {propertyObj.areaSize} m<span className={styles.sup}>2</span>
            </Grid>
          </Grid>
        </>)}

        {(!!propertyObj.internalArea) && (<>
          <SpacerT s={1} />
          <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
            <Grid item className={`${styles.value}`}>
              <span className={styles.label}>Internal area:</span> {propertyObj.internalArea} m<span className={styles.sup}>2</span>
            </Grid>
          </Grid>
        </>)}

        <SpacerT s={1} />
        <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
          <Grid item className={`${styles.label}`}>
            {propertyObj.bedrooms ?? '-'} BEDS | {propertyObj.bathrooms ?? '-'} BATHS | {propertyObj.carSpaces ?? '-'} CARS
          </Grid>
        </Grid>

        <SpacerT s={2} />
        <Grid container alignItems={"flex-start"} direction={"row"} justifyContent={"flex-start"} className={styles.notToPrint}>
          {HISTORY_OPTIONS.map((option, idx) => { return (
            <Grid
              item
              className={`${styles.tabItem} ${option === selectedHistoryOption ? styles.selected : ''}`}
              onClick={() => { setSelectedHistoryOption(option); }}
              key={idx}
            >
              {option}
            </Grid>
          ); })}
        </Grid>
        <SpacerT s={1} />

        <Grid container justifyContent="center" className={styles.notToPrint}>
          <Grid item xs={12} className={`SalesHistoryTableInPropertyReport`}>
            <TableContainer className={`${styles.tableContainer} ${styles.noMinHeight}`}>
              <Table size={dimensions.isScreenBiggerSM ? "medium" : "small"} stickyHeader>
                {renderHistoryTableHeader()}

                <TableBody className={styles.tableBody}>
                  {(selectedHistoryOption === 'SOLD' ? salesHistory : selectedHistoryOption === 'RENTED' ? rentalsHistory : combinedHistory).map((it, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        {renderHistoryTableRow(it)}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>

              {!(selectedHistoryOption === 'SOLD' ? salesHistory : selectedHistoryOption === 'RENTED' ? rentalsHistory : combinedHistory).length && (
                <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
                  <Grid item className={styles.notDefined}>
                    Not found{selectedHistoryOption === 'SOLD' ? ' sales history' : selectedHistoryOption === 'RENTED' ? ' rentals history' : ''}
                  </Grid>
                </Grid>
              )}

            </TableContainer>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" className={styles.toPrintOnly}>
          <Grid item xs={12} className={`SalesHistoryTableInPropertyReport`}>
            <TableContainer className={`${styles.tableContainer} ${styles.noMinHeight}`}>
              <Table size={dimensions.isScreenBiggerSM ? "medium" : "small"} stickyHeader>
                {renderHistoryTableHeader()}

                <TableBody className={styles.tableBody}>
                  {combinedHistory.map((it, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        {renderHistoryTableRow(it)}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>

              {!combinedHistory.length && (
                <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
                  <Grid item className={styles.notDefined}>
                    Not found
                  </Grid>
                </Grid>
              )}
            </TableContainer>
          </Grid>
        </Grid>


        <div className="page-break" />
        <Grid container justifyContent="center">
          <Grid item className={styles.blockTitle}>
            Similar properties
          </Grid>
        </Grid>

        <SpacerT s={2} />
        <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
          <Grid item className={`${styles.label}`}>
            Sold within <span className={styles.value}>{config.PROPERTY_LISTING_YEARS} year{config.PROPERTY_LISTING_YEARS === 1 ? '' : 's'}</span> - Distance <span className={styles.value}>{config.PROPERTY_LISTING_RADIUS_KILOMETERS} km</span>
          </Grid>
        </Grid>

        <SpacerT s={2} />
        {renderListingTable(soldListingsArray, 'SOLD')}

        <div className={styles.rentedSpacingForPrint} />
        <SpacerT s={4} np />
        <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
          <Grid item className={`${styles.label}`}>
            Rented within <span className={styles.value}>{config.PROPERTY_LISTING_YEARS} year{config.PROPERTY_LISTING_YEARS === 1 ? '' : 's'}</span> - Distance <span className={styles.value}>{config.PROPERTY_LISTING_RADIUS_KILOMETERS} km</span>
          </Grid>
        </Grid>

        <SpacerT s={2} />
        {renderListingTable(rentListingsArray, 'RENTED')}

        <div className="page-break" />
        <Grid container justifyContent="center">
          <Grid item className={styles.blockTitle}>
            {propertyObj.address?.locality} insights
          </Grid>
        </Grid>

        {renderMarketPerformance(suburbPerformanceStatisticsHouse, suburbPerformanceStatisticsUnit)}

        <div className="page-break" />
        <Grid container justifyContent="center">
          <Grid item className={styles.blockTitle}>
            Schools
          </Grid>
        </Grid>

        {!!schoolsArray?.length && (<>
          <SpacerT s={1} />
          <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
            <Grid item className={`${styles.label}`}>
              Schools within a <span className={`${styles.value}`}>2 km</span> distance from
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
            <Grid item className={`${styles.label}`}>
              {propertyObj.address.fullAddress}
            </Grid>
          </Grid>

          <SpacerT s={3} />
          {schoolsArray.filter((s) => s.distance <= 2000 && s.school?.profile?.url).map((school, idx) => {
            return (<React.Fragment key={idx}>
              {(idx % 4 === 0 && idx !== 0) && (
                <div className="page-break" />
              )}
              {renderSchool(school, idx)}
            </React.Fragment>);
          })}
        </>)}

        {!!demographicsArray?.length && (<>
          <div className="page-break" />
          <SpacerT s={3} np />
          <Grid container justifyContent="center">
            <Grid item className={styles.blockTitle}>
              {propertyObj.address?.locality} demographics
            </Grid>
          </Grid>

          {!!population && (<>
            <SpacerT s={2} />
            <Grid container justifyContent="center" alignItems={'center'} alignContent={'center'}>
              <Grid item className={`${styles.label}`}>
                In the year <span className={styles.value}>{population.year}</span>, the population of <span className={styles.value}>{propertyObj.address?.locality}</span> was <span className={styles.value}>{addCommas(population.total)}</span> people.
              </Grid>
            </Grid>
          </>)}

          {renderDemCharts(demographicsArray)}
        </>)}

        <SpacerT s={7} />
        <Grid container justifyContent="space-between" spacing={4} className={`${styles.notToPrint}`}>
          <Grid item>
            <Button
              className={`${styles.actionButton}`}
              onClick={() => history.push('/property-reports')}
              color="secondary"
              variant="outlined"
              type="button"
              disableElevation
            >
              BACK
            </Button>
          </Grid>
          <Grid item>
            <ReactToPrint
              trigger={() => (
                <Button
                  className={`${styles.actionButton}`}
                  onClick={() => {}}
                  color={'primary'}
                  variant={'contained'}
                  type="button"
                  disableElevation
                  disabled={!isChartsBuilt}
                >
                  <PrintIcon className={styles.normalButtonIcon} /> PRINT
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Grid>
        </Grid>
      </div>
    </>);
  };

  const renderTitle = () => {
    return (<Grid container justifyContent="space-between" spacing={2} alignContent={'center'} alignItems={'center'}>
      <Grid item>Property report</Grid>
      <Grid item>
        <ReactToPrint
          trigger={() => (
            <IconButton
              className={`${styles.iconBtn}`}
              onClick={() => {}}
              disabled={!isChartsBuilt}
            >
              <PrintIcon />
            </IconButton>
          )}
          content={() => componentRef.current}
        />
      </Grid>
    </Grid>)
  };

  return <HeaderedPageLayout
    title={null}
    aboveCardTitle={renderTitle()}
    progress={null}
    backAction={() => history.push(`/property-reports`)}
    backCaption={'BACK'}
    nextHidden
    backHidden
    inProgress={isLoading}
    noProgressBar
    noHeader
    noContentPaddings
  >
    {(!isLoading && report?.id) && (<div id={'component-to-print'} className={styles.printArea} ref={componentRef}>
      {renderContent()}
    </div>)}
    {isLoading && (<CircularProgress size={80} className={styles.cardProgress} />)}

  </HeaderedPageLayout>;
};

export default PropertyReport;
