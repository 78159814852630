import React, {useEffect, useState} from 'react';
import { Dialog, Grid } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import ErrorIcon from '@mui/icons-material/Error';

import styles from './styles.module.scss';
import './styles.global.scss';
import { withDimensions } from '../../../common/HOC';
import { logOut } from '../../../user/sagas';


let timeToLogOutCommon = 5;
let timeoutID;
let intervalID;

const BlockedLogOutModal = ({ quickExit }) => {
  const dispatch = useDispatch();
  const dimensions = useSelector(state => state.dimensions);
  const [open, setOpen] = useState(false);
  const [wasOpened, setWasOpened] = useState(false);
  const [timeToLogOut, setTimeToLogOut] = useState(5);

  const scheduleLogOut = () => {
    if (timeToLogOutCommon > 0) {
      timeoutID = setTimeout(() => {
        setTimeToLogOut(timeToLogOutCommon - 1);
        timeToLogOutCommon = (timeToLogOutCommon - 1);
        scheduleLogOut();
        }, 1000);
    } else {
      doLogOut();
    }
  };

  const doLogOut = () => {
    dispatch(logOut({}));
    setOpen(false);
    setTimeToLogOut(5);
    timeToLogOutCommon = 5;
    setWasOpened(false);
  };

  useEffect(() => {
    intervalID = setInterval(async () => {
      let shouldLogOut = false;
      const shouldLogOutItem = localStorage.getItem('REFRESH-TOKEN-EXPIRED-OR-INCORRECT');
      if (!!shouldLogOutItem) {
        const object = JSON.parse(shouldLogOutItem);
        const value = object.value;
        const createDate = object.date;
        const isOutdated = Date.now() - createDate > 1000 * 10;
        shouldLogOut = !!value && !isOutdated;
        if (isOutdated) localStorage.removeItem('REFRESH-TOKEN-EXPIRED-OR-INCORRECT');
      }

      if (!wasOpened && shouldLogOut) {
        if (quickExit) {
          dispatch(logOut({ isSoft: false }));
        } else {
          localStorage.removeItem('REFRESH-TOKEN-EXPIRED-OR-INCORRECT');
          setWasOpened(true);
          setOpen(true);
          scheduleLogOut();
        }
      }

    }, 500)
    return () => {
      if (timeoutID) clearTimeout(timeoutID);
      if (intervalID) clearInterval(intervalID);
    };
  }, []); //eslint-disable-line

  return (
    <>
      <Dialog open={open} className={'BlockingLogOutDialog'} onClose={() => {}} fullScreen={dimensions.isScreenLessSM}>
        <MuiDialogContent>
          <Grid item className={styles.note}>
            <Grid container justifyContent="space-between">
              <Grid item className={styles.iconBlock}><ErrorIcon /></Grid>
              <Grid item className={styles.textBlock}>
                Your session has expired, please login.
                Redirecting to <span className={styles.link} onClick={doLogOut}>
                  login page
                </span> {timeToLogOut - 2 > 0 ? `in ${timeToLogOut - 2}` : 'now'}
              </Grid>
            </Grid>
          </Grid>
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

export default withDimensions(BlockedLogOutModal);

